<div class="w-full h-full">
    <div class="flex items-center justify-between space-x-3 pt-6 pl-8 pb-5 text-sm">
        <div class="flex items-center space-x-3 text-xs mb-2">
            <button class=" btn-light-orange  custom-tabbtn focus:outline-none py-3 px-8 uppercase font-semibold " [routerLink]="['/features/sku-biller-all-transaction']">
                Buy load
            </button>
            <button class="btn-light-orange custom-tabbtn focus:outline-none py-3 px-8 uppercase font-semibold " [routerLink]="['/features/sku-cash-in']">
                Cash In
            </button>
            <button class="btn-light-orange custom-tabbtn focus:outline-none py-3 px-8 uppercase font-semibold " [routerLink]="['/features/sku-pay-bills']">
                pay bills
            </button>
            <button class="btn-dark-orange custom-tabbtn focus:outline-none py-3 px-8 uppercase font-semibold " [routerLink]="['/features/sku-stores']">
                stores
            </button>
            <button class="btn-light-orange custom-tabbtn focus:outline-none py-3 px-8 uppercase font-semibold " [routerLink]="['/features/sku-redeem']">
                redeem
            </button>
        </div>
        <div class="flex items-center space-x-3 pr-10"  >
            <svg (click)="print()" class="cursor-pointer " xmlns="http://www.w3.org/2000/svg" width="20" height="21.433" viewBox="0 0 25 21.433" >
                <g id="iconfinder_icon-printer_211880" transform="translate(-32 -64)">
                <path id="Path_369" data-name="Path 369" d="M155.961,313.926h.541v-7.245a.7.7,0,0,0-.692-.681H144.692a.7.7,0,0,0-.692.681v7.245h11.961Z" transform="translate(-105.749 -228.493)"/>
                <g id="Group_77" data-name="Group 77" transform="translate(32 64)">
                    <path id="Path_370" data-name="Path 370" d="M53.869,67.74H52.15V64.82a.856.856,0,0,0-.887-.82H37.743a.856.856,0,0,0-.887.82V67.74H35.137A3.34,3.34,0,0,0,32,70.787V77.53c0,1.518,1.58,3.444,3.137,3.444h1.021c.274,0,.307,0,.307-.781v-2.4A1.788,1.788,0,0,1,38.3,76.006H50.8c.988,0,1.736,1.189,1.736,2.233v1.954c0,.781.006.781.279.781h1.044a3.294,3.294,0,0,0,3.137-3V70.787A3.33,3.33,0,0,0,53.869,67.74Zm-1.328,3.4a.893.893,0,1,1,.893-.893A.9.9,0,0,1,52.54,71.144Z" transform="translate(-32 -64)"/>
                </g>
                </g>
            </svg>
        </div>
    </div>
    <div class="px-8">
        <button class="custom-btn btn-light-red px-4 py-1 text-xs"(click)="toggleDisplay()">
            <span class="material-icons text-white mr-1">
                add_circle
            </span>
            Add Product
        </button>
        <form [hidden]="isDisplay" [formGroup]="storeItemsForm">
            <div class="grid grid-cols-12 gap-3 pl-10 pr-10 mt-6">
                <div class="col-span-12  mb-3">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-xs w-180-px font-semibold">
                            Product Description:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="productDesc" type="text" placeholder="Enter Description">
                    </div>
                </div>
                <div class="col-span-6  mb-3">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-xs w-180-px font-semibold">
                            Product Name:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic"  formControlName="productName" type="text" placeholder="Product Name">
                    </div>
                </div>
                <div class="col-span-6  mb-3">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-xs w-180-px font-semibold">
                            Product Code:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic"  formControlName="productCode" type="text" placeholder="Product Code">
                    </div>
                </div>
                <div class="col-span-4  mb-3">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-sxsm w-180-px font-semibold">
                            Price:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic"  formControlName="itemPrice" type="number" placeholder="Enter Amount">
                    </div>
                </div>
                <div class="col-span-4  mb-3">
                    <div class="card-input flex items-center text-xs py-2 w-full ">
                        <span class="text-xs w-106-px font-semibold">
                            Sale Start Date:
                        </span>
                        <!-- <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy"> -->
                        <mat-form-field color="accent" appearance="">
                            <input
                                class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"
                                type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker1" readonly
                                (click)="startDate.click()"  formControlName="salesStartDate">
                            <mat-datepicker-toggle matSuffix [for]="picker1" class="focus:outline-none">
                                <svg #startDate class="focus:outline-none" matDatepickerToggleIcon
                                    id="iconfinder_calendar-book-date-monthly_2203551"
                                    xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                    <path id="Path_371" data-name="Path 371"
                                        d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z"
                                        fill="#5f4d9c" />
                                    <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4"
                                        transform="translate(13 6.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4"
                                        transform="translate(13 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4"
                                        transform="translate(8 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4"
                                        transform="translate(3 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4"
                                        transform="translate(8 6.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4"
                                        transform="translate(3 6.936)" fill="#5f4d9c" />
                                </svg>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-span-4  mb-3">
                    <div class="card-input flex items-center text-xs py-2 w-full ">
                        <span class="text-xs w-106-px font-semibold">
                            Sale End Date:
                        </span>
                        <mat-form-field color="accent" appearance="">
                            <input
                                class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"
                                type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker2" readonly
                                (click)="startDate.click()"  formControlName="salesEndDate">
                            <mat-datepicker-toggle matSuffix [for]="picker2" class="focus:outline-none">
                                <svg #startDate class="focus:outline-none" matDatepickerToggleIcon
                                    id="iconfinder_calendar-book-date-monthly_2203551"
                                    xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                    <path id="Path_371" data-name="Path 371"
                                        d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z"
                                        fill="#5f4d9c" />
                                    <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4"
                                        transform="translate(13 6.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4"
                                        transform="translate(13 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4"
                                        transform="translate(8 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4"
                                        transform="translate(3 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4"
                                        transform="translate(8 6.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4"
                                        transform="translate(3 6.936)" fill="#5f4d9c" />
                                </svg>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-span-6  mb-3">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <div class="flex items-center space-x-3">
                        <span class="text-xs w-60-px font-semibold">
                            Category:
                        </span>
                            <div #t1="matMenuTrigger" #select2 [matMenuTriggerFor]="category_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                                <span *ngIf="!category_text" class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                                <span *ngIf="category_text" class="barlow text-black text-xs font-lg p-5 italic">{{category_text}}</span>
                                <span class="material-icons violet-text">
                                    arrow_drop_down
                                </span>
                            </div>
                            <mat-menu class="w-full sports-menu" #category_select="matMenu">
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs " >
                                    == Select an option ==
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs " (click)="setCategory('Food and Beverage','2')">
                                    Food and Beverage
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs " (click)="setCategory('Prepaid Load','3')">
                                    Prepaid Load
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs " (click)="setCategory('Shopping','4')">
                                    Shopping
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs " (click)="setCategory('Entertainment','5')">
                                    Entertainment
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs " (click)="setCategory('Travel','6')">
                                    Travel
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs " (click)="setCategory('Health','7')">
                                    Health
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs " (click)="setCategory('Gaming Pins','8')">
                                    Gaming Pins
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                </div>
                <div class="col-span-6  mb-3">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-xs w-110-px font-semibold">
                            Image URL:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="goodsImgUrl" type="text" placeholder="Enter URL (Please discord 'https://')">
                    </div>
                </div>
                <div class="col-span-6  mb-3">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <div class="flex items-center space-x-3">
                        <span class="text-xs w-70-px font-semibold">
                            Field Name:
                        </span>
                            <div #t1="matMenuTrigger" #select2 [matMenuTriggerFor]="field_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                                <span *ngIf="!fieldName_text" class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                                <span *ngIf="fieldName_text" class="barlow text-black text-xs font-lg p-5 italic">{{fieldName_text}}</span>
                                <span class="material-icons violet-text ">
                                    arrow_drop_down
                                </span>
                            </div>
                            <mat-menu class="w-full sports-menu" #field_select="matMenu">
                                <button mat-menu-item class="tracking-wide focus:outline-none text-xs italic" >
                                    == Select an option ==
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none text-xs italic" (click)="setFieldName('Phone Number','Phone Number')">
                                Phone Number
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none text-xs italic" (click)="setFieldName('Email Address','Email Address')">
                                Email Address
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                </div>
                <div class="col-span-12">
                    <div class="w-full h-full flex items-end justify-center mb-5">
                        <button  (click)="store()" class="uppercase custom-btn btn-dark-orange font-semibold text-xs px-6 py-2">
                            submit
                        </button>
                    </div>
                </div>
            </div>
        </form> 
    </div>
    <div class="bg-light-orange w-full flex items-center justify-between space-x-3 pr-3 pt-4 text-sm mt-10">
        <div class="flex items-center pl-6" [formGroup]="limitForm" >
            <div class="orange-text text-xs" >Show</div>
            <input class="orange-input focus:outline-none text-center mx-1 w-25-px  text-xs p-1" type="number" formControlName="limit" (change)="limitKey($event)" value="{{size}}">
            <div class="orange-text text-xs" >entries</div>
        </div>
        <div class="flex items-center space-x-3 pr-3" [formGroup]="searchForm" (keydown)="keyDownFunction($event)">
            <div class="orange-text text-xs" >Search:</div>
            <input class="orange-input focus:outline-none pl-1 text-xs p-1" type="text" placeHolder="Search"  formControlName="search" (keyup)="searchKey($event)">
        </div>
    </div>
    <div class=" bg-light-orange w-full  maxh-550-px px-3 overflow-x-auto w-table">
        <div class="data-table4 mt-3 barlow "  style="font-size: 10px;">
            <table class="w-full">
                <thead class="capitalize">
                    <tr>
                        <td class="text-left barlow p-3 font-bold" style=" border-top: 1px #CAC2BC solid; border-right: 2px #CAC2BC solid;" colspan="3">
                        PRODUCT
                        </td>
                    
                        <td class="text-left barlow p-3 font-bold" style=" border-top: 1px #CAC2BC solid; border-right: 2px #CAC2BC solid;" >
                        
                        </td> 
                        <td class="text-left barlow p-3 font-bold" style=" border-top: 1px #CAC2BC solid; border-right: 2px #CAC2BC solid;" >
                            
                        </td>
                        <td class="text-left barlow p-3 font-bold" style=" border-top: 1px #CAC2BC solid; border-right: 2px #CAC2BC solid;" colspan="3">
                            SALES
                        </td>
                        
                        <td class="text-left barlow p-3 font-bold" style=" border-top: 1px #CAC2BC solid" >
                        
                        </td> 
                    </tr>
                    <tr>
                        <th class="text-left barlow  pl-2 pt-3 pb-3">
                            NAME
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('name', sort_type)">
                        </th>
                        <th class="text-left barlow  pl-2 pt-3 pb-3">
                            CODE
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('code', sort_type)">
                        </th>
                        <th class="text-left barlow  pl-2 pt-3 pb-3">
                            STORE
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('store', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3" style=" border-top: none ">
                            CATEGORY
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('category', sort_type)">
                        </th>
                        <th class="text-left barlow  pl-2 pt-3 pb-3" style=" border-top: none " >
                            PRICE
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('price', sort_type)">
                        </th>
                        <th class="text-left barlow  pl-2 pt-3 pb-3">
                            START DATE
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('startDate', sort_type)">
                        </th>
                        <th class="text-left barlow  pl-2 pt-3 pb-3">
                            END DATE
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('endDate', sort_type)">
                        </th>
                        <th class="text-left barlow  pl-2 pt-3 pb-3">
                            IMAGE URL
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('imageUrl', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3 "  style=" border-top: none ">
                            ACTION
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('status', sort_type)">
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container >
                        <tr class="focus:outline-none" *ngFor="let storeItems of stores_list" >
                            <td >{{storeItems.productName | nullWithDefault}}</td>
                            <td >{{storeItems.productCode | nullWithDefault}}</td>
                            <td >{{storeItems.productDesc | nullWithDefault}}</td>
                            <td *ngIf="storeItems.category | nullWithDefault ">
                                <p *ngIf="storeItems.category==2 ">Food and Beverage</p>
                                <p *ngIf="storeItems.category==3">Prepaid Load</p>
                                <p *ngIf="storeItems.category==4">Shopping</p>
                                <p *ngIf="storeItems.category==5">Entertainment</p>
                                <p *ngIf="storeItems.category==6">Travel</p>
                                <p *ngIf="storeItems.category==7">Health</p>
                                <p *ngIf="storeItems.category==8">Gaming Pins</p>
                                <p *ngIf="storeItems.category==null">N/A</p>
                            </td>
                            <td >{{storeItems.itemPrice | nullWithDefault}}</td>
                            <td >{{storeItems.salesStartDate | nullWithDefault}}</td>
                            <td >{{storeItems.salesEndDate | nullWithDefault }}</td>
                            <td class="flex-items-center" >
                                <a *ngIf="storeItems.goodsImgUrl" class="link" (click)="openSite(storeItems.goodsImgUrl)">Link</a>
                                <a *ngIf="!storeItems.goodsImgUrl" > N / A</a>
                            </td>
                            <td class="text-xs">
                                <div class="flex items-center space-x-3  ">
                                    <ng-container *ngIf="storeItems" class="pl-2">
                                        <button class="power-btn2 active " *ngIf="storeItems.status==0" (click) = "disable(storeItems.uid, 1)">
                                            Enabled
                                        </button>
                                        <button class="power-btn2" *ngIf="storeItems.status==1"  (click) = "enable(storeItems.uid, 0)">
                                            Disabled
                                        </button>
                                    </ng-container>
                                    <button  class="action-btn-blue2 custom-btn " [routerLink]="['/features/biller-dashboard/sku-stores/sku-stores-edit/'+ storeItems.uid]">
                                        Edit
                                    </button> 
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
    <div class="bg-light-orange pb-4 col-span-full flex justify-between items-center  pt-4 ">
        <div class="orange-text text-xs pl-6" >
            Showing {{size}} of {{total}} entree
        </div>
        <div class="pr-6  flex items-center justify-end table-pagination text-xs" style="text-align: right;  ">
            <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled] ="this.current_page == 1" (click)="prevPage()">
                Previous
            </button>
            <ng-container *ngIf="current_page >  1" >
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active':current_page == 1 }"  style="white-space: nowrap;" (click) = "setPage(page)">
                    {{page}}
                </button>  
            </ng-container>
            <ng-container *ngIf="current_page >  1">
                <button class="page-btn sarabun-bold orange-box px-2 py-1"  style="white-space: nowrap;">
                    ...
                </button> 
            </ng-container>
            <ng-container *ngIf="current_page != total_pages">
                <button *ngFor="let item of pages_array | slice:0:2;let i=index;" class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active':current_page == item }" style="white-space: nowrap;" (click) = "setPage(item)">
                    {{item}}
                </button>  
            </ng-container>
            <ng-container *ngIf="total_pages > 2 && current_page+1 < total_pages">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;">
                    ...
                </button> 
            </ng-container>

            <ng-container *ngIf=" current_page == total_pages || total_pages > 2 && current_page+1 <total_pages">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active': current_page == total_pages}" style="white-space: nowrap;" (click) = "setPage(total_pages)">
                    {{total_pages}}
                </button> 
            </ng-container>

            <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled]="this.current_page == total_pages " (click)= "nextPage()">
                Next 
            </button> 
        </div>
    </div>
</div> 

<!-- <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "timer" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner> -->