<div class="w-full h-full">
    <div class="px-8 mt-10">
        <form  [formGroup]="updateForm">
            <div class="grid grid-cols-12 gap-3 pl-10 pr-10 mt-6">
                <div class="col-span-12  mb-3">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-xs w-180-px font-semibold">
                            Product Description:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="productDesc" type="text" placeholder="Enter Description">
                    </div>
                </div>
                <div class="col-span-6  mb-3">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-xs w-180-px font-semibold">
                            Product Name:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic"  formControlName="productName" type="text" placeholder="Product Name">
                    </div>
                </div>
                <div class="col-span-6  mb-3">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-xs w-180-px font-semibold">
                            Product Code:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic"  formControlName="productCode" type="text" placeholder="Product Code">
                    </div>
                </div>
                <div class="col-span-4  mb-3">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-sxsm w-180-px font-semibold">
                            Price:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic"  formControlName="itemPrice" type="number" placeholder="Enter Amount">
                    </div>
                </div>
                <div class="col-span-4  mb-3">
                    <div class="card-input flex items-center text-xs py-2 w-full ">
                        <span class="text-xs w-106-px font-semibold">
                            Sale Start Date:
                        </span>
                        <!-- <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy"> -->
                        <mat-form-field color="accent" appearance="">
                            <input
                                class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"
                                type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker1" readonly
                                (click)="startDate.click()"  formControlName="salesStartDate">
                            <mat-datepicker-toggle matSuffix [for]="picker1" class="focus:outline-none">
                                <svg #startDate class="focus:outline-none" matDatepickerToggleIcon
                                    id="iconfinder_calendar-book-date-monthly_2203551"
                                    xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                    <path id="Path_371" data-name="Path 371"
                                        d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z"
                                        fill="#5f4d9c" />
                                    <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4"
                                        transform="translate(13 6.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4"
                                        transform="translate(13 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4"
                                        transform="translate(8 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4"
                                        transform="translate(3 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4"
                                        transform="translate(8 6.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4"
                                        transform="translate(3 6.936)" fill="#5f4d9c" />
                                </svg>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-span-4  mb-3">
                    <div class="card-input flex items-center text-xs py-2 w-full ">
                        <span class="text-xs w-106-px font-semibold">
                            Sale End Date:
                        </span>
                        <mat-form-field color="accent" appearance="">
                            <input
                                class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"
                                type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker2" readonly
                                (click)="startDate.click()"  formControlName="salesEndDate">
                            <mat-datepicker-toggle matSuffix [for]="picker2" class="focus:outline-none">
                                <svg #startDate class="focus:outline-none" matDatepickerToggleIcon
                                    id="iconfinder_calendar-book-date-monthly_2203551"
                                    xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                    <path id="Path_371" data-name="Path 371"
                                        d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z"
                                        fill="#5f4d9c" />
                                    <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4"
                                        transform="translate(13 6.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4"
                                        transform="translate(13 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4"
                                        transform="translate(8 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4"
                                        transform="translate(3 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4"
                                        transform="translate(8 6.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4"
                                        transform="translate(3 6.936)" fill="#5f4d9c" />
                                </svg>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-span-6  mb-3">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <div class="flex items-center space-x-3">
                        <span class="text-xs w-60-px font-semibold">
                            Category:
                        </span>
                            <div #t1="matMenuTrigger" #select2 [matMenuTriggerFor]="category_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                                <span *ngIf="!category_text" class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                                <span *ngIf="category_text" class="barlow text-black text-xs font-lg p-5 italic">{{category_text}}</span>
                                <span class="material-icons violet-text">
                                    arrow_drop_down
                                </span>
                            </div>
                            <mat-menu class="w-full sports-menu" #category_select="matMenu">
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs " >
                                    == Select an option ==
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs " (click)="setCategory('Food and Beverage','2')">
                                    Food and Beverage
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs " (click)="setCategory('Prepaid Load','3')">
                                    Prepaid Load
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs " (click)="setCategory('Shopping','4')">
                                    Shopping
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs " (click)="setCategory('Entertainment','5')">
                                    Entertainment
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs " (click)="setCategory('Travel','6')">
                                    Travel
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs " (click)="setCategory('Health','7')">
                                    Health
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs " (click)="setCategory('Gaming Pins','8')">
                                    Gaming Pins
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                </div>
                <div class="col-span-6  mb-3">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-xs w-80-px font-semibold">
                            Image URL:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="goodsImgUrl" type="text" placeholder="Enter URL (Please discord 'https://')">
                    </div>
                </div>
                <div class="col-span-6  mb-3">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <div class="flex items-center space-x-3">
                        <span class="text-xs w-70-px font-semibold">
                            Field Name:
                        </span>
                            <div #t1="matMenuTrigger" #select2 [matMenuTriggerFor]="field_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                                <span *ngIf="!fieldName_text" class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                                <span *ngIf="fieldName_text" class="barlow text-black text-xs font-lg p-5 italic">{{fieldName_text}}</span>
                                <span class="material-icons violet-text ">
                                    arrow_drop_down
                                </span>
                            </div>
                            <mat-menu class="w-full sports-menu" #field_select="matMenu">
                                <button mat-menu-item class="tracking-wide focus:outline-none text-xs italic" >
                                    == Select an option ==
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none text-xs italic" (click)="setFieldName('Phone Number','Phone Number')">
                                Phone Number
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none text-xs italic" (click)="setFieldName('Email Address','Email Address')">
                                Email Address
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                </div>
                <div class="col-span-12">
                    <div class="w-full h-full flex items-end justify-center mb-5">
                        <button  (click)="update()"  class="uppercase custom-btn btn-dark-orange font-semibold text-xs px-6 py-2">
                            submit
                        </button>
                    </div>
                </div>
            </div>
        </form> 
    </div>
</div> 
