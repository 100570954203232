import { Component, OnInit,Inject } from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from'@angular/material/dialog';


@Component({
  selector: 'app-mat-confirm-dialog',
  templateUrl: './mat-confirm-dialog.component.html',
  styleUrls: ['./mat-confirm-dialog.component.scss']
})
export class MatConfirmDialogComponent implements OnInit {
  hideButtons: boolean;

  constructor(@Inject(MAT_DIALOG_DATA)public data,
  public dialogRef:MatDialogRef<MatConfirmDialogComponent>
  ) { 
    this.hideButtons = data.hideButtons || false;
  }

  ngOnInit(): void {
  }
  closeDialog(){
    this.dialogRef.close(false);
  }

}
