<div class="login-bg h-screen w-screen overflow-hidden">
    <div class="h-full w-800-px mx-auto">
        <div class="items-center rounded-3xl flex justify-between h-full">
            <img src="assets/images/changepass.svg" class="w-350-px h-full object-contain" alt="">
            <form  [formGroup]="Form">
                <div class="w-330-px rounded-3xl bg-white minh-300-px p-5 fade-in"  >
                    <div class="text-center orange-text font-semibold text-3xl mb-5">
                        Change Password
                    </div>
                    <!-- input -->
                    <div class="flex flex-col space-y-5 mb-3">
                        <div class="flex flex-col">
                            <div class="violet-text">
                                New Password
                            </div>
                            <div class="flex items-center bg-lavender rounded-3xl h-35-px w-full px-3">
                                <span class="mr-3">
                                    <svg id="Group_56" data-name="Group 56" xmlns="http://www.w3.org/2000/svg" width="13.608" height="16.817" viewBox="0 0 13.608 16.817">
                                        <path id="Path_270" data-name="Path 270" d="M1567.495,1050.6a1.42,1.42,0,0,0-.983-1.352,5.2,5.2,0,0,0-.713-.18c0-.057,0-.132,0-.208-.021-.864.028-1.734-.077-2.589a4.581,4.581,0,0,0-3.694-3.588c-.313-.068-.634-.107-.952-.16h-.764a.818.818,0,0,1-.11.023,5.377,5.377,0,0,0-3.042,1.206,3.952,3.952,0,0,0-1.544,2.876c-.041.754-.02,1.51-.027,2.265,0,.064,0,.129,0,.2-.143.019-.271.027-.392.055a1.5,1.5,0,0,0-1.3,1.468q-.005,3.6,0,7.207a1.589,1.589,0,0,0,1.759,1.512q5.041.005,10.083-.005a2.188,2.188,0,0,0,.79-.149,1.41,1.41,0,0,0,.97-1.391v-5.877C1567.5,1051.479,1567.5,1051.042,1567.495,1050.6Zm-10.2-3.172c0-.273.015-.546.042-.817.006-.054.012-.108.018-.163a2.13,2.13,0,0,1,.117-.482c.02-.055.041-.109.064-.162a2.514,2.514,0,0,1,.2-.355,2.7,2.7,0,0,1,.162-.22c.029-.036.059-.071.09-.105a3.34,3.34,0,0,1,1.033-.763c.089-.043.18-.082.273-.119s.188-.068.285-.1.2-.054.295-.075.2-.038.3-.052c.051-.006.1-.012.154-.016s.106-.008.16-.01q.153-.007.308,0a3.771,3.771,0,0,1,2.244.8,2.981,2.981,0,0,1,.507.526,2.742,2.742,0,0,1,.3.492,2.761,2.761,0,0,1,.107.268c.018.054.034.108.049.163.021.079.039.159.054.24,0,.012,0,.025.006.037a2.731,2.731,0,0,1,.036.432q0,.5,0,.994t0,.993v.155h-6.775c-.009-.009-.017-.013-.016-.018,0-.109,0-.218,0-.328v0c0-.383-.013-.766-.011-1.149C1557.293,1047.542,1557.293,1047.487,1557.294,1047.432Zm8.489,10.446h-.214q-4.871,0-9.741,0c-.193,0-.243-.041-.243-.208.007-2.3,0-4.608,0-6.913v-.183h10.194Z" transform="translate(-1553.891 -1042.526)" fill="#5f4d9c"/>
                                        <path id="Path_271" data-name="Path 271" d="M1567.989,1070.482c0-.017,0-.033,0-.049a.692.692,0,0,0-.332-.726,1.239,1.239,0,0,1-.37-1.622,1.742,1.742,0,0,1,1.7-.848,1.619,1.619,0,0,1,1.5,1.109,1.338,1.338,0,0,1-.678,1.525.215.215,0,0,0-.126.214c.007.257.006.514,0,.771a.852.852,0,0,1-1.692,0C1567.986,1070.734,1567.989,1070.608,1567.989,1070.482Z" transform="translate(-1562.032 -1057.718)" fill="#5f4d9c"/>
                                    </svg>
                                </span>
                                <input type="password" class="focus:outline-none no-bg italic" formControlName="password" placeholder="Password">
                            </div>
                        </div>
                        <div class="flex flex-col">
                            <div class="violet-text">
                                Confirm New Password
                            </div>
                            <div class="flex items-center bg-lavender rounded-3xl h-35-px w-full px-3">
                                <span class="mr-3">
                                    <svg id="Group_56" data-name="Group 56" xmlns="http://www.w3.org/2000/svg" width="13.608" height="16.817" viewBox="0 0 13.608 16.817">
                                        <path id="Path_270" data-name="Path 270" d="M1567.495,1050.6a1.42,1.42,0,0,0-.983-1.352,5.2,5.2,0,0,0-.713-.18c0-.057,0-.132,0-.208-.021-.864.028-1.734-.077-2.589a4.581,4.581,0,0,0-3.694-3.588c-.313-.068-.634-.107-.952-.16h-.764a.818.818,0,0,1-.11.023,5.377,5.377,0,0,0-3.042,1.206,3.952,3.952,0,0,0-1.544,2.876c-.041.754-.02,1.51-.027,2.265,0,.064,0,.129,0,.2-.143.019-.271.027-.392.055a1.5,1.5,0,0,0-1.3,1.468q-.005,3.6,0,7.207a1.589,1.589,0,0,0,1.759,1.512q5.041.005,10.083-.005a2.188,2.188,0,0,0,.79-.149,1.41,1.41,0,0,0,.97-1.391v-5.877C1567.5,1051.479,1567.5,1051.042,1567.495,1050.6Zm-10.2-3.172c0-.273.015-.546.042-.817.006-.054.012-.108.018-.163a2.13,2.13,0,0,1,.117-.482c.02-.055.041-.109.064-.162a2.514,2.514,0,0,1,.2-.355,2.7,2.7,0,0,1,.162-.22c.029-.036.059-.071.09-.105a3.34,3.34,0,0,1,1.033-.763c.089-.043.18-.082.273-.119s.188-.068.285-.1.2-.054.295-.075.2-.038.3-.052c.051-.006.1-.012.154-.016s.106-.008.16-.01q.153-.007.308,0a3.771,3.771,0,0,1,2.244.8,2.981,2.981,0,0,1,.507.526,2.742,2.742,0,0,1,.3.492,2.761,2.761,0,0,1,.107.268c.018.054.034.108.049.163.021.079.039.159.054.24,0,.012,0,.025.006.037a2.731,2.731,0,0,1,.036.432q0,.5,0,.994t0,.993v.155h-6.775c-.009-.009-.017-.013-.016-.018,0-.109,0-.218,0-.328v0c0-.383-.013-.766-.011-1.149C1557.293,1047.542,1557.293,1047.487,1557.294,1047.432Zm8.489,10.446h-.214q-4.871,0-9.741,0c-.193,0-.243-.041-.243-.208.007-2.3,0-4.608,0-6.913v-.183h10.194Z" transform="translate(-1553.891 -1042.526)" fill="#5f4d9c"/>
                                        <path id="Path_271" data-name="Path 271" d="M1567.989,1070.482c0-.017,0-.033,0-.049a.692.692,0,0,0-.332-.726,1.239,1.239,0,0,1-.37-1.622,1.742,1.742,0,0,1,1.7-.848,1.619,1.619,0,0,1,1.5,1.109,1.338,1.338,0,0,1-.678,1.525.215.215,0,0,0-.126.214c.007.257.006.514,0,.771a.852.852,0,0,1-1.692,0C1567.986,1070.734,1567.989,1070.608,1567.989,1070.482Z" transform="translate(-1562.032 -1057.718)" fill="#5f4d9c"/>
                                    </svg>
                                </span>
                                <input type="password" class="focus:outline-none no-bg italic" formControlName="confirmPassword" placeholder="confirmPassword">
                            </div>
                        </div>
                    </div>
                    <button class="custom-btn btn-orange-round w-220-px btn-orange-round rounded-3xl py-2 mt-6 text-lg font-bold mx-auto flex justify-center focus:outline-none" (click)="changePass()">
                        SUBMIT
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>