<div class="w-full h-full">
    <div class="flex items-center justify-between space-x-3 pt-6 pl-8 pb-5 text-sm">
        <div class="flex items-center space-x-3 text-xs mb-2">
            <button class=" btn-light-orange custom-tabbtn focus:outline-none py-3 px-8 uppercase font-semibold " [routerLink]="['/cash-in-tranaction-logs']">
                cash in transaction
            </button>
            <button class="btn-light-orange custom-tabbtn focus:outline-none py-3 px-8 uppercase font-semibold " [routerLink]="['/cash-in-tranaction']">
                cash in transaction logs
            </button>
            <button class="btn-dark-orange custom-tabbtn focus:outline-none py-3 px-8 uppercase font-semibold " [routerLink]="['/cash-out-tranaction']">
                cash out transaction
            </button>
        </div>
        <div class="flex items-center space-x-3 pr-10"  >
            <svg (click)="print()" class="cursor-pointer " xmlns="http://www.w3.org/2000/svg" width="20" height="21.433" viewBox="0 0 25 21.433" >
                <g id="iconfinder_icon-printer_211880" transform="translate(-32 -64)">
                <path id="Path_369" data-name="Path 369" d="M155.961,313.926h.541v-7.245a.7.7,0,0,0-.692-.681H144.692a.7.7,0,0,0-.692.681v7.245h11.961Z" transform="translate(-105.749 -228.493)"/>
                <g id="Group_77" data-name="Group 77" transform="translate(32 64)">
                    <path id="Path_370" data-name="Path 370" d="M53.869,67.74H52.15V64.82a.856.856,0,0,0-.887-.82H37.743a.856.856,0,0,0-.887.82V67.74H35.137A3.34,3.34,0,0,0,32,70.787V77.53c0,1.518,1.58,3.444,3.137,3.444h1.021c.274,0,.307,0,.307-.781v-2.4A1.788,1.788,0,0,1,38.3,76.006H50.8c.988,0,1.736,1.189,1.736,2.233v1.954c0,.781.006.781.279.781h1.044a3.294,3.294,0,0,0,3.137-3V70.787A3.33,3.33,0,0,0,53.869,67.74Zm-1.328,3.4a.893.893,0,1,1,.893-.893A.9.9,0,0,1,52.54,71.144Z" transform="translate(-32 -64)"/>
                </g>
                </g>
            </svg>
        </div>
    </div>
    <div class="w-900-px mx-auto">
        <form [formGroup]="form">
        <div class="grid grid-cols-8 gap-3">
            <div class="col-span-4">
                <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                    <span class="text-xs w-130-px font-semibold ">
                        Transaction ID:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="transactionId" type="text" placeholder="Transaction ID">
                </div>
                <div class="card-input flex items-center text-xs py-2 w-3/4 mb-3">
                    <span class="text-xs w-106-px font-semibold ">
                        Date From:
                    </span>
                    <!-- <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy"> -->
                    <mat-form-field color="accent" appearance="">
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker1" readonly (click)="startDate.click()" formControlName="dateFrom" >
                        <mat-datepicker-toggle matSuffix [for]="picker1" class="focus:outline-none" >
                            <svg #startDate class="focus:outline-none" matDatepickerToggleIcon id="iconfinder_calendar-book-date-monthly_2203551" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                <path id="Path_371" data-name="Path 371" d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z" fill="#5f4d9c"/>
                                <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4" transform="translate(13 6.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4" transform="translate(13 11.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4" transform="translate(8 11.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4" transform="translate(3 11.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4" transform="translate(8 6.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4" transform="translate(3 6.936)" fill="#5f4d9c"/>
                            </svg>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="card-input flex items-center text-xs py-2 w-3/4 mb-3">
                    <div class="flex items-center ">
                    <span class="text-xs w-95-px font-semibold " >
                         Bank Name:
                    </span>
                        <div #t2="matMenuTrigger" #select2 [matMenuTriggerFor]="status_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                            <span *ngIf="!bankName_text"class="barlow text-black text-xs font-lg p-5 italic">All</span>
                            <span *ngIf="bankName_text"class="barlow text-black text-xs font-lg p-5 italic">{{bankName_text}}</span>
                            <span class="material-icons violet-text ">
                                arrow_drop_down
                            </span>
                        </div>
                        <mat-menu class="w-full sports-menu" #status_select="matMenu">
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" >
                                All 
                           </button>
                           <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBankType('  Banco de Oro (BDO)','BDO')">
                              Banco de Oro (BDO)
                           </button>
                           <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs"  (click)="setBankType(' Asia United Bank (AUB)','AUB')">
                               Asia United Bank (AUB)
                           </button>
                           <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBankType('UnionBank','UnionBank')">
                               UnionBank
                           </button>
                           <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBankType('Security Bank','Security Bank')">
                               Security Bank
                           </button>
                           <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBankType('Metropolitan Bank & Trust Co. (MetroBank)','MetroBank')">
                               Metropolitan Bank & Trust Co. (MetroBank)
                           </button>
                           <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBankType('Landbank of the Philippines','Landbank')">
                               Landbank of the Philippines
                           </button>
                           <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBankType(' Philippine National Bank (PNB)','PNB')">
                               Philippine National Bank (PNB)
                           </button>
                           <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBankType('Philippine Savings Bank (PSBank)','PSBank')">
                               Philippine Savings Bank (PSBank)
                           </button>
                           <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBankType('Rizal Commercial Banking Corporation (RCBC)','RCBC')">
                               Rizal Commercial Banking Corporation (RCBC)
                           </button>
                           <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBankType(' East West Banking Corporation','East West Bank')">
                               East West Banking Corporation
                           </button>                        
                           <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBankType(' United Coconut Planters Bank (UCPB)','UPCB')">
                                United Coconut Planters Bank (UCPB)
                           </button>
                           <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBankType('Gcash','Gcash')">
                               Gcash
                           </button>
                           <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBankType('Paymaya','Paymaya')">
                               Paymaya
                           </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <div class="col-span-4">
                <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                    <span class="text-xs w-141-px font-semibold ">
                        Phone Number:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="phoneNumber" type="text" placeholder="Phone Number">
                </div>
                <div class="card-input flex items-center text-xs py-2 w-3/4 mb-3">
                    <span class="text-xs w-106-px font-semibold ">
                        Date To:
                    </span>
                    <mat-form-field color="accent" appearance="">
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker2" readonly (click)="startDate.click()" formControlName="dateTo" >
                        <mat-datepicker-toggle matSuffix [for]="picker2" class="focus:outline-none" >
                            <svg #startDate class="focus:outline-none" matDatepickerToggleIcon id="iconfinder_calendar-book-date-monthly_2203551" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                <path id="Path_371" data-name="Path 371" d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z" fill="#5f4d9c"/>
                                <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4" transform="translate(13 6.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4" transform="translate(13 11.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4" transform="translate(8 11.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4" transform="translate(3 11.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4" transform="translate(8 6.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4" transform="translate(3 6.936)" fill="#5f4d9c"/>
                            </svg>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </div>
        
                <div class="card-input flex items-center text-xs py-2 w-3/4 mb-3">
                    <div class="flex items-center ">
                        <span class="text-xs w-95-px font-semibold ">
                            Status:
                        </span>
                        <div #t3="matMenuTrigger" #select3 [matMenuTriggerFor]="payment_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                            <span *ngIf="!status_text"class="barlow text-black text-xs font-lg p-5 italic">All</span>
                            <span *ngIf="status_text"class="barlow text-black text-xs font-lg p-5 italic">{{status_text}}</span>
                            <span class="material-icons violet-text ">
                                arrow_drop_down
                            </span>
                        </div>
                        <mat-menu class="w-full sports-menu" #payment_select="matMenu">
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" >
                                All
                             </button>
                             <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setStatusType('Verified','1')">
                                Verified
                             </button>
                             <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setStatusType('Approved','2')">
                                Approved
                             </button>
                             <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setStatusType('Pending','0')">
                                Pending
                             </button>
                             <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setStatusType('Rejected','3')">
                               Rejected
                             </button>
                        </mat-menu>
                    </div>
                </div>
            </div>

        </div>
    </form>
        <div class="w-full flex items-center justify-center mt-20 mb-4">
            <button (click)="submit()"  class="uppercase custom-btn btn-dark-orange font-semibold text-xs px-6 py-2">
                submit
            </button>
        </div>
    </div>
    <div class="bg-light-orange w-full flex items-center justify-between space-x-3 pr-3 pt-4 text-sm mt-10">
        <ng-container *ngIf="!itemLists_filter && !itemLists_filter2" >
            <div class="flex items-center pl-6" [formGroup]="limitForm">
                <div class="orange-text text-xs" >Show</div>
                <input class="orange-input focus:outline-none text-center mx-1 w-25-px  text-xs p-1" type="number" formControlName="limit" (change)="limitKey($event)" value="{{size}}" >
                <div class="orange-text text-xs" >entries</div>
            </div>
        </ng-container>
        <ng-container *ngIf="itemLists_filter && !itemLists_filter2 && total_filter > 9" >
            <div class="flex items-center  pl-6" [formGroup]="limitForm_filter">
                <div class="orange-text text-xs" >Show</div>
                <input class="orange-input focus:outline-none text-center mx-1 w-25-px  text-xs p-1" type="number" formControlName="limit" (change)="limitKey_filter($event)" value="{{size_filter}}" >
                <div class="orange-text text-xs" >entries</div>
            </div>
        </ng-container>
        <ng-container *ngIf="itemLists_filter && !itemLists_filter2 && total_filter < 10 " >
            <div class="flex items-center  pl-6" [formGroup]="limitForm_filter">
                <div class="orange-text text-xs" >Show</div>
                <input class="orange-input focus:outline-none text-center mx-1 w-25-px  text-xs p-1" type="number" formControlName="limit" (change)="limitKey_filter($event)" value="{{total_filter}}" >
                <div class="orange-text text-xs" >entries</div>
            </div>
        </ng-container>
        <ng-container *ngIf="itemLists_filter2 && !itemLists_filter && total_filter2 > 9 ">
            <div class="flex items-center  pl-6" [formGroup]="limitForm_filter2">
                <div class="orange-text text-xs" >Show</div>
                <input class="orange-input focus:outline-none text-center mx-1 w-25-px  text-xs p-1" type="number" formControlName="limit" (change)="limitKey_filter2($event)" value="{{size_filter2}}" >
                <div class="orange-text text-xs" >entries</div>
            </div>
        </ng-container>
        <ng-container *ngIf="itemLists_filter2 && !itemLists_filter && total_filter2 < 10 " >
            <div class="flex items-center  pl-6" [formGroup]="limitForm_filter2">
                <div class="orange-text text-xs" >Show</div>
                <input class="orange-input focus:outline-none text-center mx-1 w-25-px  text-xs p-1" type="number" formControlName="limit" (change)="limitKey_filter2($event)" value="{{total_filter2}}" >
                <div class="orange-text text-xs" >entries</div>
            </div>
        </ng-container>
        <ng-container   *ngIf="itemLists && !itemLists_filter2" >
            <div class="flex items-center space-x-3 pr-3" [formGroup]="searchForm" (keydown)="keyDownFunction($event)">
                <div class="orange-text text-xs" >Search:</div>
                <input class="orange-input focus:outline-none pl-1 text-xs p-1" type="text" placeHolder="Search"  formControlName="search" (keyup)="searchKey($event)">
            </div>
        </ng-container>
        <ng-container  *ngIf="itemLists_filter" >
            <div class="flex items-center space-x-3 pr-3" [formGroup]="searchForm_filter" (keydown)="keyDownFunction($event)">
                <div class="orange-text text-xs" >Search:</div>
                <input class="orange-input focus:outline-none pl-1 text-xs p-1" type="text" placeHolder="Search"  formControlName="search" (keyup)="searchKey_filter($event)">
            </div>
        </ng-container>
        <ng-container  *ngIf="itemLists_filter2" >
            <div class="flex items-center space-x-3 pr-3" [formGroup]="searchForm_filter2" (keydown)="keyDownFunction($event)">
                <div class="orange-text text-xs" >Search:</div>
                <input class="orange-input focus:outline-none pl-1 text-xs p-1" type="text" placeHolder="Search"  formControlName="search" (keyup)="searchKey_filter2($event)">
            </div>
        </ng-container>
    </div>
    <div class=" bg-light-orange w-full  maxh-550-px px-3 overflow-x-auto w-table">
        <div class="data-table4 mt-3 barlow text-xs "   style="font-size: 10px;">
            <table class="w-full" >
                <thead class="capitalize">
                    <tr>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            TRANSACTION ID
                            <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('transactionId', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3 ">
                            FULL NAME
                            <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('firstName', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            PHONE NUMBER
                            <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('phoneNumber', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            BANK NAME
                            <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('bankName', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            ACCOUNT NUMBER
                            <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('accountNumber', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            ACCOUNT NAME
                            <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('accountName', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            AMOUNT
                            <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('amount', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            EMAIL
                            <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('email', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            STATUS
                            <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('status', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            DATE
                            <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('date', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            IMAGE
                            <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('imageUrl', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            TRANSACTION IMAGE
                            <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('transactionImageUrl', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            VERIFIED STATUS
                            <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('verifiedStatus', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            VERIFIED BY
                            <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('verifiedBy', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            DATE VERIFIED
                            <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('dateVerified', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            APPROVE STATUS
                            <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('approvedStatus', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            APPROVE BY
                            <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('approvedBy', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3 ">
                            DATE APPROVE
                            <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('dateApproved', sort_type)">
                        </th>
                        <!-- <th class="text-left barlow pl-2 pt-3 pb-3">
                            ACTION 
                        </th> -->
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="!itemLists_filter && !itemLists_filter2">
                        <tr class="focus:outline-none" *ngFor="let item of itemLists" >
                            <td>{{ item.transactionId | nullWithDefault }}</td>
                            <td >{{ item.fullName | nullWithDefault | titlecase}}</td>
                            <td >{{ item.phoneNumber | nullWithDefault }}</td>
                            <td >{{ item.bankName | nullWithDefault }}</td>
                            <td >{{ item.accountNumber | nullWithDefault }}</td>
                            <td >{{ item.accountName | nullWithDefault }}</td>
                            <td >{{ item.amount | nullWithDefault }}</td>
                            <td >{{ item.email | nullWithDefault }}</td>
                            <td >{{ item.status | nullWithDefault }}</td>
                            <td >{{ item.date | nullWithDefault }}</td>
                            <td class="flex-items-center" >
                                <a *ngIf="item.imageUrl" class="link" (click)="openSite(item.imageUrl)">Link</a>
                                <a *ngIf="!item.imageUrl" >N / A</a>
                            </td>
                            <td class="flex-items-center" >
                                <a *ngIf="item.transactionImageUrl" class="link" (click)="openSite2(item.transactionImageUrl)">Link</a>
                                <a *ngIf="!item.transactionImageUrl" >N / A</a>
                            </td>
                            <td >{{ item.verifiedStatus | nullWithDefault }}</td>
                            <td >{{ item.verifiedBy | nullWithDefault }}</td>
                            <td >{{ item.dateVerified | nullWithDefault }}</td>
                            <td >{{ item.approvedStatus | nullWithDefault }}</td>
                            <td >{{ item.approvedBy | nullWithDefault }}</td>
                            <td >{{ item.dateApproved | nullWithDefault }}</td>
                            <!-- <td  style="font-size: 10px;">
                                <div class="flex items-center space-x-2">
                                    <button class="power-btn3 active pl-2" (click)="verify(item.uid)">
                                        Verify
                                    </button>
                                    <button class="action-btn-blue4 custom-btn pl-2" (click)="cancel(item.uid)">
                                        Cancel
                                    </button>
                                </div>
                            </td> -->
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="itemLists_filter">
                        <tr class="focus:outline-none" *ngFor="let item of itemLists_filter" >
                            <td>{{ item.transactionId | nullWithDefault }}</td>
                            <td >{{ item.fullName | nullWithDefault | titlecase}}</td>
                            <td >{{ item.phoneNumber | nullWithDefault }}</td>
                            <td >{{ item.bankName | nullWithDefault }}</td>
                            <td >{{ item.accountNumber | nullWithDefault }}</td>
                            <td >{{ item.accountName | nullWithDefault }}</td>
                            <td >{{ item.amount | nullWithDefault }}</td>
                            <td >{{ item.email | nullWithDefault }}</td>
                            <td >{{ item.status | nullWithDefault }}</td>
                            <td >{{ item.date | nullWithDefault }}</td>
                            <td class="flex-items-center" >
                                <a *ngIf="item.imageUrl" class="link" (click)="openSite(item.imageUrl)">Link</a>
                                <a *ngIf="!item.imageUrl" >N / A</a>
                            </td>
                            <td class="flex-items-center" >
                                <a *ngIf="item.transactionImageUrl" class="link" (click)="openSite2(item.transactionImageUrl)">Link</a>
                                <a *ngIf="!item.transactionImageUrl" >N / A</a>
                            </td>
                            <td >{{ item.verifiedStatus | nullWithDefault }}</td>
                            <td >{{ item.verifiedBy | nullWithDefault }}</td>
                            <td >{{ item.dateVerified | nullWithDefault }}</td>
                            <td >{{ item.approvedStatus | nullWithDefault }}</td>
                            <td >{{ item.approvedBy | nullWithDefault }}</td>
                            <td >{{ item.dateApproved | nullWithDefault }}</td>
                            <!-- <td  style="font-size: 10px;">
                                <div class="flex items-center space-x-2">
                                    <button class="power-btn3 active pl-2" (click)="verify(item.uid)">
                                        Verify
                                    </button>
                                    <button class="action-btn-blue4 custom-btn pl-2" (click)="cancel(item.uid)">
                                        Cancel
                                    </button>
                                </div>
                            </td> -->
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="itemLists_filter2">
                        <tr class="focus:outline-none" *ngFor="let item of itemLists_filter2" >
                            <td>{{ item.transactionId | nullWithDefault }}</td>
                            <td >{{ item.fullName | nullWithDefault | titlecase}}</td>
                            <td >{{ item.phoneNumber | nullWithDefault }}</td>
                            <td >{{ item.bankName | nullWithDefault }}</td>
                            <td >{{ item.accountNumber | nullWithDefault }}</td>
                            <td >{{ item.accountName | nullWithDefault }}</td>
                            <td >{{ item.amount | nullWithDefault }}</td>
                            <td >{{ item.email | nullWithDefault }}</td>
                            <td >{{ item.status | nullWithDefault }}</td>
                            <td >{{ item.date | nullWithDefault }}</td>
                            <td class="flex-items-center" >
                                <a *ngIf="item.imageUrl" class="link" (click)="openSite(item.imageUrl)">Link</a>
                                <a *ngIf="!item.imageUrl" >N / A</a>
                            </td>
                            <td class="flex-items-center" >
                                <a *ngIf="item.transactionImageUrl" class="link" (click)="openSite2(item.transactionImageUrl)">Link</a>
                                <a *ngIf="!item.transactionImageUrl" >N / A</a>
                            </td>
                            <td >{{ item.verifiedStatus | nullWithDefault }}</td>
                            <td >{{ item.verifiedBy | nullWithDefault }}</td>
                            <td >{{ item.dateVerified | nullWithDefault }}</td>
                            <td >{{ item.approvedStatus | nullWithDefault }}</td>
                            <td >{{ item.approvedBy | nullWithDefault }}</td>
                            <td >{{ item.dateApproved | nullWithDefault }}</td>
                            <!-- <td  style="font-size: 10px;">
                                <div class="flex items-center space-x-2">
                                    <button class="power-btn3 active pl-2" (click)="verify(item.uid)">
                                        Verify
                                    </button>
                                    <button class="action-btn-blue4 custom-btn pl-2" (click)="cancel(item.uid)">
                                        Cancel
                                    </button>
                                </div>
                            </td> -->
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
    <div class="bg-light-orange pb-4 col-span-full flex justify-between items-center  pt-4 ">
        <ng-container *ngIf="!itemLists_filter && !itemLists_filter2" >
            <div class="orange-text text-xs pl-6" >
                Showing {{size}} of {{total}} entree
            </div>
        </ng-container>
        <ng-container *ngIf="itemLists_filter && !itemLists_filter2 && total_filter > 9 " >
            <div class="orange-text text-xs pl-6" >
                Showing {{size_filter}} of {{total_filter}} entree
           </div>
        </ng-container>
        <ng-container *ngIf="itemLists_filter && !itemLists_filter2 && total_filter < 10 " >
            <div class="orange-text text-xs pl-6" >
                Showing {{total_filter}} of {{total_filter}} entree
            </div>
        </ng-container>
        <ng-container *ngIf="itemLists_filter2 && !itemLists_filter && total_filter2 > 9 " >
            <div class="orange-text text-xs pl-6" >
                Showing {{size_filter2}} of {{total_filter2}} entree
            </div>
        </ng-container>
        <ng-container *ngIf="itemLists_filter2 && !itemLists_filter && total_filter2 < 10 " >
            <div class="orange-text text-xs pl-6" >
                Showing {{total_filter2}} of {{total_filter2}} entree
            </div>
        </ng-container>
        <div class="pr-6  flex items-center justify-end table-pagination text-xs" style="text-align: right;  ">
            <ng-container  *ngIf="option==1">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled] ="this.current_page == 1" (click)="prevPage()">
                    Previous
                </button>
            </ng-container>
            <ng-container  *ngIf=" option==2 ">
               <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled] ="this.current_page_filter == 1" (click)="prevPage()">
                    Previous
                </button>
            </ng-container>
            <ng-container  *ngIf=" option==3 ">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled] ="this.current_page_filter2== 1" (click)="prevPage()">
                    Previous
                </button>
            </ng-container>
            <ng-container  *ngIf=" option==1 ">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active':current_page == 1 }"  style="white-space: nowrap;" (click) = "setPage(page)">
                    {{page}}
                </button>  
            </ng-container>
            <ng-container  *ngIf="option==2 && total_filter > 10 ">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active':current_page_filter == 1 }"  style="white-space: nowrap;" (click) = "setPage(page_filter)">
                    {{page_filter}}
                </button> 
            </ng-container>
            <ng-container  *ngIf="option==3 && total_filter2 > 10 ">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active':current_page_filter2 == 1 }"  style="white-space: nowrap;" (click) = "setPage(page_filter2)">
                    {{page_filter2}}
                </button> 
            </ng-container>
            <ng-container *ngIf="!itemLists_filter && !itemLists_filter2 && current_page >  2">
                <button class="page-btn sarabun-bold orange-box px-2 py-1"  style="white-space: nowrap;">
                    ...
               </button> 
            </ng-container>
            <ng-container *ngIf="itemLists_filter && current_page_filter >  2">
                <button class="page-btn sarabun-bold orange-box px-2 py-1"  style="white-space: nowrap;">
                    ...
                </button> 
            </ng-container>
            <ng-container *ngIf="itemLists_filter2 && current_page_filter2 >  2">
                <button class="page-btn sarabun-bold orange-box px-2 py-1"  style="white-space: nowrap;">
                    ...
                </button> 
            </ng-container>
            <ng-container  *ngIf="option==1">
                <button *ngFor="let item of pages_array | slice:1:3;let i=index;" class=" py-1 page-btn sarabun-bold orange-box px-2" [ngClass]="{'active':current_page == item }" style="white-space: nowrap;" (click) = "setPage(item)">
                    {{item}}
                </button>                  
            </ng-container>
            <ng-container  *ngIf="option==2 && total_filter > 30">
                <button *ngFor="let item_filter of pages_array_filter | slice:1:3;let i=index;" class=" py-1 page-btn sarabun-bold orange-box px-2" [ngClass]="{'active':current_page_filter == item_filter}" style="white-space: nowrap;" (click) = "setPage(item_filter)">
                    {{item_filter}}
                </button>  
            </ng-container>
            <ng-container  *ngIf="option==3 && total_filter2 > 30">
                <button *ngFor="let item_filter2 of pages_array_filter2 | slice:1:3;let i=index;" class=" py-1 page-btn sarabun-bold orange-box px-2" [ngClass]="{'active':current_page_filter2 == item_filter2}" style="white-space: nowrap;" (click) = "setPage(item_filter2)">
                    {{item_filter2}}
                </button>  
            </ng-container>
            <ng-container *ngIf="itemLists && !itemLists_filter && !itemLists_filter2 && total_pages > 4 && current_page+1 < total_pages">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;">
                    ...
                </button> 
            </ng-container>
            <ng-container *ngIf="!itemLists && itemLists_filter && !itemLists_filter2 && total_pages_filter > 4 && current_page_filter+1 < total_pages_filter">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;">
                    ...
                </button> 
            </ng-container>
            <ng-container *ngIf="!itemLists && !itemLists_filter && itemLists_filter2 && total_pages_filter2 > 4 && current_page_filter2+1 < total_pages_filter2">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;">
                    ...
                </button> 
            </ng-container>
            <ng-container  *ngIf="option==1 && total_pages > 4 && current_page+1 < total_pages">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active': current_page == item}" style="white-space: nowrap;" (click) = "setPage(total_pages)">
                    {{total_pages}}
                </button> 
            </ng-container>
            <ng-container  *ngIf="option==2  && total_pages_filter > 4 && current_page_filter+1 < total_pages_filter">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active': current_page_filter == item_filter}" style="white-space: nowrap;" (click) = "setPage(total_pages_filter)">
                    {{total_pages_filter}}
                </button> 
            </ng-container>
            <ng-container  *ngIf="option==2 && total_filter < 30">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active': current_page_filter == total_pages_filter}" style="white-space: nowrap;" (click) = "setPage(total_pages_filter)">
                   {{total_pages_filter}}
                </button> 
            </ng-container>
            <ng-container  *ngIf="option==3  && total_pages_filter2 > 4 && current_page_filter2+1 < total_pages_filter2">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active': current_page_filter2 == item_filter2}" style="white-space: nowrap;" (click) = "setPage(total_pages_filter2)">
                    {{total_pages_filter2}}
                </button> 
            </ng-container>
            <ng-container  *ngIf="option==3 && total_filter2 < 30">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active': current_page_filter2 == total_pages_filter2}" style="white-space: nowrap;" (click) = "setPage(total_pages_filter2)">
                    {{total_pages_filter2}}
                </button> 
            </ng-container>
            <ng-container *ngIf="option==1">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled]="this.current_page == total_pages " (click)= "nextPage()">
                    Next 
                </button> 
            </ng-container>
            <ng-container *ngIf="option==2">
               <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled]="this.current_page_filter == total_pages_filter " (click)= "nextPage()">
                    Next 
                </button> 
            </ng-container>
            <ng-container *ngIf="option==3">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled]="this.current_page_filter2 == total_pages_filter2 " (click)= "nextPage()">
                    Next 
                </button> 
            </ng-container>
        </div>
    </div>
</div> 

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "timer" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>