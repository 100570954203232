
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { RequestsService } from 'src/app/services/http/requests.service';
import { FormBuilder, FormGroup , Validators } from '@angular/forms';
import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/app/lib/environment';
import { ToastrService } from 'ngx-toastr';


// import { NgxSpinnerService } from "ngx-spinner";

import { Urls } from 'src/app/lib/urls';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  show = true;
  logged_in='';
  login_password_type: string = 'password';
  show_forgot = false;
  public loginForm: FormGroup;
  public sendForm: FormGroup;

  constructor(
    private route: Router,
    public userService: UserService,
    public request: RequestsService,
    private fb : FormBuilder,
    private toastr: ToastrService,
    private http: HttpClient,
    private env: environment
  ) {
    this.loginForm = this.fb.group({
      username: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      password: [
        '',
        Validators.compose([ 
          Validators.required
        ])
      ],
    });
    this.sendForm = this.fb.group({
      username: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      redirectUrl: [
        //         'http://localhost:4200/send-pass',
                'https://megapay-portal-2.web.app/send-pass',
                // 'https://megapay-portal2-prod.web.app/send-pass',
                Validators.compose([ 
                  Validators.required
                ])
              ],
    });
  }

  ngOnInit(): void {
    console.log("LOGIN MODULE")
  }

  showLoginPassword(bool) {
    this.show = bool;
    //console.log('boolean', bool);
    if (bool) {
      this.login_password_type = 'text';
    } else {
      this.login_password_type = 'password';
    }
  }
  forgotPass(){
    this.show_forgot = !this.show_forgot
  }

  login() {
    let httpOptions = {
        headers: new HttpHeaders({
            'Authorization': 'Basic ' + btoa(this.loginForm.controls.username.value + ':' + this.loginForm.controls.password.value)
        })
    };
  
    this.http.get(this.env.getUrl(Urls.login), httpOptions).subscribe(
        (response: any) => {
            if (response && response.result && response.result.sessionId) {
                localStorage.setItem('sessionId', response.result.sessionId);
                localStorage.setItem('username', this.loginForm.controls.username.value);
                localStorage.setItem('loginTime', response.result.loginTime);

                // Store firstname and accounttype
                localStorage.setItem('firstName', response.result.firstName);
                localStorage.setItem('lastName', response.result.lastName);
                localStorage.setItem('accountType', response.result.accountType);
                localStorage.setItem('uid', response.result.uid);                
                
                // Encode and store the password
                const encodedPassword = btoa(this.loginForm.controls.password.value);
                localStorage.setItem('encodedPassword', encodedPassword);
                
                console.log('Login response:', response);
                console.log('Session ID:', response.result.sessionId);
                this.toastr.success('You have logged in successfully!', 'Success!');
                this.route.navigate(['']);
            } else {
                this.toastr.error('Session ID not received', 'Error!');
            }
        },
        (error) => {
            console.error('Error during login:', error);
            this.toastr.error(error?.error?.message || 'Something went wrong!', 'Error!');
        }
    );
  }
  

  send(){
    this.request.post(Urls.sendForgotPasswordEmail,{
        username:this.sendForm.controls.username.value,
        redirectUrl:this.sendForm.controls.redirectUrl.value,
    }).then(response =>{
        if(response['status']==200){
            this.toastr.success(response['message']+'!','Success!');
            this.route.navigate(['/send-pass'])
        }
        else{
            this.toastr.error(response['error']['message']+ '!','Something went Wrong')
        }
    })
}
}