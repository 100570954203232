import { Injectable } from '@angular/core';
import { EnvService } from 'src/app/lib/env.service';
import { Urls } from 'src/app/lib/urls';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  private unseen_count = new BehaviorSubject(0);

  constructor(private env: EnvService) { }

  unseen_count_function(count: number) {
      this.unseen_count.next(count);
  }

  getCountObservable(): Observable<number> {
      return this.unseen_count.asObservable();
  }

  getAds() {
      return new Promise(resolve => {
          let sessionId = localStorage.getItem('sessionId');
          if (sessionId) {
              let httpOptions = {
                  headers: new HttpHeaders({
                      'Content-Type': 'application/json',
                      'Accept': 'application/json',
                      'Authorization': 'Session ' + sessionId
                  })
              };
              let url = this.env.getUrl(Urls.ads);
              this.env.http.get(url, httpOptions).subscribe(
                  data => resolve(data),
                  ex => resolve({ error: 1, message: ex, request: 'ads' })
              );
          } else {
              resolve({ error: 1, message: 'Session ID not found', request: 'ads' });
          }
      });
  }

  getAnnouncements() {
      return new Promise(resolve => {
          let sessionId = localStorage.getItem('sessionId');
          if (sessionId) {
              let httpOptions = {
                  headers: new HttpHeaders({
                      'Content-Type': 'application/json',
                      'Accept': 'application/json',
                      'Authorization': 'Session ' + sessionId
                  })
              };
              let url = this.env.getUrl(Urls.announcements);
              this.env.http.get(url, httpOptions).subscribe(
                  data => resolve(data),
                  ex => resolve({ error: 1, message: ex, request: 'announcements' })
              );
          } else {
              resolve({ error: 1, message: 'Session ID not found', request: 'announcements' });
          }
      });
  }
}