<div class="w-full h-full ">
    <div class="flex items-center justify-between space-x-3 pt-6 pl-8 pb-5 text-sm">
        <div class="flex items-center space-x-3 text-xs mb-2">
            <button class="btn-light-orange custom-tabbtn focus:outline-none py-3 px-6 uppercase font-semibold " [routerLink]="['/features/ads-dashboard-screen']">
                Screen
            </button>
            <button class="btn-dark-orange custom-tabbtn focus:outline-none py-3 px-6 uppercase font-semibold " [routerLink]="['/features/ads-dashboard-link']">
               Link
            </button>
        </div>
        <div class="flex items-center space-x-3 pr-10"  >
            <svg (click)="print()" class="cursor-pointer " xmlns="http://www.w3.org/2000/svg" width="20" height="21.433" viewBox="0 0 25 21.433" >
                <g id="iconfinder_icon-printer_211880" transform="translate(-32 -64)">
                <path id="Path_369" data-name="Path 369" d="M155.961,313.926h.541v-7.245a.7.7,0,0,0-.692-.681H144.692a.7.7,0,0,0-.692.681v7.245h11.961Z" transform="translate(-105.749 -228.493)"/>
                <g id="Group_77" data-name="Group 77" transform="translate(32 64)">
                    <path id="Path_370" data-name="Path 370" d="M53.869,67.74H52.15V64.82a.856.856,0,0,0-.887-.82H37.743a.856.856,0,0,0-.887.82V67.74H35.137A3.34,3.34,0,0,0,32,70.787V77.53c0,1.518,1.58,3.444,3.137,3.444h1.021c.274,0,.307,0,.307-.781v-2.4A1.788,1.788,0,0,1,38.3,76.006H50.8c.988,0,1.736,1.189,1.736,2.233v1.954c0,.781.006.781.279.781h1.044a3.294,3.294,0,0,0,3.137-3V70.787A3.33,3.33,0,0,0,53.869,67.74Zm-1.328,3.4a.893.893,0,1,1,.893-.893A.9.9,0,0,1,52.54,71.144Z" transform="translate(-32 -64)"/>
                </g>
                </g>
            </svg>
        </div>
    </div>
    <form *ngIf="adsLinkForm" [formGroup]="adsLinkForm">
        <div class="px-8">
            <div class="grid grid-cols-12 gap-3">
                <div class="col-span-8 space-y-5">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-xs w-110-px font-semibold">
                            Title:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="title" type="text" placeholder="Title">
                    </div>
                    <div class="card-input flex items-start text-xs py-2 w-full">
                        <span class="text-xs w-110-px font-semibold">
                            Details:
                        </span>
                        <textarea class="placeholder-black focus:outline-none w-full h-70-px bg-gray-100 rounded-md pl-3 resize-none italic" formControlName="description" placeholder="Enter Details ( Maximum of 65 characters )" maxlength="65" name="" id="" cols="30" rows="10"></textarea>
                    </div>
                    <div class="flex flex-col items-start text-xs pl-5 mb-3">
                        <div class="flex items-center space-x-3 font-semibold">
                            <span>Image: </span>
                            <input type='file' class="hidden" (change)="onSelectFile($event)" #featuredImg>
                            <button class="browse-btn px-4 py-2 focus:outline-none" (click)="featuredImg.click()">
                                Browse...
                            </button>
                        </div>
                        <img [src]="featured_img" class="w-full h-200-px object-contain" *ngIf="featured_img"> <br/>
                    </div>
                </div>
                <div class="col-span-4">
                    <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                        <span class="text-xs w-106-px font-semibold">
                            Date From:
                        </span>
                        <mat-form-field color="accent" appearance="">
                            <input
                                formControlName="dateFrom"
                                class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"
                                type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker1" readonly
                                (click)="startDate.click()">
                            <mat-datepicker-toggle matSuffix [for]="picker1" class="focus:outline-none">
                                <svg #startDate class="focus:outline-none" matDatepickerToggleIcon
                                    id="iconfinder_calendar-book-date-monthly_2203551"
                                    xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                    <path id="Path_371" data-name="Path 371"
                                        d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z"
                                        fill="#5f4d9c" />
                                    <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4"
                                        transform="translate(13 6.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4"
                                        transform="translate(13 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4"
                                        transform="translate(8 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4"
                                        transform="translate(3 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4"
                                        transform="translate(8 6.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4"
                                        transform="translate(3 6.936)" fill="#5f4d9c" />
                                </svg>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                        <span class="text-xs w-106-px font-semibold">
                            Date To:
                        </span>
                        <mat-form-field color="accent" appearance="">
                            <input
                                formControlName="dateTo"
                                class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"
                                type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker2" readonly
                                (click)="startDate.click()">
                            <mat-datepicker-toggle matSuffix [for]="picker2" class="focus:outline-none">
                                <svg #startDate class="focus:outline-none" matDatepickerToggleIcon
                                    id="iconfinder_calendar-book-date-monthly_2203551"
                                    xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                    <path id="Path_371" data-name="Path 371"
                                        d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z"
                                        fill="#5f4d9c" />
                                    <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4"
                                        transform="translate(13 6.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4"
                                        transform="translate(13 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4"
                                        transform="translate(8 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4"
                                        transform="translate(3 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4"
                                        transform="translate(8 6.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4"
                                        transform="translate(3 6.936)" fill="#5f4d9c" />
                                </svg>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-xs w-110-px font-semibold">
                            Link:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="link" type="text" placeholder="Enter URL">
                    </div>
                    <div class="card-input flex items-center text-xs py-2 w-full mt-3">
                        <span class="text-xs w-280-px font-semibold">
                            Duration (In Seconds):
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="duration" type="text" placeholder="Duration">
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="w-full flex items-center justify-center mt-6 mb-4">
        <button (click)="store()" class="uppercase custom-btn btn-dark-orange font-semibold text-xs px-6 py-2">
            Upload
        </button>
    </div>
    <div class="bg-light-orange w-full flex items-center justify-between space-x-3 pr-3 pt-4 text-sm mt-6">
        <div class="flex items-center pl-6" [formGroup]="limitForm" >
            <div class="orange-text text-xs" >Show</div>
            <input class="orange-input focus:outline-none text-center mx-1 w-25-px  text-xs p-1" type="number" formControlName="limit" (change)="limitKey($event)" value="{{size}}">
            <div class="orange-text text-xs" >entries</div>
        </div>
        <div class="flex items-center space-x-3 pr-3" [formGroup]="searchForm" (keydown)="keyDownFunction($event)">
            <div class="orange-text text-xs" >Search:</div>
            <input class="orange-input focus:outline-none pl-1 text-xs p-1" type="text" placeHolder="Search"  formControlName="search" (keyup)="searchKey($event)">
        </div>
     </div>

     <div class=" bg-light-orange w-full  maxh-550-px px-3 overflow-x-auto w-table">
        <div class="data-table4 mt-3 barlow " style="font-size: 10px;">
            <table class="w-full" >
                <thead class="capitalize">
                    <tr>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            TITLE
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('title', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            IMAGE
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('imageUrl', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            SCREEN / LINK
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('screen', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            STATUS
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('status', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            PRIORITY
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('priority', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            DATE / TIME
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('dateTime', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            DETAILS
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('details', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            ACTION
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('status', sort_type)">
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container >
                        <tr class="focus:outline-none" *ngFor="let ads_lists of ads_list" >
                            <td >{{ads_lists.title}}</td>
                            <td><a href="{{ads_lists.imageUrl}}"><img src="{{ads_lists.imageUrl}}" style="width:100px"></a></td>
                            <td *ngIf="ads_lists.adsType==2">
                                <p *ngIf="ads_lists.screen==1">Cash-In</p>
                                <p *ngIf="ads_lists.screen==7">Buy Load</p>
                                <p *ngIf="ads_lists.screen==8">Pay Bills</p>
                                <p *ngIf="ads_lists.screen==10">Redeem</p>
                                <p *ngIf="ads_lists.screen==11">Purchase</p>
                                <p *ngIf="ads_lists.screen==null">N/A</p>
                            </td>
                            <td *ngIf="ads_lists.adsType==1">
                                <p *ngIf="ads_lists.link"><a href="{{ads_lists.link}}">{{ads_lists.link}}</a></p>
                            </td>
                            <td>
                                <p *ngIf="ads_lists.status==0">Active</p>
                                <p *ngIf="ads_lists.status!=0">Inactive</p>
                            </td>
                            <td >{{ads_lists.priority}}</td>
                            <td >{{ads_lists.createDate}}</td>
                            <td >{{ads_lists.description}}</td>
                            <td class="text-xs">
                                <div class="flex items-center space-x-3  ">
                                    <ng-container *ngIf="ads_lists">
                                        <button class="power-btn2 " *ngIf="ads_lists.status==0" (click) = "changeStatus(ads_lists.uid, 1)">
                                            Disabled
                                        </button>
                                        <button class="power-btn2 active " *ngIf="ads_lists.status==1"  (click) = "changeStatus(ads_lists.uid, 0)">
                                            Enabled
                                        </button>
                                    </ng-container>
                                    <!-- <button *ngIf="ads_lists.adsType==2" (click)="setheader(3)" class="action-btn-blue2 custom-btn " [routerLink]="['/features/edit-ads-screen/'+ads_lists.uid]">
                                        Edit
                                    </button> -->
                                    <button class="action-btn-blue2 custom-btn" [routerLink]="['/features/edit-ads-link/'+ads_lists.uid]">
                                        Edit
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
         </div>
    </div>
    <div class="bg-light-orange pb-4 col-span-full flex justify-between items-center  pt-4 ">
        <div class="orange-text text-xs pl-6" >
            Showing {{size}} of {{total}} entree
        </div>
        <div class="pr-6  flex items-center justify-end table-pagination text-xs" style="text-align: right; ">
            <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled] ="this.current_page == 1" (click)="prevPage()">
                Previous
            </button>
            <ng-container >
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active':current_page == 1 }"  style="white-space: nowrap;" (click) = "setPage(page)">
                    {{page}}
                </button>  
            </ng-container>
            <ng-container *ngIf="current_page >  3">
                <button class="page-btn sarabun-bold orange-box px-2 py-1"  style="white-space: nowrap;">
                    ...
                </button> 
            </ng-container>
            <ng-container *ngFor="let item of pages_array | slice:1:3;let i=index;">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active':current_page == item }" style="white-space: nowrap;" (click) = "setPage(item)">
                    {{item}}
                </button>  
            </ng-container>
            <ng-container *ngIf="total_pages > 4 && current_page+1 < total_pages">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;">
                    ...
                </button> 
            </ng-container>
            <ng-container *ngIf="total_pages > 4 && current_page+1 <total_pages">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active': current_page == item}" style="white-space: nowrap;" (click) = "setPage(total_pages)">
                    {{total_pages}}
                </button> 
            </ng-container>
            <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled]="this.current_page == total_pages " (click)= "nextPage()">
                Next 
            </button> 
        </div>
    </div>
<!-- </div> 
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "timer" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner> -->