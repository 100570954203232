import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { RequestsService } from 'src/app/services/http/requests.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { DebounceService } from 'src/app/services/debounce.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'src/app/services/dialog.service';
import { Urls } from 'src/app/lib/urls';


@Component({
  selector: 'app-features-announcement-edit',
  templateUrl: './features-announcement-edit.component.html',
  styleUrls: ['./features-announcement-edit.component.scss']
})
export class FeaturesAnnouncementEditComponent implements OnInit {
  
  public updateForm: FormGroup;
  isLoading: boolean;
  selected_redirection:string | Blob;
  uid:any;
  
  //image
  img: any;
  imageFile: {link: string, file: any, name: string};
  file:any;

  

  constructor(
    private modal: NgxSmartModalService,
    private route: ActivatedRoute,
    private router: Router,
    private fb : FormBuilder,
    private request: RequestsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private dialogService:DialogService,
    public userService: UserService,
    public debounce: DebounceService,
    
  ) { 
    this.initializeForm()
  }

  ngOnInit(): void {
    this.uid = this.route.snapshot.paramMap.get('id');
    this.get_announcement();
   
  }

  onSelectFile(event) { 
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      let link=event.srcElement.files[0];
      let name=event.srcElement.files[0].name;
      reader.onload = (event: any) => {
        this.imageFile = {
            link: event.target.result,
            file: link,
            name: name
        };
        this.img = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]); 
    }
    console.log(event.srcElement.files[0].name);
  }

 
  initializeForm(){
    this.updateForm = this.fb.group({
      webViewUrl: [''],
      description: [''],
      dateFrom: [''],
      dateTo: [''],
      img: [''],
    });
  }

  get_announcement(){
    this.request.put(Urls.announcements + '/' + this.uid,{}).then(response => { 
      this.updateForm = this.fb.group({
        webViewUrl:(response['result']['webViewUrl']),
        description: (response['result']['description']),
        dateFrom:(response['result']['dateFrom']),
        dateTo:(response['result']['dateTo']),
        
      });
      this.img=response['result']['imageUrl'];
    }) 
  }

  update(){
    if(!this.imageFile){
      this.spinner.show();
      this.updateForm.markAllAsTouched();
      let formData = new FormData();
      var url="";
      if(this.updateForm.controls.webViewUrl.value.includes('https://')){
        url=this.updateForm.controls.webViewUrl.value.replace("https://", "www.");
      }else{
        url=this.updateForm.controls.webViewUrl.value;
      }
      
      formData.append('webViewUrl', url);
      formData.append('dateFrom', moment.utc(this.updateForm.controls.dateFrom.value).format('YYYY-MM-DDTHH:mm:ss'));
      formData.append('dateTo', moment.utc(this.updateForm.controls.dateTo.value).format('YYYY-MM-DDTHH:mm:ss'));
      formData.append('description', this.updateForm.controls.description.value);
      this.request.put(Urls.announcements + '/' + this.uid, formData).then(response => {
        console.log(response);
        if(response['status']==200){
          this.toastr.success(response['message']+'!', 'Success!');
          this.get_announcement();
          this.router.navigate(['//features/announcement']);
        }else{
          // console.log(response['error']);
          this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
        }
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      });
      this.updateForm.reset();
    }else {
      this.spinner.show();
      this.updateForm.markAllAsTouched();
      let formData = new FormData();
      var url="";
      if(this.updateForm.controls.webViewUrl.value.includes('https://')){
        url=this.updateForm.controls.webViewUrl.value.replace("https://", "www.");
      }else{
        url=this.updateForm.controls.webViewUrl.value;
      }
      
      formData.append('webViewUrl', url);
      formData.append('dateFrom', moment.utc(this.updateForm.controls.dateFrom.value).format('YYYY-MM-DDTHH:mm:ss'));
      formData.append('dateTo', moment.utc(this.updateForm.controls.dateTo.value).format('YYYY-MM-DDTHH:mm:ss'));
      formData.append('description', this.updateForm.controls.description.value);
      formData.append('image',this.imageFile.file)
      this.request.put(Urls.announcements + '/' + this.uid, formData).then(response => {
        console.log(response);
        if(response['status']==200){
          this.toastr.success(response['message']+'!', 'Success!');
          this.get_announcement();
          this.router.navigate(['/features/announcement']);
        }else{
          // console.log(response['error']);
          this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
        }
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      });
      this.updateForm.reset();
    }
   
  } 
}
