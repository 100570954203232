<div class="w-full h-full">
    <div class="px-8">
        <form  [formGroup]="updateForm">
            <div class="grid grid-cols-12 gap-3 pl-10 pr-10 mt-6">
                <div class="col-span-6 mb-3">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-xs w-110-px font-semibold">
                            Biller Name:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="billerName" type="text" placeholder="Biller Name">
                    </div>
                </div>
                <div class="col-span-6 mb-3">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <div class="flex items-center space-x-3">
                        <span class="text-xs w-120-px font-semibold">
                            Biller Category:
                        </span>
                            <div #t1="matMenuTrigger" #select1 [matMenuTriggerFor]="billerCategory_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                                <span *ngIf="!billerCategory_text" class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                                <span *ngIf="billerCategory_text" class="barlow text-black text-xs font-lg p-5 italic">{{billerCategory_text}}</span>
                                <span class="material-icons violet-text ">
                                    arrow_drop_down
                                </span>
                            </div>
                            <mat-menu class="w-full sports-menu" #billerCategory_select="matMenu">
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs"  >
                                    == Select an option ==
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBillerCategory('Telecom','2')">
                                    Telecom
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBillerCategory('Credit Card','3')">
                                    Credit Card
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBillerCategory('Cable and Internet','4')">
                                    Cable and Internet
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBillerCategory('Government','5')">
                                    Government
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBillerCategory('Loans','6')">
                                    Loans
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBillerCategory('Others','7')">
                                    Others
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBillerCategory('Travel','8')">
                                    Travel
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBillerCategory('Transpo','9')">
                                    Transpo
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBillerCategory('Shopping','10')">
                                    Shopping
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBillerCategory('Power','11')">
                                    Power
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBillerCategory('Water','12')">
                                    Water
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                </div>
                <div class="col-span-12 ">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <div class="text-xs w-140-px font-semibold">
                            Biller Description:
                        </div>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="billerDescription" type="text" placeholder="Enter Description">
                    </div>
                </div>
                
                <div class="col-span-4">
                    <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                        <span class="text-xs w-190-px font-semibold">
                            First Field Name:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="firstFieldName" type="text" placeholder="Field Name">
                    </div>
                    <div class="card-input flex items-center text-xs py-2 w-full mt-6">
                        <span class="text-xs w-190-px font-semibold">
                            Second Field Name:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="secondFieldName" type="text" placeholder="Field Name">
                    </div>
                </div>
                <div class="col-span-4 mb-3">
                    <div class="card-input flex items-center text-xs py-2 w-full ">
                        <div class="flex items-center space-x-3">
                        <span class="text-xs w-100-px font-semibold">
                            First Field Format:
                        </span>
                            <div #2="matMenuTrigger" #select2 [matMenuTriggerFor]="firstField_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                                <span *ngIf="!firstFieldFormat_text"  class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                                <span *ngIf="firstFieldFormat_text" class="barlow text-black text-xs font-lg p-5 italic">{{firstFieldFormat_text}}</span>
                                <span class="material-icons violet-text pr-2 ">
                                    arrow_drop_down
                                </span>
                            </div>
                            <mat-menu class="w-full sports-menu" #firstField_select="matMenu">
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" >
                                    == Select an option ==
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setFirstFieldFormat('Numeric','1')">
                                    Numeric
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setFirstFieldFormat('Alphanumeric','2')">
                                Alphanumeric
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                    <div class="card-input flex items-center text-xs py-2 w-full mt-6">
                        <div class="flex items-center ">
                        <span class="text-xs w-116-px font-semibold">
                            Second Field Format:
                        </span>
                            <div #t2="matMenuTrigger" #select2 [matMenuTriggerFor]="secondField_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                                <span *ngIf="!secondFieldFormat_text"  class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                                <span *ngIf="secondFieldFormat_text" class="barlow text-black text-xs font-lg p-5 italic">{{secondFieldFormat_text}}</span>
                                <span class="material-icons violet-text pr-3">
                                    arrow_drop_down
                                </span>
                            </div>
                            <mat-menu class="w-full sports-menu" #secondField_select="matMenu">
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" >
                                    == Select an option ==
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setSecondFieldFormat('Numeric','1')">
                                Numeric
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setSecondFieldFormat('Alphanumeric','2')">
                                Alphanumeric
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                </div>

                <div class="col-span-4 mb-1">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-xs w-300-px font-semibold">
                            First Field Max Length:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="firstFieldWidth" type="number" placeholder="Length">
                    </div>
                    <div class="card-input flex items-center text-xs py-2 w-full mt-6">
                        <span class="text-xs w-300-px font-semibold">
                            Second Field Max Length:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="secondFieldWidth"  type="number" placeholder="Length">
                    </div>
                </div>
                <div class="col-span-12 ">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-xs w-140-px font-semibold">
                            Convenience Fee:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="convenienceFee" type="number" placeholder="Enter Convenience Fee">
                    </div>
                </div>
                <div class="col-span-12 mt-5">
                    <div class="w-full h-full flex items-end justify-center ">
                        <button (click)="update()" class="uppercase custom-btn btn-dark-orange font-semibold text-xs px-6 py-2">
                            submit
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div> 