<div class="h-full w-full">
    <div class="flex items-center justify-between space-x-3 pt-6 pl-8 pb-5 text-sm">
        <div class="flex items-center space-x-3 text-xs mb-2">
            <button class="custom-tabbtn focus:outline-none py-3 px-8 uppercase font-semibold {{ selected_tab == 1 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(1)">
                Buy load
            </button>
            <button class="custom-tabbtn focus:outline-none py-3 px-8  uppercase font-semibold {{ selected_tab == 2 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(2)">
                pay bills
            </button>
            <button class="custom-tabbtn focus:outline-none py-3 px-8  uppercase font-semibold {{ selected_tab == 3 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(3)">
                cash in
            </button>
            <button class="custom-tabbtn focus:outline-none py-3 px-8 uppercase font-semibold {{ selected_tab == 4 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(4)">
                stores
            </button>
            <button class="custom-tabbtn focus:outline-none py-3 px-8 uppercase font-semibold {{ selected_tab == 5 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(5)">
                redeem
            </button>
        </div>
        <div class="flex items-center space-x-3 pr-10"  >
            <svg class="cursor-pointer " xmlns="http://www.w3.org/2000/svg" width="20" height="21.433" viewBox="0 0 25 21.433" >
                <g id="iconfinder_icon-printer_211880" transform="translate(-32 -64)">
                <path id="Path_369" data-name="Path 369" d="M155.961,313.926h.541v-7.245a.7.7,0,0,0-.692-.681H144.692a.7.7,0,0,0-.692.681v7.245h11.961Z" transform="translate(-105.749 -228.493)"/>
                <g id="Group_77" data-name="Group 77" transform="translate(32 64)">
                    <path id="Path_370" data-name="Path 370" d="M53.869,67.74H52.15V64.82a.856.856,0,0,0-.887-.82H37.743a.856.856,0,0,0-.887.82V67.74H35.137A3.34,3.34,0,0,0,32,70.787V77.53c0,1.518,1.58,3.444,3.137,3.444h1.021c.274,0,.307,0,.307-.781v-2.4A1.788,1.788,0,0,1,38.3,76.006H50.8c.988,0,1.736,1.189,1.736,2.233v1.954c0,.781.006.781.279.781h1.044a3.294,3.294,0,0,0,3.137-3V70.787A3.33,3.33,0,0,0,53.869,67.74Zm-1.328,3.4a.893.893,0,1,1,.893-.893A.9.9,0,0,1,52.54,71.144Z" transform="translate(-32 -64)"/>
                </g>
                </g>
            </svg>
        </div>
    </div>
   
    <!-- rendered tab -->
    <div class="w-full h-full">
        <ng-container *ngIf="selected_tab == 1">
            <app-admin-buyload></app-admin-buyload>
        </ng-container>
        <ng-container *ngIf="selected_tab == 2">
            <app-admin-tp-pay-bills></app-admin-tp-pay-bills>
        </ng-container>
        <ng-container *ngIf="selected_tab == 3">
            <app-admin-tp-cash-in></app-admin-tp-cash-in>
        </ng-container>
        <ng-container *ngIf="selected_tab == 4">
            <app-admin-tp-stores></app-admin-tp-stores>
        </ng-container>
        <ng-container *ngIf="selected_tab == 5">
            <app-admin-tp-redeem></app-admin-tp-redeem>
        </ng-container>
        
    </div>
</div>