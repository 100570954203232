<div>
    <div class="col-span-full">
        <div class="flex items-center space-x-9 mb-3">
            <div class="col-span-full mr-20">
                <h2 class="text-xs t">{{data.message1}}</h2>
            </div>
            <div class="col-span-full">
                <button class="btn-orange-close custom-btn" (click)="closeDialog()">
                    <span class="material-icons text-xs">
                        close
                    </span>
                </button>
            </div>
        </div>
        <div class="flex items-center space-x-2 mb-3">
            <div class="col-span-full">
                <h2 class="text-xs t">{{data.message2}}</h2>
            </div>
        </div>
        <div class="flex items-center justify-end space-x-2" *ngIf="!hideButtons">
            <div class="">
                <button  type="button" id="no-button" [mat-dialog-close]="false" class="custom-btn py-2 px-2 w-full btn-dark-orange rounded">
                    <span class="text-xs">
                        No
                    </span>
                </button>
            </div>
            <div class="">
                <button type="button" id="yes-button"[mat-dialog-close]="true" class="custom-btn py-2 px-2 w-full btn-dark-orange rounded">
                    <span class="text-xs">
                        Yes
                    </span>
                </button>   
            </div>
        </div>
    </div>
</div>
