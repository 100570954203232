import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { RequestsService } from 'src/app/services/http/requests.service';
import { Urls } from 'src/app/lib/urls';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-accounts-edit',
  templateUrl: './accounts-edit.component.html',
  styleUrls: ['./accounts-edit.component.scss']
})
export class AccountsEditComponent implements OnInit {

  public accountsEditForm: FormGroup;
  uid:any;
  userName:any;
  currentEmail:any;
  currentTier_text:any;
  currentAccountType_text:any;

  //tiertype
  tierType_text:any;
  tierId:any;

  //account type
  accountType_text:any;
  memberLevel:any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb : FormBuilder,
    private request: RequestsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { 
    this.initializeForm()
  }

  ngOnInit(): void {
    this.uid = this.route.snapshot.paramMap.get('id');
    this.get_user();
   }

   initializeForm(){
    this.accountsEditForm = this.fb.group({
      firstName: [''],
      middleName: [''],
      lastName: [''],
      userName: [''],
      email: [''],
    });
  }

  get_user(){
    this.request.get(Urls.userAccount + '/' + this.uid, ).then(response => { 
      this.userName=response['result']['userName']; 
      this.currentEmail=response['result']['email'];
      this.tierId=response['result']['tierId']; 
        if(response['result']['tierId']==1){ 
          this.currentTier_text='Default';
        }else if (response['result']['tierId']==4) {
          this.currentTier_text=' Full';
        }else if (response['result']['tierId']==5) {
          this.currentTier_text='Internal';
        }else if (response['result']['tierId']==6) {
          this.currentTier_text='Business';
        }else if (response['result']['tierId']==7) {
          this.currentTier_text='MegaBiz';
        }else {
          this.currentTier_text='MegaOne';
        }
      this.memberLevel=response['result']['memberLevel'];
        if(response['result']['memberLevel']==1){ 
          this.currentAccountType_text='Not Merchant';
        }else {
          this.currentAccountType_text='Merchant';
        }
      this.accountsEditForm = this.fb.group({
        firstName:(response['result']['firstName']),
        middleName: (response['result']['middleName']),
        lastName:(response['result']['lastName']),
        userName:(response['result']['']),
        email:(response['result']['']),
      });
    }) 
  }

  update(){
    this.spinner.show();
    this.request.put(Urls.userAccount + '/' + this.uid, {
      firstName:this.accountsEditForm.controls.firstName.value,
      middleName:this.accountsEditForm.controls.middleName.value,
      lastName:this.accountsEditForm.controls.lastName.value,
      userName:this.accountsEditForm.controls.userName.value,
      email:this.accountsEditForm.controls.email.value,
      tierId:this.tierId,
      memberLevel:this.memberLevel
    }).then(response => {
      console.log(response);
      if(response['status']==200){
        this.toastr.success(response['message']+'!', 'Success!');
        this.router.navigate(['/accounts']);
      }
      else{
        this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
      }
    })
    this.accountsEditForm.reset();
    this.tierType_text=null;
    this.accountType_text=null;
  } 

   setTierType(text,value){
    this.tierId=value;
    this.tierType_text=text;
  }

  setAccountType(text,value){
    this.memberLevel=value;
    this.accountType_text=text;
  }
}

