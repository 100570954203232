<div class="w-full h-full">
    <div class="flex items-center justify-between space-x-3 pt-9 text-sm">
        <div class="flex items-center space-x-3 text-xs"></div>
        <div class="flex items-center space-x-3 text-xs"></div>
        <div class="flex items-center space-x-3 pr-10"  >
            <svg (click)="print()" class="cursor-pointer " xmlns="http://www.w3.org/2000/svg" width="20" height="21.433" viewBox="0 0 25 21.433" >
                <g id="iconfinder_icon-printer_211880" transform="translate(-32 -64)">
                <path id="Path_369" data-name="Path 369" d="M155.961,313.926h.541v-7.245a.7.7,0,0,0-.692-.681H144.692a.7.7,0,0,0-.692.681v7.245h11.961Z" transform="translate(-105.749 -228.493)"/>
                <g id="Group_77" data-name="Group 77" transform="translate(32 64)">
                    <path id="Path_370" data-name="Path 370" d="M53.869,67.74H52.15V64.82a.856.856,0,0,0-.887-.82H37.743a.856.856,0,0,0-.887.82V67.74H35.137A3.34,3.34,0,0,0,32,70.787V77.53c0,1.518,1.58,3.444,3.137,3.444h1.021c.274,0,.307,0,.307-.781v-2.4A1.788,1.788,0,0,1,38.3,76.006H50.8c.988,0,1.736,1.189,1.736,2.233v1.954c0,.781.006.781.279.781h1.044a3.294,3.294,0,0,0,3.137-3V70.787A3.33,3.33,0,0,0,53.869,67.74Zm-1.328,3.4a.893.893,0,1,1,.893-.893A.9.9,0,0,1,52.54,71.144Z" transform="translate(-32 -64)"/>
                </g>
                </g>
            </svg>
        </div>
    </div>
    <div class="w-900-px mx-auto">
        <form [formGroup]="form" >
            <div class="grid grid-cols-12 gap-3">
                <div class="col-span-12">
                    <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                        <span class="text-sm w-130-px font-semibold">
                        Period:
                        </span>
                        <div #t2="matMenuTrigger" #select2 [matMenuTriggerFor]="period_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                            <span *ngIf="!period_text "class="barlow text-black text-xs font-lg p-5 italic">Choose a period</span>
                            <span *ngIf="period_text"class="barlow text-black text-xs font-lg p-5 italic">{{period_text}}</span>
                            <span class="material-icons violet-text ml-3">
                                arrow_drop_down
                            </span>
                        </div>
                        <mat-menu class="w-full sports-menu" #period_select="matMenu">
                            <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs " >
                                Choose a period
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setPeriodType('Daily','Daily')">
                                Daily
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setPeriodType('Weekly','Weekly')">
                                Weekly
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setPeriodType('Monthly','Monthly')">
                                Monthly
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setPeriodType('Annually','Annually')">
                                Annually
                            </button> 
                        </mat-menu>
                    </div>
                </div>
                <div class="col-span-4">
                    <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                        <span class="text-xs w-106-px font-semibold">
                            Date From:
                        </span>
                        <!-- <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy"> -->
                        <mat-form-field color="accent" appearance="">
                            <input
                                class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic"
                                type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker1" readonly
                                (click)="startDate.click()" formControlName="startDate" >
                            <mat-datepicker-toggle matSuffix [for]="picker1" class="focus:outline-none">
                                <svg #startDate class="focus:outline-none" matDatepickerToggleIcon
                                    id="iconfinder_calendar-book-date-monthly_2203551"
                                    xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                    <path id="Path_371" data-name="Path 371"
                                        d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z"
                                        fill="#5f4d9c" />
                                    <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4"
                                        transform="translate(13 6.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4"
                                        transform="translate(13 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4"
                                        transform="translate(8 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4"
                                        transform="translate(3 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4"
                                        transform="translate(8 6.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4"
                                        transform="translate(3 6.936)" fill="#5f4d9c" />
                                </svg>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-span-4">
                    <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                        <span class="text-xs w-106-px font-semibold">
                            Date To:
                        </span>
                        <mat-form-field color="accent" appearance="">
                            <input
                                class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic"
                                type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker2" readonly
                                (click)="startDate.click()" formControlName="endDate">
                            <mat-datepicker-toggle matSuffix [for]="picker2" class="focus:outline-none">
                                <svg #startDate class="focus:outline-none" matDatepickerToggleIcon
                                    id="iconfinder_calendar-book-date-monthly_2203551"
                                    xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                    <path id="Path_371" data-name="Path 371"
                                        d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z"
                                        fill="#5f4d9c" />
                                    <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4"
                                        transform="translate(13 6.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4"
                                        transform="translate(13 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4"
                                        transform="translate(8 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4"
                                        transform="translate(3 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4"
                                        transform="translate(8 6.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4"
                                        transform="translate(3 6.936)" fill="#5f4d9c" />
                                </svg>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </div>  
        </form>
        <div class="flex items-center space-x-3 text-sm justify-center mt-14">
            <button  (click)="submit()" class="uppercase custom-btn btn-dark-orange font-semibold text-sm px-6 py-2 mt-5 mb-5 " >
                SUBMIT
            </button>
        </div>
    </div>
    <div class="bg-light-orange w-full flex items-center justify-between space-x-3 pr-3 pt-4 text-sm mt-10">
        <ng-container *ngIf="!itemLists_filter && !itemLists_filter2" >
            <div class="flex items-center pl-6" [formGroup]="limitForm">
                <div class="orange-text text-xs" >Show</div>
                <input class="orange-input focus:outline-none text-center mx-1 w-25-px  text-xs p-1" type="number" formControlName="limit" (change)="limitKey($event)" value="{{size}}" >
                <div class="orange-text text-xs" >entries</div>
            </div>
        </ng-container>
        <ng-container *ngIf="itemLists_filter && !itemLists_filter2 && total_filter > 9" >
            <div class="flex items-center  pl-6" [formGroup]="limitForm_filter">
                <div class="orange-text text-xs" >Show</div>
                <input class="orange-input focus:outline-none text-center mx-1 w-25-px  text-xs p-1" type="number" formControlName="limit" (change)="limitKey_filter($event)" value="{{size_filter}}" >
                <div class="orange-text text-xs" >entries</div>
            </div>
        </ng-container>
        <ng-container *ngIf="itemLists_filter && !itemLists_filter2 && total_filter < 10 " >
            <div class="flex items-center  pl-6" [formGroup]="limitForm_filter">
                <div class="orange-text text-xs" >Show</div>
                <input class="orange-input focus:outline-none text-center mx-1 w-25-px  text-xs p-1" type="number" formControlName="limit" (change)="limitKey_filter($event)" value="{{total_filter}}" >
                <div class="orange-text text-xs" >entries</div>
            </div>
        </ng-container>
        <ng-container *ngIf="itemLists_filter2 && !itemLists_filter && total_filter2 > 9 ">
            <div class="flex items-center  pl-6" [formGroup]="limitForm_filter2">
                <div class="orange-text text-xs" >Show</div>
                <input class="orange-input focus:outline-none text-center mx-1 w-25-px  text-xs p-1" type="number" formControlName="limit" (change)="limitKey_filter2($event)" value="{{size_filter2}}" >
                <div class="orange-text text-xs" >entries</div>
            </div>
        </ng-container>
        <ng-container *ngIf="itemLists_filter2 && !itemLists_filter && total_filter2 < 10 " >
            <div class="flex items-center  pl-6" [formGroup]="limitForm_filter2">
                <div class="orange-text text-xs" >Show</div>
                <input class="orange-input focus:outline-none text-center mx-1 w-25-px  text-xs p-1" type="number" formControlName="limit" (change)="limitKey_filter2($event)" value="{{total_filter2}}" >
                <div class="orange-text text-xs" >entries</div>
            </div>
        </ng-container>
        <ng-container   *ngIf="itemLists && !itemLists_filter2" >
            <div class="flex items-center space-x-3 pr-3" [formGroup]="searchForm" (keydown)="keyDownFunction($event)">
                <div class="orange-text text-xs" >Search:</div>
                <input class="orange-input focus:outline-none pl-1 text-xs p-1" type="text" placeHolder="Search"  formControlName="search" (keyup)="searchKey($event)">
            </div>
        </ng-container>
        <ng-container  *ngIf="itemLists_filter" >
            <div class="flex items-center space-x-3 pr-3" [formGroup]="searchForm_filter" (keydown)="keyDownFunction($event)">
                <div class="orange-text text-xs" >Search:</div>
                <input class="orange-input focus:outline-none pl-1 text-xs p-1" type="text" placeHolder="Search"  formControlName="search" (keyup)="searchKey_filter($event)">
            </div>
        </ng-container>
        <ng-container  *ngIf="itemLists_filter2" >
            <div class="flex items-center space-x-3 pr-3" [formGroup]="searchForm_filter2" (keydown)="keyDownFunction($event)">
                <div class="orange-text text-xs" >Search:</div>
                <input class="orange-input focus:outline-none pl-1 text-xs p-1" type="text" placeHolder="Search"  formControlName="search" (keyup)="searchKey_filter2($event)">
            </div>
        </ng-container>
    </div>

    <div class=" bg-light-orange w-full  maxh-550-px py-4 px-3 overflow-x-auto w-table">
        <div class="data-table mt-3 barlow text-xs">
            <table class="w-full">
                <thead class="capitalize"> 
                    <tr>
                        <td class="text-left barlow p-3 font-bold" style=" border-top: 1px #CAC2BC solid; border-right: 2px #CAC2BC solid;" ></td>
                        <td class="text-left barlow p-3 font-bold" colspan="7" style=" border: 1px #CAC2BC solid;">
                            CASH IN (PHP)
                        </td>
                        <td class="text-left barlow p-3 font-bold" style=" border-top: 1px #CAC2BC solid; border-right: 2px #CAC2BC solid; border-left: 2px #CAC2BC solid;" ></td>
                        <td class="text-left barlow p-3 font-bold" style=" border-top: 1px #CAC2BC solid; border-right: 2px #CAC2BC solid;" ></td>
                        <td class="text-left barlow p-3 font-bold" style=" border-top: 1px #CAC2BC solid" ></td>
                    </tr>
                    <tr>
                        <th class="text-left barlow" style=" border-top: none">
                           DATE
                        </th>
                        <th class="text-left barlow">
                            2C2P CARDS
                        </th>
                        <th class="text-left barlow">
                            OTC
                        </th>
                        <th class="text-left barlow">
                            BANK TRANSFER
                        </th>
                        <th class="text-left barlow">
                            DRAGON PAY
                        </th>
                        <th class="text-left barlow">
                            BPI
                        </th>
                        <th class="text-left barlow">
                            EC LINK
                        </th>
                        <th class="text-left barlow" style=" border-right: 2px #CAC2BC solid;">
                            TOTAL
                        </th>
                        <th class="text-left barlow" style=" border-top: none">
                           CASH BACK (PHP)
                        </th>
                        <th class="text-left barlow" style=" border-top: none">
                            BUY LOAD (PHP)
                        </th>
                        <th class="text-left barlow" style=" border-top: none">
                           PAY BILLS (PHP)
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="!itemLists_filter && !itemLists_filter2">
                        <tr class="focus:outline-none" *ngFor="let item of itemLists" >
                            <td>{{item.startDate | date:' yyyy-dd-MM  hh:mm:ss'| nullWithDefault}}</td>
                            <td>{{ item.toctopCards | nullWithDefault }}</td>
                            <td>{{ item.otc  | nullWithDefault}}</td>
                            <td>{{ item.bankTransfer | nullWithDefault }}</td>
                            <td>{{ item.dragonPay | nullWithDefault }}</td>
                            <td>{{ item.bpi | nullWithDefault }}</td>
                            <td>{{ item.ecLink | nullWithDefault }}</td>
                            <td>{{ item.total | nullWithDefault }}</td>
                            <td>{{ item.cashBack | nullWithDefault }}</td>
                            <td>{{ item.buyLoad | nullWithDefault }}</td>
                            <td>{{ item.payBills | nullWithDefault }}</td>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="itemLists_filter">
                        <tr class="focus:outline-none" *ngFor="let item of itemLists_filter" >
                            <td>{{item.startDate | date:' yyyy-dd-MM  hh:mm:ss'| nullWithDefault}}</td>
                            <td>{{ item.toctopCards | nullWithDefault }}</td>
                            <td>{{ item.otc  | nullWithDefault}}</td>
                            <td>{{ item.bankTransfer | nullWithDefault }}</td>
                            <td>{{ item.dragonPay | nullWithDefault }}</td>
                            <td>{{ item.bpi | nullWithDefault }}</td>
                            <td>{{ item.ecLink | nullWithDefault }}</td>
                            <td>{{ item.total | nullWithDefault }}</td>
                            <td>{{ item.cashBack | nullWithDefault }}</td>
                            <td>{{ item.buyLoad | nullWithDefault }}</td>
                            <td>{{ item.payBills | nullWithDefault }}</td>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="itemLists_filter2">
                        <tr class="focus:outline-none" *ngFor="let item of itemLists_filter2" >
                            <td>{{item.startDate | date:' yyyy-dd-MM  hh:mm:ss'| nullWithDefault}}</td>
                            <td>{{ item.toctopCards | nullWithDefault }}</td>
                            <td>{{ item.otc  | nullWithDefault}}</td>
                            <td>{{ item.bankTransfer | nullWithDefault }}</td>
                            <td>{{ item.dragonPay | nullWithDefault }}</td>
                            <td>{{ item.bpi | nullWithDefault }}</td>
                            <td>{{ item.ecLink | nullWithDefault }}</td>
                            <td>{{ item.total | nullWithDefault }}</td>
                            <td>{{ item.cashBack | nullWithDefault }}</td>
                            <td>{{ item.buyLoad | nullWithDefault }}</td>
                            <td>{{ item.payBills | nullWithDefault }}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>

    <div class="bg-light-orange pb-4 col-span-full flex justify-between items-center  pt-4 ">
        <ng-container *ngIf="!itemLists_filter && !itemLists_filter2" >
            <div class="orange-text text-xs pl-6" >
                Showing {{size}} of {{total}} entree
            </div>
        </ng-container>
        <ng-container *ngIf="itemLists_filter && !itemLists_filter2 && total_filter > 9 " >
            <div class="orange-text text-xs pl-6" >
                Showing {{size_filter}} of {{total_filter}} entree
           </div>
        </ng-container>
        <ng-container *ngIf="itemLists_filter && !itemLists_filter2 && total_filter < 10 " >
            <div class="orange-text text-xs pl-6" >
                Showing {{total_filter}} of {{total_filter}} entree
            </div>
        </ng-container>
        <ng-container *ngIf="itemLists_filter2 && !itemLists_filter && total_filter2 > 9 " >
            <div class="orange-text text-xs pl-6" >
                Showing {{size_filter2}} of {{total_filter2}} entree
            </div>
        </ng-container>
        <ng-container *ngIf="itemLists_filter2 && !itemLists_filter && total_filter2 < 10 " >
            <div class="orange-text text-xs pl-6" >
                Showing {{total_filter2}} of {{total_filter2}} entree
            </div>
        </ng-container>
        <div class="pr-6  flex items-center justify-end table-pagination text-xs" style="text-align: right;  ">
            <ng-container  *ngIf="option==1">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled] ="this.current_page == 1" (click)="prevPage()">
                    Previous
                </button>
            </ng-container>
            <ng-container  *ngIf=" option==2 ">
               <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled] ="this.current_page_filter == 1" (click)="prevPage()">
                    Previous
                </button>
            </ng-container>
            <ng-container  *ngIf=" option==3 ">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled] ="this.current_page_filter2== 1" (click)="prevPage()">
                    Previous
                </button>
            </ng-container>
            <ng-container  *ngIf=" option==1 ">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active':current_page == 1 }"  style="white-space: nowrap;" (click) = "setPage(page)">
                    {{page}}
                </button>  
            </ng-container>
            <ng-container  *ngIf="option==2 && total_filter > 10 ">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active':current_page_filter == 1 }"  style="white-space: nowrap;" (click) = "setPage(page_filter)">
                    {{page_filter}}
                </button> 
            </ng-container>
            <ng-container  *ngIf="option==3 && total_filter2 > 10 ">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active':current_page_filter2 == 1 }"  style="white-space: nowrap;" (click) = "setPage(page_filter2)">
                    {{page_filter2}}
                </button> 
            </ng-container>
            <ng-container *ngIf="!itemLists_filter && !itemLists_filter2 && current_page >  2">
                <button class="page-btn sarabun-bold orange-box px-2 py-1"  style="white-space: nowrap;">
                    ...
               </button> 
            </ng-container>
            <ng-container *ngIf="itemLists_filter && current_page_filter >  2">
                <button class="page-btn sarabun-bold orange-box px-2 py-1"  style="white-space: nowrap;">
                    ...
                </button> 
            </ng-container>
            <ng-container *ngIf="itemLists_filter2 && current_page_filter2 >  2">
                <button class="page-btn sarabun-bold orange-box px-2 py-1"  style="white-space: nowrap;">
                    ...
                </button> 
            </ng-container>
            <ng-container  *ngIf="option==1">
                <button *ngFor="let item of pages_array | slice:1:3;let i=index;" class=" py-1 page-btn sarabun-bold orange-box px-2" [ngClass]="{'active':current_page == item }" style="white-space: nowrap;" (click) = "setPage(item)">
                    {{item}}
                </button>                  
            </ng-container>
            <ng-container  *ngIf="option==2 && total_filter > 30">
                <button *ngFor="let item_filter of pages_array_filter | slice:1:3;let i=index;" class=" py-1 page-btn sarabun-bold orange-box px-2" [ngClass]="{'active':current_page_filter == item_filter}" style="white-space: nowrap;" (click) = "setPage(item_filter)">
                    {{item_filter}}
                </button>  
            </ng-container>
            <ng-container  *ngIf="option==3 && total_filter2 > 30">
                <button *ngFor="let item_filter2 of pages_array_filter2 | slice:1:3;let i=index;" class=" py-1 page-btn sarabun-bold orange-box px-2" [ngClass]="{'active':current_page_filter2 == item_filter2}" style="white-space: nowrap;" (click) = "setPage(item_filter2)">
                    {{item_filter2}}
                </button>  
            </ng-container>
            <ng-container *ngIf="itemLists && !itemLists_filter && !itemLists_filter2 && total_pages > 4 && current_page+1 < total_pages">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;">
                    ...
                </button> 
            </ng-container>
            <ng-container *ngIf="!itemLists && itemLists_filter && !itemLists_filter2 && total_pages_filter > 4 && current_page_filter+1 < total_pages_filter">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;">
                    ...
                </button> 
            </ng-container>
            <ng-container *ngIf="!itemLists && !itemLists_filter && itemLists_filter2 && total_pages_filter2 > 4 && current_page_filter2+1 < total_pages_filter2">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;">
                    ...
                </button> 
            </ng-container>
            <ng-container  *ngIf="option==1 && total_pages > 4 && current_page+1 < total_pages">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active': current_page == item}" style="white-space: nowrap;" (click) = "setPage(total_pages)">
                    {{total_pages}}
                </button> 
            </ng-container>
            <ng-container  *ngIf="option==2  && total_pages_filter > 4 && current_page_filter+1 < total_pages_filter">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active': current_page_filter == item_filter}" style="white-space: nowrap;" (click) = "setPage(total_pages_filter)">
                    {{total_pages_filter}}
                </button> 
            </ng-container>
            <ng-container  *ngIf="option==2 && total_filter < 30">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active': current_page_filter == total_pages_filter}" style="white-space: nowrap;" (click) = "setPage(total_pages_filter)">
                   {{total_pages_filter}}
                </button> 
            </ng-container>
            <ng-container  *ngIf="option==3  && total_pages_filter2 > 4 && current_page_filter2+1 < total_pages_filter2">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active': current_page_filter2 == item_filter2}" style="white-space: nowrap;" (click) = "setPage(total_pages_filter2)">
                    {{total_pages_filter2}}
                </button> 
            </ng-container>
            <ng-container  *ngIf="option==3 && total_filter2 < 30">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active': current_page_filter2 == total_pages_filter2}" style="white-space: nowrap;" (click) = "setPage(total_pages_filter2)">
                    {{total_pages_filter2}}
                </button> 
            </ng-container>
            <ng-container *ngIf="option==1">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled]="this.current_page == total_pages " (click)= "nextPage()">
                    Next 
                </button> 
            </ng-container>
            <ng-container *ngIf="option==2">
               <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled]="this.current_page_filter == total_pages_filter " (click)= "nextPage()">
                    Next 
                </button> 
            </ng-container>
            <ng-container *ngIf="option==3">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled]="this.current_page_filter2 == total_pages_filter2 " (click)= "nextPage()">
                    Next 
                </button> 
            </ng-container>
        </div>
    </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "timer" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
