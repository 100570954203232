import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { RequestsService } from 'src/app/services/http/requests.service';
import { Urls } from 'src/app/lib/urls';
import { ActivatedRoute, Router} from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { CustomValidationService } from 'src/app/services/custom_validation/custom-validation.service';
@Component({
  selector: 'app-change-pass',
  templateUrl: './change-pass.component.html',
  styleUrls: ['./change-pass.component.scss']
})
export class ChangePassComponent implements OnInit {
  public Form: FormGroup;
  uid: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb : FormBuilder,
    private request: RequestsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private customValidator: CustomValidationService
  ) { 
    this.initializeForm()

  }

  ngOnInit(): void {
  }

  initializeForm(){
    this.Form = this.fb.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    },
    {
      validator: this.customValidator.passwordMatchValidator(
        'password',
        'confirmPassword'
      ),
    });
  }

  changePass(){
    if (this.Form.valid) {
      this.spinner.show();
      // Retrieve the UID from local storage
      const uid = localStorage.getItem('uid');
    
      // Check if UID is available
      if (!uid) {
        this.toastr.error('User ID not found!', 'Error');
        return;
      }
    
      // Make a PUT request to the backend API to change the password
      this.request.put(`${Urls.users}/${uid}`, {
        password:this.Form.controls.password.value,
      }).then(response => {
        console.log(response);
        if(response['status'] === 200){
          this.toastr.success(response['message'], 'Success!');
          this.router.navigate(['/login'])
        } else {
          this.toastr.error(response['error']['message'], 'Error');
        }
      }).catch(error => {
        console.error(error);
        this.toastr.error('Something went wrong while changing password!', 'Error');
      }).finally(() => {
        this.spinner.hide();
      });
    } else {
      this.toastr.error('Passwords do not match!', 'Error');
    }
  } 
}