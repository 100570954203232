import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { RequestsService } from 'src/app/services/http/requests.service';
import { Urls } from 'src/app/lib/urls';
import { ActivatedRoute, Router} from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-portal-modify-account',
  templateUrl: './admin-portal-modify-account.component.html',
  styleUrls: ['./admin-portal-modify-account.component.scss']
})
export class AdminPortalModifyAccountComponent implements OnInit {
  
  public modifyAccountForm: FormGroup;
  uid:any;
  username=[];
  firstName=[];
  lastName=[];
  email=[];
  users:any;
    
  // account type
  accountType:any;
  accountType_text:any;
  ads_details:any
  loggedAcc: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb : FormBuilder,
    private request: RequestsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { 
     this.initializeForm()
  }
  
  ngOnInit(): void {
    this.loggedAcc = localStorage.getItem('accountType');
    this.uid = this.route.snapshot.paramMap.get('id');
    this.get_account();
  }
  
  initializeForm(){
    this.modifyAccountForm = this.fb.group({
      username: [''],
      firstName: [''],
      lastName: [''],
      email: [''],
      password:['']
    });
  }

  get_account(){
    this.request.put(Urls.users + '/' + this.uid,{}).then(response => { 
      this.modifyAccountForm = this.fb.group({
        username:(response['result']['username']),
        firstName: (response['result']['firstName']),
        lastName:(response['result']['lastName']),
        email:(response['result']['email']),
        password:(response['result']['password']),
      });
      this.accountType=response['result']['accountType']; 
      if(response['result']['accountType']==1){ 
        this.accountType_text='Admin';
      }else if (response['result']['accountType']=='Admin') {
        this.accountType_text=' Admin';
      }else if (response['result']['accountType']==2) {
        this.accountType_text=' Super Admin';
      }else if (response['result']['accountType']=='Super Admin') {
        this.accountType_text=' Super Admin';
      }else if (response['result']['accountType']==3) {
        this.accountType_text='User';
      }else if (response['result']['accountType']=='User') {
        this.accountType_text='User';
      }else if (response['result']['accountType']=='Finance') {
        this.accountType_text='Finance';
      }else if (response['result']['accountType']=='MIS') {
        this.accountType_text='MIS';
      }else {
        this.accountType_text='N/A';
      }
   }) 
  }
 
  update(){
    this.spinner.show();
    this.request.put(Urls.users + '/' + this.uid, {
      username:this.modifyAccountForm.controls.username.value,
      firstName:this.modifyAccountForm.controls.firstName.value,
      lastName:this.modifyAccountForm.controls.lastName.value,
      email:this.modifyAccountForm.controls.email.value,
      password:this.modifyAccountForm.controls.password.value,
      accountType:this.accountType
    }).then(response => {
      console.log(response);
      if(response['status']==200){
        this.toastr.success(response['message']+'!', 'Success!');
        this.router.navigate(['/admin/portal-account']);
      }else if(response['status']==401){
        this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
        this.router.navigate(['/admin/portal-account']);
      }
      else{
        this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
      }
    })
    this.accountType_text=null;
  } 

  setAccountType(text,value){
    this.accountType=value;
    this.accountType_text=text;
  }
}
  