<div class="w-full h-full">
    <div class="w-700-px mx-auto mt-8">
        <form  [formGroup]="updateForm">
            <div class="grid grid-cols-12 gap-6">
                <div class="col-span-12 space-y-6">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-xs w-60-px font-semibold ">
                            URL:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" type="text" placeholder="URL" formControlName="webViewUrl">
                    </div>
                    <div class="card-input flex items-start text-xs py-2 w-full">
                        <span class="text-xs w-110-px font-semibold ">
                            Description:
                        </span>
                        <textarea formControlName="description" class="placeholder-black focus:outline-none w-full h-70-px bg-gray-100 rounded-md pl-3 resize-none italic" placeholder="Description (optional)" name="" id="" cols="30" rows="10"></textarea>
                    </div>
                    <div class="flex flex-col items-start text-xs pl-5 mb-3">
                        <div class="flex items-center space-x-3 font-semibold ">
                            <span>Cover Image: </span>
                            <input type='file' class="hidden" (change)="onSelectFile($event)" #coverImg>
                            <button class="browse-btn px-4 py-2 focus:outline-none" (click)="coverImg.click()">
                                Browse...
                            </button>
                        </div>
                        <img [src]="img" class="w-full h-200-px object-contain mt-3" *ngIf="img"> <br/>
                    </div>
                </div>
                <div class="col-span-6">
                    <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                        <span class="text-xs w-106-px font-semibold ">
                            Date From:
                        </span>
                        <!-- <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy"> -->
                        <mat-form-field color="accent" appearance="">
                            <input
                                class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic"
                                type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker1" readonly
                                (click)="startDate.click()" formControlName="dateFrom">
                            <mat-datepicker-toggle matSuffix [for]="picker1" class="focus:outline-none">
                                <svg #startDate class="focus:outline-none" matDatepickerToggleIcon
                                    id="iconfinder_calendar-book-date-monthly_2203551"
                                    xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                    <path id="Path_371" data-name="Path 371"
                                        d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z"
                                        fill="#5f4d9c" />
                                    <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4"
                                        transform="translate(13 6.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4"
                                        transform="translate(13 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4"
                                        transform="translate(8 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4"
                                        transform="translate(3 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4"
                                        transform="translate(8 6.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4"
                                        transform="translate(3 6.936)" fill="#5f4d9c" />
                                </svg>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-span-6">
                    <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                        <span class="text-xs w-106-px font-semibold ">
                            Date To:
                        </span>
                        <mat-form-field color="accent" appearance="">
                            <input
                                class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic"
                                type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker2" readonly
                                (click)="startDate.click()" formControlName="dateTo">
                            <mat-datepicker-toggle matSuffix [for]="picker2" class="focus:outline-none">
                                <svg #startDate class="focus:outline-none" matDatepickerToggleIcon
                                    id="iconfinder_calendar-book-date-monthly_2203551"
                                    xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                    <path id="Path_371" data-name="Path 371"
                                        d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z"
                                        fill="#5f4d9c" />
                                    <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4"
                                        transform="translate(13 6.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4"
                                        transform="translate(13 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4"
                                        transform="translate(8 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4"
                                        transform="translate(3 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4"
                                        transform="translate(8 6.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4"
                                        transform="translate(3 6.936)" fill="#5f4d9c" />
                                </svg>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form> 
        <div class="w-full flex items-center justify-center mt-6 mb-4">
            <button (click)="update()" class="uppercase custom-btn btn-dark-orange font-semibold text-xs px-6 py-2 mb-5" >
                Update
            </button>
        </div>
    </div>
</div>
