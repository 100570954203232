<div class="w-full h-full">
    <div class="flex items-center justify-between space-x-3 pt-6 pl-8 pb-5 text-sm">
        <div class="flex items-center space-x-3 text-xs mb-2">
            <button class="btn-light-orange custom-tabbtn focus:outline-none py-3 px-6 uppercase font-semibold " [routerLink]="['/transactions/all-transaction']">
                all transaction
            </button>
            <button class="btn-light-orange custom-tabbtn focus:outline-none py-3 px-6 uppercase font-semibold " [routerLink]="['/transactions/buy-load']">
                buy load
            </button>
            <button class="btn-light-orange custom-tabbtn focus:outline-none py-3 px-6 uppercase font-semibold " [routerLink]="['/transactions/cash-in']">
                cash in
            </button>
            <button class="btn-dark-orange custom-tabbtn focus:outline-none py-3 px-6 uppercase font-semibold " [routerLink]="['/transactions/pay-bills']">
                pay bills
            </button>
            <button class="btn-light-orange custom-tabbtn focus:outline-none py-3 px-6 uppercase font-semibold " [routerLink]="['/transactions/transfer-funds']">
                transfer funds
            </button>
            <button class="btn-light-orange custom-tabbtn focus:outline-none py-3 px-6 uppercase font-semibold " [routerLink]="['/transactions/store-purchase']">
                store purchase
            </button>
            <button class="btn-light-orange custom-tabbtn focus:outline-none py-3 px-6 uppercase font-semibold " [routerLink]="['/transactions/mega-reward']">
                mega rewards
            </button>
            <button class="btn-light-orange custom-tabbtn focus:outline-none py-3 px-6 uppercase font-semibold " [routerLink]="['/transactions/promo']">
                promo
            </button>
            <button class="btn-light-orange custom-tabbtn focus:outline-none py-3 px-6 uppercase font-semibold " [routerLink]="['/transactions/overview']">
                overview
            </button>
        </div>
        <div class="flex items-center space-x-3 pr-10"  >
            <svg (click)="print()" class="cursor-pointer " xmlns="http://www.w3.org/2000/svg" width="20" height="21.433" viewBox="0 0 25 21.433" >
                <g id="iconfinder_icon-printer_211880" transform="translate(-32 -64)">
                <path id="Path_369" data-name="Path 369" d="M155.961,313.926h.541v-7.245a.7.7,0,0,0-.692-.681H144.692a.7.7,0,0,0-.692.681v7.245h11.961Z" transform="translate(-105.749 -228.493)"/>
                <g id="Group_77" data-name="Group 77" transform="translate(32 64)">
                    <path id="Path_370" data-name="Path 370" d="M53.869,67.74H52.15V64.82a.856.856,0,0,0-.887-.82H37.743a.856.856,0,0,0-.887.82V67.74H35.137A3.34,3.34,0,0,0,32,70.787V77.53c0,1.518,1.58,3.444,3.137,3.444h1.021c.274,0,.307,0,.307-.781v-2.4A1.788,1.788,0,0,1,38.3,76.006H50.8c.988,0,1.736,1.189,1.736,2.233v1.954c0,.781.006.781.279.781h1.044a3.294,3.294,0,0,0,3.137-3V70.787A3.33,3.33,0,0,0,53.869,67.74Zm-1.328,3.4a.893.893,0,1,1,.893-.893A.9.9,0,0,1,52.54,71.144Z" transform="translate(-32 -64)"/>
                </g>
                </g>
            </svg>
        </div>
    </div>
    <div class="w-900-px mx-auto">
        <form [formGroup]="form">
            <div class="grid grid-cols-9 gap-3">
                <div class="col-span-3">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-xs w-130-px font-semibold">
                            Transaction ID:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="transactionId" type="text" placeholder="Transaction ID">
                    </div>
                </div>
                <div class="col-span-3">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-xs w-141-px font-semibold">
                            Phone Number:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="phoneNumber" type="text" placeholder="Phone Number">
                    </div>
                </div>
                <div class="col-span-3">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-xs w-130-px font-semibold">
                            Biller Name:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="billerName"  type="text" placeholder="Biller Name">
                    </div>
                </div>
                <div class="col-span-3">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-xs w-106-px font-semibold">
                            First Name:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" style="text-transform: capitalize;" formControlName="firstName" type="text" placeholder="First Name">
                    </div>
                </div>
                <div class="col-span-3">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-xs w-141-px font-semibold">
                            Middle Name:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" style="text-transform: capitalize;" formControlName="middleName"  type="text" placeholder="Middle Name">
                    </div>
                </div>
                <div class="col-span-3">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-xs w-106-px font-semibold">
                            Last Name:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic"style="text-transform: capitalize;"  formControlName="lastName"  type="text" placeholder="Last Name">
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-12 gap-5 mt-3">
                <div class="col-span-2"></div>
                <div class="col-span-4">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-xs w-106-px font-semibold">
                            Date From:
                        </span>
                        <!-- <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy"> -->
                        <mat-form-field color="accent" appearance="">
                            <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker1" readonly (click)="startDate.click()" formControlName="dateFrom"  >
                            <mat-datepicker-toggle matSuffix [for]="picker1" class="focus:outline-none" >
                                <svg #startDate class="focus:outline-none" matDatepickerToggleIcon id="iconfinder_calendar-book-date-monthly_2203551" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                    <path id="Path_371" data-name="Path 371" d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z" fill="#5f4d9c"/>
                                    <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4" transform="translate(13 6.936)" fill="#5f4d9c"/>
                                    <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4" transform="translate(13 11.936)" fill="#5f4d9c"/>
                                    <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4" transform="translate(8 11.936)" fill="#5f4d9c"/>
                                    <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4" transform="translate(3 11.936)" fill="#5f4d9c"/>
                                    <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4" transform="translate(8 6.936)" fill="#5f4d9c"/>
                                    <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4" transform="translate(3 6.936)" fill="#5f4d9c"/>
                                </svg>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-span-4">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-xs w-106-px font-semibold">
                            Date To:
                        </span>
                        <mat-form-field color="accent" appearance="">
                            <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker2" readonly (click)="startDate.click()" formControlName="dateTo"  >
                            <mat-datepicker-toggle matSuffix [for]="picker2" class="focus:outline-none" >
                                <svg #startDate class="focus:outline-none" matDatepickerToggleIcon id="iconfinder_calendar-book-date-monthly_2203551" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                    <path id="Path_371" data-name="Path 371" d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z" fill="#5f4d9c"/>
                                    <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4" transform="translate(13 6.936)" fill="#5f4d9c"/>
                                    <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4" transform="translate(13 11.936)" fill="#5f4d9c"/>
                                    <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4" transform="translate(8 11.936)" fill="#5f4d9c"/>
                                    <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4" transform="translate(3 11.936)" fill="#5f4d9c"/>
                                    <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4" transform="translate(8 6.936)" fill="#5f4d9c"/>
                                    <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4" transform="translate(3 6.936)" fill="#5f4d9c"/>
                                </svg>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field> </div>
                </div>
                <div class="col-span-2"></div>
            </div>
            <div class="grid grid-cols-12 gap-5 mt-3">
                <div class="col-span-2"></div>
                <div class="col-span-4">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                    <div class="flex items-center ">
                        <span class="text-xs w-60-px font-semibold">
                            Category:
                        </span>
                            <div #t2="matMenuTrigger" #select2 [matMenuTriggerFor]="category_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                                <span *ngIf="!categoryType_text" class="barlow text-black text-xs italic font-lg p-5 italic">All</span>
                                <span *ngIf="categoryType_text" class="barlow text-black text-xs italic font-lg p-5 ">{{categoryType_text}}</span>
                                <span class="material-icons violet-text ">
                                    arrow_drop_down
                                </span>
                            </div>
                            <mat-menu class="w-full sports-menu" #category_select="matMenu">
                                <button mat-menu-item class="tracking-wide focus:outline-none text-xs italic ">
                                    All
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none text-xs italic " (click)="setCategoryType('Telecom','2')">
                                    Telecom
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none text-xs italic " (click)="setCategoryType('Credit Card','3')">
                                    Credit Card
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none text-xs italic " (click)="setCategoryType('Cable and Internet','4')">
                                    Cable and Internet
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none text-xs italic " (click)="setCategoryType('Government','5')">
                                    Government
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none text-xs italic " (click)="setCategoryType('Loans','6')">
                                    Loans
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none text-xs italic " (click)="setCategoryType('Others','7')">
                                    Others
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none text-xs italic " (click)="setCategoryType('Travel','8')">
                                    Travel
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none text-xs italic " (click)="setCategoryType('Transpo','9')">
                                    Transpo
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none text-xs italic " (click)="setCategoryType('Shopping','10')">
                                    Shopping
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none text-xs italic " (click)="setCategoryType('Power','11')">
                                    Power
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none text-xs italic " (click)="setCategoryType('Water','12')">
                                    Water
                                </button>
                            </mat-menu>
                        </div>
                        </div>
                </div>
                <div class="col-span-4">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                    <div class="flex items-center ">
                        <span class="text-xs w-60-px font-semibold">
                            Status:
                        </span>
                            <div #t2="matMenuTrigger" #select2 [matMenuTriggerFor]="category_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                                <span *ngIf="!statusType_text" class="barlow text-black text-xs italic font-lg p-5 italic">All</span>
                                <span *ngIf="statusType_text" class="barlow text-black text-xs italic font-lg p-5 ">{{statusType_text}}</span>
                                <span class="material-icons violet-text ">
                                    arrow_drop_down
                                </span>
                            </div>
                            <mat-menu class="w-full sports-menu" #category_select="matMenu">
                                <button mat-menu-item class="tracking-wide focus:outline-none text-xs italic ">
                                    All
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none text-xs italic " (click)="setStatusType('Success','1')">
                                    Success
                                </button>
                                <button mat-menu-item class="tracking-wide focus:outline-none text-xs italic " (click)="setStatusType('Failed','3')">
                                   Failed
                                </button>
                            </mat-menu>
                        </div>
                        </div>
                </div>
            </div>
        </form>
        <div class="w-full flex items-center justify-center mt-10 mb-4">
            <button (click)="submit()"  class="uppercase custom-btn btn-dark-orange font-semibold text-xs px-6 py-2">
                submit
            </button>
        </div>
    </div>
    <div *ngIf="submitted">
        <div class="bg-light-orange w-full flex items-center justify-between space-x-3 pr-3 pt-4 text-sm mt-10">
            <ng-container *ngIf="!itemLists_filter && !itemLists_filter2" >
                <div class="flex items-center pl-6" [formGroup]="limitForm">
                    <div class="orange-text text-xs" >Show</div>
                    <input class="orange-input focus:outline-none text-center mx-1 w-25-px  text-xs p-1" type="number" formControlName="limit" (change)="limitKey($event)" value="{{size}}" >
                    <div class="orange-text text-xs" >entries</div>
                </div>
            </ng-container>
            <ng-container *ngIf="itemLists_filter && !itemLists_filter2 && total_filter > 9" >
                <div class="flex items-center  pl-6" [formGroup]="limitForm_filter">
                    <div class="orange-text text-xs" >Show</div>
                    <input class="orange-input focus:outline-none text-center mx-1 w-25-px  text-xs p-1" type="number" formControlName="limit" (change)="limitKey_filter($event)" value="{{size_filter}}" >
                    <div class="orange-text text-xs" >entries</div>
                </div>
            </ng-container>
            <ng-container *ngIf="itemLists_filter && !itemLists_filter2 && total_filter < 10 " >
                <div class="flex items-center  pl-6" [formGroup]="limitForm_filter">
                    <div class="orange-text text-xs" >Show</div>
                    <input class="orange-input focus:outline-none text-center mx-1 w-25-px  text-xs p-1" type="number" formControlName="limit" (change)="limitKey_filter($event)" value="{{total_filter}}" >
                    <div class="orange-text text-xs" >entries</div>
                </div>
            </ng-container>
            <ng-container *ngIf="itemLists_filter2 && !itemLists_filter && total_filter2 > 9 ">
                <div class="flex items-center  pl-6" [formGroup]="limitForm_filter2">
                    <div class="orange-text text-xs" >Show</div>
                    <input class="orange-input focus:outline-none text-center mx-1 w-25-px  text-xs p-1" type="number" formControlName="limit" (change)="limitKey_filter2($event)" value="{{size_filter2}}" >
                    <div class="orange-text text-xs" >entries</div>
                </div>
            </ng-container>
            <ng-container *ngIf="itemLists_filter2 && !itemLists_filter && total_filter2 < 10 " >
                <div class="flex items-center  pl-6" [formGroup]="limitForm_filter2">
                    <div class="orange-text text-xs" >Show</div>
                    <input class="orange-input focus:outline-none text-center mx-1 w-25-px  text-xs p-1" type="number" formControlName="limit" (change)="limitKey_filter2($event)" value="{{total_filter2}}" >
                    <div class="orange-text text-xs" >entries</div>
                </div>
            </ng-container>
            <ng-container   *ngIf="itemLists && !itemLists_filter2" >
                <div class="flex items-center space-x-3 pr-3" [formGroup]="searchForm" (keydown)="keyDownFunction($event)">
                    <div class="orange-text text-xs" >Search:</div>
                    <input class="orange-input focus:outline-none pl-1 text-xs p-1" type="text" placeHolder="Search"  formControlName="search" (keyup)="searchKey($event)">
                </div>
            </ng-container>
            <ng-container  *ngIf="itemLists_filter" >
                <div class="flex items-center space-x-3 pr-3" [formGroup]="searchForm_filter" (keydown)="keyDownFunction($event)">
                    <div class="orange-text text-xs" >Search:</div>
                    <input class="orange-input focus:outline-none pl-1 text-xs p-1" type="text" placeHolder="Search"  formControlName="search" (keyup)="searchKey_filter($event)">
                </div>
            </ng-container>
            <ng-container  *ngIf="itemLists_filter2" >
                <div class="flex items-center space-x-3 pr-3" [formGroup]="searchForm_filter2" (keydown)="keyDownFunction($event)">
                    <div class="orange-text text-xs" >Search:</div>
                    <input class="orange-input focus:outline-none pl-1 text-xs p-1" type="text" placeHolder="Search"  formControlName="search" (keyup)="searchKey_filter2($event)">
                </div>
            </ng-container>
        </div>
        <div class=" bg-light-orange w-full  maxh-550-px px-3 overflow-x-auto w-table">
            <div class="data-table4 mt-3 barlow text-xs"   style="font-size: 10px;">
                <table class="w-full" >
                    <thead>
                        <tr>
                            <th class="text-left barlow pl-2 pt-3 pb-3">
                                TRANSACTION ID
                                <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('transactionId', sort_type)">
                            </th>
                            <th class="text-left barlow pl-2 pt-3 pb-3">
                                DATE / TIME
                                <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('dateTime', sort_type)">
                            </th>
                            <th class="text-left barlow pl-2 pt-3 pb-3">
                                NAME
                                <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('firstName', sort_type)">
                            </th>
                            <th class="text-left barlow pl-2 pt-3 pb-3">
                                PHONE NUMBER
                                <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('phoneNumber', sort_type)">
                            </th>
                            <th class="text-left barlow pl-2 pt-3 pb-3">
                                ACCOUNT NUMBER
                                <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('accountNumber', sort_type)">
                            </th>
                            <th class="text-left barlow pl-2 pt-3 pb-3">
                                ACCOUNT NAME
                                <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('accountName', sort_type)">
                            </th>
                            <th class="text-left barlow pl-2 pt-3 pb-3">
                                BILLER NAME
                                <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('billerName', sort_type)">
                            </th>
                            <th class="text-left barlow pl-2 pt-3 pb-3">
                                CATEGORY
                                <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('category', sort_type)">
                            </th>
                            <th class="text-left barlow pl-2 pt-3 pb-3">
                                AMOUNT DUE
                                <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('amountDue', sort_type)">
                            </th>
                            <th class="text-left barlow pl-2 pt-3 pb-3">
                                CONV FEE
                                <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('convFee', sort_type)">
                            </th>
                            <th class="text-left barlow pl-2 pt-3 pb-3">
                                TOTAL AMOUNT
                                <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('totalAmount', sort_type)">
                            </th>
                            <th class="text-left barlow pl-2 pt-3 pb-3">
                                MODIFIED DATE/TIME
                                <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('modifiedDateTime', sort_type)">
                            </th>
                            <th class="text-left barlow pl-2 pt-3 pb-3">
                                STATUS
                                <img src="assets/images/icon/sort2.webp" style="height:20px;float:right" (click)= "sortList('status', sort_type)">
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="!itemLists_filter && !itemLists_filter2">
                            <tr class="focus:outline-none" *ngFor="let item of itemLists" >
                                <td>{{ item.transactionId | nullWithDefault }}</td>
                                <td >{{ item.dateTime | nullWithDefault | date:' yyyy-dd-MM  hh:mm:ss' }}</td>
                                <td >{{ item.name | nullWithDefault  | titlecase }}</td>
                                <td >{{ item.phoneNumber | nullWithDefault }}</td>
                                <td >{{ item.accountNumber | nullWithDefault }}</td>
                                <td >{{ item.accountName | nullWithDefault }}</td>
                                <td >{{ item.billerName | nullWithDefault | titlecase}}</td>
                                <td >{{ item.category | nullWithDefault }}</td>
                                <td >{{ item.amountDue | nullWithDefault }}</td>
                                <td >{{ item.convFee | nullWithDefault }}</td>
                                <td >{{ item.totalAmount | nullWithDefault  }}</td>
                                <td >{{ item.modifiedDateTime | nullWithDefault | date:' yyyy-dd-MM  hh:mm:ss'}}</td>
                                <td >{{ item.status | nullWithDefault  }}</td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="itemLists_filter">
                            <tr class="focus:outline-none" *ngFor="let item of itemLists_filter" >
                                <td>{{ item.transactionId | nullWithDefault }}</td>
                                <td >{{ item.dateTime | nullWithDefault | date:' yyyy-dd-MM  hh:mm:ss' }}</td>
                                <td >{{ item.name | nullWithDefault  | titlecase }}</td>
                                <td >{{ item.phoneNumber | nullWithDefault }}</td>
                                <td >{{ item.accountNumber | nullWithDefault }}</td>
                                <td >{{ item.accountName | nullWithDefault }}</td>
                                <td >{{ item.billerName | nullWithDefault | titlecase}}</td>
                                <td >{{ item.category | nullWithDefault }}</td>
                                <td >{{ item.amountDue | nullWithDefault }}</td>
                                <td >{{ item.convFee | nullWithDefault }}</td>
                                <td >{{ item.totalAmount | nullWithDefault  }}</td>
                                <td >{{ item.modifiedDateTime | nullWithDefault | date:' yyyy-dd-MM  hh:mm:ss'}}</td>
                                <td >{{ item.status | nullWithDefault  }}</td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="itemLists_filter2">
                            <tr class="focus:outline-none" *ngFor="let item of itemLists_filter2" >
                                <td>{{ item.transactionId | nullWithDefault }}</td>
                                <td >{{ item.dateTime | nullWithDefault | date:' yyyy-dd-MM  hh:mm:ss' }}</td>
                                <td >{{ item.name | nullWithDefault  | titlecase }}</td>
                                <td >{{ item.phoneNumber | nullWithDefault }}</td>
                                <td >{{ item.accountNumber | nullWithDefault }}</td>
                                <td >{{ item.accountName | nullWithDefault }}</td>
                                <td >{{ item.billerName | nullWithDefault | titlecase}}</td>
                                <td >{{ item.category | nullWithDefault }}</td>
                                <td >{{ item.amountDue | nullWithDefault }}</td>
                                <td >{{ item.convFee | nullWithDefault }}</td>
                                <td >{{ item.totalAmount | nullWithDefault  }}</td>
                                <td >{{ item.modifiedDateTime | nullWithDefault | date:' yyyy-dd-MM  hh:mm:ss'}}</td>
                                <td >{{ item.status | nullWithDefault  }}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="bg-light-orange pb-4 col-span-full flex justify-between items-center  pt-4 ">
            <ng-container *ngIf="!itemLists_filter && !itemLists_filter2" >
                <div class="orange-text text-xs pl-6" >
                    Showing {{size}} of {{total}} entree
                </div>
            </ng-container>
            <ng-container *ngIf="itemLists_filter && !itemLists_filter2 && total_filter > 9 " >
                <div class="orange-text text-xs pl-6" >
                    Showing {{size_filter}} of {{total_filter}} entree
               </div>
            </ng-container>
            <ng-container *ngIf="itemLists_filter && !itemLists_filter2 && total_filter < 10 " >
                <div class="orange-text text-xs pl-6" >
                    Showing {{total_filter}} of {{total_filter}} entree
                </div>
            </ng-container>
            <ng-container *ngIf="itemLists_filter2 && !itemLists_filter && total_filter2 > 9 " >
                <div class="orange-text text-xs pl-6" >
                    Showing {{size_filter2}} of {{total_filter2}} entree
                </div>
            </ng-container>
            <ng-container *ngIf="itemLists_filter2 && !itemLists_filter && total_filter2 < 10 " >
                <div class="orange-text text-xs pl-6" >
                    Showing {{total_filter2}} of {{total_filter2}} entree
                </div>
            </ng-container>
            <div class="pr-6  flex items-center justify-end table-pagination text-xs" style="text-align: right;  ">
                <ng-container  *ngIf="option==1">
                    <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled] ="this.current_page == 1" (click)="prevPage()">
                        Previous
                    </button>
                </ng-container>
                <ng-container  *ngIf=" option==2 ">
                   <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled] ="this.current_page_filter == 1" (click)="prevPage()">
                        Previous
                    </button>
                </ng-container>
                <ng-container  *ngIf=" option==3 ">
                    <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled] ="this.current_page_filter2== 1" (click)="prevPage()">
                        Previous
                    </button>
                </ng-container>
                <ng-container  *ngIf=" option==1 ">
                    <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active':current_page == 1 }"  style="white-space: nowrap;" (click) = "setPage(page)">
                        {{page}}
                    </button>  
                </ng-container>
                <ng-container  *ngIf="option==2 && total_filter > 10 ">
                    <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active':current_page_filter == 1 }"  style="white-space: nowrap;" (click) = "setPage(page_filter)">
                        {{page_filter}}
                    </button> 
                </ng-container>
                <ng-container  *ngIf="option==3 && total_filter2 > 10 ">
                    <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active':current_page_filter2 == 1 }"  style="white-space: nowrap;" (click) = "setPage(page_filter2)">
                        {{page_filter2}}
                    </button> 
                </ng-container>
                <ng-container *ngIf="!itemLists_filter && !itemLists_filter2 && current_page >  2">
                    <button class="page-btn sarabun-bold orange-box px-2 py-1"  style="white-space: nowrap;">
                        ...
                   </button> 
                </ng-container>
                <ng-container *ngIf="itemLists_filter && current_page_filter >  2">
                    <button class="page-btn sarabun-bold orange-box px-2 py-1"  style="white-space: nowrap;">
                        ...
                    </button> 
                </ng-container>
                <ng-container *ngIf="itemLists_filter2 && current_page_filter2 >  2">
                    <button class="page-btn sarabun-bold orange-box px-2 py-1"  style="white-space: nowrap;">
                        ...
                    </button> 
                </ng-container>
                <ng-container  *ngIf="option==1">
                    <button *ngFor="let item of pages_array | slice:1:3;let i=index;" class=" py-1 page-btn sarabun-bold orange-box px-2" [ngClass]="{'active':current_page == item }" style="white-space: nowrap;" (click) = "setPage(item)">
                        {{item}}
                    </button>                  
                </ng-container>
                <ng-container  *ngIf="option==2 && total_filter > 30">
                    <button *ngFor="let item_filter of pages_array_filter | slice:1:3;let i=index;" class=" py-1 page-btn sarabun-bold orange-box px-2" [ngClass]="{'active':current_page_filter == item_filter}" style="white-space: nowrap;" (click) = "setPage(item_filter)">
                        {{item_filter}}
                    </button>  
                </ng-container>
                <ng-container  *ngIf="option==3 && total_filter2 > 30">
                    <button *ngFor="let item_filter2 of pages_array_filter2 | slice:1:3;let i=index;" class=" py-1 page-btn sarabun-bold orange-box px-2" [ngClass]="{'active':current_page_filter2 == item_filter2}" style="white-space: nowrap;" (click) = "setPage(item_filter2)">
                        {{item_filter2}}
                    </button>  
                </ng-container>
                <ng-container *ngIf="itemLists && !itemLists_filter && !itemLists_filter2 && total_pages > 4 && current_page+1 < total_pages">
                    <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;">
                        ...
                    </button> 
                </ng-container>
                <ng-container *ngIf="!itemLists && itemLists_filter && !itemLists_filter2 && total_pages_filter > 4 && current_page_filter+1 < total_pages_filter">
                    <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;">
                        ...
                    </button> 
                </ng-container>
                <ng-container *ngIf="!itemLists && !itemLists_filter && itemLists_filter2 && total_pages_filter2 > 4 && current_page_filter2+1 < total_pages_filter2">
                    <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;">
                        ...
                    </button> 
                </ng-container>
                <ng-container  *ngIf="option==1 && total_pages > 4 && current_page+1 <total_pages">
                    <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active': current_page == item}" style="white-space: nowrap;" (click) = "setPage(total_pages)">
                        {{total_pages}}
                    </button> 
                </ng-container>
                <ng-container  *ngIf="option==2  && total_pages_filter > 4 && current_page_filter+1 < total_pages_filter">
                    <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active': current_page_filter == item_filter}" style="white-space: nowrap;" (click) = "setPage(total_pages_filter)">
                        {{total_pages_filter}}
                    </button> 
                </ng-container>
                <ng-container  *ngIf="option==2 && total_filter < 30">
                    <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active': current_page_filter == total_pages_filter}" style="white-space: nowrap;" (click) = "setPage(total_pages_filter)">
                       {{total_pages_filter}}
                    </button> 
                </ng-container>
                <ng-container  *ngIf="option==3  && total_pages_filter2 > 4 && current_page_filter2+1 < total_pages_filter2">
                    <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active': current_page_filter2 == item_filter2}" style="white-space: nowrap;" (click) = "setPage(total_pages_filter2)">
                        {{total_pages_filter2}}
                    </button> 
                </ng-container>
                <ng-container  *ngIf="option==3 && total_filter2 < 30">
                    <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active': current_page_filter2 == total_pages_filter2}" style="white-space: nowrap;" (click) = "setPage(total_pages_filter2)">
                        {{total_pages_filter2}}
                    </button> 
                </ng-container>
                <ng-container *ngIf="option==1">
                    <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled]="this.current_page == total_pages " (click)= "nextPage()">
                        Next 
                    </button> 
                </ng-container>
                <ng-container *ngIf="option==2">
                   <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled]="this.current_page_filter == total_pages_filter " (click)= "nextPage()">
                        Next 
                    </button> 
                </ng-container>
                <ng-container *ngIf="option==3">
                    <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled]="this.current_page_filter2 == total_pages_filter2 " (click)= "nextPage()">
                        Next 
                    </button> 
                </ng-container>
            </div>
        </div>
    </div>
</div> 
