<div class="w-full h-full">
    <div class="px-8 mt-10">
        <form [formGroup]="updateForm">
            <div class="grid grid-cols-12 gap-3 pl-10 pr-10 mt-6">
                <div class="col-span-4  mb-3">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-xs w-180-px font-semibold">
                            Name:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic"  formControlName="name" type="text" placeholder="Product Name">
                    </div>
                </div>
                <div class="col-span-4  mb-3">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-xs w-180-px font-semibold">
                            Code:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic"  formControlName="code" type="text" placeholder="Product Code">
                    </div>
                </div>
                <div class="col-span-4  mb-3">
                    <div class="card-input flex items-center text-xs py-2 w-full">
                        <span class="text-xs w-180-px font-semibold">
                           Description
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic"  formControlName="description" type="text" placeholder="Product Code">
                    </div>
                </div>
                <div class="col-span-12">
                    <div class="w-full h-full flex items-end justify-center mb-5">
                        <button  (click)="update()" class="uppercase custom-btn btn-dark-orange font-semibold text-xs px-6 py-2">
                            submit
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>