import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Urls } from 'src/app/lib/urls';
import { RequestsService } from 'src/app/services/http/requests.service';

@Component({
  selector: 'app-admin-system-maintenance',
  templateUrl: './admin-system-maintenance.component.html',
  styleUrls: ['./admin-system-maintenance.component.scss']
})
export class AdminSystemMaintenanceComponent implements OnInit {
  maintenanceType_text: string;
  onMaintenanceMode: string;

  constructor(
    private toastr: ToastrService,
    private request: RequestsService,

  ) { }

  ngOnInit(): void {
  }

  submit() {
    this.request.get(Urls.maintenance + '/' + this.onMaintenanceMode ).then(response => {
      console.log(response); 
      if(response['status']==200){
        this.toastr.success(response['message']+'!', 'Success!');
      }
      else{
        this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
      }
    })  
    this.maintenanceType_text=null; 
    this.onMaintenanceMode=null; 
  }
  

  setMaintenanceType(text: string, value: string) {
    this.onMaintenanceMode = value;
    this.maintenanceType_text = text;
  }
}