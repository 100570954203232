import { Component, OnInit, VERSION } from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { RequestsService } from 'src/app/services/http/requests.service';
import { Urls } from 'src/app/lib/urls';
import { DebounceService } from 'src/app/services/debounce.service';
import { NgxSpinnerService } from "ngx-spinner";
import { DialogService } from 'src/app/services/dialog.service';
import { ToastrService } from 'ngx-toastr';
// import * as XLSX from 'xlsx';

@Component({
  selector: 'app-reports-top-user',
  templateUrl: './reports-top-user.component.html',
  styleUrls: ['./reports-top-user.component.scss']
})
export class ReportsTopUserComponent implements OnInit {
  public form: FormGroup;
  isLoading: boolean;
  itemLists:any;
  search:any;
  tabId:any;
  fileName:any;
  url:any;
  params={ 
    search: '',
  }

  //pagination
  page=1;
  size=10;
  total_pages:any;
  current_page = 1;
  pages_array=[
  ];
  total:any;
  item:any;
  lastPage:any;

  //paginationfilter
  page_filter=1;
  size_filter=10;
  total_pages_filter:any;
  current_page_filter = 1;
  pages_array_filter=[
  ];
  total_filter:any;
  item_filter:any;
  lastPage_filter:any;
  itemLists_filter:any;

  //paginationfilter2
  page_filter2=1;
  size_filter2=10;
  total_pages_filter2:any;
  current_page_filter2 = 1;
  pages_array_filter2=[
  ];
  total_filter2:any;
  item_filter2:any;
  lastPage_filter2:any;
  itemLists_filter2:any;

  //search
  searchKey: any=null;
  searchForm : FormGroup;
  search_keyword='';

  //searchfilter
  searchKey_filter: any=null;
  searchForm_filter : FormGroup;
  search_keyword_filter='';

   //searchfilter2
   searchKey_filter2: any=null;
   searchForm_filter2 : FormGroup;
   search_keyword_filter2='';


  //selection viewALL or search
  option = 0
  selectedOption:any;

  //limit
  limitKey:any=null;
  limitForm : FormGroup; 

  //limitFilter
  limitKey_filter:any=null;
  limitForm_filter: FormGroup; 

  //limitFilter
  limitKey_filter2:any=null;
  limitForm_filter2: FormGroup; 

  //null with default
  name = 'Angular ' + VERSION.major;

  //filterfields
  filterFields:any;
  filterFields2:any;

  //sorting
  sort_column="dateTime";
  sort_type="ASC";

  tradeType:any;
  tradeType_text:any

  daysOverview: number = 0; 

  cashInOverview: any[] = [];
  buyLoadOverview: any[] = [];
  payBillsOverview: any[] = [];
  data: any[] = [];

  dateFromValue: Date;
  dateToValue: Date;
  cashInOverview_filter2: any;
  buyLoadOverview2_filter2: any;
  payBillsOverview2_filter2: any;
  payBillsOverview_filter: any;
  cashInOverview_filter: any;
  buyLoadOverview_filter: any;
  buyLoadOverview_filter2: any;
  payBillsOverview_filter2: any;


  constructor(
    private fb : FormBuilder,
    public debounce: DebounceService,
    private request: RequestsService,
    private spinner: NgxSpinnerService,
    private dialogService:DialogService,
    private toastr: ToastrService,
  ) { 
    this.initializeForm();
  }

  ngOnInit(): void {
    var tableHeader = document.getElementsByTagName("th");  
    for (var i = 0; i < tableHeader.length; i++) { 
      tableHeader[i].classList.add("headerSortDown");
   }
    this.get_List();
  }

  initializeForm(){
    this.form = this.fb.group({
      dateFrom: [null], 
      dateTo: [null], 
    });
   //search
   this.searchKey = this.debounce.debounce(() => {
    this.current_page=1;
    this.search_keyword=this.searchForm.value.search;
    console.log('searchKeyword', this.search_keyword)
    this.get_List();
  }, 500); 
  this.searchForm = this.fb.group({
    search:['']
  });
//searchfilter  
  this.searchKey_filter = this.debounce.debounce(() => {
    this.current_page_filter=1;
    this.search_keyword_filter=this.searchForm_filter.value.search;
    console.log('searchKeyword', this.search_keyword_filter)
    this.get_search_list();
  }, 500); 
  this.searchForm_filter = this.fb.group({
    search:['']
  });
//searchfilter2
  this.searchKey_filter2 = this.debounce.debounce(() => {
    this.current_page_filter2=1;
    this.search_keyword_filter2=this.searchForm_filter2.value.search;
    console.log('searchKeyword', this.search_keyword_filter2)
    this.get_search_list2();
  }, 500); 
  this.searchForm_filter2 = this.fb.group({
    search:['']
  });
    //limit
    this.limitKey = this.debounce.debounce(() => {
      this.current_page=1;
      this.size=this.limitForm.value.limit;
      this.get_List();
    }, 500);  
    this.limitForm = this.fb.group({
      limit:['']
    }); 
    //limitfilter
    this.limitKey_filter = this.debounce.debounce(() => {
      this.current_page_filter=1;
      this.size_filter=this.limitForm_filter.value.limit;
      this.get_search_list();
    }, 500);  
    this.limitForm_filter = this.fb.group({
      limit:['']
    }); 
    //limitfilter2
    this.limitKey_filter2 = this.debounce.debounce(() => {
      this.current_page_filter2=1;
      this.size_filter2=this.limitForm_filter2.value.limit;
      this.get_search_list2();
    }, 500);  
    this.limitForm_filter2 = this.fb.group({
      limit:['']
    }); 
  }
  
  
  submit(){
    this.searchForm_filter.reset();
    this.searchForm_filter2.reset();
    this.search_keyword_filter = null;
    this.search_keyword_filter2 = null;
    if(this.itemLists || this.cashInOverview || this.buyLoadOverview || this.payBillsOverview ||  this.itemLists_filter || this.cashInOverview_filter || this.buyLoadOverview_filter || this.payBillsOverview_filter){
      this.itemLists = null;
      this.cashInOverview = null;
      this.buyLoadOverview = null;
      this.payBillsOverview_filter = null;
      this.itemLists_filter = null;
      this.cashInOverview_filter = null;
      this.buyLoadOverview_filter = null;
      this.payBillsOverview_filter = null;
      this.current_page_filter=1;
      this.getSearch2()
    }else if(this.itemLists || this.cashInOverview || this.buyLoadOverview || this.payBillsOverview ||  this.itemLists_filter2 || this.cashInOverview_filter2 || this.buyLoadOverview_filter2 || this.payBillsOverview_filter2){
      this.itemLists=null;
      this.cashInOverview = null;
      this.buyLoadOverview = null;
      this.payBillsOverview_filter = null;
      this.itemLists_filter2 = null;
      this.cashInOverview_filter2 = null;
      this.buyLoadOverview_filter2 = null;
      this.payBillsOverview_filter2 = null;
      this.current_page_filter2=1;
      this.getSearch()
    }
  }

  getSearch(){
    this.option = 2
    this.spinner.show();
    let params ={ 
      dateFrom: this.form.controls.dateFrom.value,
      dateTo: this.form.controls.dateTo.value,
      tradeType:this.tradeType,
      daysOverview:this.daysOverview,
    }
    this.pages_array_filter=[];
    this.request.post(Urls.topUsers + '?page=' + this.current_page_filter + '&size=' + this.size_filter, params).then(response => {
      if(response['status']==200){
        this.cashInOverview_filter = response['result']['cashInOverview'];
        this.buyLoadOverview_filter = response['result']['buyLoadOverview'];
        this.payBillsOverview_filter = response['result']['payBillsOverview'];
        this.itemLists_filter=response['result']['data'];
        this.filterFields=response['result']['filterFields']
        console.log(this.cashInOverview_filter)
        console.log(this.buyLoadOverview_filter)
        console.log(this.payBillsOverview_filter)
        console.log(this.itemLists_filter)
        this.selectedOption="getSearch";
        console.log(this.selectedOption)
          if(this.current_page_filter  === 0  ){
            this.pages_array_filter=[];
            for(let i=this.current_page_filter; i<=response['result']['totalPages'];i++){
              this.pages_array_filter.push(i);
            };
          }else if(this.current_page_filter===1){
            for(let i=1; i<=response['result']['totalPages'];i++){
              this.pages_array_filter.push(i);
            };
          }else if(this.current_page_filter===response['result']['totalPages']){
            for(let i=this.current_page_filter-2; i<=response['result']['totalPages'];i++){
              this.pages_array_filter.push(i);
            };
          }else{
            for(let i=this.current_page_filter-1; i<=response['result']['totalPages'];i++){
              this.pages_array_filter.push(i);
            };
          }
          this.total_pages_filter=parseInt(response['result']['totalPages']);
          this.current_page_filter=response['result']['currentPage'];
          this.total_filter=parseInt(response['result']['totalItems']);
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
        }
      }).finally(()=>{
    }); 
    this.dateFromValue = this.form.get('dateFrom').value;
    this.dateToValue = this.form.get('dateTo').value;
  }

  get_search_list(){
    this.option = 2
    this.spinner.show();
    let params ={ 
      dateFrom: this.filterFields.dateFrom,
      dateTo:this.filterFields.dateTo,
      tradeType:this.filterFields.tradeType,
      daysOverview:this.filterFields.daysOverview,
    }
    this.pages_array_filter=[];
    console.log(params)
    this.request.post(Urls.topUsers + '?page=' + this.current_page_filter + '&size=' + this.size_filter, params).then(response => {
      if(response['status']==200){
        this.cashInOverview_filter = response['result']['cashInOverview'];
        this.buyLoadOverview_filter = response['result']['buyLoadOverview'];
        this.payBillsOverview_filter = response['result']['payBillsOverview'];
        this.itemLists_filter=response['result']['data'];
        this.filterFields=response['result']['filterFields']
        this.selectedOption="getSearch";
        console.log(this.selectedOption)
          if(this.current_page_filter  === 0  ){
            this.pages_array_filter=[];
            for(let i=this.current_page_filter; i<=response['result']['totalPages'];i++){
              this.pages_array_filter.push(i);
            };
          }else if(this.current_page_filter===1){
            for(let i=1; i<=response['result']['totalPages'];i++){
              this.pages_array_filter.push(i);
            };
          }else if(this.current_page_filter===response['result']['totalPages']){
            for(let i=this.current_page_filter-2; i<=response['result']['totalPages'];i++){
              this.pages_array_filter.push(i);
            };
          }else{
            for(let i=this.current_page_filter-1; i<=response['result']['totalPages'];i++){
              this.pages_array_filter.push(i);
            };
          }
          this.total_pages_filter=parseInt(response['result']['totalPages']);
          this.current_page_filter=response['result']['currentPage'];
          this.total_filter=parseInt(response['result']['totalItems']);
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
        }
      }).finally(()=>{
    }); 
  }

  getSearch2(){
    this.option = 3
    this.spinner.show();
    let params ={ 
      dateFrom: this.form.controls.dateFrom.value,
      dateTo: this.form.controls.dateTo.value,
      tradeType:this.tradeType,
      daysOverview:this.daysOverview,
    }
    this.pages_array_filter2=[];
    this.request.post(Urls.topUsers + '?page=' + this.current_page_filter2 + '&size=' + this.size_filter2, params).then(response => {
      if(response['status']==200){
        this.cashInOverview_filter2 = response['result']['cashInOverview'];
        this.buyLoadOverview_filter2 = response['result']['buyLoadOverview'];
        this.payBillsOverview_filter2 = response['result']['payBillsOverview'];
        this.itemLists_filter2=response['result']['data'];
        this.filterFields2=response['result']['filterFields']
        console.log(this.cashInOverview_filter2)
        console.log(this.buyLoadOverview_filter2)
        console.log(this.payBillsOverview_filter2)
        console.log(this.itemLists_filter2)
        this.selectedOption="getSearch2";
        console.log(this.selectedOption)
          if(this.current_page_filter2  === 0  ){
            this.pages_array_filter2=[];
            for(let i=this.current_page_filter2; i<=response['result']['totalPages'];i++){
              this.pages_array_filter2.push(i);
            };
          }else if(this.current_page_filter2===1){
            for(let i=1; i<=response['result']['totalPages'];i++){
              this.pages_array_filter2.push(i);
            };
          }else if(this.current_page_filter2===response['result']['totalPages']){
            for(let i=this.current_page_filter2-2; i<=response['result']['totalPages'];i++){
              this.pages_array_filter2.push(i);
            };
          }else{
            for(let i=this.current_page_filter2-1; i<=response['result']['totalPages'];i++){
              this.pages_array_filter2.push(i);
            };
          }
          this.total_pages_filter2=parseInt(response['result']['totalPages']);
          this.current_page_filter2=response['result']['currentPage'];
          this.total_filter2=parseInt(response['result']['totalItems']);
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
        }
      }).finally(()=>{
    }); 
    this.dateFromValue = this.form.get('dateFrom').value;
    this.dateToValue = this.form.get('dateTo').value;
  }

  get_search_list2(){
    this.option = 3
    this.spinner.show();
    let params ={ 
      dateFrom: this.filterFields2.dateFrom,
      dateTo:this.filterFields2.dateTo,
      tradeType:this.filterFields2.tradeType,
      daysOverview:this.filterFields2.daysOverview,
    }
    this.pages_array_filter2=[];
    console.log(params)
    this.request.post(Urls.topUsers + '?page=' + this.current_page_filter2 + '&size=' + this.size_filter2, params).then(response => {
      if(response['status']==200){
        this.cashInOverview_filter2 = response['result']['cashInOverview'];
        this.buyLoadOverview_filter2 = response['result']['buyLoadOverview'];
        this.payBillsOverview_filter2 = response['result']['payBillsOverview'];
        this.itemLists_filter2=response['result']['data'];
        this.filterFields2=response['result']['filterFields']
        console.log(this.filterFields2)
        this.selectedOption="getSearch2";
        console.log(this.selectedOption)
          if(this.current_page_filter2  === 0  ){
            this.pages_array_filter2=[];
            for(let i=this.current_page_filter2; i<=response['result']['totalPages'];i++){
              this.pages_array_filter2.push(i);
            };
          }else if(this.current_page_filter2===1){
            for(let i=1; i<=response['result']['totalPages'];i++){
              this.pages_array_filter2.push(i);
            };
          }else if(this.current_page_filter2===response['result']['totalPages']){
            for(let i=this.current_page_filter2-2; i<=response['result']['totalPages'];i++){
              this.pages_array_filter2.push(i);
            };
          }else{
            for(let i=this.current_page_filter2-1; i<=response['result']['totalPages'];i++){
              this.pages_array_filter2.push(i);
            };
          }
          this.total_pages_filter2=parseInt(response['result']['totalPages']);
          this.current_page_filter2=response['result']['currentPage'];
          this.total_filter2=parseInt(response['result']['totalItems']);
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
        }
      }).finally(()=>{
    }); 
  }

  get_List() {
    this.option = 1;
    this.spinner.show();
    let params = {};
    this.pages_array = [];
    this.request.post(Urls.topUsers + '?page=' + this.current_page + '&size=' + this.size, params)
      .then(response => {
        if (response['status'] === 200) {
          this.cashInOverview = response['result']['cashInOverview'];
          this.buyLoadOverview = response['result']['buyLoadOverview'];
          this.payBillsOverview = response['result']['payBillsOverview'];
          this.itemLists = response['result']['data'];
          if (this.current_page === 0) {
            this.pages_array = [];
            for (let i = this.current_page; i <= response['result']['totalPages']; i++) {
              this.pages_array.push(i);
            }
          } else if (this.current_page === 1) {
            for (let i = 1; i <= response['result']['totalPages']; i++) {
              this.pages_array.push(i);
            }
          } else if (this.current_page === response['result']['totalPages']) {
            for (let i = this.current_page - 2; i <= response['result']['totalPages']; i++) {
              this.pages_array.push(i);
            }
          } else {
            for (let i = this.current_page - 1; i <= response['result']['totalPages']; i++) {
              this.pages_array.push(i);
            }
          }
          
          this.total_pages = parseInt(response['result']['totalPages']);
          this.current_page = response['result']['currentPage'];
          this.total = parseInt(response['result']['totalItems']);
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
        }
      })
      .finally(() => {});
  }
  
  setTradeType(text,value){
    this.tradeType=value;
    this.tradeType_text=text;
  }
  setDays(amount: number): void {
    this.daysOverview = amount;
    console.log(`Setting days to `,this.daysOverview);
  }
  print(){
    this.spinner.show();
    var current = new Date();
    this.params=JSON.parse(localStorage.getItem('itemLists')); 
    this.fileName=current.toLocaleDateString()+"-topUser.xlsx";
    console.log('parent params',this.params);
    this.request.postBlob(Urls.exportTopUsers,this.params).then(report => {
      const blob = new Blob([report], { type: 'application/vnd.ms-excel' });
      const data = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = data;
      link.download = this.fileName;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        this.spinner.hide();
      }, 500);
    });
  }
  reset(){
    this.form.reset();
    this.searchForm_filter.reset();
    this.searchForm_filter2.reset();
    this.tradeType=null; 
    this.tradeType_text=null; 
    this.daysOverview=null; 
    this.dateFromValue=null;
    this.dateToValue=null;
  }
}
