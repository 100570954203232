import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpClientModule } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Urls } from './urls';
// import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class EnvService {
  private secure = true;

    /* For Local */
    // private secure = false;
    private payload: any;

    /* LIVE Production */
    // private domain = 'https://backend.megapay.com.ph:444/megapayportalbackend/api';
    // private url = 'https://backend.megapay.com.ph:444/megapayportalbackend/api';
    // private secureUrl = 'https://backend.megapay.com.ph:444/megapayportalbackend/api';

    /* LIVE Staging */
    private domain = 'development.megapay.com.ph:444/megapayportalbackend/api/';
    private url = 'https://development.megapay.com.ph:444/megapayportalbackend/api/';
    private secureUrl = 'https://development.megapay.com.ph:444/megapayportalbackend/api/';

    // /* LIVE Staging */
    // private domain = 'development.megapay.com.ph:444/megapayportalbackend/api/';
    // private url = 'https://staging.megapay.com.ph:444/megapayportalbackend/api/';
    // private secureUrl = 'https://staging.megapay.com.ph:444/megapayportalbackend/api/';

  constructor(public http: HttpClient, public router: Router) {}

  getUrl(path: string, params: any = null): any {
      let url = this.secure ? this.secureUrl : this.url;
      url += path;
      if (params) {
          url += this.createUrlParam(params);
      }
      return url;
  }

  createUrlParam(p) {
      let uriStr = '?';
      for (let key of Object.keys(p)) {
          if (p[key]) {
              uriStr += key + '=' + p[key] + '&';
          }
      }
      return uriStr;
  }

  getHttpOptions() {
    const sessionId = this.getCookie('sessionId');
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (sessionId) {
      headers = headers.append('Authorization', 'Bearer ' + sessionId);
    }

    return { headers: headers };
  }

//   getUrl(url: string) {
//     return `https://development.megapay.com.ph:444/megapayportalbackend/api/${url}`;
//   }

  getCookie(name: string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
}