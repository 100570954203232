import { Component, OnInit ,  VERSION} from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { RequestsService } from 'src/app/services/http/requests.service';
import { Urls } from 'src/app/lib/urls';
import { DebounceService } from 'src/app/services/debounce.service';
import { NgxSpinnerService } from "ngx-spinner";
import { DialogService } from 'src/app/services/dialog.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute,Router} from '@angular/router';
// import * as XLSX from 'xlsx';


@Component({
  selector: 'app-cash-in-transaction-logs',
  templateUrl: './cash-in-transaction-logs.component.html',
  styleUrls: ['./cash-in-transaction-logs.component.scss']
})
export class CashInTransactionLogsComponent implements OnInit {
  public form: FormGroup;
  isLoading: boolean;
  itemLists:any;
  search:any;
  tabId:any;
  fileName:any;
  url:any;
  params={ 
    search: '',
  }

  //pagination
  page=1;
  size=10;
  total_pages:any;
  current_page = 1;
  pages_array=[
  ];
  total:any;
  item:any;
  lastPage:any;

  //paginationfilter
  page_filter=1;
  size_filter=10;
  total_pages_filter:any;
  current_page_filter = 1;
  pages_array_filter=[
  ];
  total_filter:any;
  item_filter:any;
  lastPage_filter:any;
  itemLists_filter:any;

  //paginationfilter2
  page_filter2=1;
  size_filter2=10;
  total_pages_filter2:any;
  current_page_filter2 = 1;
  pages_array_filter2=[
  ];
  total_filter2:any;
  item_filter2:any;
  lastPage_filter2:any;
  itemLists_filter2:any;
 
  //search
  searchKey: any=null;
  searchForm : FormGroup;
  search_keyword='';

  //searchfilter
  searchKey_filter: any=null;
  searchForm_filter : FormGroup;
  search_keyword_filter='';

  //searchfilter2
  searchKey_filter2: any=null;
  searchForm_filter2 : FormGroup;
  search_keyword_filter2='';

  //selection viewALL or search
  option = 0
  selectedOption:any;

  //limit
  limitKey:any=null;
  limitForm : FormGroup; 

  //limitFilter
  limitKey_filter:any=null;
  limitForm_filter: FormGroup; 

  //limitFilter
  limitKey_filter2:any=null;
  limitForm_filter2: FormGroup; 

  //null with default
  name = 'Angular ' + VERSION.major;

  //filterfields
  filterFields:any;
  filterFields2:any;
  
  //bankName
  bankName_text:any;
  bankName:any;

  //status
  status_text:any;
  status:number;

  uid:any;
  
  //sorting
  sort_column="dateTime";
  sort_type="ASC";

  accountType='';

  constructor(
    private fb : FormBuilder,
    public debounce: DebounceService,
    private request: RequestsService,
    private spinner: NgxSpinnerService,
    private dialogService:DialogService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.initializeForm();
   }

  ngOnInit(): void {
    var tableHeader = document.getElementsByTagName("th");  
    for (var i = 0; i < tableHeader.length; i++) { 
      tableHeader[i].classList.add("headerSortDown");
    }
    this.uid = this.route.snapshot.paramMap.get('id');
    this.get_List();

      // Retrieve the account type from localStorage and parse it
      const accountType = localStorage.getItem('accountType');
      if (accountType) {
          this.accountType = accountType;
      } else {
          this.accountType = null; // Handle case when 'accountType' is not present in localStorage
      }
    console.log('logged', this.accountType);

  }
  
  initializeForm(){
    this.form = this.fb.group({
      transactionId: [null],
      phoneNumber: [null],
      dateFrom: [null], 
      dateTo: [null], 
    });
    //search
    this.searchKey = this.debounce.debounce(() => {
      this.current_page=1;
      this.search_keyword=this.searchForm.value.search;
      console.log('searchKeyword', this.search_keyword)
      this.get_List();
    }, 500); 
    this.searchForm = this.fb.group({
      search:['']
    });
  //searchfilter  
    this.searchKey_filter = this.debounce.debounce(() => {
      this.current_page_filter=1;
      this.search_keyword_filter=this.searchForm_filter.value.search;
      console.log('searchKeyword', this.search_keyword_filter)
      this.get_search_list();
    }, 500); 
    this.searchForm_filter = this.fb.group({
      search:['']
    });
  //searchfilter2
    this.searchKey_filter2 = this.debounce.debounce(() => {
      this.current_page_filter2=1;
      this.search_keyword_filter2=this.searchForm_filter2.value.search;
      console.log('searchKeyword', this.search_keyword_filter2)
      this.get_search_list2();
    }, 500); 
    this.searchForm_filter2 = this.fb.group({
      search:['']
    });
  //limit
    this.limitKey = this.debounce.debounce(() => {
      this.current_page=1;
      this.size=this.limitForm.value.limit;
      this.get_List();
    }, 500);  
    this.limitForm = this.fb.group({
      limit:['']
    }); 
  //limitfilter
    this.limitKey_filter = this.debounce.debounce(() => {
      this.current_page_filter=1;
      this.size_filter=this.limitForm_filter.value.limit;
      this.get_search_list();
    }, 500);  
    this.limitForm_filter = this.fb.group({
      limit:['']
    }); 
    //limitfilter2
    this.limitKey_filter2 = this.debounce.debounce(() => {
      this.current_page_filter2=1;
      this.size_filter2=this.limitForm_filter2.value.limit;
      this.get_search_list2();
    }, 500);  
    this.limitForm_filter2 = this.fb.group({
      limit:['']
    }); 
  }

  sortList(sort_column, sort_type){
    this.spinner.show();
    if(sort_type=="ASC"&&this.sort_column!=sort_column&&this.sort_type==sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="ASC"&&this.sort_column==sort_column&&this.sort_type!=sort_type){
      this.sort_type="ASC";
      this.sort_column=sort_column;
    }else if(sort_type=="ASC"&&this.sort_column==sort_column&&this.sort_type==sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="DESC"&&this.sort_column!=sort_column&&this.sort_type==sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="DESC"&&this.sort_column==sort_column&&this.sort_type!=sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="DESC"&&this.sort_column==sort_column&&this.sort_type==sort_type){
      this.sort_type="ASC";
      this.sort_column=sort_column;
    }
    if(this.itemLists){
      this.get_List();
    }else if (this.itemLists_filter){
      this.itemLists=null;
      this.current_page_filter=1;
      this.get_search_list()
    }else if(this.itemLists_filter2){
      this.itemLists=null;
      this.current_page_filter2=1;
      this.get_search_list2();
    }
    console.log('sort_column',this.sort_column);
    console.log('sort_type',this.sort_type);
  }

  submit(){
    this.searchForm_filter.reset();
    this.searchForm_filter2.reset();
    this.search_keyword_filter=null;
    this.search_keyword_filter2=null;
    if(this.itemLists || this.itemLists_filter){
      this.itemLists=null;
      this.itemLists_filter=null;
      this.current_page_filter=1;
      this.getSearch2()
    }else if(this.itemLists || this.itemLists_filter2){
      this.itemLists=null;
      this.itemLists_filter2=null;
      this.current_page_filter2=1;
      this.getSearch()
    }
  }
  
getSearch(){
  this.option = 2
  let params ={ 
    transactionId:this.form.controls.transactionId.value,
    phoneNumber:this.form.controls.phoneNumber.value,
    dateFrom: this.form.controls.dateFrom.value,
    dateTo: this.form.controls.dateTo.value,
    bankName:this.bankName,
    status:this.status,
    sortBy: {
      [this.sort_column]: this.sort_type
    }
  }
  this.pages_array_filter=[];
  this.request.post(Urls.cashinDeposits + '?page=' + this.current_page_filter + '&size=' + this.size_filter, params).then(response => {
    if(response['status']==200){
      localStorage.setItem('itemLists', JSON.stringify(response['result']['data']));
      this.itemLists_filter=response['result']['data'];
      this.filterFields=response['result']['filterFields']
      console.log(this.itemLists_filter)
      console.log(this.filterFields)
      this.selectedOption="getSearch";
      console.log(this.selectedOption)
        if(this.current_page_filter  === 0  ){
          this.pages_array_filter=[];
          for(let i=this.current_page_filter; i<=response['result']['totalPages'];i++){
            this.pages_array_filter.push(i);
          };
        }else if(this.current_page_filter==1){
          for(let i=1; i<=response['result']['totalPages'];i++){
            this.pages_array_filter.push(i);
          };
        }else if(this.current_page_filter==response['result']['totalPages']){
          for(let i=this.current_page_filter-2; i<=response['result']['totalPages'];i++){
            this.pages_array_filter.push(i);
          };
        }else{
          for(let i=this.current_page_filter-1; i<=response['result']['totalPages'];i++){
            this.pages_array_filter.push(i);
          };
        }
        this.total_pages_filter=parseInt(response['result']['totalPages']);
        this.current_page_filter=response['result']['currentPage'];
        this.total_filter=parseInt(response['result']['totalItems']);
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      }
    }).finally(()=>{
  }); 
  this.form.reset();
  this.bankName=null;
  this.bankName_text=null;
  this.status=null;
  this.status_text=null;
}

get_search_list(){
  this.option = 2
  let params ={
    transactionId:this.filterFields.transactionId,
    phoneNumber:this.filterFields.phoneNumber,
    dateFrom:this.filterFields.dateFrom,
    dateTo:this.filterFields.dateTo,
    bankName:this.filterFields.bankName,
    status:this.filterFields.status,
    search:this.search_keyword_filter,
    sortBy: {
      [this.sort_column]: this.sort_type
    }
  }
  this.pages_array_filter=[];
  console.log(params)
  this.request.post(Urls.cashinDeposits + '?page=' + this.current_page_filter + '&size=' + this.size_filter, params).then(response => {
    if(response['status']==200){
      localStorage.setItem('itemLists', JSON.stringify(response['result']['data']));
      this.itemLists_filter=response['result']['data'];
      this.filterFields=response['result']['filterFields']
      this.selectedOption="getSearch";
      console.log(this.selectedOption)
        if(this.current_page_filter  === 0  ){
          this.pages_array_filter=[];
          for(let i=this.current_page_filter; i<=response['result']['totalPages'];i++){
            this.pages_array_filter.push(i);
          };
        }else if(this.current_page_filter==1){
          for(let i=1; i<=response['result']['totalPages'];i++){
            this.pages_array_filter.push(i);
          };
        }else if(this.current_page_filter==response['result']['totalPages']){
          for(let i=this.current_page_filter-2; i<=response['result']['totalPages'];i++){
            this.pages_array_filter.push(i);
          };
        }else{
          for(let i=this.current_page_filter-1; i<=response['result']['totalPages'];i++){
            this.pages_array_filter.push(i);
          };
        }
        this.total_pages_filter=parseInt(response['result']['totalPages']);
        this.current_page_filter=response['result']['currentPage'];
        this.total_filter=parseInt(response['result']['totalItems']);
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      }
    }).finally(()=>{
  }); 
}

getSearch2(){
  this.option = 3
  let params ={ 
    transactionId:this.form.controls.transactionId.value,
    phoneNumber:this.form.controls.phoneNumber.value,
    dateFrom: this.form.controls.dateFrom.value,
    dateTo: this.form.controls.dateTo.value,
    bankName:this.bankName,
    status:this.status,
    sortBy: {
      [this.sort_column]: this.sort_type
    }
  }
  this.pages_array_filter2=[];
  this.request.post(Urls.cashinDeposits + '?page=' + this.current_page_filter2 + '&size=' + this.size_filter2, params).then(response => {
    if(response['status']==200){
      localStorage.setItem('itemLists', JSON.stringify(response['result']['data']));
      this.itemLists_filter2=response['result']['data'];
      this.filterFields2=response['result']['filterFields']
      console.log(this.itemLists_filter2)
      console.log(this.filterFields2)
      this.selectedOption="getSearch2";
      console.log(this.selectedOption)
        if(this.current_page_filter2  === 0  ){
          this.pages_array_filter2=[];
          for(let i=this.current_page_filter2; i<=response['result']['totalPages'];i++){
            this.pages_array_filter2.push(i);
          };
        }else if(this.current_page_filter2==1){
          for(let i=1; i<=response['result']['totalPages'];i++){
            this.pages_array_filter2.push(i);
          };
        }else if(this.current_page_filter2==response['result']['totalPages']){
          for(let i=this.current_page_filter2-2; i<=response['result']['totalPages'];i++){
            this.pages_array_filter2.push(i);
          };
        }else{
          for(let i=this.current_page_filter2-1; i<=response['result']['totalPages'];i++){
            this.pages_array_filter2.push(i);
          };
        }
        this.total_pages_filter2=parseInt(response['result']['totalPages']);
        this.current_page_filter2=response['result']['currentPage'];
        this.total_filter2=parseInt(response['result']['totalItems']);
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      }
    }).finally(()=>{
  }); 
  this.form.reset();
  this.bankName=null;
  this.bankName_text=null;
  this.status=null;
  this.status_text=null;
}

get_search_list2(){
  this.option = 3
  this.spinner.show();
  let params ={ 
    transactionId:this.filterFields2.transactionId,
    phoneNumber:this.filterFields2.phoneNumber,
    dateFrom:this.filterFields2.dateFrom,
    dateTo:this.filterFields2.dateTo,
    bankName:this.filterFields2.bankName,
    status:this.filterFields2.status,
    search:this.search_keyword_filter2,
    sortBy: {
      [this.sort_column]: this.sort_type
    }
  }
  this.pages_array_filter2=[];
  console.log(params)
  this.request.post(Urls.cashinDeposits + '?page=' + this.current_page_filter2 + '&size=' + this.size_filter2, params).then(response => {
    if(response['status']==200){
      localStorage.setItem('itemLists', JSON.stringify(response['result']['data']));
      this.itemLists_filter2=response['result']['data'];
      this.filterFields2=response['result']['filterFields']
      console.log(this.filterFields2)
      this.selectedOption="getSearch2";
      console.log(this.selectedOption)
        if(this.current_page_filter2  === 0  ){
          this.pages_array_filter2=[];
          for(let i=this.current_page_filter2; i<=response['result']['totalPages'];i++){
            this.pages_array_filter2.push(i);
          };
        }else if(this.current_page_filter2==1){
          for(let i=1; i<=response['result']['totalPages'];i++){
            this.pages_array_filter2.push(i);
          };
        }else if(this.current_page_filter2==response['result']['totalPages']){
          for(let i=this.current_page_filter2-2; i<=response['result']['totalPages'];i++){
            this.pages_array_filter2.push(i);
          };
        }else{
          for(let i=this.current_page_filter2-1; i<=response['result']['totalPages'];i++){
            this.pages_array_filter2.push(i);
          };
        }
        this.total_pages_filter2=parseInt(response['result']['totalPages']);
        this.current_page_filter2=response['result']['currentPage'];
        this.total_filter2=parseInt(response['result']['totalItems']);
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      }
    }).finally(()=>{
  }); 
}

get_List(){
    this.option = 1
    this.spinner.show();
    let params ={ 
      search : this.search_keyword,
      sortBy: {
        [this.sort_column]: this.sort_type
      }
    }
    this.pages_array=[];
    this.request.post(Urls.cashinDeposits + '?page=' + this.current_page + '&size=' + this.size, params).then(response => {
      this.itemLists=response['result']['data'];
      if(response['status']==200){
          if(this.current_page  === 0  ){
            this.pages_array=[];
            for(let i=this.current_page; i<=response['result']['totalPages'];i++){
              this.pages_array.push(i);
            };
          }else if(this.current_page==1){
            for(let i=1; i<=response['result']['totalPages'];i++){
              this.pages_array.push(i);
            };
          }else if(this.current_page==response['result']['totalPages']){
            for(let i=this.current_page-2; i<=response['result']['totalPages'];i++){
              this.pages_array.push(i);
            };
          }else{
            for(let i=this.current_page-1; i<=response['result']['totalPages'];i++){
              this.pages_array.push(i);
            };
          }
          this.total_pages=parseInt(response['result']['totalPages']);
          this.current_page=response['result']['currentPage'];
          this.total=parseInt(response['result']['totalItems']);
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
        }
      }).finally(()=>{
    }); 
  }

  setPage(num){
    if (this.option == 1){ 
      // TOTAL PAGE USE THIS
      console.log("option is 1 : viewALL")
      this.current_page = num;
      this.get_List();
    }else if (this.option == 2){ 
      // TOTAL PAGE USE THIS
      console.log("option is 2 : searchFILTER")
      this.current_page_filter = num;
      this.get_search_list()
    }else if (this.option == 3){ 
      // TOTAL PAGE USE THIS
      console.log("option is 3 : searchFILTER2")
      this.current_page_filter2 = num;
      this.get_search_list2()
    }
  }

  nextPage(){
    if (this.option == 1){ 
      // TOTAL PAGE USE THIS
      console.log("option is 1 : viewALL")
      if(this.current_page < Number(this.total_pages)){
        this.current_page++;
        this.get_List()
      }
    }
    else if (this.option == 2){
      // FILTER PAGE USE NEXT FILTER
      console.log("option is 2 : searchFILTER")
      if(this.current_page_filter < Number(this.total_pages_filter)){
        this.current_page_filter++;
        this.get_search_list()
      }
    } else if (this.option == 3){
      // FILTER PAGE USE NEXT FILTER
      console.log("option is 3 : searchFILTER2")
      if(this.current_page_filter2 < Number(this.total_pages_filter2)){
        this.current_page_filter2++;
        this.get_search_list2()
      }
    }
  }

  prevPage(){
    console.log("prevPage")
    if (this.option == 1){
      // TOTAL PAGE USE THIS
      console.log("option is 1 : viewALL")
      if(this.current_page < Number(this.total_pages)){
        this.current_page--;
        this.get_List()
      }else if (this.current_page == Number(this.total_pages)){
        this.current_page--;
        this.get_List()
      }
    }
    else if (this.option == 2){
      // FILTER PAGE USE NEXT FILTER
      console.log("option is 2 : searchFILTER")
      if(this.current_page_filter < Number(this.total_pages_filter)){
        this.current_page_filter--;
        this.get_search_list()
      }else if(this.current_page_filter == Number(this.total_pages_filter)){
        this.current_page_filter--;
        this.get_search_list()
      }
    }
    else if (this.option == 3){
      // FILTER PAGE USE NEXT FILTER
      console.log("option is 3 : searchFILTER2")
      if(this.current_page_filter2 < Number(this.total_pages_filter2)){
        this.current_page_filter2--;
        this.get_search_list2()
      }else if(this.current_page_filter2 == Number(this.total_pages_filter2)){
        this.current_page_filter2--;
        this.get_search_list2()
      }
    }
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.searchList();
    }
  }

  searchList(){
    if(this.option === 1){
      this.current_page=1;
      this.search_keyword=this.searchForm.value.search;
      console.log('searchKeyword', this.search_keyword)
      this.get_List();
    }else if(this.option === 2){
      this.current_page_filter=1;
      this.search_keyword_filter=this.searchForm_filter.value.search;
      console.log('searchKeyword', this.search_keyword_filter)
      this.get_search_list();
    }
    else if(this.option === 3){
      this.current_page_filter2=1;
      this.search_keyword_filter2=this.searchForm_filter2.value.search;
      console.log('searchKeyword', this.search_keyword_filter2)
      this.get_search_list2();
    }
  }

  setheader(index){
    localStorage.setItem('navigate', index);
  } 
  
  cancel(uid){
    this.dialogService.openConfirmDialog('intererpelorts.megapay.ph says','Are you sure you want to cancel this transaction?')
    .afterClosed().subscribe(res=>{
      if(res){
        let params ={ 
          action:'reject',
        }
        this.request.post(Urls.cashinDeposits + uid,params).then(response => {
          if(response['status']==200){
            this.toastr.success(response['message']+'!', 'Success!');
          }
          else{
            this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
          }
        });
      }
    });
  }

verify(uid){
  this.dialogService.openConfirmDialog('intererpelorts.megapay.ph says','Are you sure you want to verify this transaction?')
  .afterClosed().subscribe(res=>{
    if(res){
    let params ={ 
      action:'verify',
    }
    this.request.post(Urls.cashinDeposits + uid,params).then(response => {
        if(response['status']==200){
          this.toastr.success(response['message']+'!', 'Success!');
        }
        else{
          this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
        }
      }).catch(error => {
        console.error('Error during POST request:', error);
        this.toastr.error('Failed to verify transaction!', 'Error');
      });
    }
  });
} 

approve(uid){
  if(this.accountType === 'Super Admin' || this.accountType === 'Admin'){
    this.dialogService.openConfirmDialog('intererpelorts.megapay.ph says','Are you sure you want to approve this transaction?')
    .afterClosed().subscribe(res=>{
      if(res){
      let params ={ 
        action:'approve',
      }
      this.request.post(Urls.cashinDeposits + uid,params).then(response => {
          if(response['status']==200){
            this.toastr.success(response['message']+'!', 'Success!');
          }
          else{
            this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
          }
        }).catch(error => {
          console.error('Error during POST request:', error);
          this.toastr.error('Failed to approve transaction!', 'Error');
        });
      }
    });
  }else {
    this.toastr.error('You are not allowed to approve this transaction!', 'Error');
  }
} 




 
  setBankType(text,value){
    this.bankName=value;
    this.bankName_text=text;
    
  }
  setStatusType(text,value){
    this.status=value;
    this.status_text=text;
    
  }
  openSite(imageUrl) {
    window.open( imageUrl, '_blank');
 } 
  print(){
    this.spinner.show();
    var current = new Date();
    this.params=JSON.parse(localStorage.getItem('itemLists')); 
    this.fileName=current.toLocaleDateString()+"-cash-in-transaction-logs.xlsx";
    console.log('parent params',this.params);
    this.request.postBlob(Urls.exportCashinDeposits,this.params).then(report => {
      const blob = new Blob([report], { type: 'application/vnd.ms-excel' });
      const data = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = data;
      link.download = this.fileName;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        this.spinner.hide();
      }, 500);
    });
  }

}