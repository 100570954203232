<div class="w-full h-full ">
    <div class=" w-full  maxh-550-px py-4 px-3 overflow-x-auto w-table mt-5">
        <div class="w-full flex items-center justify-between space-x-3 pr-5 text-sm ">
            <div class="flex items-center pl-5"></div>
            <div class="flex items-center space-x-3">
                <div class="orange-text text-xs" >Search:</div>
                <input class="orange-input focus:outline-none  text-xs px-2 py-1" type="text"  [(ngModel)]="search" [ngModelOptions]="{standalone: true}" >
            </div>
        </div>
        <div class="data-table4 mt-3 barlow  m-5  "  style="font-size: 10px;">
            <table class="w-full" >
                <thead class="bg-light-orange "> 
                    <tr class="header-row" style="border-left: 1px #CAC2BC solid; border-right: 1px #CAC2BC solid;">
                        <th class="text-left barlow orange-text pl-2 pt-3 pb-3">
                            USERNAME
                        </th>
                        <th class="text-left barlow orange-text pl-2 pt-3 pb-3">
                            FULLNAME
                        </th>
                        <th class="text-left barlow orange-text">
                            EMAIL
                        </th>
                        <th class="text-left barlow orange-text">
                            ACCOUNT TYPE
                        </th>
                        <th class="text-left barlow orange-text pl-2 pt-3 pb-3">
                            DATE CREATED
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-row-not-expanded focus:outline-none" style="border-left: 1px #CAC2BC solid; border-right: 1px #CAC2BC solid;"
                    *ngFor="let user of users | filter:search ; index as i">
                        <td >
                            <div class="flex items-center ">
                                <button (click)="user.isExpanded = !user.isExpanded" class="material-icons lock-unlock w-30-px  h-full focus:outline-none"  *ngIf="!user.isExpanded">
                                    <img src="assets/images/icon/add icon (blue).png" >
                                </button>
                                <button (click)="user.isExpanded = !user.isExpanded" class=" material-icons lock-unlock w-30-px h-full focus:outline-none"  *ngIf="user.isExpanded">
                                    <img src="assets/images/icon/minus icon (red).png" >
                                </button>
                                {{ user.username | nullWithDefault }}
                            </div>
                            <div *ngIf="user.isExpanded" class="expanded-panel">
                                <div class="table-container mt-5 " >
                                    <table class="w-full ">
                                        <tr class="header-row">
                                            <th class="w-90-px pl-2 p-2" style="text-align: left">Last Login</th>
                                            <th class="p-2" style="text-align: left">{{user.loginTime  | date:' yyyy-dd-MM  hh:mm:ss' | nullWithDefault}}</th> 
                                         </tr>
                                         <tr class="header-row" style="text-align: left">
                                             <th class="w-90-px pl-2 p-2">Modify Date </th>
                                             <th class="p-2" style="text-align: left">{{user.dateModified | date:' yyyy-dd-MM  hh:mm:ss' | nullWithDefault}}</th> 
                                         </tr>
                                         <tr class="header-row" style="text-align: left">
                                             <th class="w-90-px pl-2 p-2">Modify By </th>
                                             <th class="p-2"  style="text-align: left">{{user.modifiedBy  | nullWithDefault}}</th> 
                                         </tr>
                                         <tr class="header-row">
                                             <th class="w-90-px pl-2 p-2" style="text-align: left">Status</th>
                                             <th class="p-2"  style="text-align: left">
                                                 <p *ngIf="user.status==0">Active</p>
                                                 <p *ngIf="user.status!==0"> Inactive</p>
                                             </th>
                                         </tr>
                                        <tr class="header-row" style=" border-bottom: 0px !important;">
                                            <th class=" w-90-px mt-5 pl-2"  style="text-align: left">
                                                <br>Action<br><br>
                                            </th>
                                            <th>
                                                <ng-container *ngIf="user">
                                                    <p class="  cursor-pointer" style="text-align: left;margin-top: 15px; margin-left: 7px;" *ngIf="user.status==0" (click) = "changeStatus(user.uid, 1)">LOCK</p>
                                                    <p class="  cursor-pointer" style="text-align: left;margin-top: 15px; margin-left: 7px;" *ngIf="user.status==1" (click) = "changeStatus(user.uid, 0)">UNLOCK</p>
                                              </ng-container>
                                               <br>
                                                <p class=" cursor-pointer"  style="text-align: left; margin-left: 7px;" [routerLink]="['/admin/portal-account/portal-modify-account/'+user.uid]" > Account Settings</p>
                                            </th>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </td> 
                        <td>
                            <div>{{user.firstName | nullWithDefault  | titlecase}}&nbsp;{{user.lastName  | titlecase}}</div>
                            <div *ngIf="user.isExpanded" class="expanded-panel"><div class="table-container mt-56" ></div></div>      
                        </td>
                        <td>
                            <div>{{user.email | nullWithDefault}}</div>
                            <div *ngIf="user.isExpanded" class="expanded-panel"><div class="table-container mt-56" ></div></div>      
                        </td>
                        <td>
                            <div  *ngIf="user.accountType | nullWithDefault">  
                                <p *ngIf="user.accountType==1">Admin</p>
                                <p *ngIf="user.accountType==2">Super Admin</p>
                                <p *ngIf="user.accountType==3">User</p>
                                <p *ngIf="user.accountType=='Admin'">Admin</p>
                                <p *ngIf="user.accountType=='Super Admin'">Super Admin</p>
                                <p *ngIf="user.accountType=='User'">User</p>
                                <p *ngIf="user.accountType=='Finance'">Finance</p>
                                <p *ngIf="user.accountType=='MIS'">MIS</p>
                            </div>
                            <div *ngIf="user.isExpanded" class="expanded-panel"><div class="table-container mt-56" ></div></div>      
                        </td>
                        <td>
                            <div>{{user.dateCreated  | date:' yyyy-dd-MM hh:mm:ss' | nullWithDefault}}</div>
                            <div *ngIf="user.isExpanded" class="expanded-panel"><div class="table-container mt-56" ></div></div>      
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="col-span-full flex justify-between items-center mt-2">
                <div class="orange-text text-xs pl-6" >
                    Showing {{page}} of {{size}} entree
                </div>
            </div>
            <div class="flex items-center space-x-3 text-xs justify-start">
                <button class="uppercase custom-btn btn-dark-orange font-semibold text-xs px-6 py-2 mt-10 mb-5 " [routerLink]="['/admin/portal-account/portal-create-account']" routerLinkActive="router-link-active">
                CREATE ACCOUNT
                </button>
            </div>
        </div>
    </div>
</div>