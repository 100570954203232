<div class="w-full h-full">
    <div class="grid grid-cols-12 gap-3">
        <div class="col-span-12">
            <div class="text-m text-black font-bold uppercase text-center">
                KYC Details
            </div>
        </div>
        <div class="col-span-12">
            <hr class="border border-gray-300 w-full ">
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-60-px font-semibold">
                    Name:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    {{firstName | nullWithDefault | titlecase }}&nbsp;{{middleName  | titlecase}}&nbsp;{{lastName  | titlecase}}
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-140-px font-semibold">
                    Phone Number:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    {{userName  | nullWithDefault}}
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-70-px font-semibold">
                    Address:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    {{address  | nullWithDefault |titlecase  }}
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-40-px font-semibold">
                    City:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    {{city  | nullWithDefault  |titlecase  }}
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-70-px font-semibold">
                    Province:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    {{province  | nullWithDefault   |titlecase  }}
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-90-px font-semibold">
                    Zip Code
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    {{zipCode  | nullWithDefault}}
                </div>
            </div>
        </div>
        <div class="col-span-4">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-418-px font-semibold">
                    Birth Date (yyyy-mm-dd): 
                </span>
                <div *ngIf="year || month || day" class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    {{ year }}&nbsp;- {{ month | number : '2.0-0' }}&nbsp;-{{ day | number : '2.0-0' }}
                </div>
                <div *ngIf="!year || !month || !day" class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    N / A
                </div>
            </div>
        </div>
        <div class="col-span-4">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-120-px font-semibold">
                    Birth Place:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    {{birthPlace  | nullWithDefault  |titlecase }}
                </div>
            </div>
        </div>
        <div class="col-span-4">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-140-px font-semibold">
                    Email Verified:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    {{emailVerified_text | nullWithDefault}}
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-100-px font-semibold">
                    Civil Status:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    {{civilStatus_text  | nullWithDefault }}
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-100-px font-semibold">
                    Nationality:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    {{nationality  | nullWithDefault }}
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-190-px font-semibold">
                    Employment Status:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    {{employmentStatus  | nullWithDefault }}
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-150-px font-semibold">
                    Source of Fund:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    {{sourceOfFund  | nullWithDefault }}
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-50-px font-semibold">
                    Status:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    {{status_text | nullWithDefault}}
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-30-px font-semibold">
                    Tier:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    {{tierType_text | nullWithDefault}}
                </div>
            </div>
        </div>
        <div class="col-span-6 mb-3">
            <div class="pl-2 flex flex-col">
                <div class="text-xs font-semibold">
                    Selfie Image
                </div>
                <div class="w-220-px h-190-px border-2 border-gray-300">
                    <img [src]="imageSelfieUrl" class="w-full h-200-px object-contain" *ngIf="imageSelfieUrl"> <br/>
                    <div class="py-12 text-center text-xs italic " *ngIf="!imageIdUrl" >
                        N / A
                    </div>
                </div>
            </div>
        </div>
        <div class="col-span-6  mb-10">
            <div class="pl-2 flex flex-col">
                <div class="text-xs font-semibold">
                    ID Image
                </div>
                <div class="w-220-px h-190-px border-2 border-gray-300">
                    <img [src]="imageIdUrl" class="w-full h-200-px object-contain" *ngIf="imageIdUrl"> <br/>
                    <div class="py-12 text-center text-xs italic " *ngIf="!imageIdUrl" >
                        N / A
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>