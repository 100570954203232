<div class="w-full h-full">
    <div class="grid grid-cols-12 gap-3">
        <div class="col-span-12">
            <div class="text-m text-black font-bold uppercase text-center">
                Basic Information
            </div>
        </div>
        <div class="col-span-12">
            <hr class="border border-gray-300 w-full ">
        </div>
        <div class="col-span-4">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-120-px font-semibold">
                    First Name:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    {{firstName | nullWithDefault |titlecase }}
                </div>
            </div>
        </div>
        <div class="col-span-4">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-140-px font-semibold">
                    Middle Name:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    {{middleName | nullWithDefault | titlecase }}
                </div>
            </div>
        </div>
        <div class="col-span-4">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-120-px font-semibold">
                    Last Name:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    {{lastName | nullWithDefault  | titlecase}}
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-140-px font-semibold">
                    Phone Number:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    {{userName | nullWithDefault}}
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-140-px font-semibold">
                    Email:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    {{email | nullWithDefault}}
                </div>
            </div>
        </div>
        <div class="col-span-4">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-140-px font-semibold">
                    Register Date:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    {{createDate | date:' yyyy-dd-MM  hh:mm:ss'| nullWithDefault}}
                </div>
            </div>
        </div>
        <div class="col-span-4">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-150-px font-semibold">
                    Referrer Name:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic"  *ngFor="let ref of referrerData" >
                   {{ref.firstName | nullWithDefault  }}&nbsp;{{ref.lastName | nullWithDefault  }}
                </div>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic"  *ngIf="referrerData == null" >
                    N / A
                </div>
            </div>
        </div>
        <div class="col-span-4">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-190-px font-semibold">
                    Referrer Number:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    {{referrerNumber | nullWithDefault}}
                </div>
            </div>
        </div>
        <div class="col-span-12">
            <hr class="border border-gray-300 w-full my-1 mb-3">
        </div>
        <div class="col-span-4">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-120-px font-semibold">
                    Tier:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    {{tierType_text | nullWithDefault  | titlecase}}
                </div>
            </div>
        </div>
        <div class="col-span-4">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-140-px font-semibold">
                    Active:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    {{status_text | nullWithDefault  | titlecase}}
                </div>
            </div>
        </div>
        <div class="col-span-4">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-140-px font-semibold">
                    Email Verified:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center italic">
                    {{emailVerified_text | nullWithDefault}}
                </div>
            </div>
        </div>
        <div class="col-span-12">
            <hr class="border border-gray-300 w-full my-1 mb-3">
        </div>
        <div class="col-span-12 mb-10">
            <div class="card-input text-xs  w-full " >
                <table class="w-full" >
                    <thead class="bg-gray-100 " > 
                        <tr >
                            <th class="text-left text-xs  font-semibold p-2 ">
                                CARD NO
                            </th>
                            <th class="text-left  text-xs font-semibold p-2 ">
                                CARDHOLDER NAME
                            </th>
                            <th class="text-left text-xs font-semibold p-2 ">
                               CARD EMAIL
                            </th>
                            <th class="text-left text-xs font-semibold p-2 ">
                               TIME
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let abc of abcLists ">
                            <td class=" text-left text-xs  p-2 italic" >
                                {{abc.cardNo | nullWithDefault}}
                            </td>
                            <td class=" text-left text-xs  p-2 italic" >
                                {{abc.cardHolderName | nullWithDefault | titlecase}}
                            </td>
                            <td class=" text-left text-xs  p-2 italic" >
                                {{abc.cardEmail | nullWithDefault}}
                            </td>
                            <td class=" text-left text-xs  p-2 italic " >
                                {{abc.createDate  | date:' yyyy-dd-MM  hh:mm:ss' | nullWithDefault}}
                            </td>
                        </tr>
                        <tr *ngIf="abcLists == ''">
                            <td class=" text-center text-xs  p-2 italic" colspan="4">
                              No Records Found !
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>