import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { RequestsService } from 'src/app/services/http/requests.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { DebounceService } from 'src/app/services/debounce.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'src/app/services/dialog.service';
import { Urls } from 'src/app/lib/urls';

@Component({
  selector: 'app-features-announcement',
  templateUrl: './features-announcement.component.html',
  styleUrls: ['./features-announcement.component.scss']
})
export class FeaturesAnnouncementComponent implements OnInit {
img:any;
announcement_lists:any;

search:any;
  tabId:any;
  fileName:any;
  url:any;
  params={ 
    search: '',
  }

//search
searchKey: any=null;
searchForm : FormGroup;
search_keyword='';

//limit
limitKey:any=null;
limitForm : FormGroup;

//pagination
page=1;
size=5;
total_pages:any;
current_page = 1;
pages_array=[
];
total:any;

isLoading=true;
public announcementForm: FormGroup;
imageFile: {link: string, file: any, name: string};

//sorting
sort_column="dateTime";
sort_type="ASC";

currentDate= new Date();
status:any;

  constructor(
    public userService: UserService,
    private toastr: ToastrService,
    public request: RequestsService,
    private fb : FormBuilder,
    public debounce: DebounceService,
    private spinner: NgxSpinnerService,
    private dialogService:DialogService,
  ) { 
    this.announcementForm = this.fb.group({
      webViewUrl: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      dateFrom: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      dateTo: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      description: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      image: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
    });
    this.searchKey = this.debounce.debounce(() => {
      this.current_page=1;
      this.search_keyword=this.searchForm.value.search;
      console.log('searchKeyword', this.search_keyword)
      this.get_announcement_list();
    }, 500);  
    this.limitKey = this.debounce.debounce(() => {
      this.current_page=1;
      this.size=this.limitForm.value.limit;
      this.get_announcement_list();
    }, 500);  
    this.searchForm = this.fb.group({
      search:['']
    });
    this.limitForm = this.fb.group({
      limit:['']
    });
  }
  sortList(sort_column, sort_type){
    this.spinner.show();
    if(sort_type=="ASC"&&this.sort_column!=sort_column&&this.sort_type==sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="ASC"&&this.sort_column==sort_column&&this.sort_type!=sort_type){
      this.sort_type="ASC";
      this.sort_column=sort_column;
    }else if(sort_type=="ASC"&&this.sort_column==sort_column&&this.sort_type==sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="DESC"&&this.sort_column!=sort_column&&this.sort_type==sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="DESC"&&this.sort_column==sort_column&&this.sort_type!=sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="DESC"&&this.sort_column==sort_column&&this.sort_type==sort_type){
      this.sort_type="ASC";
      this.sort_column=sort_column;
    }
    if(this.announcement_lists){
      this.get_announcement_list();
    }
    console.log('sort_column',this.sort_column);
    console.log('sort_type',this.sort_type);
  }

  ngOnInit(): void {
    this.get_announcement_list();
    var tableHeader = document.getElementsByTagName("th");  
    for (var i = 0; i < tableHeader.length; i++) { 
      tableHeader[i].classList.add("headerSortDown");
    }

  }

  onSelectFile(event) { 
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      let link=event.srcElement.files[0];
      let name=event.srcElement.files[0].name;
      reader.onload = (event: any) => {
        this.imageFile = {
            link: event.target.result,
            file: link,
            name: name
        };
        this.img = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]); 
    }
    console.log(event.srcElement.files[0].name);
  }

  get_announcement_list(){
    this.spinner.show();
    let params ={ 
      webViewUrl : this.search_keyword,
      search : this.search_keyword,
      sortBy: {
        [this.sort_column]: this.sort_type
      }
    }
    this.pages_array=[];
    this.request.post(Urls.announcements + '?page=' + this.current_page + '&size=' + this.size, params).then(response => {
    this.status=response['result']['status'];
        console.log(this.status)
      if(response['status']==200){
        this.announcement_lists=response['result']['data'];
        
        this.isLoading=false;
        if(this.current_page  === 0  ){
          this.pages_array=[];
          for(let i=this.current_page; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }else if(this.current_page==1){
          for(let i=1; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }else if(this.current_page==response['result']['totalPages']){
          for(let i=this.current_page-2; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }else{
          for(let i=this.current_page-1; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }
        this.total_pages=parseInt(response['result']['totalPages']);
        this.current_page=response['result']['currentPage'];
        this.total=parseInt(response['result']['totalItems']);
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      }
    }).finally(()=>{
    });   
  }

  initializeForm(){
    
  }

  store(){
    this.spinner.show();
    let formData = new FormData();
    var url="";
    if(this.announcementForm.controls.webViewUrl.value.includes('https://')){
      url=this.announcementForm.controls.webViewUrl.value.replace("https://", "www.");
    }else{
      url=this.announcementForm.controls.webViewUrl.value;
    }
    formData.append('webViewUrl', url);
    formData.append('dateFrom', moment.utc(this.announcementForm.controls.dateFrom.value).format());
    formData.append('dateTo', moment.utc(this.announcementForm.controls.dateTo.value).format());
    formData.append('description', this.announcementForm.controls.description.value);
    formData.append('image', this.imageFile.file);
    this.request.post(Urls.store_announcement, formData).then(response => {
      console.log(response);
      if(response['status']==201){
        this.toastr.success(response['message']+'!', 'Success!');
        this.get_announcement_list();
      }else{
        // console.log(response['error']);
        this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
      }
      setTimeout(() => {
        this.spinner.hide();
      }, 500);
    });
    this.announcementForm.reset();
  } 

  setPage(num){
    this.current_page = num;
    this.get_announcement_list();
  }
  nextPage(){
    if(this.current_page < Number(this.total_pages)){
         this.current_page++;
         this.get_announcement_list()
     }
   }
  prevPage(){
    if(this.current_page <= Number(this.total_pages)){
      this.current_page--;
      this.get_announcement_list();
    }
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.searchList();
    }
  }
  searchList(){
    this.current_page=1;
    this.search_keyword=this.searchForm.value.search;
    console.log('searchKeyword', this.search_keyword)
    this.get_announcement_list();
  }
  
  disable(id,value){
    this.dialogService.openConfirmDialog('intererpelorts.megapay.ph says','Are you sure you want to disable this announcement?')
    .afterClosed().subscribe(res=>{
      if(res){
        let formData = new FormData();
        formData.append('status', value);
        this.request.put(Urls.announcements + '/' + id, formData).then(response => {
          if(response['status']==200){
            this.get_announcement_list();
          this.toastr.success(response['message']+'!', 'Success!');
          }
          else{
            this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
          }
        })
      }
    });
  }
  
  enable(id,value){
    this.dialogService.openConfirmDialog('intererpelorts.megapay.ph says','Are you sure you want to enable this announcement?')
    .afterClosed().subscribe(res=>{
      if(res){
        let formData = new FormData();
        formData.append('status', value);
        this.request.put(Urls.announcements + '/' + id, formData).then(response => {
          if(response['status']==200){
            this.get_announcement_list();
          this.toastr.success(response['message']+'!', 'Success!');
          }
          else{
            this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
          }
        })
      }
    });
  } 

  print(){
    this.spinner.show();
    var current = new Date();
    this.params=JSON.parse(localStorage.getItem('announcement_lists')); 
    this.fileName=current.toLocaleDateString()+"-announcements.xlsx";
    console.log('parent params',this.params);
    this.request.postBlob(Urls.exportAnnouncements,this.params).then(report => {
      const blob = new Blob([report], { type: 'application/vnd.ms-excel' });
      const data = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = data;
      link.download = this.fileName;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        this.spinner.hide();
      }, 500);
    });
  }
}


