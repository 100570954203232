import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ads-dashboard',
  templateUrl: './ads-dashboard.component.html',
  styleUrls: ['./ads-dashboard.component.scss']
})
export class AdsDashboardComponent implements OnInit {

  selected_tab: any = 1;
  constructor() { }

  ngOnInit(): void {
    var tableHeader = document.getElementsByTagName("th");  
    for (var i = 0; i < tableHeader.length; i++) { 
      tableHeader[i].classList.add("headerSortDown");
    }
  }
  selectTab(tab){
    this.selected_tab = tab
  }
}
