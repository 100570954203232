import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestsService } from 'src/app/services/http/requests.service';
import { Urls } from 'src/app/lib/urls';
import { DebounceService } from 'src/app/services/debounce.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-edit-ads-screen',
  templateUrl: './edit-ads-screen.component.html',
  styleUrls: ['./edit-ads-screen.component.scss']
})
export class EditAdsScreenComponent implements OnInit {
  uid:any;
  featured_img: any;
  public ads_screen_form: FormGroup;
  ads_details:any;
  imageFile: {link: string, file: any, name: string};
  screen:any;
  screen_text:any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb : FormBuilder,
    private toastr: ToastrService,
    private request: RequestsService,
    private spinner: NgxSpinnerService
    ) {
    this.initializeForm()
  }

  ngOnInit(): void {
    this.uid = this.route.snapshot.paramMap.get('id');
    this.get_ads_list();
  }
  
  onSelectFile(event) { 
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      let link=event.srcElement.files[0];
      let name=event.srcElement.files[0].name;
      reader.onload = (event: any) => {
        this.imageFile = {
            link: event.target.result,
            file: link,
            name: name
        };
        this.featured_img = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  initializeForm(){
    this.ads_screen_form = this.fb.group({
      title: [''],
      description: [''],
      dateTo: [''],
      dateFrom: [''],
      duration:  [''],
    });
  }

  get_ads_list(){
    this.spinner.show();
    this.request.put(Urls.ads + '/' + this.uid, '').then(response => {
      if(response['status']==200){
        this.ads_details=response['result'];
        this.featured_img=response['result']['imageUrl'];
        this.screen=response['result']['screen'];
        if(response['result']['screen']==1){
          this.screen_text='Cash-in';
        }else if (response['result']['screen']==2) {
          this.screen_text='Buy Load';
        }else if (response['result']['screen']==3) {
          this.screen_text='Paybills';
        }else if (response['result']['screen']==4) {
          this.screen_text='Redeem';
        }else {
          this.screen_text='Purchase';
        }
        this.set_ads_value();
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      }
    }).finally(()=>{
    });   
  }

  update(){
    this.spinner.show();
    this.ads_screen_form.markAllAsTouched();
    let formData = new FormData();
    formData.append('title', this.ads_screen_form.controls.title.value);
    formData.append('description', this.ads_screen_form.controls.description.value);
    formData.append('dateFrom', moment(this.ads_screen_form.controls.dateFrom.value).format('YYYY-MM-DD HH:mm:ss'));
    formData.append('dateTo', moment(this.ads_screen_form.controls.dateTo.value).format('YYYY-MM-DD HH:mm:ss'));
    formData.append('screen', this.screen);
    formData.append('duration', this.ads_screen_form.controls.duration.value);
    this.request.put(Urls.ads + '/' + this.uid, formData).then(response => {
      if(response['status']==200){
        this.toastr.success(response['message']+'!', 'Succes!');
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
        this.router.navigate(['/features/ads-dashboard-screen']);
      }
    })
    this.ads_screen_form.reset();
    this.featured_img=null;
  } 
  
  setScreen(text,value){
    this.screen=value;
    this.screen_text=text;
  }

  set_ads_value(){
    this.ads_screen_form.controls.title.setValue(this.ads_details.title);
    this.ads_screen_form.controls.description.setValue(this.ads_details.description);
    this.ads_screen_form.controls.dateFrom.setValue(this.ads_details.dateFrom);
    this.ads_screen_form.controls.dateTo.setValue(this.ads_details.dateTo);
    this.ads_screen_form.controls.duration.setValue(this.ads_details.duration);
  }
}
