<div class="w-full h-full">
    <div class="flex items-center justify-between space-x-3 pt-6 pl-8 pb-5 text-sm">
        <div class="flex items-center space-x-3 text-xs mb-2">
            <button class=" btn-dark-orange  custom-tabbtn focus:outline-none py-3 px-8 uppercase font-semibold " [routerLink]="['/features/sku-biller-all-transaction']">
                Buy load
            </button>
            <button class="btn-light-orange custom-tabbtn focus:outline-none py-3 px-8 uppercase font-semibold " [routerLink]="['/features/sku-cash-in']">
                Cash In
            </button>
            <button class="btn-light-orange custom-tabbtn focus:outline-none py-3 px-8 uppercase font-semibold " [routerLink]="['/features/sku-pay-bills']">
                pay bills
            </button>
            <button class="btn-light-orange custom-tabbtn focus:outline-none py-3 px-8 uppercase font-semibold " [routerLink]="['/features/sku-stores']">
                stores
            </button>
            <button class="btn-light-orange custom-tabbtn focus:outline-none py-3 px-8 uppercase font-semibold " [routerLink]="['/features/sku-redeem']">
                redeem
            </button>
        </div>
        <div class="flex items-center space-x-3 pr-10"  >
            <svg (click)="print()" class="cursor-pointer " xmlns="http://www.w3.org/2000/svg" width="20" height="21.433" viewBox="0 0 25 21.433" >
                <g id="iconfinder_icon-printer_211880" transform="translate(-32 -64)">
                <path id="Path_369" data-name="Path 369" d="M155.961,313.926h.541v-7.245a.7.7,0,0,0-.692-.681H144.692a.7.7,0,0,0-.692.681v7.245h11.961Z" transform="translate(-105.749 -228.493)"/>
                <g id="Group_77" data-name="Group 77" transform="translate(32 64)">
                    <path id="Path_370" data-name="Path 370" d="M53.869,67.74H52.15V64.82a.856.856,0,0,0-.887-.82H37.743a.856.856,0,0,0-.887.82V67.74H35.137A3.34,3.34,0,0,0,32,70.787V77.53c0,1.518,1.58,3.444,3.137,3.444h1.021c.274,0,.307,0,.307-.781v-2.4A1.788,1.788,0,0,1,38.3,76.006H50.8c.988,0,1.736,1.189,1.736,2.233v1.954c0,.781.006.781.279.781h1.044a3.294,3.294,0,0,0,3.137-3V70.787A3.33,3.33,0,0,0,53.869,67.74Zm-1.328,3.4a.893.893,0,1,1,.893-.893A.9.9,0,0,1,52.54,71.144Z" transform="translate(-32 -64)"/>
                </g>
                </g>
            </svg>
        </div>
    </div>
    <div class="px-8">
        <div class="grid grid-cols-9 gap-3">
            <div class="col-span-5">                
                <div class="card-input flex items-center text-sm py-2 w-11/12 mb-3 ">
                    <div class="flex items-center space-x-4 ">
                    <span class="text-xs w-100-px font-semibold">
                        Provider: 
                    </span>
                        <div #t1="matMenuTrigger" #select1 [matMenuTriggerFor]="type_select" class="  rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                            <span *ngIf="!providerDesc_text" class="barlow text-black text-xs  p-5 italic">== Select an option ==</span>
                            <span *ngIf="providerDesc_text" class="barlow text-black text-xs p-5 italic ">{{providerDesc_text}}</span>
                            <span class="material-icons violet-text">
                                arrow_drop_down
                            </span>
                        </div>
                        <mat-menu class="w-full sports-menu" #type_select="matMenu" >
                            <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs " >
                                == Select an option ==
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs "  (click)="setLoadProvider('Default ( Resound for Globe, Digilink for Smart/Sun/TNT ) ','0')">
                                Default (Resound for Globe, Digilink for Smart/Sun/TNT)
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs "  (click)="setLoadProvider(' Digilink ( Digilink For All Network )','1')">
                                Digilink (Digilink For All Network)
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs "  (click)="setLoadProvider('Resound ( Resound For All Network )','2')">
                                Resound (Resound For All Network)
                             </button> 
                        </mat-menu>
                    </div>
                </div>
            </div>
            
            <div class="col-span-4">
                <div class="w-full h-full flex items-end justify-end pr-10">
                    <button  (click)="switchLoadProvider()" class="uppercase custom-btn btn-dark-orange font-semibold text-xs px-6 py-2">
                        submit
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="px-8 mt-10 ">
        <button class="custom-btn btn-light-red px-4 py-1 text-xs" (click)="toggleDisplay()">
            <span class="material-icons text-white mr-1">
                add_circle
            </span>
            Add Buy Load SKU
        </button>
        <form [hidden]="isDisplay" [formGroup]="buyLoadForm">
        <div  class="grid grid-cols-12 gap-3 pl-10 pr-10 mt-6">
            <div class="col-span-12 mb-3">
                <div class="card-input flex items-center text-xs py-2 w-1/2">
                    <div class="flex items-center space-x-3">
                    <span class="text-xs w-100-px font-semibold">
                        Load Provider:
                    </span>
                        <div #t2="matMenuTrigger" #select2 [matMenuTriggerFor]="load_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                            <span *ngIf="!provider_text" class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                            <span *ngIf="provider_text" class="barlow text-black text-xs font-lg p-5 italic">{{provider_text}}</span>
                            <span class="material-icons violet-text">
                                arrow_drop_down
                            </span>
                        </div>
                        <mat-menu class="w-full sports-menu" #load_select="matMenu">
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" >
                                == Select an option ==
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs"  (click)="setProvider('Default ( Resound for Globe, Digilink for Smart/Sun/TNT ) ','0')">
                                Default (Resound for Globe, Digilink for Smart/Sun/TNT)
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs"  (click)="setProvider(' Digilink ( Digilink For All Network )','1')">
                               Digilink (Digilink For All Network)
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs"  (click)="setProvider('Resound ( Resound For All Network )','2')">
                            Resound (Resound For All Network)
                             </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <div class="col-span-4">
                <div class="card-input flex items-center text-xs py-2 w-full  mb-6">
                    <span class="text-xs w-60-px font-semibold">
                        Name:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="itemName" type="text" placeholder="Enter Name">
                </div>
                <div class="card-input flex items-center text-xs py-2 w-full  mb-3 mt-3">
                    <div class="flex items-center ">
                    <span class="text-xs w-100-px font-semibold">
                        Network Provider:
                    </span>
                        <div #t3="matMenuTrigger" #select3 [matMenuTriggerFor]="network_select" class="rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                            <span *ngIf="!providerType_text" class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                            <span *ngIf="providerType_text" class="barlow text-black text-xs font-lg p-5 italic">{{providerType_text}}</span>
                            <span class="material-icons violet-text ">
                                arrow_drop_down
                            </span>
                        </div>
                        <mat-menu class="w-full sports-menu" #network_select="matMenu">
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" >
                                == Select an option ==
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setProviderType('Smart','1')">
                                Smart
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setProviderType('Globe','2')">
                                Globe
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setProviderType('Sun','3')">
                                Sun
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setProviderType('TNT','4')">
                                TNT
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setProviderType(' Smart Bro','5')">
                                Smart Bro
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setProviderType('Kuryente Load','6')">
                                Kuryente Load
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setProviderType('Cignal','7')">
                               Cignal
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setProviderType('DITO','8')">
                                DITO
                             </button>
                             <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setProviderType('TM','9')">
                                TM
                             </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <div class="col-span-4">
                <div class="card-input flex items-center text-xs py-2 w-full  mb-6 ">
                    <span class="text-xs w-80-px font-semibold">
                        Item ID:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="itemId"  type="text" placeholder="Enter ID">
                </div>
                <div class="card-input flex items-center text-xs py-2 w-full  mb-3 mt-3">
                    <div class="flex items-center ">
                    <span class="text-xs w-100-px font-semibold">
                        Item Type:
                    </span>
                        <div  #t4="matMenuTrigger" #select4 [matMenuTriggerFor]="item_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                            <span *ngIf="!itemType_text"class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                            <span *ngIf="itemType_text"class="barlow text-black text-xs font-lg p-5 italic">{{itemType_text}}</span>
                            <span class="material-icons violet-text ">
                                arrow_drop_down
                            </span>
                        </div>
                        <mat-menu class="w-full sports-menu" #item_select="matMenu">
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" >
                                == Select an option ==
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setItemType('Promo','1')">
                                Promo 
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic"  (click)="setItemType('Load','2')">
                                Load
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs"  (click)="setItemType('Text','3')">
                                Text
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setItemType('Combo','4')">
                                Combo
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs"   (click)="setItemType('Meralco','5')">
                               Meralco
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs"  (click)="setItemType('Cignal','6')">
                               Cignal
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setItemType(' Cignal PPV','7')">
                               Cignal PPV
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <div class="col-span-4 ">
                <div class="card-input flex items-center text-xs py-2 w-full  mb-6">
                    <span class="text-xs w-80-px font-semibold">
                        Category:
                    </span>
                    <input [readonly]=true  class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic"type="text" placeholder="Enter category">
                </div>
                <div class="card-input flex items-center text-xs py-2 w-full  mb-3 ">
                    <span class="text-xs w-50-px font-semibold">
                        Price:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="itemPrice" type="number" placeholder="Enter price"n>
                </div>
            </div>
            <div class="col-span-12  mb-3">
                <div class="card-input flex items-start text-xs py-2 w-full">
                    <span class="text-xs w-160-px font-semibold ">
                        Item Description:
                    </span>
                    <textarea class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 resize-none h-70-px italic" formControlName="itemDesc" placeholder="Enter Description" name="" id="" cols="30" rows="10"></textarea>
                </div>
            </div>
            
            <div class="col-span-12">
                <div class="w-full h-full flex items-end justify-center">
                    <button (click)="store2()" class="uppercase custom-btn btn-dark-orange font-semibold text-xs px-6 py-2">
                        submit
                    </button>
                </div>
            </div>
        </div>
    </form>
    </div>

    <div class="bg-light-orange w-full flex items-center justify-between space-x-3 pr-3 pt-4 text-sm mt-10">
        <div class="flex items-center pl-6" [formGroup]="limitForm" >
            <div class="orange-text text-xs" >Show</div>
            <input class="orange-input focus:outline-none text-center mx-1 w-25-px  text-xs p-1" type="number" formControlName="limit" (change)="limitKey($event)" value="{{size}}">
            <div class="orange-text text-xs" >entries</div>
        </div>
        <div class="flex items-center space-x-3 pr-3" [formGroup]="searchForm" (keydown)="keyDownFunction($event)">
            <div class="orange-text text-xs" >Search:</div>
            <input class="orange-input focus:outline-none pl-1 text-xs p-1" type="text" placeHolder="Search"  formControlName="search" (keyup)="searchKey($event)">
        </div>
    </div>
    <div class=" bg-light-orange w-full  maxh-550-px px-3 overflow-x-auto w-table">
        <div class="data-table4 mt-3 barlow "  style="font-size: 10px;">
            <table class="w-full">
                <thead class="capitalize">
                    <tr>
                        <td class="text-left barlow pl-2 pt-3 pb-3 font-bold" style=" border-top: 1px #CAC2BC solid; border-right: 2px #CAC2BC solid;" colspan="5">
                            SKU INFO
                         </td>
                       
                         <td class="text-left barlow pl-2 pt-3 pb-3 font-bold" style=" border-top: 1px #CAC2BC solid; border-right: 2px #CAC2BC solid;" >
                          
                        </td> 
                        <td class="text-left barlow pl-2 pt-3 pb-3  font-bold" style=" border-top: 1px #CAC2BC solid; border-right: 2px #CAC2BC solid;" >
                          
                        </td> 
                        <td class="text-left barlow pl-2 pt-3 pb-3  font-bold" style=" border-top: 1px #CAC2BC solid; border-right: 2px #CAC2BC solid;" >
                          
                        </td> 
                        <td class="text-left barlow pl-2 pt-3 pb-3 font-bold" style=" border-top: 1px #CAC2BC solid; border-right: 2px #CAC2BC solid;" >
                          
                        </td> 
                        <td class="text-left barlow pl-2 pt-3 pb-3 font-bold" style=" border-top: 1px #CAC2BC solid; border-right: 2px #CAC2BC solid;" >
                          
                        </td> 
                        <td class="text-left barlow pl-2 pt-3 pb-3  font-bold" style=" border-top: 1px #CAC2BC solid; border-right: 2px #CAC2BC solid;" >
                          
                        </td> 
                        <td class="text-left barlow pl-2 pt-3 pb-3 font-bold" style=" border-top: 1px #CAC2BC solid" >
                          
                        </td> 
                    </tr>
                    <tr>
                        <th class="text-left barlow pl-2 pt-3 pb-3 ">
                            NAME
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('name', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3 ">
                           ITEM TYPE
                           <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('itemType', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3 ">
                          ITEM ID
                          <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('itemId', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3 ">
                           CATEGORY
                           <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('category', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3 ">
                           DESCRIPTION
                           <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('description', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3 " style=" border-top: none">
                           PRICE
                           <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('price', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3 " style=" border-top: none" >
                          LOAD PROVIDER
                          <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('loadProvider', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3 " style=" border-top: none">
                           NETWORK PROVIDER
                           <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('itemType', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3 "  style=" border-top: none">
                           SORT
                           <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('sort', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3 " style=" border-top: none">
                           STATUS
                           <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('status', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3 " style=" border-top: none">
                           IMAGE URL
                           <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('imageUrl', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3 "  style=" border-top: none">
                            ACTION
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('status', sort_type)">
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container >
                        <tr class="focus:outline-none" *ngFor="let buyLoad of buyLoad_lists " >
                            <td >{{buyLoad.itemName | nullWithDefault}}</td>
                            <td *ngIf="buyLoad.itemType | nullWithDefault ">
                                <p *ngIf="buyLoad.itemType==1">Promo</p>
                                <p *ngIf="buyLoad.itemType==2">Load</p>
                                <p *ngIf="buyLoad.itemType==3">Text</p>
                                <p *ngIf="buyLoad.itemType==4">Combo</p>
                                <p *ngIf="buyLoad.itemType==5">Meralco</p>
                                <p *ngIf="buyLoad.itemType==6">Cignal</p>
                                <p *ngIf="buyLoad.itemType==7">Cignal PPV</p>  
                                <p *ngIf="buyLoad.itemType==8">Gaming Pins</p>  
                                <p *ngIf="buyLoad.itemType==9">N/A</p>  
                                <p *ngIf="buyLoad.itemType==10">N/A</p>
                            </td>

                            <td >{{buyLoad.itemId | nullWithDefault}}</td>
                            <td >{{buyLoad.itemCategory | nullWithDefault}}</td>
                            <td >{{buyLoad.itemDesc | nullWithDefault}}</td>

                            <!-- For Staging price in database has  two zeros-->
                            <td >{{buyLoad.itemPrice / 100 |number:'1.2-2' |nullWithDefault}}</td>

                            <!-- For Production price in database has four zeros-->
                            <!-- <td >{{buyLoad.itemPrice / 10000 |number:'1.2-2' |nullWithDefault}}</td> -->

                            <td *ngIf="buyLoad.provider | nullWithDefault">
                                <p *ngIf="buyLoad.provider==0"> Default (Resound for Globe, Digilink for Smart/Sun/TNT)</p>
                                <p *ngIf="buyLoad.provider==1"> Digilink (Digilink For All Network)</p>
                                <p *ngIf="buyLoad.provider==2"> Resound (Resound For All Network)</p>
                                <p *ngIf="buyLoad.provider==null"> N/A</p>
                            </td>
                            <td *ngIf="buyLoad.providerType | nullWithDefault ">
                                <p *ngIf="buyLoad.providerType==1">Smart</p>
                                <p *ngIf="buyLoad.providerType==2">Globe</p>
                                <p *ngIf="buyLoad.providerType==3">Sun</p>
                                <p *ngIf="buyLoad.providerType==4">TNT</p>
                                <p *ngIf="buyLoad.providerType==5">Smart Bro</p>
                                <p *ngIf="buyLoad.providerType==6">Kuryente Load</p>
                                <p *ngIf="buyLoad.providerType==7">Cignal</p> 
                                <p *ngIf="buyLoad.providerType==8">DITO</p> 
                                <p *ngIf="buyLoad.providerType==9">TM</p> 
                            </td>
                            <td >{{buyLoad.sort | nullWithDefault}}</td>
                            <td>
                                <p *ngIf="buyLoad.status==0">Active</p>
                                <p *ngIf="buyLoad.status!==0">Inactive</p>
                            </td>
                            <td >{{buyLoad.imageUrl | nullWithDefault}}</td>
                            <td class="text-xs">
                                <div class="flex items-center space-x-3  ">
                                    <ng-container *ngIf="buyLoad" class="pl-2">
                                        <button class="power-btn2 active "  *ngIf="buyLoad.status==0" (click) = "disable(buyLoad.uid, 1)">
                                            Enabled
                                        </button>
                                        <button class="power-btn2"  *ngIf="buyLoad.status==1"  (click) = "enable(buyLoad.uid, 0)">
                                            Disabled
                                        </button>
                                    </ng-container>
                                    <button  class="action-btn-blue2 custom-btn " [routerLink]="['/features/biller-dashboard/sku-biller-all-transaction/sku-biller-all-transaction-edit/'+ buyLoad.uid]">
                                        Edit
                                    </button> 
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
    <div class="bg-light-orange pb-4 col-span-full flex justify-between items-center  pt-4 ">
        <div class="orange-text text-xs pl-6" >
            Showing {{size}} of {{total}} entree
        </div>
        <div class="pr-6  flex items-center justify-end table-pagination text-xs" style="text-align: right;  ">
            <button class="page-btn sarabun-bold orange-box px-2  py-1" style="white-space: nowrap;" [disabled] ="this.current_page == 1" (click)="prevPage()">
                Previous
            </button>
            <ng-container *ngIf="current_page >  1" >
                <button class="page-btn sarabun-bold orange-box px-2  py-1" [ngClass]="{'active':current_page == 1 }"  style="white-space: nowrap;" (click) = "setPage(page)">
                    {{page}}
                </button>  
            </ng-container>
            <ng-container *ngIf="current_page >  1">
                <button class="page-btn sarabun-bold orange-box px-2  py-1"  style="white-space: nowrap;">
                    ...
                </button> 
            </ng-container>
            <ng-container *ngIf="current_page != total_pages">
                <button *ngFor="let item of pages_array | slice:0:2;let i=index;" class="page-btn sarabun-bold orange-box px-2  py-1" [ngClass]="{'active':current_page == item }" style="white-space: nowrap;" (click) = "setPage(item)">
                    {{item}}
                </button>  
            </ng-container>
            <ng-container *ngIf="total_pages > 2 && current_page+1 < total_pages">
                <button class="page-btn sarabun-bold orange-box px-2  py-1" style="white-space: nowrap;">
                    ...
                </button> 
            </ng-container>

            <ng-container *ngIf=" current_page == total_pages || total_pages > 2 && current_page+1 <total_pages">
                <button class="page-btn sarabun-bold orange-box px-2  py-1" [ngClass]="{'active': current_page == total_pages}" style="white-space: nowrap;" (click) = "setPage(total_pages)">
                    {{total_pages}}
                </button> 
            </ng-container>

            <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled]="this.current_page == total_pages " (click)= "nextPage()">
                Next 
            </button> 
        </div>
    </div>
</div> 


<!-- <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "timer" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner> -->