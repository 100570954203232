import { Component, OnInit ,  VERSION} from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { RequestsService } from 'src/app/services/http/requests.service';
import { Urls } from 'src/app/lib/urls';
import { DebounceService } from 'src/app/services/debounce.service';

import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import {HttpClient, HttpParams } from'@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { CurrencyPipe} from'@angular/common';
import { DecimalPipe} from'@angular/common';
import { MatDialog,MatDialogConfig} from'@angular/material/dialog';
import { DialogService } from 'src/app/services/dialog.service';



@Component({
  selector: 'app-sku-biller-all-transaction',
  templateUrl: './sku-biller-all-transaction.component.html',
  styleUrls: ['./sku-biller-all-transaction.component.scss']
})
export class SkuBillerAllTransactionComponent implements OnInit {
  
  public buyLoadForm: FormGroup;
  buyLoad_lists:any;
  isLoading: boolean;
  status:number;
  search:any;
  id:any;
  
  //add SKU
  isDisplay= true;
  toggleDisplay(){
    this.isDisplay= 
    !this.isDisplay;
  }
  
  // load providers
  providerDesc:any;
  providerDesc_text:any;

  // load provider
  provider:any;
  provider_text:any;

  // provider Type
  providerType:any;
  providerType_text:any;

  // item Type
  itemType:any;
  itemType_text:any;
 
  //pagination
  page=1;
  size=10;
  total_pages:any;
  current_page = 1;
  pages_array=[
  ];
  total:any;
  item:any;
  lastPage:any;

 //search
 searchKey: any=null;
 searchForm : FormGroup;
 search_keyword='';

 //limit
 limitKey:any=null;
 limitForm : FormGroup;

  //null with default
  name = 'Angular ' + VERSION.major;

  //sorting
sort_column="dateTime";
sort_type="ASC";

  //print
  tabId:any;
  fileName:any;
  url:any;
  params={ 
    search: '',
  }
 
  constructor(
  
    private fb : FormBuilder,
    public debounce: DebounceService,
    private request: RequestsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private http:HttpClient,
    private route: ActivatedRoute,
    private cp: CurrencyPipe,
    private dp:DecimalPipe,
    private md:MatDialog,
    private dialogService:DialogService,
  ) {
    this.initializeForm()
    }
   
  ngOnInit(): void {
  this.get_LoadProvider_list();
  this.get_buyLoad_list();
  
  var tableHeader = document.getElementsByTagName("th");  
    for (var i = 0; i < tableHeader.length; i++) { 
      tableHeader[i].classList.add("headerSortDown");
    }
    
  }

  initializeForm(){
    this.buyLoadForm = this.fb.group({
      itemName: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      itemId: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      itemDesc: [
        '',
        Validators.compose([
          Validators.required
        ]) 
      ],
      itemPrice: [
        '',
        Validators.compose([
          Validators.required
        ])
      ], 
    });
    this.searchKey = this.debounce.debounce(() => {
      this.current_page=1;
      this.search_keyword=this.searchForm.value.search;
      console.log('searchKeyword', this.search_keyword)
      this.get_buyLoad_list();
    }, 500); 
    this.searchForm = this.fb.group({
      search:['']
    });
    this.limitKey = this.debounce.debounce(() => {
      this.current_page=1;
      this.size=this.limitForm.value.limit;
      this.get_buyLoad_list();
    }, 500);  
    this.limitForm = this.fb.group({
      limit:['']
    });
  }

  
  sortList(sort_column, sort_type){
    this.spinner.show();
    if(sort_type=="ASC"&&this.sort_column!=sort_column&&this.sort_type==sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="ASC"&&this.sort_column==sort_column&&this.sort_type!=sort_type){
      this.sort_type="ASC";
      this.sort_column=sort_column;
    }else if(sort_type=="ASC"&&this.sort_column==sort_column&&this.sort_type==sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="DESC"&&this.sort_column!=sort_column&&this.sort_type==sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="DESC"&&this.sort_column==sort_column&&this.sort_type!=sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="DESC"&&this.sort_column==sort_column&&this.sort_type==sort_type){
      this.sort_type="ASC";
      this.sort_column=sort_column;
    }
    if(this.buyLoad_lists){
      this.get_buyLoad_list();
    }
    console.log('sort_column',this.sort_column);
    console.log('sort_type',this.sort_type);
  }

  get_LoadProvider_list(){
    this.request.get(Urls.providers + '/' ,).then(response => {
       console.log(response);
     })  
   }

  store2(){
    this.spinner.show();
    const modifiedItemPrice = this.buyLoadForm.controls.itemPrice.value * 100;

    this.request.post(Urls.store_buyload, {
      itemName:this.buyLoadForm.controls.itemName.value,
      itemId:this.buyLoadForm.controls.itemId.value,
      itemDesc:this.buyLoadForm.controls.itemDesc.value,
      itemPrice:modifiedItemPrice,
      provider:this.provider,
      providerType:this.providerType,
      itemType:this.itemType,
    }).then(response => {
      console.log(response);
      if(response['status']==200){
        this.toastr.success(response['message']+'!', 'Success!');
      }
      else{
        this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
      }
      this.page=this.lastPage;
      this.get_buyLoad_list();
      setTimeout(() => {
        this.spinner.hide();
      }, 500);
    })
    this.buyLoadForm.reset();
    this.provider_text=null;
    this.providerType_text=null;
    this.itemType_text=null;
  } 
  
  get_buyLoad_list(){
    this.spinner.show();
    let params ={ 
      search : this.search_keyword,
      sortBy: {
        [this.sort_column]: this.sort_type
      }
    }
    this.pages_array=[];
    this.request.post(Urls.buyload + '?page=' + this.current_page + '&size=' + this.size, params).then(response => {
      if(response['status']==200){
        this.buyLoad_lists=response['result']['data'];
        this.isLoading=false;
        if(this.current_page %1 === 0  ){
          this.pages_array=[];
          for(let i=this.current_page; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }else if(this.current_page==1){
          for(let i=1; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }else if(this.current_page==response['result']['totalPages']){
          for(let i=this.current_page-2; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }else{
          for(let i=this.current_page-1; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }
        this.total_pages=parseInt(response['result']['totalPages']);
        this.current_page=response['result']['currentPage'];
        this.total=parseInt(response['result']['totalItems']);
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      }
    }).finally(()=>{
    });   
  }

  setLoadProvider(text,value){
    this.providerDesc=value;
    this.providerDesc_text=text;
    
  }

  setProvider(text,value){
    this.provider=value;
    this.provider_text=text;
  }

  setProviderType(text,value){
    this.providerType=value;
    this.providerType_text=text;
  }

  setItemType(text,value){ 
    this.itemType=value;
    this.itemType_text=text; 
  }

  setPage(num){
    this.current_page = num;
    this.get_buyLoad_list();
  }
  setFirst(num){
    this.page = num;
    this.get_buyLoad_list();
  }

  nextPage(){
    if(this.current_page < Number(this.total_pages)){
         this.current_page++;
         this.get_buyLoad_list()
     }
   }

  prevPage(){
    if(this.current_page <= Number(this.total_pages)){
      this.current_page--;
      this.get_buyLoad_list();
    }
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.searchList();
    }
  }

  searchList(){
    this.current_page=1;
    this.search_keyword=this.searchForm.value.search;
    console.log('searchKeyword', this.search_keyword)
    this.get_buyLoad_list();
  }

  setheader(index){
    localStorage.setItem('navigate', index);
  } 
 
  switchLoadProvider(){ 
    this.request.put(Urls.providers + '/' + '?provider=' + this.providerDesc ,{
      status:this.status
      }).then(response => {
      console.log(response); 
      this.get_LoadProvider_list(); 
      if(response['status']==200){
        this.toastr.success(response['message']+'!', 'Success!');
      }
      else{
        this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
      }
      this.providerDesc_text=null;
    })    
  }
  
  disable(id,value){
    this.dialogService.openConfirmDialog('intererpelorts.megapay.ph says','Are you sure you want to disable this transaction?')
    .afterClosed().subscribe(res=>{
      if(res){
        this.status=value;
        this.request.put(Urls.buyload + '/' + id,{
          status:this.status
        }).then(response => {
          console.log(response);
          if(response['status']==200){
            this.get_buyLoad_list();
            this.toastr.success(response['message']+'!', 'Success!');
          }
          else{
            this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
          }
        });
      }
    });
  }
  
  enable(id,value){
    this.dialogService.openConfirmDialog('intererpelorts.megapay.ph says','Are you sure you want to enable this transaction?')
    .afterClosed().subscribe(res=>{
      if(res){
        this.status=value;
        this.request.put(Urls.buyload + '/' + id,{
          status:this.status
          }).then(response => {
          console.log(response);
          if(response['status']==200){
            this.get_buyLoad_list();
            this.toastr.success(response['message']+'!', 'Success!');
          }
          else{
            this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
          }
        });
      }
    });
  } 
  print(){
    this.spinner.show();
    var current = new Date();
    this.params=JSON.parse(localStorage.getItem('buyLoad_lists')); 
    this.fileName=current.toLocaleDateString()+"-buyload.xlsx";
    console.log('parent params',this.params);
    this.request.postBlob(Urls.exportBuyload,this.params).then(report => {
      const blob = new Blob([report], { type: 'application/vnd.ms-excel' });
      const data = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = data;
      link.download = this.fileName;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        this.spinner.hide();
      }, 500);
    });

    
  }
}
