<div class="w-full h-full">
    <div class="flex items-center justify-between space-x-3 pt-6 pl-8 pb-5 text-sm">
        <div class="flex items-center space-x-3 text-xs mb-2">
            <button class=" btn-light-orange  custom-tabbtn focus:outline-none py-3 px-8 uppercase font-semibold " [routerLink]="['/features/sku-biller-all-transaction']">
                Buy load
            </button>
            <button class="btn-dark-orange custom-tabbtn focus:outline-none py-3 px-8 uppercase font-semibold " [routerLink]="['/features/sku-cash-in']">
                Cash In
            </button>
            <button class="btn-light-orange custom-tabbtn focus:outline-none py-3 px-8 uppercase font-semibold " [routerLink]="['/features/sku-pay-bills']">
                pay bills
            </button>
            <button class="btn-light-orange custom-tabbtn focus:outline-none py-3 px-8 uppercase font-semibold " [routerLink]="['/features/sku-stores']">
                stores
            </button>
            <button class="btn-light-orange custom-tabbtn focus:outline-none py-3 px-8 uppercase font-semibold " [routerLink]="['/features/sku-redeem']">
                redeem
            </button>
        </div>
    </div>
    <div class="px-8">
        <div class="grid grid-cols-9 gap-3">
            <div class="col-span-5">                
                <div class="flex items-center space-x-4">
                    <div #dropdown class="card-input flex items-center space-x-4">
                        <span class="text-xs w-100-px font-semibold">Provider:</span>
                        <button (click)="toggleDropdown()" class="rounded-md py-3 pl-0 minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                            <span *ngIf="!switchProviders_text" class="barlow text-black text-xs p-5 italic">== Select an option ==</span>
                            <span *ngIf="switchProviders_text" class="barlow text-black text-xs p-5 italic">{{switchProviders_text}}</span>
                            <span class="material-icons violet-text">arrow_drop_down</span>
                        </button>
                        <div id="myDropdown" class="dropdown-content" [ngClass]="{ 'show': isDropdownOpen }">
                            <label [ngClass]="{ 'active': selectedProvider === 'all' }" class="tracking-wide focus:outline-none italic text-xs">
                                <div class="sort">
                                    Default - Show All Available Facility
                                    <input type="checkbox" id="defaultCheckbox" class="checkbox" value="all" [(ngModel)]="defaultChecked" (change)="onDefaultChange()">
                                    <span class="checkmark"></span>
                                </div>    
                            </label>

                        <label [ngClass]="{ 'active': selectedProvider === 'maya' || selectedProvider === 'both' }" class="tracking-wide focus:outline-none italic text-xs">
                            <div class="sort">
                                Maya 
                                <input type="checkbox" id="mayaCheckbox" class="checkbox" value="maya" [(ngModel)]="mayaChecked" (change)="onMayaChange()" [disabled]="defaultChecked">
                                <span class="checkmark"></span>
                            </div>
                        </label>
                        
                        <label [ngClass]="{ 'active': selectedProvider === '2c2p' || selectedProvider === 'both' }" class="tracking-wide focus:outline-none italic text-xs">
                            <div class="sort">
                                2C2P 
                                <input type="checkbox" id="c2c2pCheckbox" class="checkbox" value="2c2p" [(ngModel)]="c2c2pChecked" (change)="onC2C2PChange()" [disabled]="defaultChecked">
                                <span class="checkmark"></span>
                            </div>
                        </label>
                        
                                              
                    </div>                                                               
                </div>
            </div>
        </div>
        <div class="col-span-4">
            <div class="w-full h-full flex items-end justify-end pr-10">
                <button (click)="submitSelections()" class="uppercase custom-btn btn-dark-orange font-semibold text-xs px-6 py-2">
                    submit
                </button>
            </div>
        </div>
    </div>
</div>

<div class="px-8 mt-10 ">
    <button class="custom-btn btn-light-red px-4 py-1 text-xs" (click)="toggleDisplay()">
        <span class="material-icons text-white mr-1">
            add_circle
        </span>
        Add Provider
    </button>
    <form [hidden]="isDisplay" [formGroup]="Form">
        <div class="grid grid-cols-12 gap-3 pl-10 pr-10 mt-6">
            <div class="col-span-4  mb-3">
                <div class="card-input flex items-center text-xs py-2 w-full">
                    <span class="text-xs w-180-px font-semibold">
                        Name:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic"  formControlName="name" type="text" placeholder="Product Name">
                </div>
            </div>
            <div class="col-span-4  mb-3">
                <div class="card-input flex items-center text-xs py-2 w-full">
                    <span class="text-xs w-180-px font-semibold">
                        Code:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic"  formControlName="code" type="text" placeholder="Product Code">
                </div>
            </div>
            <div class="col-span-4  mb-3">
                <div class="card-input flex items-center text-xs py-2 w-full">
                    <span class="text-xs w-180-px font-semibold">
                       Description
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic"  formControlName="description" type="text" placeholder="Product Decription">
                </div>
            </div>
            <div class="col-span-12">
                <div class="w-full h-full flex items-end justify-center mb-5">
                    <button  (click)="store()" class="uppercase custom-btn btn-dark-orange font-semibold text-xs px-6 py-2">
                        submit
                    </button>
                </div>
            </div>
        </div>
    </form> 
</div>
<div class=" w-full  maxh-550-px px-3 overflow-x-auto w-table mt-10">
    <div class="data-table4 mt-3 barlow " style="font-size: 10px;">
        <table class="w-full bg-light-orange ">
            <thead class="capitalize">
                <tr>
                    <td class="text-left barlow pl-2 pt-3 pb-3 font-bold" style=" border-top: 1px #CAC2BC solid; " colspan="9">
                        CASH IN INFO
                     </td>
                </tr>
                <tr>
                    <th class="text-left barlow pl-2 pt-3 pb-3 ">
                        NAME
                        <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" >
                    </th>
                    <th class="text-left barlow pl-2 pt-3 pb-3 ">
                       CODE
                       <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" >
                    </th>
                    <th class="text-left barlow pl-2 pt-3 pb-3 ">
                       DESCRIPTION
                       <img src="assets/images/icon/sort2.webp" style="height:15px;float:right">
                    </th>
                    <th class="text-left barlow pl-2 pt-3 pb-3 " >
                       ENABLED
                       <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" >
                    </th>
                    <th class="text-left barlow pl-2 pt-3 pb-3 " >
                      CREATE USER
                      <img src="assets/images/icon/sort2.webp" style="height:15px;float:right">
                    </th>
                    <th class="text-left barlow pl-2 pt-3 pb-3 "  >
                        DATE CREATED
                        <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" >
                     </th>
                    <th class="text-left barlow pl-2 pt-3 pb-3 " >
                       MODIFIED DATE
                       <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" >
                    </th>
                    <th class="text-left barlow pl-2 pt-3 pb-3 " >
                       MODIFIED USER
                       <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" >
                    </th>
                    <th class="text-left barlow pl-2 pt-3 pb-3 " >
                       ACTION
                        <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" >
                     </th>
                </tr>
            </thead>
            <tbody>
                <ng-container >
                    <tr class="focus:outline-none" *ngFor="let buyLoad of buyLoad_lists " >
                        <td >{{buyLoad.name | nullWithDefault}}</td>
                        <td >{{buyLoad.code | nullWithDefault}}</td>
                        <td >{{buyLoad.description | nullWithDefault}}</td>
                        <td >{{buyLoad.isEnabled | nullWithDefault}}</td>
                        <td >{{buyLoad.createUser | nullWithDefault}}</td>
                        <td >{{buyLoad.createDate | nullWithDefault}}</td>
                        <td >{{buyLoad.modifyDate | nullWithDefault}}</td>
                        <td >{{buyLoad.modifyUser | nullWithDefault}}</td>
                        <td class="text-xs">
                            <button  class="action-btn-blue2 custom-btn " [routerLink]="['/features/biller-dashboard/sku-cash-in/sku-cash-in-edit/'+ buyLoad.uid]">
                                Edit
                            </button> 
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
</div>