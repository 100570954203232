import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-cash-in-transaction',
  templateUrl: './cash-in-transaction.component.html',
  styleUrls: ['./cash-in-transaction.component.scss']
})
export class CashInTransactionComponent implements OnInit {
  
  //bankName
  bankName_text:any;
  bankName:any;

  //status
  status_text:any;
  status:number;
  constructor(
    private dialogService:DialogService,
  ) { }

  ngOnInit(): void {
    var tableHeader = document.getElementsByTagName("th");  
    for (var i = 0; i < tableHeader.length; i++) { 
      tableHeader[i].classList.add("headerSortDown");
    }
  }
  approve(){
    // approve(id,value){
    this.dialogService.openConfirmDialog('intererpelorts.megapay.ph says','Are you sure you want to approve the transaction?')
    .afterClosed().subscribe(res=>{
      // if(res){
      //   this.status=value;
      //   this.request.put(Urls.buyload + '/' + id,{
      //     status:this.status
      //   }).then(response => {
      //     console.log(response);
      //       this.get_buyLoad_list();
      //       this.toastr.success(response['message']+'!', 'Success!');
      //   });
      // }
    });
  }

  setBankType(text,value){
    this.bankName=value;
    this.bankName_text=text;
    
  }
  setStatusType(text,value){
    this.status=value;
    this.status_text=text;
    
  }

}
