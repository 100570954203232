import { Component, OnInit,VERSION } from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { RequestsService } from 'src/app/services/http/requests.service';
import { Urls } from 'src/app/lib/urls';
import { ActivatedRoute, Router} from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-accounts-kyc-detail',
  templateUrl: './accounts-kyc-detail.component.html',
  styleUrls: ['./accounts-kyc-detail.component.scss']
})
export class AccountsKycDetailComponent implements OnInit { 

  uid:any;
  firstName:any;
  lastName:any;
  middleName:any;
  userName:any;
  address:any;
  city:any;
  province:any;
  zipCode:any;
  birthPlace:any;
  year:any;
  month:any;
  day:any;
  nationality:any;
  employmentStatus:any;
  sourceOfFund:any;
  imageSelfieUrl: any;
  imageIdUrl:any;
  imageFile: {link: string, file: any, name: string};

  //emailverified
  emailVerified_text:any;
  emailVerified:any;
  
  //tiertype
  tierType_text:any;
  tierId:any;

  //status
  status_text:any;
  status:any;

  //civil status
  civilStatus:any;
  civilStatus_text:any;

  //null with default
  name = 'Angular ' + VERSION.major;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb : FormBuilder,
    private request: RequestsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.uid = this.route.snapshot.paramMap.get('id');
    this.get_user();
  }
  
  get_user(){
    this.request.get(Urls.userAccount + '/' + this.uid, ).then(response => { 
      console.log(response);
      this.firstName=response['result']['firstName'];
      this.lastName=response['result']['lastName'];
      this.middleName=response['result']['middleName']; 
      this.userName=response['result']['userName'];
      this.address=response['result']['address'];
      this.city=response['result']['city']; 
      this.province=response['result']['province']; 
      this.zipCode=response['result']['zipCode']; 
      this.birthPlace=response['result']['birthPlace']; 
      this.year=response['result']['year']; 
      this.month=response['result']['month']; 
      this.day=response['result']['day']; 
      this.nationality=response['result']['nationality']; 
      this.employmentStatus=response['result']['employmentStatus']; 
      this.sourceOfFund=response['result']['sourceOfFund'];
      this.imageSelfieUrl=response['result']['imageSelfieUrl'];
      this.imageIdUrl=response['result']['imageIdUrl'];
      //civilstatus
      this.civilStatus=response['result']['civilStatus']; 
      if(response['result']['civilStatus']==1){ 
        this.civilStatus_text='Single';
      }else {
        this.civilStatus_text='Married';
      }
      //emailVerified
      this.emailVerified=response['result']['emailVerified']; 
      if(response['result']['emailVerified']==0){ 
        this.emailVerified_text='Yes';
      }else {
        this.emailVerified_text='No';
      }
      //tier
      this.tierId=response['result']['tierId']; 
      if(response['result']['tierId']==1){ 
        this.tierType_text='Default';
      }else if (response['result']['tierId']==4) {
        this.tierType_text=' Full';
      }else if (response['result']['tierId']==5) {
        this.tierType_text='Internal';
      }else if (response['result']['tierId']==6) {
        this.tierType_text='Business';
      }else if (response['result']['tierId']==7) {
        this.tierType_text='MegaBiz';
      }else {
        this.tierType_text='MegaOne';
      }
      //status
      this.status=response['result']['status']; 
      if(response['result']['status']==0){ 
        this.status_text='Active';
      }else {
        this.status_text='Inactive';
      }
      //civilstatus
      this.civilStatus=response['result']['civilStatus']; 
      if(response['result']['civilStatus']==1){ 
        this.civilStatus_text='Single';
      }else {
        this.civilStatus_text='Married';
      }
   }) 
  }
}
