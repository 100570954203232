import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Urls } from 'src/app/lib/urls';
import { RequestsService } from 'src/app/services/http/requests.service';
import { DialogService } from 'src/app/services/dialog.service';
import { DatePipe } from '@angular/common';
import { formattedError } from '@angular/compiler';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  currentUser=[];
  firstName: string | null = null;
  lastName: string | null = null;
  accountType: string | null = null;
  loginTime: string |null = null;
  show_dropdown: boolean = false;

  constructor(
    private route: Router,
    private request: RequestsService,
    private dialogService:DialogService,
    private datePipe:DatePipe
  ) {}

  ngOnInit(): void {
    console.log('url: ', this.route.url);
    if (this.route.url == '/dashboard') {
      localStorage.setItem('navigate', '0');  
    }

    // Retrieve firstname and accounttype from localStorage
    this.firstName = localStorage.getItem('firstName');
    this.lastName = localStorage.getItem('lastName');
    this.accountType = localStorage.getItem('accountType');
    this.loginTime = localStorage.getItem('loginTime');
    console.log('Logged in as:', this.firstName, this.lastName); // Check these values
    console.log('Account type:', this.accountType); // Check these values
}


  showDropdown(bool: boolean): void {
    if (bool) {
      this.show_dropdown = !this.show_dropdown;
    } else {
      setTimeout(() => {
        this.show_dropdown = false;
      }, 300);
    }
  }

  logout(): void {
    this.request.get(Urls.logout + '/').then(response => {
      console.log(response);
    });
    setTimeout(() => {
      localStorage.removeItem('sessionId');
      localStorage.removeItem('firstName');
      localStorage.removeItem('lastName');
      localStorage.removeItem('username');
      localStorage.removeItem('accountType');
      localStorage.removeItem('uid');
      localStorage.removeItem('encodedPassword');
      this.route.navigate(['/login']);
    }, 300);
  }

  setheader(index: number): void {
    localStorage.setItem('navigate', index.toString());
  }

  lastLogin(){
    const formattedLoginTime = this.datePipe.transform(this.loginTime,'yyyy-dd-MM hh:mm:ss');
    this.dialogService.openConfirmDialog('intererpelorts.megapay.ph says', formattedLoginTime,true);
  }
}