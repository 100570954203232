<div class="w-full h-full">
    <div class="px-8">
        <div class="grid grid-cols-9 gap-3">
            <!-- <div class="col-span-5">                
                <div class="card-input flex items-center text-sm py-2 w-11/12 mb-3 ">
                    <div class="flex items-center space-x-4 ">
                    <span class="text-xs w-100-px font-semibold">
                        Provider: 
                    </span>
                        <div #t1="matMenuTrigger" #select1 [matMenuTriggerFor]="type_select" class="  rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                            <span *ngIf="!providerDesc_text" class="barlow text-black text-xs  p-5 italic">== Select an option ==</span>
                            <span *ngIf="providerDesc_text" class="barlow text-black text-xs p-5 italic ">{{providerDesc_text}}</span>
                            <span class="material-icons violet-text">
                                arrow_drop_down
                            </span>
                        </div>
                        <mat-menu class="w-full sports-menu" #type_select="matMenu" >
                            <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs " >
                                == Select an option ==
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs "  (click)="setLoadProvider('Default ( Resound for Globe, Digilink for Smart/Sun/TNT ) ','0')">
                                Default (Resound for Globe, Digilink for Smart/Sun/TNT)
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs "  (click)="setLoadProvider(' Digilink ( Digilink For All Network )','1')">
                                Digilink (Digilink For All Network)
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs "  (click)="setLoadProvider('Resound ( Resound For All Network )','2')">
                                Resound (Resound For All Network)
                             </button> 
                        </mat-menu>
                    </div>
                </div>
            </div> -->
            
            <!-- <div class="col-span-4">
                <div class="w-full h-full flex items-end justify-end pr-10">
                    <button  (click)="switchLoadProvider()"   class="uppercase custom-btn btn-dark-orange font-semibold text-xs px-6 py-2">
                        submit
                    </button>
                </div>
            </div> -->
        </div>
    </div>
    <div class="px-8 mt-10 ">
        <!-- <button class="custom-btn btn-light-red px-4 py-1 text-xs" (click)="toggleDisplay()">
            <span class="material-icons text-white mr-1">
                add_circle
            </span>
            Add Buy Load SKU
        </button> -->
        <!-- <form [hidden]="isDisplay" [formGroup]="updateForm"> -->
        <form  [formGroup]="updateForm">
        <div  class="grid grid-cols-12 gap-3 pl-10 pr-10 mt-6">
            <div class="col-span-12 mb-3">
                <div class="card-input flex items-center text-xs py-2 w-1/2">
                    <div class="flex items-center space-x-3">
                    <span class="text-xs w-100-px font-semibold">
                        Load Provider:
                    </span>
                        <div #t2="matMenuTrigger" #select2 [matMenuTriggerFor]="load_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                            <span *ngIf="!provider_text" class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                            <span *ngIf="provider_text" class="barlow text-black text-xs font-lg p-5 italic">{{provider_text}}</span>
                            <span class="material-icons violet-text">
                                arrow_drop_down
                            </span>
                        </div>
                        <mat-menu class="w-full sports-menu" #load_select="matMenu">
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" >
                                == Select an option ==
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs"  (click)="setProvider('Default ( Resound for Globe, Digilink for Smart/Sun/TNT ) ','0')">
                                Default (Resound for Globe, Digilink for Smart/Sun/TNT)
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs"  (click)="setProvider(' Digilink ( Digilink For All Network )','1')">
                               Digilink (Digilink For All Network)
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs"  (click)="setProvider('Resound ( Resound For All Network )','2')">
                            Resound (Resound For All Network)
                             </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <div class="col-span-4">
                <div class="card-input flex items-center text-xs py-2 w-full  mb-6">
                    <span class="text-xs w-60-px font-semibold">
                        Name:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="itemName" type="text" placeholder="Enter Name">
                </div>
                <div class="card-input flex items-center text-xs py-2 w-full  mb-3 mt-3">
                    <div class="flex items-center ">
                    <span class="text-xs w-100-px font-semibold">
                        Network Provider:
                    </span>
                        <div #t3="matMenuTrigger" #select3 [matMenuTriggerFor]="network_select" class="rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                            <span *ngIf="!providerType_text" class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                            <span *ngIf="providerType_text" class="barlow text-black text-xs font-lg p-5 italic">{{providerType_text}}</span>
                            <span class="material-icons violet-text ">
                                arrow_drop_down
                            </span>
                        </div>
                        <mat-menu class="w-full sports-menu" #network_select="matMenu">
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" >
                                == Select an option ==
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setProviderType('Smart','1')">
                                Smart
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setProviderType('Globe','2')">
                                Globe
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setProviderType('Sun','3')">
                                Sun
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setProviderType('TNT','4')">
                                TNT
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setProviderType(' Smart Bro','5')">
                                Smart Bro
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setProviderType('Kuryente Load','6')">
                                Kuryente Load
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setProviderType('Cignal','7')">
                               Cignal
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setProviderType('DITO','8')">
                                DITO
                             </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <div class="col-span-4">
                <div class="card-input flex items-center text-xs py-2 w-full  mb-6 ">
                    <span class="text-xs w-80-px font-semibold">
                        Item ID:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="itemId"  type="text" placeholder="Enter ID">
                </div>
                <div class="card-input flex items-center text-xs py-2 w-full  mb-3 mt-3">
                    <div class="flex items-center ">
                    <span class="text-xs w-100-px font-semibold">
                        Item Type:
                    </span>
                        <div  #t4="matMenuTrigger" #select4 [matMenuTriggerFor]="item_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                            <span *ngIf="!itemType_text"class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                            <span *ngIf="itemType_text"class="barlow text-black text-xs font-lg p-5 italic">{{itemType_text}}</span>
                            <span class="material-icons violet-text ">
                                arrow_drop_down
                            </span>
                        </div>
                        <mat-menu class="w-full sports-menu" #item_select="matMenu">
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" >
                                == Select an option ==
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setItemType('Promo','1')">
                                Promo 
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic"  (click)="setItemType('Load','2')">
                                Load
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs"  (click)="setItemType('Text','3')">
                                Text
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setItemType('Combo','4')">
                                Combo
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs"   (click)="setItemType('Meralco','5')">
                               Meralco
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs"  (click)="setItemType('Cignal','6')">
                               Cignal
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setItemType(' Cignal PPV','7')">
                               Cignal PPV
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <div class="col-span-4 ">
                <div class="card-input flex items-center text-xs py-2 w-full  mb-6">
                    <span class="text-xs w-80-px font-semibold">
                        Category:
                    </span>
                    <input [readonly]=true  class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic"type="text" placeholder="Enter category">
                </div>
                <div class="card-input flex items-center text-xs py-2 w-full  mb-3 ">
                    <span class="text-xs w-50-px font-semibold">
                        Price:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="itemPrice" type="number" placeholder="Enter price"n>
                </div>
            </div>
            <div class="col-span-12  mb-3">
                <div class="card-input flex items-start text-xs py-2 w-full">
                    <span class="text-xs w-160-px font-semibold ">
                        Item Description:
                    </span>
                    <textarea class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 resize-none h-70-px italic" formControlName="itemDesc" placeholder="Enter Description" name="" id="" cols="30" rows="10"></textarea>
                </div>
            </div>
            
            <div class="col-span-12">
                <div class="w-full h-full flex items-end justify-center">
                    <button (click)="update()"  class="uppercase custom-btn btn-dark-orange font-semibold text-xs px-6 py-2">
                        submit
                    </button>
                </div>
            </div>
        </div>
    </form>
    </div>

</div> 

