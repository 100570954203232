<div class="w-full h-full">
    <div class="flex items-center justify-between space-x-3 pt-6 pl-8 pb-5 text-sm">
       <div class="flex items-center space-x-3 text-xs mb-2">
        <button class=" btn-light-orange  custom-tabbtn focus:outline-none py-3 px-8 uppercase font-semibold " [routerLink]="['/features/sku-biller-all-transaction']">
            Buy load
        </button>
        <button class="btn-light-orange custom-tabbtn focus:outline-none py-3 px-8 uppercase font-semibold " [routerLink]="['/features/sku-cash-in']">
            Cash In
        </button>
        <button class="btn-dark-orange custom-tabbtn focus:outline-none py-3 px-8 uppercase font-semibold " [routerLink]="['/features/sku-pay-bills']">
            pay bills
        </button>
        <button class="btn-light-orange custom-tabbtn focus:outline-none py-3 px-8 uppercase font-semibold " [routerLink]="['/features/sku-stores']">
            stores
        </button>
        <button class="btn-light-orange custom-tabbtn focus:outline-none py-3 px-8 uppercase font-semibold " [routerLink]="['/features/sku-redeem']">
            redeem
        </button>
       </div>
       <div class="flex items-center space-x-3 pr-10"  >
           <svg (click)="print()" class="cursor-pointer " xmlns="http://www.w3.org/2000/svg" width="20" height="21.433" viewBox="0 0 25 21.433" >
               <g id="iconfinder_icon-printer_211880" transform="translate(-32 -64)">
               <path id="Path_369" data-name="Path 369" d="M155.961,313.926h.541v-7.245a.7.7,0,0,0-.692-.681H144.692a.7.7,0,0,0-.692.681v7.245h11.961Z" transform="translate(-105.749 -228.493)"/>
               <g id="Group_77" data-name="Group 77" transform="translate(32 64)">
                   <path id="Path_370" data-name="Path 370" d="M53.869,67.74H52.15V64.82a.856.856,0,0,0-.887-.82H37.743a.856.856,0,0,0-.887.82V67.74H35.137A3.34,3.34,0,0,0,32,70.787V77.53c0,1.518,1.58,3.444,3.137,3.444h1.021c.274,0,.307,0,.307-.781v-2.4A1.788,1.788,0,0,1,38.3,76.006H50.8c.988,0,1.736,1.189,1.736,2.233v1.954c0,.781.006.781.279.781h1.044a3.294,3.294,0,0,0,3.137-3V70.787A3.33,3.33,0,0,0,53.869,67.74Zm-1.328,3.4a.893.893,0,1,1,.893-.893A.9.9,0,0,1,52.54,71.144Z" transform="translate(-32 -64)"/>
               </g>
               </g>
           </svg>
       </div>
   </div>
   <div class="px-8">
       <button class="custom-btn btn-light-red px-4 py-1 text-xs" (click)="toggleDisplay()">
           <span class="material-icons text-white mr-1">
               add_circle
           </span>
           Add Biller
       </button>
       <form [hidden]="isDisplay" [formGroup]="payBillsForm">
           <div class="grid grid-cols-12 gap-3 pl-10 pr-10 mt-6">
               <div class="col-span-6 mb-3">
                   <div class="card-input flex items-center text-xs py-2 w-full">
                       <span class="text-xs w-110-px font-semibold">
                           Biller Name:
                       </span>
                       <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="billerName" type="text" placeholder="Biller Name">
                   </div>
               </div>
               <div class="col-span-6 mb-3">
                   <div class="card-input flex items-center text-xs py-2 w-full">
                       <div class="flex items-center space-x-3">
                       <span class="text-xs w-120-px font-semibold">
                           Biller Category:
                       </span>
                           <div #t1="matMenuTrigger" #select1 [matMenuTriggerFor]="billerCategory_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                               <span *ngIf="!billerCategory_text" class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                               <span *ngIf="billerCategory_text" class="barlow text-black text-xs font-lg p-5 italic">{{billerCategory_text}}</span>
                               <span class="material-icons violet-text ">
                                   arrow_drop_down
                               </span>
                           </div>
                           <mat-menu class="w-full sports-menu" #billerCategory_select="matMenu">
                               <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs"  >
                                   == Select an option ==
                               </button>
                               <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBillerCategory('Telecom','2')">
                                   Telecom
                               </button>
                               <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBillerCategory('Credit Card','3')">
                                   Credit Card
                               </button>
                               <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBillerCategory('Cable and Internet','4')">
                                   Cable and Internet
                               </button>
                               <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBillerCategory('Government','5')">
                                   Government
                               </button>
                               <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBillerCategory('Loans','6')">
                                   Loans
                               </button>
                               <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBillerCategory('Others','7')">
                                   Others
                               </button>
                               <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBillerCategory('Travel','8')">
                                   Travel
                               </button>
                               <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBillerCategory('Transpo','9')">
                                   Transpo
                               </button>
                               <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBillerCategory('Shopping','10')">
                                   Shopping
                               </button>
                               <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBillerCategory('Power','11')">
                                   Power
                               </button>
                               <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setBillerCategory('Water','12')">
                                   Water
                               </button>
                           </mat-menu>
                       </div>
                   </div>
               </div>
               <div class="col-span-12 ">
                   <div class="card-input flex items-center text-xs py-2 w-full">
                       <div class="text-xs w-140-px font-semibold">
                           Biller Description:
                       </div>
                       <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="billerDescription" type="text" placeholder="Enter Description">
                   </div>
               </div>
               
               <div class="col-span-4">
                   <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                       <span class="text-xs w-190-px font-semibold">
                           First Field Name:
                       </span>
                       <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="firstFieldName" type="text" placeholder="Field Name">
                   </div>
                   <div class="card-input flex items-center text-xs py-2 w-full mt-6">
                       <span class="text-xs w-190-px font-semibold">
                           Second Field Name:
                       </span>
                       <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="secondFieldName" type="text" placeholder="Field Name">
                   </div>
               </div>
               <div class="col-span-4 mb-3">
                   <div class="card-input flex items-center text-xs py-2 w-full ">
                       <div class="flex items-center space-x-3">
                       <span class="text-xs w-100-px font-semibold">
                           First Field Format:
                       </span>
                           <div #2="matMenuTrigger" #select2 [matMenuTriggerFor]="firstField_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                               <span *ngIf="!firstFieldFormat_text"  class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                               <span *ngIf="firstFieldFormat_text" class="barlow text-black text-xs font-lg p-5 italic">{{firstFieldFormat_text}}</span>
                               <span class="material-icons violet-text pr-2 ">
                                   arrow_drop_down
                               </span>
                           </div>
                           <mat-menu class="w-full sports-menu" #firstField_select="matMenu">
                               <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" >
                                   == Select an option ==
                               </button>
                               <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setFirstFieldFormat('Numeric','1')">
                                   Numeric
                               </button>
                               <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setFirstFieldFormat('Alphanumeric','2')">
                               Alphanumeric
                               </button>
                           </mat-menu>
                       </div>
                   </div>
                   <div class="card-input flex items-center text-xs py-2 w-full mt-6">
                       <div class="flex items-center ">
                       <span class="text-xs w-116-px font-semibold">
                           Second Field Format:
                       </span>
                           <div #t2="matMenuTrigger" #select2 [matMenuTriggerFor]="secondField_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                               <span *ngIf="!secondFieldFormat_text"  class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                               <span *ngIf="secondFieldFormat_text" class="barlow text-black text-xs font-lg p-5 italic">{{secondFieldFormat_text}}</span>
                               <span class="material-icons violet-text pr-3">
                                   arrow_drop_down
                               </span>
                           </div>
                           <mat-menu class="w-full sports-menu" #secondField_select="matMenu">
                               <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" >
                                   == Select an option ==
                               </button>
                               <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setSecondFieldFormat('Numeric','1')">
                               Numeric
                               </button>
                               <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setSecondFieldFormat('Alphanumeric','2')">
                               Alphanumeric
                               </button>
                           </mat-menu>
                       </div>
                   </div>
               </div>

               <div class="col-span-4 mb-1">
                   <div class="card-input flex items-center text-xs py-2 w-full">
                       <span class="text-xs w-300-px font-semibold">
                           First Field Max Length:
                       </span>
                       <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="firstFieldWidth" type="number" placeholder="Length">
                   </div>
                   <div class="card-input flex items-center text-xs py-2 w-full mt-6">
                       <span class="text-xs w-300-px font-semibold">
                           Second Field Max Length:
                       </span>
                       <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="secondFieldWidth"  type="number" placeholder="Length">
                   </div>
               </div>
               <div class="col-span-12 ">
                   <div class="card-input flex items-center text-xs py-2 w-full">
                       <span class="text-xs w-140-px font-semibold">
                           Convenience Fee:
                       </span>
                       <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="convenienceFee" type="number" placeholder="Enter Convenience Fee">
                   </div>
               </div>
               <div class="col-span-12 mt-5">
                   <div class="w-full h-full flex items-end justify-center ">
                       <button  (click)="store()" class="uppercase custom-btn btn-dark-orange font-semibold text-xs px-6 py-2">
                           submit
                       </button>
                   </div>
               </div>
           </div>
       </form>
   </div>
   <div class="bg-light-orange w-full flex items-center justify-between space-x-3 pr-3 pt-4 text-sm mt-10">
       <div class="flex items-center pl-6" [formGroup]="limitForm" >
           <div class="orange-text text-xs" >Show</div>
           <input class="orange-input focus:outline-none text-center mx-1 w-25-px  text-xs p-1" type="number" formControlName="limit" (change)="limitKey($event)" value="{{size}}">
           <div class="orange-text text-xs" >entries</div>
       </div>
       <div class="flex items-center space-x-3 pr-3" [formGroup]="searchForm" (keydown)="keyDownFunction($event)">
           <div class="orange-text text-xs" >Search:</div>
           <input class="orange-input focus:outline-none pl-1 text-xs p-1" type="text" placeHolder="Search"  formControlName="search" (keyup)="searchKey($event)">
       </div>
   </div>
   <div class=" bg-light-orange w-full  maxh-550-px px-3 overflow-x-auto w-table">
       <div class="data-table4 mt-3 barlow "   style="font-size: 10px;">
           <table class="w-full" >
               <thead >
                   <tr>
                       <td class="text-left barlow pl-2 pt-3 pb-3  font-bold" style=" border-top: 1px #CAC2BC solid; border-right: 2px #CAC2BC solid;" colspan="3">
                           BILLER
                        </td>
                        <td class="text-left barlow pl-2 pt-3 pb-3  font-bold" style=" border-top: 1px #CAC2BC solid; border-right: 2px #CAC2BC solid;" colspan="3">
                           FIRST FIELD
                        </td>
                        <td class="text-left barlow pl-2 pt-3 pb-3  font-bold" style=" border-top: 1px #CAC2BC solid; border-right: 2px #CAC2BC solid;" colspan="3">
                           SECOND FIELD
                        </td>
                        <td class="text-left barlow pl-2 pt-3 pb-3  font-bold" style=" border-top: 1px #CAC2BC solid; border-right: 2px #CAC2BC solid;" >
                         
                       </td> 
                       <td class="text-left barlow pl-2 pt-3 pb-3  font-bold" style=" border-top: 1px #CAC2BC solid; border-right: 2px #CAC2BC solid;" >
                         
                       </td> 
                       <td class="text-left barlow pl-2 pt-3 pb-3  font-bold" style=" border-top: 1px #CAC2BC solid; border-right: 2px #CAC2BC solid;" >
                         
                       </td> 
                       <td class="text-left barlow pl-2 pt-3 pb-3 font-bold" style=" border-top: 1px #CAC2BC solid; border-right: 2px #CAC2BC solid;" >
                         
                       </td> 
                       <td class="text-left barlow pl-2 pt-3 pb-3  font-bold" style=" border-top: 1px #CAC2BC solid" >
                         
                       </td> 
                   </tr>
                   <tr>
                       <th class="text-left barlow pl-2 pt-3 pb-3 ">
                           NAME
                           <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('name', sort_type)">
                       </th>
                       <th class="text-left barlow pl-2 pt-3 pb-3  ">
                           CATEGORY
                           <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('category', sort_type)">
                       </th>
                       <th class="text-left barlow pl-2 pt-3 pb-3 ">
                           DESCRIPTION
                           <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('description', sort_type)">
                       </th>
                       <th class="text-left barlow pl-2 pt-3 pb-3  ">
                           LABEL
                           <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('firstFieldName', sort_type)">
                       </th>
                       <th class="text-left barlow pl-2 pt-3 pb-3  ">
                           FORMAT
                           <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('firstFieldFormat', sort_type)">
                       </th>
                       <th class="text-left barlow pl-2 pt-3 pb-3  ">
                           MAX LENGTH
                           <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('firstFieldWidth', sort_type)">
                       </th>
                       <th class="text-left barlow pl-2 pt-3 pb-3 ">
                           LABEL
                           <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('secondFieldName', sort_type)">
                       </th>
                       <th class="text-left barlow pl-2 pt-3 pb-3 ">
                           FORMAT
                           <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('secondFieldFormat', sort_type)">
                       </th>
                       <th class="text-left barlow pl-2 pt-3 pb-3 ">
                           MAX LENGTH
                           <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('secondFieldWidth', sort_type)">
                       </th>
                       <th class="text-left barlow pl-2 pt-3 pb-3 " style=" border-top: none">
                           CONV FEE
                           <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('convenienceFee', sort_type)">
                       </th>
                       <th class="text-left barlow pl-2 pt-3 pb-3  " style=" border-top: none">
                           STATUS
                           <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('status', sort_type)">
                       </th>
                       <th class="text-left barlow pl-2 pt-3 pb-3  " style=" border-top: none">
                           LIMIT
                           <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('hasLimit', sort_type)">
                       </th>
                       <th class="text-left barlow pl-2 pt-3 pb-3 " style=" border-top: none">
                           IMAGE URL
                           <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('imageUrl', sort_type)">
                       </th>
                       <th class="text-left barlow pl-2 pt-3 pb-3  " style=" border-top: none">
                           ACTION
                           <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('status', sort_type)">
                       </th>
                   </tr>
               </thead>
               <tbody>
                   <ng-container >
                       <tr  class="focus:outline-none" *ngFor="let paybills of paybills_lists "  >
                           <td > {{paybills.billerName | nullWithDefault | titlecase}}</td>
                           <td *ngIf="paybills.billerCategory">
                               <p *ngIf="paybills.billerCategory==2">Telecom</p>
                               <p *ngIf="paybills.billerCategory==3">Credit Card</p>
                               <p *ngIf="paybills.billerCategory==4">Cable and Internet</p>
                               <p *ngIf="paybills.billerCategory==5">Government</p>
                               <p *ngIf="paybills.billerCategory==6">Loans</p>
                               <p *ngIf="paybills.billerCategory==7">Others</p>
                               <p *ngIf="paybills.billerCategory==8">Travel</p>
                               <p *ngIf="paybills.billerCategory==9">Transpo</p>
                               <p *ngIf="paybills.billerCategory==10">Shopping</p> 
                               <p *ngIf="paybills.billerCategory==11">Power</p>
                               <p *ngIf="paybills.billerCategory==12">Water</p>
                           </td>
                           <td> {{paybills.billerDescription | nullWithDefault}}</td>
                           <td >{{paybills.firstFieldName  | nullWithDefault | titlecase}}</td>
                           <td *ngIf="paybills.firstFieldFormat">
                               <p *ngIf="paybills.firstFieldFormat==1">Numeric</p>
                               <p *ngIf="paybills.firstFieldFormat==2">Alphanumeric</p>
                           </td>   
                           <td >
                               <p *ngIf="paybills.firstFieldWidth !== null"> {{paybills.firstFieldWidth}}</p>
                               <p *ngIf="paybills.firstFieldWidth == null">0</p>
                           </td>
                           <td >{{paybills.secondFieldName | nullWithDefault | titlecase}}</td>
                           <td *ngIf="paybills.secondFieldFormat">
                               <p *ngIf="paybills.secondFieldFormat==1">Numeric</p>
                               <p *ngIf="paybills.secondFieldFormat==2">Alphanumeric</p>
                           </td>
                           <td >
                               <p *ngIf="paybills.secondFieldWidth !== null"> {{paybills.secondFieldWidth}}</p>
                               <p *ngIf="paybills.secondFieldWidth == null">0</p>
                           </td>
                           <td >{{paybills.convenienceFee }}
                           </td>
                           <td>
                               <p *ngIf="paybills.status==0">Active</p>
                               <p *ngIf="paybills.status!==0">Inactive</p>
                           </td>
                           <td>
                               <p *ngIf="paybills.hasLimit==0">Yes</p>
                               <p *ngIf="paybills.hasLimit!==0">No</p>
                           </td>
                           <td class="flex-items-center" >
                               
                               <a *ngIf="paybills.imageUrl" class="link" (click)="openSite(paybills.imageUrl)">Link</a>
                               <a *ngIf="!paybills.imageUrl" >N/A</a>
                           </td>
                           
                           <td class="text-xs">
                               <div class="flex items-center space-x-3  ">
                                   <ng-container *ngIf="paybills" class="pl-2">
                                       <button class="power-btn2 active" *ngIf="paybills.status==0" (click) = "disable(paybills.uid, 1)">
                                           Enabled
                                       </button>
                                       <button class="power-btn2" *ngIf="paybills.status==1"  (click) = "enable(paybills.uid, 0)">
                                           Disabled
                                       </button>
                                   </ng-container>
                                   <button  class="action-btn-blue2 custom-btn " [routerLink]="['/features/biller-dashboard/sku-pay-bills/sku-paybills-edit/'+ paybills.uid]">
                                       Edit
                                   </button>  
                               </div>
                           </td>
                       </tr>
                   </ng-container>
               </tbody>
           </table>
       </div>
   </div>
   <div class="bg-light-orange pb-4 col-span-full flex justify-between items-center  pt-4 ">
       <div class="orange-text text-xs pl-6" >
           Showing {{size}} of {{total}} entree
       </div>
       <div class="pr-6  flex items-center justify-end table-pagination text-xs" style="text-align: right;  ">
           <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled] ="this.current_page == 1" (click)="prevPage()">
               Previous
           </button>
           <ng-container *ngIf="current_page >  1" >
               <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active':current_page == 1 }"  style="white-space: nowrap;" (click) = "setPage(page)">
                   {{page}}
               </button>  
           </ng-container>
           <ng-container *ngIf="current_page >  1">
               <button class="page-btn sarabun-bold orange-box px-2 py-1"  style="white-space: nowrap;">
                   ...
               </button> 
           </ng-container>
           <ng-container *ngIf="current_page != total_pages">
               <button *ngFor="let item of pages_array | slice:0:2;let i=index;" class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active':current_page == item }" style="white-space: nowrap;" (click) = "setPage(item)">
                   {{item}}
               </button>  
           </ng-container>
           <ng-container *ngIf="total_pages > 2 && current_page+1 < total_pages">
               <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;">
                   ...
               </button> 
           </ng-container>
           
           <ng-container *ngIf=" current_page == total_pages || total_pages > 2 && current_page+1 <total_pages">
               <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active': current_page == total_pages}" style="white-space: nowrap;" (click) = "setPage(total_pages)">
                   {{total_pages}}
               </button> 
           </ng-container>
           <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled]="this.current_page == total_pages " (click)= "nextPage()">
               Next 
           </button> 
     </div>
   </div>
</div> 

<!-- <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "timer" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner> -->