import { Component, OnInit ,  VERSION} from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { RequestsService } from 'src/app/services/http/requests.service';
import { Urls } from 'src/app/lib/urls';
import { DebounceService } from 'src/app/services/debounce.service';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import {HttpClient, HttpParams } from'@angular/common/http';
import { CurrencyPipe} from'@angular/common';
import { DecimalPipe} from'@angular/common';
import { MatDialog,MatDialogConfig} from'@angular/material/dialog';
import { DialogService } from 'src/app/services/dialog.service';



@Component({
  selector: 'app-kyc-form',
  templateUrl: './kyc-form.component.html',
  styleUrls: ['./kyc-form.component.scss']
})
export class KycFormComponent implements OnInit {
  uid:any;
  firstName:any;
  lastName:any;
  middleName:any;
  userName:any;
  email:any;
  city:any;
  province:any;
  year:any;
  month:any;
  day:any;
  birthPlace:any;
  nationality:any;
  zipCode:any;
  employmentStatus:any;
  sourceOfFund:any;
  civilStatus:any;
  address:any;
  civilStatus_text:any
  location:any;
  rotation_id=0;
  rotation_selfie=0;
  zoom_id=100;
  zoom_selfie=100;
  //null with default
  name = 'Angular ' + VERSION.major;

  selected_redirection:string | Blob;

  //image
  imageSelfieUrl: any;
  imageIdUrl: any;
  imageFile: {link: string, file: any, name: string};
  file:any;

  registrations_lists:any;
  img:any;

  constructor(
    private fb : FormBuilder,
    public debounce: DebounceService,
    private request: RequestsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private http:HttpClient,
    private route: ActivatedRoute,
    private cp: CurrencyPipe,
    private dp:DecimalPipe,
    private md:MatDialog,
    private dialogService:DialogService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.uid = this.route.snapshot.paramMap.get('id');
    this.get_kyc_form();   
  }
  get_kyc_form(){
    this.request.get(Urls.kycRegistrations + '/' + this.uid, ).then(response => { 
      console.log(response);
      this.firstName=response['result']['firstName'];
      this.lastName=response['result']['lastName'];
      this.middleName=response['result']['middleName']; 
      this.userName=response['result']['userName']; 
      this.email=response['result']['email']; 
      this.city=response['result']['city'];
      this.province=response['result']['province'];  
      this.year=response['result']['year']; 
      this.month=response['result']['month']; 
      this.day=response['result']['day']; 
      this.birthPlace=response['result']['birthPlace']; 
      this.nationality=response['result']['nationality']; 
      this.zipCode=response['result']['zipCode']; 
      this.employmentStatus=response['result']['employmentStatus']; 
      this.sourceOfFund=response['result']['sourceOfFund']; 
      this.location=response['result']['location']; 
      this.address=response['result']['address']; 
      this.imageIdUrl=response['result']['imageIdUrl'];
      this.imageSelfieUrl=response['result']['imageSelfieUrl'];

      console.log('id image',this.imageIdUrl);
      console.log('selfie image', this.imageSelfieUrl);

      this.civilStatus=response['result']['civilStatus']; 
      if(response['result']['civilStatus']==1){ 
        this.civilStatus_text='Single';
      }else {
        this.civilStatus_text='Married';
      }
     
    })
  }
  disApprove(id)
    disApprove(){
    this.dialogService.openConfirmDialog('intererpelorts.megapay.ph says','Are you sure you want to Disapprove this user?')
    .afterClosed().subscribe(res=>{
      if(res){
        // this.status=value;
        this.request.get(Urls.kycRegistrations + '/' + this.uid + '/' + 'reject' ).then(response => {
          if(response['status']==200){
            this.get_kyc_form();
            this.toastr.success(response['message']+'!', 'Success!');
          }
          else{
            this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
          }
          this.router.navigate(['/kyc/kyc-registration'])
        });
      }
    });
  }
  
  approve(id)
  approve(){
    this.dialogService.openConfirmDialog('intererpelorts.megapay.ph says','Are you sure you want to Approve this user?')
    .afterClosed().subscribe(res=>{
      if(res){
      //   this.status=value;
      this.request.get(Urls.kycRegistrations + '/' + this.uid + '/' + 'approve' ).then(response => {
          if(response['status']==200){
            this.get_kyc_form();
            this.toastr.success(response['message']+'!', 'Success!');
          }
          else{
            this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
          }
          this.router.navigate(['/kyc/kyc-registration']);
        });
      }
    });
  } 

  zoomIn(zoom_value, image_type){
    if(image_type=='id_image'){
      var img = document.getElementById('id_image');
      this.zoom_id=zoom_value + 100;
      img.style.transform = 'scale('+this.zoom_id+'%)';
      img.style.transformOrigin='0 0';
    }else{
      var img = document.getElementById('selfie_image');
      this.zoom_selfie=zoom_value + 100;
      img.style.transform = 'scale('+this.zoom_selfie+'%)';
      img.style.transformOrigin='0 0';
    }
  }

  zoomOut(zoom_value, image_type){
    if(image_type=='id_image'){
      var img = document.getElementById('id_image');
      this.zoom_id=zoom_value - 100;
      if(this.zoom_id==0){
        this.zoom_id = 100;
      }
      img.style.transform = 'scale('+this.zoom_id+'%)';
      console.log(this.zoom_id);
    }else{
      var img = document.getElementById('selfie_image');
      this.zoom_selfie=zoom_value - 100;
      if(this.zoom_selfie==0){
        this.zoom_selfie = 100;
      }
      img.style.transform = 'scale('+this.zoom_selfie+'%)';
    }
  }
  
  rotate(rotation, image_type){
    if(image_type=='id_image'){
      var img = document.getElementById('id_image');
    }else{
      var img = document.getElementById('selfie_image');
    }
    this.rotation_id = rotation + 90;
      if (this.rotation_id === 360) { 
        this.rotation_id = 0;
      }
      img.style.transformOrigin='0 0';
      img.style.transform = 'rotate('+this.rotation_id+'deg)';
  }

}
