import { Component, OnInit ,  VERSION} from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { RequestsService } from 'src/app/services/http/requests.service';
import { Urls } from 'src/app/lib/urls';
import { DebounceService } from 'src/app/services/debounce.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import {HttpClient } from'@angular/common/http';
import { ActivatedRoute ,Router} from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-admin-portal-create-account',
  templateUrl: './admin-portal-create-account.component.html',
  styleUrls: ['./admin-portal-create-account.component.scss']
})
export class AdminPortalCreateAccountComponent implements OnInit {

 public createAccountForm: FormGroup;
  
  // account type
  accountType:any;
  accountType_text:any;


  constructor(
    private fb : FormBuilder,
    public debounce: DebounceService,
    private request: RequestsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    public userService: UserService,
  ) { 
    this.initializeForm()
  }

  ngOnInit(): void {

  }

  initializeForm(){
    this.createAccountForm = this.fb.group({
      username: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      firstName: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      lastName: [
        '',
        Validators.compose([
          Validators.required
        ]) 
      ],
      email: [
        '',
        Validators.compose([
          Validators.required
        ])
      ], 
    });
  }

  store(){
    this.spinner.show();
    this.request.post(Urls.store_users, {
      username:this.createAccountForm.controls.username.value,
      firstName:this.createAccountForm.controls.firstName.value,
      lastName:this.createAccountForm.controls.lastName.value,
      email:this.createAccountForm.controls.email.value,
      accountType:this.accountType
    }).then(response => {
      console.log(response);
      if(response['status']==200){
        this.toastr.success(response['message']+'!', 'Success!');
        this.router.navigate(['/admin/portal-account']);
      }
      else{
        this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
      }
      setTimeout(() => {
        this.spinner.hide();
      }, 500);
    })
    this.createAccountForm.reset();
    this.accountType_text=null; 
  }
   
  setAccountType(text,value){
    this.accountType=value;
    this.accountType_text=text;
  } 
}

