import { Component, OnInit, VERSION } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl} from '@angular/forms';
import { RequestsService } from 'src/app/services/http/requests.service';
import { Urls } from 'src/app/lib/urls';
import { DebounceService } from 'src/app/services/debounce.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'src/app/services/dialog.service';


@Component({
  selector: 'app-sku-pay-bills',
  templateUrl: './sku-pay-bills.component.html',
  styleUrls: ['./sku-pay-bills.component.scss']
})
export class SkuPayBillsComponent implements OnInit {
  public payBillsForm: FormGroup;
  paybills_lists:any;
  status:number=1;
  search:any;
  isLoading: boolean;
 
  //add biller
  isDisplay= true;
  id: any;
  toggleDisplay(){
    this.isDisplay= !this.isDisplay;
  }

   // Biller Provider
   billerProvider_text:any;
   billerProvider:any;
 

  // Biller Category
  billerCategory_text:any;
  billerCategory:any;

  // First Field Format
  firstFieldFormat_text:any;
  firstFieldFormat:any;

  //Second Field Format
  secondFieldFormat_text:any;
  secondFieldFormat:any;

  //pagination
  page=1;
  page2=2;
  size=10;
  total_pages:any;
  total_pages2:any;
  current_page = 1;
  pages_array=[
  ];
  total:any;
  item:any;
  lastPage:any;

  //search
  searchKey: any=null;
  searchForm : FormGroup;
  search_keyword='';

  //limit
  limitKey:any=null;
  limitForm : FormGroup;

  //null with default
  name = 'Angular ' + VERSION.major;

  fieldNamesLists:any;
 
  firstFieldNames:any;
  secondFieldNames:any;

  billerName:any;
  billerDescription:any;
  imageUrls:any;

  //sorting
  sort_column="dateTime";
  sort_type="ASC";

  //print
  tabId:any;
  fileName:any;
  url:any;
  params={ 
    search: '',
  }

  constructor(
    private modal: NgxSmartModalService,
    private fb : FormBuilder,
    public debounce: DebounceService,
    private request: RequestsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private dialogService:DialogService,
  ) {
    this.initializeForm()
   }

  ngOnInit(): void {
    this.get_payBills_list();
    this.get_field_names();
    var tableHeader = document.getElementsByTagName("th");  
    for (var i = 0; i < tableHeader.length; i++) { 
      tableHeader[i].classList.add("headerSortDown");
    }
   
  }
  initializeForm(){
    this.payBillsForm = this.fb.group({
      billerName: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      billerDescription: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      firstFieldName: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      firstFieldWidth: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      
      secondFieldName: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      secondFieldWidth: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      convenienceFee:[
        '',
        Validators.compose([
          Validators.required
        ])
      ]
     
    });

    this.searchKey = this.debounce.debounce(() => {
      this.current_page=1;
      this.search_keyword=this.searchForm.value.search;
      console.log('searchKeyword', this.search_keyword)
      this.get_payBills_list();
    }, 500); 

    this.searchForm = this.fb.group({
      search:['']
    });

    this.limitKey = this.debounce.debounce(() => {
      this.current_page=1;
      this.size=this.limitForm.value.limit;
      this.get_payBills_list();
    }, 500);  

    this.limitForm = this.fb.group({
      limit:['']
    });
  }
  
  sortList(sort_column, sort_type){
    this.spinner.show();
    if(sort_type=="ASC"&&this.sort_column!=sort_column&&this.sort_type==sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="ASC"&&this.sort_column==sort_column&&this.sort_type!=sort_type){
      this.sort_type="ASC";
      this.sort_column=sort_column;
    }else if(sort_type=="ASC"&&this.sort_column==sort_column&&this.sort_type==sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="DESC"&&this.sort_column!=sort_column&&this.sort_type==sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="DESC"&&this.sort_column==sort_column&&this.sort_type!=sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="DESC"&&this.sort_column==sort_column&&this.sort_type==sort_type){
      this.sort_type="ASC";
      this.sort_column=sort_column;
    }
    if(this.paybills_lists){
      this.get_payBills_list();
    }
    console.log('sort_column',this.sort_column);
    console.log('sort_type',this.sort_type);
  }


  store(){
    if(
      this.payBillsForm.controls.billerName.value == null ||
      this.payBillsForm.controls.billerDescription.value == null ||
      this.payBillsForm.controls.firstFieldName.value == null ||
      this.payBillsForm.controls.firstFieldWidth.value == null ||
      this.payBillsForm.controls.secondFieldName.value == null ||
      this.payBillsForm.controls.secondFieldWidth.value == null ||
      this.payBillsForm.controls.convenienceFee.value == null ||
      this.billerCategory == null || this.firstFieldFormat == null || this.secondFieldFormat == null || this.billerProvider == null){
      this.spinner.show();
      let formData = new FormData();
      this.request.post(Urls.store_billers, formData).then(response => {
        this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
          setTimeout(() => {
          this.spinner.hide();
        }, 500);
      });
      this.payBillsForm.reset();
      this.billerProvider_text=null;
      this.firstFieldFormat_text=null;
      this.secondFieldFormat_text=null
      this.billerCategory_text=null;
    }
    else{
      this.spinner.show();
      let formData = new FormData();
      formData.append('billerName', this.payBillsForm.controls.billerName.value);
      formData.append('billerDescription', this.payBillsForm.controls.billerDescription.value);
      formData.append('firstFieldName', this.payBillsForm.controls.firstFieldName.value);
      formData.append('firstFieldWidth', this.payBillsForm.controls.firstFieldWidth.value);
      formData.append('secondFieldName', this.payBillsForm.controls.secondFieldName.value);
      formData.append('secondFieldWidth', this.payBillsForm.controls.secondFieldWidth.value);
      formData.append('convenienceFee', this.payBillsForm.controls.convenienceFee.value);
      formData.append('billerCategory', this.billerCategory);
      formData.append('firstFieldFormat', this.firstFieldFormat);
      formData.append('secondFieldFormat', this.secondFieldFormat);
      formData.append('platform', this.billerProvider);
      this.request.post(Urls.store_billers, formData).then(response => {
        if(response['status']==200){
          this.toastr.success(response['message']+'!', 'Successs!');
          this.get_payBills_list();
       
        }else{
          this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
        }
       
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      });
      this.payBillsForm.reset();
      this.billerProvider_text=null;
      this.firstFieldFormat_text=null;
      this.secondFieldFormat_text=null
      this.billerCategory_text=null;
    }
    
  } 

  get_field_names(){
    this.request.get(Urls.fields_billers).then(response => {
      this.fieldNamesLists=response['result'];
      console.log(response)
    })
  } 

  get_payBills_list(){
    this.spinner.show();
    let params ={ 
      search : this.search_keyword,
      sortBy: {
        [this.sort_column]: this.sort_type
      }
    }
    this.request.post(Urls.billers + '?page=' + this.current_page + '&size=' + this.size, params).then(response => {
      if(response['status']==200){
        this.paybills_lists=response['result']['data'];
        this.imageUrls=response['result']['imageUrl'];
        this.isLoading=false;
        if(this.current_page % 1 === 0  ){
          this.pages_array=[];
          for(let i=this.current_page; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }else if(this.current_page==1){
          for(let i=1; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }else if(this.current_page==response['result']['totalPages']){
          for(let i=this.current_page-2; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }else{
          for(let i=this.current_page-1; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }
        this.total_pages=parseInt(response['result']['totalPages']);
        this.current_page=response['result']['currentPage'];
        this.total=parseInt(response['result']['totalItems']);
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      }
      
    }).finally(()=>{
  });   
  
  }
  setBillerProvider(text,value){
    this.billerProvider=value;
    this.billerProvider_text=text;
}

  setBillerCategory(text,value){
      this.billerCategory=value;
      this.billerCategory_text=text;
  }
  setFirstFieldFormat(text,value){
    this.firstFieldFormat=value;
    this.firstFieldFormat_text=text;
  }
  setSecondFieldFormat(text,value){
    this.secondFieldFormat=value;
    this.secondFieldFormat_text=text;
  }
  
  setPage(num){
    this.current_page = num;
    this.get_payBills_list();
  }
  setFirst(num){
    this.page = num;
    this.get_payBills_list();
  }

  nextPage(){
    if(this.current_page < Number(this.total_pages)){
         this.current_page++;
         this.get_payBills_list()
     }
   }

  prevPage(){
    if(this.current_page <= Number(this.total_pages)){
      this.current_page--;
      this.get_payBills_list();
    }
  }


  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.searchList();
    }
  }

  searchList(){
    this.current_page=1;
    this.search_keyword=this.searchForm.value.search;
    console.log('searchKeyword', this.search_keyword)
    this.get_payBills_list();
  }

  setheader(index){
    localStorage.setItem('navigate', index);
  } 
 

  disable(id,value){
    this.dialogService.openConfirmDialog('intererpelorts.megapay.ph says','Are you sure you want to disable this Biller?')
    .afterClosed().subscribe(res=>{
      if(res){
        let formData = new FormData();
        formData.append('status', value);
        this.request.put(Urls.billers + '/' + id, formData).then(response => {
          console.log(response);
          if(response['status']==200){
            this.get_payBills_list();
            this.toastr.success(response['message']+'!', 'Success!');
          }
          else{
            this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
          }
        })
      }
    });
  }
  
  enable(id,value){
    this.dialogService.openConfirmDialog('intererpelorts.megapay.ph says','Are you sure you want to enable this Biller?')
    .afterClosed().subscribe(res=>{
      if(res){
        let formData = new FormData();
        formData.append('status', value);
        this.request.put(Urls.billers + '/' + id, formData).then(response => {
          console.log(response);
          if(response['status']==200){
            this.get_payBills_list();
            this.toastr.success(response['message']+'!', 'Success!');
          }
          else{
            this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
          }
        })
      }
    });
  }
  openSite(imageUrl) {
    window.open( imageUrl, '_blank');
 } 

 print(){
  this.spinner.show();
  var current = new Date();
  this.params=JSON.parse(localStorage.getItem('paybills_lists')); 
  this.fileName=current.toLocaleDateString()+"-paybills.xlsx";
  console.log('parent params',this.params);
  this.request.postBlob(Urls.exportPaybills,this.params).then(report => {
    const blob = new Blob([report], { type: 'application/vnd.ms-excel' });
    const data = window.URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.href = data;
    link.download = this.fileName;
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    setTimeout(() => {
      this.spinner.hide();
    }, 500);
  });
}
}
 
