import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/app/lib/environment';

@Injectable({
  providedIn: 'root'
})
export class RequestsService {
  api_user_profile() {
    throw new Error('Method not implemented.');
  }

  constructor(
    private http: HttpClient,
    private env: environment
  ) { }

  private getAuthHeader(): HttpHeaders {
    const username = localStorage.getItem('username');
    const encodedPassword = localStorage.getItem('encodedPassword');
    const password = atob(encodedPassword);
    const authHeader = 'Basic ' + btoa(username + ':' + password);

    console.log('Authorization Header:', authHeader); // Debugging statement

    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': authHeader
    });
  }

  post(url, data): any {
    const httpOptions = {
      headers: this.getAuthHeader()
    };

    console.log('HTTP Options:', httpOptions); // Debugging statement

    return new Promise((resolve) => {
      this.http.post(this.env.getUrl(url), data, httpOptions).subscribe(
        result => {
          resolve(result);
        },
        error => {
          resolve(error);
        }
      );
    });
  }

  postBlob(url, data): any {
    const httpOptions = {
      headers: this.getAuthHeader(),
      responseType: 'blob' as 'json'
    };

    console.log('HTTP Options:', httpOptions); // Debugging statement

    return new Promise((resolve) => {
      this.http.post(this.env.getUrl(url), data, httpOptions).subscribe(
        result => {
          resolve(result);
        },
        error => {
          resolve(error);
        }
      );
    });
  }

  get(url): any {
    const httpOptions = {
      headers: this.getAuthHeader()
    };

    console.log('HTTP Options:', httpOptions); // Debugging statement

    return new Promise((resolve) => {
      this.http.get(this.env.getUrl(url), httpOptions).subscribe(
        result => {
          resolve(result);
        },
        error => {
          resolve(error);
        }
      );
    });
  }

  put(url, data): any {
    const httpOptions = {
      headers: this.getAuthHeader()
    };

    console.log('HTTP Options:', httpOptions); // Debugging statement

    return new Promise((resolve) => {
      this.http.put(this.env.getUrl(url), data, httpOptions).subscribe(
        result => {
          resolve(result);
        },
        error => {
          resolve(error);
        }
      );
    });
  }

  debounce(func, wait) {
    let timeout;

    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }


  post2(url, data): any {
    const httpOptions = {
      headers: new HttpHeaders({
        // Add other headers here, but exclude authentication headers
        'Content-Type': 'application/json'
      })
    };
  
    return new Promise((resolve) => {
      this.http.post(this.env.getUrl(url), data, httpOptions).subscribe(
        result => {
          resolve(result);
        },
        error => {
          resolve(error);
        }
      );
    });
  }

 
}