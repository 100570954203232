import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { RequestsService } from 'src/app/services/http/requests.service';
import { Urls } from 'src/app/lib/urls';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sku-cash-in-edit',
  templateUrl: './sku-cash-in-edit.component.html',
  styleUrls: ['./sku-cash-in-edit.component.scss']
})
export class SkuCashInEditComponent implements OnInit {
  public updateForm: FormGroup;
  uid:any;
  status:number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb : FormBuilder,
    private request: RequestsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    this.initializeForm()
  }

  ngOnInit(): void {
    this.uid = this.route.snapshot.paramMap.get('id');
  }

  initializeForm(){
    this.updateForm = this.fb.group({
     name: [''],
     code: [''],
     description: [''],
    });
  }

  update(){
    this.spinner.show();
    this.request.put(Urls.updateCashIn + '/' + this.uid + '/update', {
      name:this.updateForm.controls.name.value,
      code:this.updateForm.controls.code.value,
      description:this.updateForm.controls.description.value,
    }).then(response => {
      console.log(response);
      if(response['status']==200){
        this.toastr.success(response['message']+'!', 'Success!');
        this.router.navigate(['/features/sku-cash-in']);
      }
      else{
        this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
      }
    })
    this.updateForm.reset();
  }
}