import { Component, OnInit ,  VERSION} from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { RequestsService } from 'src/app/services/http/requests.service';
import { Urls } from 'src/app/lib/urls';
import { DebounceService } from 'src/app/services/debounce.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-sku-stores',
  templateUrl: './sku-stores.component.html',
  styleUrls: ['./sku-stores.component.scss']
})
export class SkuStoresComponent implements OnInit {
   //add SKU
   isDisplay= true;
   toggleDisplay(){
     this.isDisplay= 
     !this.isDisplay;
   }

  public storeItemsForm: FormGroup;
  isLoading: boolean;
  stores_list:any;
  lastPage:any;
  selected_redirection:string | Blob;
  search:any;
  status:number;

  // category
  category_text:any;
  category:any;

  //fieldName
  fieldName_text:any;
  fieldName:any;

  //pagination
  page=1;
  size=10;
  total_pages:any;
  current_page = 1;
  pages_array=[
  ];
  total:any;

  //search
  searchKey: any=null;
  searchForm : FormGroup;
  search_keyword='';

  //limit
  limitKey:any=null;
  limitForm : FormGroup;
  
  //null with default
  name = 'Angular ' + VERSION.major;

  //sorting
  sort_column="dateTime";
  sort_type="ASC";

  //print
  tabId:any;
  fileName:any;
  url:any;
  params={ 
    search: '',
  }


  constructor( 
    private fb : FormBuilder,
    public debounce: DebounceService,
    private request: RequestsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private dialogService:DialogService,
    ) {
      this.initializeForm()
    }

  ngOnInit(): void {
    var tableHeader = document.getElementsByTagName("th");  
    for (var i = 0; i < tableHeader.length; i++) { 
      tableHeader[i].classList.add("headerSortDown");
    }
    this.get_stores_list();
 
  }
  initializeForm(){
    this.storeItemsForm = this.fb.group({
      productName: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      productCode: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      productDesc: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      itemPrice: [
        '',
        Validators.compose([
          Validators.required
        ]) 
      ],
      salesStartDate: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      salesEndDate: [
        '',
        Validators.compose([
          Validators.required
        ])
      ], 
      goodsImgUrl: [
        '',
        Validators.compose([
          Validators.required
        ])
      ]
    });
    this.searchKey = this.debounce.debounce(() => {
      this.current_page=1;
      this.search_keyword=this.searchForm.value.search;
      console.log('searchKeyword', this.search_keyword)
      this.get_stores_list();
    }, 500); 

    this.searchForm = this.fb.group({
      search:['']
    });

    this.limitKey = this.debounce.debounce(() => {
      this.current_page=1;
      this.size=this.limitForm.value.limit;
      this.get_stores_list();
    }, 500);  

    this.limitForm = this.fb.group({
      limit:['']
    });
  }
  
  sortList(sort_column, sort_type){
    this.spinner.show();
    if(sort_type=="ASC"&&this.sort_column!=sort_column&&this.sort_type==sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="ASC"&&this.sort_column==sort_column&&this.sort_type!=sort_type){
      this.sort_type="ASC";
      this.sort_column=sort_column;
    }else if(sort_type=="ASC"&&this.sort_column==sort_column&&this.sort_type==sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="DESC"&&this.sort_column!=sort_column&&this.sort_type==sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="DESC"&&this.sort_column==sort_column&&this.sort_type!=sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="DESC"&&this.sort_column==sort_column&&this.sort_type==sort_type){
      this.sort_type="ASC";
      this.sort_column=sort_column;
    }
    if(this.stores_list){
      this.get_stores_list();
    }
    console.log('sort_column',this.sort_column);
    console.log('sort_type',this.sort_type);
  }

  store(){
    this.spinner.show();
    this.request.post(Urls.store_items, {
      productName:this.storeItemsForm.controls.productName.value,
      productCode:this.storeItemsForm.controls.productCode.value,
      productDesc:this.storeItemsForm.controls.productDesc.value,
      itemPrice:this.storeItemsForm.controls.itemPrice.value,
      salesStartDate:this.storeItemsForm.controls.salesStartDate.value.format('YYYY-MM-DD'),
      salesEndDate:this.storeItemsForm.controls.salesEndDate.value.format('YYYY-MM-DD'),
      goodsImgUrl:this.storeItemsForm.controls.goodsImgUrl.value,
      category:this.category,
      fieldName:this.fieldName
    }).then(response => {
      console.log(response);
      if(response['status']==200){
        this.toastr.success(response['message']+'!', 'Success!');
      }
      else{
        this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
      }
      this.get_stores_list();
      setTimeout(() => {
        this.spinner.hide();
      }, 500);
    })
    this.storeItemsForm.reset();
    this.category_text=null;
    this.fieldName_text=null;
  } 
  

  get_stores_list(){
    this.spinner.show();
    let params ={ 
      search : this.search_keyword,
      sortBy: {
        [this.sort_column]: this.sort_type
      }
    }
    this.pages_array=[];
    this.request.post(Urls.items + '?page=' + this.current_page + '&size=' + this.size, params).then(response => {
      if(response['status']==200){
        this.stores_list=response['result']['data'];
        this.isLoading=false;
        if(this.current_page %1 === 0  ){
          this.pages_array=[];
          for(let i=this.current_page; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }else if(this.current_page==1){
          for(let i=1; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }else if(this.current_page==response['result']['totalPages']){
          for(let i=this.current_page-2; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }else{
          for(let i=this.current_page-1; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }
        this.total_pages=parseInt(response['result']['totalPages']);
        this.current_page=response['result']['currentPage'];
        this.total=parseInt(response['result']['totalItems']);
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      }
    }).finally(()=>{
    });   
  }
  
  setCategory(text,value){
    this.category=value;
    this.category_text=text;
  }

  setFieldName(text,value){
    this.fieldName=value;
    this.fieldName_text=text;

  }
  setPage(num){
    this.current_page = num;
    this.get_stores_list();
  }
  setFirst(num){
    this.page = num;
    this.get_stores_list();
  }

  nextPage(){
    if(this.current_page < Number(this.total_pages)){
         this.current_page++;
         this.get_stores_list()
     }
   }

  prevPage(){
    if(this.current_page <= Number(this.total_pages)){
      this.current_page--;
      this.get_stores_list();
    }
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.searchList();
    }
  }

  searchList(){
    this.current_page=1;
    this.search_keyword=this.searchForm.value.search;
    console.log('searchKeyword', this.search_keyword)
    this.get_stores_list();
  }

  setheader(index){
    localStorage.setItem('navigate', index);
  } 
 
  openSite(goodsImgUrl) {
    window.open( goodsImgUrl, '_blank');
  } 

  disable(id,value){
    this.dialogService.openConfirmDialog('intererpelorts.megapay.ph says','Are you sure you want to disable this item?')
    .afterClosed().subscribe(res=>{
      if(res){
        this.status=value;
        this.request.put(Urls.items + '/' + id,{
          status:this.status
        }).then(response => {
          console.log(response);
          if(response['status']==200){
            this.get_stores_list();
            this.toastr.success(response['message']+'!', 'Success!');
          }
          else{
            this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
          }
        });
      }
    });
  }

  enable(id,value){
    this.dialogService.openConfirmDialog('intererpelorts.megapay.ph says','Are you sure you want to enable this item?')
    .afterClosed().subscribe(res=>{
      if(res){
        this.status=value;
        this.request.put(Urls.items + '/' + id,{
          status:this.status
          }).then(response => {
          console.log(response);
          if(response['status']==200){
            this.get_stores_list();
            this.toastr.success(response['message']+'!', 'Success!');
          }
          else{
            this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
          }
        });
      }
    });
  } 
  print(){
    this.spinner.show();
    var current = new Date();
    this.params=JSON.parse(localStorage.getItem('stores_list')); 
    this.fileName=current.toLocaleDateString()+"-stores.xlsx";
    console.log('parent params',this.params);
    this.request.postBlob(Urls.exportItems,this.params).then(report => {
      const blob = new Blob([report], { type: 'application/vnd.ms-excel' });
      const data = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = data;
      link.download = this.fileName;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        this.spinner.hide();
      }, 500);
    });
  }
}


