<div class="h-full w-full">
    <div class="px-8 py-5">
        <div class="flex items-center space-x-1 text-sm justify-center  w-900-px mx-auto relative">
            <button class="custom-tabbtn focus:outline-none py-3 px-10 uppercase font-bold mb-5 btn-light-orange-no">
                KYC FORM
            </button>
            <div class="absolute right-0 close-orange flex items-center justify-center">
                <span class="material-icons orange-text text-base cursor-pointer" [routerLink]="['/kyc/kyc-registration']" routerLinkActive="router-link-active" >
                    close
                </span>
            </div>
        </div>
        <div class="w-900-px mx-auto">
            <div class="grid grid-cols-12 gap-10">
                <div class="col-span-6">
                    <div class="card-input flex items-center text-xs py-2 w-full mb-4">
                        <span class="text-xs w-60-px font-semibold">
                            Name:
                        </span>
                        <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                            {{firstName | nullWithDefault |titlecase }}&nbsp;{{middleName |titlecase }} &nbsp;{{lastName |titlecase }}
                        </div>
                    </div>

                    <div class="card-input flex items-center text-xs py-2 w-full mb-4">
                        <span class="text-xs w-60-px font-semibold">
                            Email:
                        </span>
                        <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                            {{email | nullWithDefault }}
                        </div>
                    </div>

                    <div class="card-input flex items-center text-xs py-2 w-full mb-4">
                        <span class="text-xs w-165-px font-semibold">
                            Current Address:
                        </span>
                        <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                            {{location | nullWithDefault |titlecase }}
                        </div>
                    </div>

                    <div class="card-input flex items-center text-xs py-2 w-full mb-4">
                        <span class="text-xs w-70-px font-semibold">
                            Province:
                        </span>
                        <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                            {{province | nullWithDefault |titlecase }}
                        </div>
                    </div>

                    <div class="card-input flex items-center text-xs py-2 w-full mb-4">
                        <span class="text-xs w-290-px font-semibold ">
                            Birth Date: (yyyy-mm-dd)
                        </span>
                        <div *ngIf="year || month || day" class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                            {{ year }}&nbsp;- {{ month | number : '2.0-0' }}&nbsp;-{{ day | number : '2.0-0' }}
                        </div>
                        <div *ngIf="!year || !month || !day" class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                            Not Applicable
                        </div>
                    </div>

                    <div class="card-input flex items-center text-xs py-2 w-full mb-4">
                        <span class="text-xs w-90-px font-semibold">
                            Nationality:
                        </span>
                        <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                            {{nationality | nullWithDefault |titlecase }}
                        </div>
                    </div>

                    <div class="card-input flex items-center text-xs py-2 w-full mb-4">
                        <span class="text-xs w-220-px font-semibold">
                            Employment Status:
                        </span>
                        <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                            {{employmentStatus | nullWithDefault |titlecase }}
                        </div>
                    </div>
                    <div class="flex items-center justify-center w-full mt-1">
                        <div>
                            <div class="flex justify-between mb-1 font-semibold">
                                <div class="text-xs ">Selfie Image</div>
                                <div class="flex items-center space-x-1">
                                    <span class="material-icons text-base btn-icon cursor-pointer" (click) = "rotate(rotation_id,'selfie_image')" >
                                        refresh
                                    </span>
                                    <span class="material-icons text-base btn-icon cursor-pointer" (click) = "zoomOut(zoom_selfie,'selfie_image')">
                                        zoom_out
                                    </span>
                                    <span class="material-icons text-base btn-icon cursor-pointer" (click) = "zoomIn(zoom_selfie,'selfie_image')">
                                        zoom_in
                                    </span>
                                </div>
                            </div>
                            <div class="card-input flex items-center text-sm py-2 w-250-px h-250-px mb-3" style="overflow: scroll;">
                                <!-- <img src="https://www.pinoydriver.com/resources/wp-content/uploads/2019/06/foreign-drivers-license-ph.png" id="selfie_image"> -->
                                <img [src]="imageSelfieUrl" *ngIf="imageSelfieUrl" id="selfie_image">
                                <div class="pl-16 text-center text-xs italic " *ngIf="!imageSelfieUrl">
                                    Not Applicable
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-span-6">
                    <div class="card-input flex items-center text-xs py-2 w-full mb-4">
                        <span class="text-xs w-140-px font-semibold">
                            Phone number:
                        </span>
                        <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                            {{userName | nullWithDefault  }}
                        </div>
                    </div>
                    <div class="card-input flex items-center text-xs py-2 w-full mb-4">
                        <span class="text-xs w-100-px font-semibold">
                            Civil Status:
                        </span>
                        <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                            {{civilStatus_text  | nullWithDefault |titlecase  }}
                        </div>
                    </div>
                    <div class="card-input flex items-center text-xs py-2 w-full mb-4">
                        <span class="text-xs w-220-px font-semibold">
                            Permanent Address:
                        </span>
                        <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                            {{address | nullWithDefault|titlecase  }}
                        </div>
                    </div>
                    <div class="card-input flex items-center text-xs py-2 w-full mb-4">
                        <span class="text-xs w-45-px font-semibold">
                            City:
                        </span>
                        <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                            {{city | nullWithDefault |titlecase  }}
                        </div>
                    </div>
                    <div class="card-input flex items-center text-xs py-2 w-full mb-4">
                        <span class="text-xs w-100-px font-semibold">
                            Birth Place:
                        </span>
                        <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                            {{birthPlace | nullWithDefault |titlecase  }}
                        </div>
                    </div>
                    <div class="card-input flex items-center text-xs py-2 w-full mb-4">
                        <span class="text-xs w-100-px font-semibold">
                            Zip Code:
                        </span>
                        <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                            {{zipCode | nullWithDefault  }}
                        </div>
                    </div>
                    <div class="card-input flex items-center text-xs py-2 w-full mb-4">
                        <span class="text-xs w-160-px font-semibold">
                            Source of Fund:
                        </span>
                        <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                            {{sourceOfFund | nullWithDefault  |titlecase }}
                        </div>
                    </div>

                    <div class="flex items-center justify-center w-full mt-1">
                        <div>
                            <div class="flex justify-between mb-1 font-semibold">
                                <div class="text-xs">ID Image</div>
                                <div class="flex items-center space-x-1 ">
                                    <span class="material-icons text-base btn-icon cursor-pointer" (click) = "rotate(rotation_id,'id_image')" >
                                        refresh
                                    </span>
                                    <span class="material-icons text-base btn-icon cursor-pointer" (click) = "zoomOut(zoom_id,'id_image')">
                                        zoom_out
                                    </span>
                                    <span class="material-icons text-base btn-icon cursor-pointer" (click) = "zoomIn(zoom_id,'id_image')">
                                        zoom_in
                                    </span>
                                    
                                </div>
                            </div>
                            <div class="card-input flex items-center text-sm py-2 w-250-px h-250-px mb-3" style="overflow: scroll;">
                                <!-- <img src="https://www.pinoydriver.com/resources/wp-content/uploads/2019/06/foreign-drivers-license-ph.png" id="id_image"> -->
                                <img [src]="imageIdUrl" *ngIf="imageIdUrl" id="id_image">
                                <div class="pl-16 text-center text-xs italic " *ngIf="!imageIdUrl" >
                                    Not Applicable
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

            <div class="w-full flex items-center justify-center mt-4 mb-4 space-x-2">
                <button class="uppercase custom-btn btn-dark-orange font-semibold text-xs px-6 py-2 focus:outline-none" (click) = "approve(uid)">
                    Approve
                </button>
                <button class="uppercase custom-btn btn-dark-orange font-semibold text-xs px-6 py-2 focus:outline-none" (click) = "disApprove(uid)">
                    disApprove
                </button>
            </div>
        </div>
    </div>
</div>