<div class="h-full w-full">
    <form [formGroup]="modifyAccountForm">
        <div class="px-8 py-5">
            <div class="flex items-center space-x-1 text-xs justify-center mb-3 w-900-px mx-auto relative">
               <span class="orange-text py-3 px-3 uppercase font-bold mb-5">
                Modify Account 
               </span>
                <div class="absolute right-0 close-orange flex items-center justify-center">
                    <button class="btn-orange-close custom-btn" [routerLink]="['/admin/portal-account']" routerLinkActive="router-link-active">
                        <span class="material-icons text-xs">
                            close
                        </span>
                    </button>
                </div>
            </div>
            <div class="w-950-px mx-auto">
                <div class="grid grid-cols-12 gap-3">
                    <div class="col-span-6">
                        <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                            <span class="text-xs w-110-px font-semibold">
                                Username
                            </span>
                            <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="username" type="text" placeholder="Username">
                        </div>
                        <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                            <span class="text-xs w-110-px font-semibold">
                                First Name: 
                            </span>
                            <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" style="text-transform: capitalize;"  formControlName="firstName" type="text" placeholder="First Name">
                        </div>
                        <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                            <span class="text-xs w-110-px font-semibold">
                                Last Name:
                            </span>
                            <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" style="text-transform: capitalize;" formControlName="lastName" type="text" placeholder="Last Name">
                        </div>
                        <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                            <span class="text-xs w-110-px font-semibold">
                                Email:
                            </span>
                            <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="email" type="text" placeholder="Email">
                        </div> 
                        <div *ngIf="loggedAcc === 'Super Admin'"class="card-input flex items-center text-xs py-2 w-full mb-3">
                            <span class="text-xs w-110-px font-semibold">
                               Password:
                            </span>
                            <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="password" type="text" placeholder="********">
                        </div> 
                        <div class="card-input flex items-center text-xs py-2 w-full mb-3 mt-3">
                            <div class="flex items-center ">
                                <span class="text-xs w-90-px font-semibold">
                                    Account Type:
                                </span>
                                <div #t3="matMenuTrigger" #select3 [matMenuTriggerFor]="network_select" class="rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                                    <span *ngIf="!accountType_text"class="barlow text-black text-xs italic font-lg p-5 ">== Select an option ==</span>
                                    <span *ngIf="accountType_text"class="barlow text-black text-xs italic font-lg p-5 ">{{accountType_text}}</span>
                                    <span class="material-icons violet-text ml-3">
                                    arrow_drop_down
                                </span>
                                </div>
                                <mat-menu class="w-full sports-menu" #network_select="matMenu">
                                    <button mat-menu-item class="tracking-wide focus:outline-none text-xs italic" >
                                        == Select an option ==
                                    </button>
                                    <button mat-menu-item class="tracking-wide focus:outline-none text-xs  italic" (click)="setAccountType('Admin ','Admin')">
                                        Admin
                                    </button>
                                    <button mat-menu-item class="tracking-wide focus:outline-none text-xs italic" (click)="setAccountType('User','User')">
                                        User
                                    </button>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-6 pl-32" >
                        <div class="flex items-center space-x-3 text-xs justify-start  mt-56 " >
                            <button (click)="update()" class="uppercase custom-btn btn-dark-orange font-bold text-xs px-4 py-1 ml-24" >
                                APPLY
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
