import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestsService } from 'src/app/services/http/requests.service';
import { Urls } from 'src/app/lib/urls';
import { DebounceService } from 'src/app/services/debounce.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ads-dashboard-screen',
  templateUrl: './ads-dashboard-screen.component.html',
  styleUrls: ['./ads-dashboard-screen.component.scss']
})
export class AdsDashboardScreenComponent implements OnInit {
  
  featured_img: any;
  public ads_screen_form: FormGroup;
  isLoading: boolean;
  selected_redirection: string | Blob;
  ads_list:any;
  lastPage:any;
  screen:any;
  screen_text:any;
  imageFile: {link: string, file: any, name: string};

  search:any;
  tabId:any;
  fileName:any;
  url:any;
  params={ 
    search: '',
  }


  //pagination
  page=1;
  size=5;
  total_pages:any;
  current_page = 1;
  pages_array=[
  ];
  total:any;

  //search
  searchKey: any=null;
  searchForm : FormGroup;
  search_keyword='';

  //limit
  limitKey:any=null;
  limitForm : FormGroup;

  //sorting
  sort_column="dateTime";
  sort_type="ASC";


  constructor(
    private modal: NgxSmartModalService,
    private fb : FormBuilder,
    public debounce: DebounceService,
    private request: RequestsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    this.initializeForm();
   }

  ngOnInit(): void {
    this.get_ads_list();
    var tableHeader = document.getElementsByTagName("th");  
    for (var i = 0; i < tableHeader.length; i++) { 
      tableHeader[i].classList.add("headerSortDown");
    }
  }

  initializeForm(){
    this.ads_screen_form = this.fb.group({
      title: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      description: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      dateTo: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      dateFrom: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      
      duration: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
    });
    this.searchKey = this.debounce.debounce(() => {
      this.current_page=1;
      this.search_keyword=this.searchForm.value.search;
      console.log('searchKeyword', this.search_keyword)
      this.get_ads_list();
    }, 500); 
    this.searchForm = this.fb.group({
      search:['']
    });
    this.limitKey = this.debounce.debounce(() => {
      this.current_page=1;
      this.size=this.limitForm.value.limit;
      this.get_ads_list();
    }, 500);  
    this.limitForm = this.fb.group({
      limit:['']
    });
  }

  
  sortList(sort_column, sort_type){
    this.spinner.show();
    if(sort_type=="ASC"&&this.sort_column!=sort_column&&this.sort_type==sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="ASC"&&this.sort_column==sort_column&&this.sort_type!=sort_type){
      this.sort_type="ASC";
      this.sort_column=sort_column;
    }else if(sort_type=="ASC"&&this.sort_column==sort_column&&this.sort_type==sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="DESC"&&this.sort_column!=sort_column&&this.sort_type==sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="DESC"&&this.sort_column==sort_column&&this.sort_type!=sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="DESC"&&this.sort_column==sort_column&&this.sort_type==sort_type){
      this.sort_type="ASC";
      this.sort_column=sort_column;
    }
   this.get_ads_list();
    console.log('sort_column',this.sort_column);
    console.log('sort_type',this.sort_type);
  }


  onSelectFile(event) { 
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      let link=event.srcElement.files[0];
      let name=event.srcElement.files[0].name;
      reader.onload = (event: any) => {
        this.imageFile = {
            link: event.target.result,
            file: link,
            name: name
        };
        this.featured_img = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  store(){
    this.spinner.show();
    let formData = new FormData();
    formData.append('image', this.imageFile.file);
    formData.append('title', this.ads_screen_form.controls.title.value);
    formData.append('description', this.ads_screen_form.controls.description.value);
    formData.append('dateFrom', moment(this.ads_screen_form.controls.dateFrom.value).format('YYYY-MM-DD HH:mm:ss'));
    formData.append('dateTo', moment(this.ads_screen_form.controls.dateTo.value).format('YYYY-MM-DD HH:mm:ss'));
    formData.append('screen', this.screen);
    formData.append('duration', this.ads_screen_form.controls.duration.value);
    this.request.post(Urls.store_ads, formData).then(response => {
      console.log(response);
      this.page=this.lastPage;
      this.get_ads_list();
      this.toastr.success(response['message']+'!', 'Succes!');
      setTimeout(() => {
        this.spinner.hide();
      }, 500);
    })
    this.ads_screen_form.reset();
    this.featured_img=null;
  }  
 
  get_ads_list(){
    this.spinner.show();
    let params ={ 
      search : this.search_keyword,
      sortBy: {
        [this.sort_column]: this.sort_type
      }
    }
    this.request.post(Urls.ads + '?page=' + this.current_page + '&size=' + this.size, params).then(response => {
      if(response['status']==200){
        this.ads_list=response['result']['data'];
        if(this.current_page === 0  ){
          this.pages_array=[];
          for(let i=this.current_page; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }else if(this.current_page==1){
          for(let i=1; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }else if(this.current_page==response['result']['totalPages']){
          for(let i=this.current_page-2; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }else{
          for(let i=this.current_page-1; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }
        this.total_pages=parseInt(response['result']['totalPages']);
        this.current_page=response['result']['currentPage'];
        this.total=parseInt(response['result']['totalItems']);
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      }
    }).finally(()=>{
    });   
  }

  setScreen(text,value){
    this.screen=value;
    this.screen_text=text;
  }

  setPage(num){
    this.current_page = num;
    this.get_ads_list();
  }

  nextPage(){
    if(this.current_page < Number(this.total_pages)){
         this.current_page++;
         this.get_ads_list()
     }
   }

  prevPage(){
    if(this.current_page <= Number(this.total_pages)){
      this.current_page--;
      this.get_ads_list();
    }
  }
  
  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.searchList();
    }
  }

  searchList(){
    this.current_page=1;
    this.search_keyword=this.searchForm.value.search;
    console.log('searchKeyword', this.search_keyword)
    this.get_ads_list();
  }

  changeStatus(id, value){
    let formData = new FormData();
    formData.append('status', value);
    this.request.put(Urls.ads + '/' + id, formData).then(response => {
      console.log(response);
      this.get_ads_list();
      this.toastr.success(response['message']+'!', 'Succes!');
    })
  }

  setheader(index){
    localStorage.setItem('navigate', index);
  }
  
  print(){
    this.spinner.show();
    var current = new Date();
    this.params=JSON.parse(localStorage.getItem('ads_list')); 
    this.fileName=current.toLocaleDateString()+"-ads-dashboard-screen.xlsx";
    console.log('parent params',this.params);
    this.request.postBlob(Urls.exportAds,this.params).then(report => {
      const blob = new Blob([report], { type: 'application/vnd.ms-excel' });
      const data = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = data;
      link.download = this.fileName;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        this.spinner.hide();
      }, 500);
    });
  }
}
