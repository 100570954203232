<div class="w-full h-full">
    <div class="flex items-center justify-between space-x-3 pt-9 text-xs">
        <div class="flex items-center space-x-3 text-xs"></div>
        <div class="flex items-center space-x-3 text-xs"></div>
        <div class="flex items-center space-x-3 pr-10"  >
            <svg (click)="print()" class="cursor-pointer " xmlns="http://www.w3.org/2000/svg" width="20" height="21.433" viewBox="0 0 25 21.433" >
                <g id="iconfinder_icon-printer_211880" transform="translate(-32 -64)">
                <path id="Path_369" data-name="Path 369" d="M155.961,313.926h.541v-7.245a.7.7,0,0,0-.692-.681H144.692a.7.7,0,0,0-.692.681v7.245h11.961Z" transform="translate(-105.749 -228.493)"/>
                <g id="Group_77" data-name="Group 77" transform="translate(32 64)">
                    <path id="Path_370" data-name="Path 370" d="M53.869,67.74H52.15V64.82a.856.856,0,0,0-.887-.82H37.743a.856.856,0,0,0-.887.82V67.74H35.137A3.34,3.34,0,0,0,32,70.787V77.53c0,1.518,1.58,3.444,3.137,3.444h1.021c.274,0,.307,0,.307-.781v-2.4A1.788,1.788,0,0,1,38.3,76.006H50.8c.988,0,1.736,1.189,1.736,2.233v1.954c0,.781.006.781.279.781h1.044a3.294,3.294,0,0,0,3.137-3V70.787A3.33,3.33,0,0,0,53.869,67.74Zm-1.328,3.4a.893.893,0,1,1,.893-.893A.9.9,0,0,1,52.54,71.144Z" transform="translate(-32 -64)"/>
                </g>
                </g>
            </svg>
        </div>
    </div>
    <div class="w-900-px mx-auto">
        <form [formGroup]="form" >
        <div class="grid grid-cols-8 gap-7">
            <div class="col-span-8 mt-20">
                <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                    <span class="text-xs w-130-px font-semibold">
                        Select a Report:
                    </span>
                    <div #t2="matMenuTrigger" #select2 [matMenuTriggerFor]="report_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                        <span *ngIf="!report_text "class="barlow text-black text-xs font-lg p-5 italic">Choose a period</span>
                        <span *ngIf="report_text"class="barlow text-black text-xs font-lg p-5 italic">{{report_text}}</span>
                        <span class="material-icons violet-text ml-3">
                            arrow_drop_down
                        </span>
                    </div>
                    <mat-menu class="w-full sports-menu" #report_select="matMenu">
                        <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs " >
                            Choose a query type
                        </button>
                        <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setReportType('Amount','Amount')">
                            Amount
                        </button>
                        <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setReportType('New Users','New Users')">
                            New Users
                        </button><button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setReportType('Active Users','Active Users')">
                            Active Users
                        </button><button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setReportType('Number of Transactions','Number of Transactions')">
                            Number of Transactions
                        </button>
                    </mat-menu>
                </div>
            </div>
            <div class="col-span-8">
                <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                    <span class="text-xs w-130-px font-semibold">
                        Transaction Type:
                    </span>
                    <div #t2="matMenuTrigger" #select2 [matMenuTriggerFor]="transaction_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                        <span *ngIf="!transaction_text "class="barlow text-black text-xs font-lg p-5 italic">Choose a transaction type</span>
                        <span *ngIf="transaction_text"class="barlow text-black text-xs font-lg p-5 italic">{{transaction_text}}</span>
                        <span class="material-icons violet-text ml-3">
                            arrow_drop_down
                        </span>
                    </div>
                    <mat-menu class="w-full sports-menu" #transaction_select="matMenu">
                        <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs " >
                            Choose a transaction type
                        </button>
                        <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setTransactionType('All','All')">
                            All
                        </button>
                        <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setTransactionType('Cash In','Cash-In')">
                            Cash In
                        </button>
                        <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setTransactionType('Scan QR','Scan QR')">
                            Scan QR
                        </button>
                        <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setTransactionType('Receive Money','Receive Money')">
                            Receive Money
                        </button>
                        <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setTransactionType('Transfer Funds','Transfer Funds')">
                            Transfer Funds
                        </button>
                        <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setTransactionType('Buyload','Buy Load')">
                            Buyload
                        </button>
                        <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setTransactionType('Paybills','Pay Bills')">
                            Paybills
                        </button>
                        <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setTransactionType('Buyload Cashback','Buy Load Cash Back')">
                            Buyload Cashback
                        </button>
                    </mat-menu>
                </div>
            </div>
            <div class="col-span-8">
                <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                    <span class="text-xs w-130-px font-semibold">
                       Period:
                    </span>
                    <div #t2="matMenuTrigger" #select2 [matMenuTriggerFor]="period_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                        <span *ngIf="!period_text "class="barlow text-black text-xs font-lg p-5 italic">Choose a period</span>
                        <span *ngIf="period_text"class="barlow text-black text-xs font-lg p-5 italic">{{period_text}}</span>
                        <span class="material-icons violet-text ml-3">
                            arrow_drop_down
                        </span>
                    </div>
                    <mat-menu class="w-full sports-menu" #period_select="matMenu">
                        <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs " >
                            Choose a period
                        </button>
                        <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setPeriodType('Daily','Daily')">
                            Daily
                        </button>
                        <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setPeriodType('Weekly','Weekly')">
                            Weekly
                        </button>
                        <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setPeriodType('Monthly','Monthly')">
                            Monthly
                        </button>
                        <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setPeriodType('Annually','Annually')">
                            Annually
                        </button> 
                    </mat-menu>
                </div>
            </div>
        </div>
        <div class="flex items-center space-x-3 text-xs justify-start mt-7">
            <div class="col-span-1">
                <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                    <span class="text-xs w-150-px font-semibold">
                        Date From:
                    </span>
                    <!-- <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy"> -->
                    <mat-form-field color="accent" appearance="">
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker1" readonly (click)="startDate.click()" formControlName="dateFrom">
                        <mat-datepicker-toggle matSuffix [for]="picker1" class="focus:outline-none" >
                            <svg #startDate class="focus:outline-none" matDatepickerToggleIcon id="iconfinder_calendar-book-date-monthly_2203551" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                <path id="Path_371" data-name="Path 371" d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z" fill="#5f4d9c"/>
                                <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4" transform="translate(13 6.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4" transform="translate(13 11.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4" transform="translate(8 11.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4" transform="translate(3 11.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4" transform="translate(8 6.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4" transform="translate(3 6.936)" fill="#5f4d9c"/>
                            </svg>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-span-1">
                <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                    <span class="text-xs w-135-px font-semibold">
                        Date To:
                    </span>
                    <mat-form-field color="accent" appearance="">
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker2" readonly (click)="startDate.click()" formControlName="dateTo">
                        <mat-datepicker-toggle matSuffix [for]="picker2" class="focus:outline-none" >
                            <svg #startDate class="focus:outline-none" matDatepickerToggleIcon id="iconfinder_calendar-book-date-monthly_2203551" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                <path id="Path_371" data-name="Path 371" d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z" fill="#5f4d9c"/>
                                <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4" transform="translate(13 6.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4" transform="translate(13 11.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4" transform="translate(8 11.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4" transform="translate(3 11.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4" transform="translate(8 6.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4" transform="translate(3 6.936)" fill="#5f4d9c"/>
                            </svg>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </div>  
            </div>
        </div>
        <div class="ml-7 mt-5">
            <p class="text-xs  font-semibold">TRANSACTION:{{ transactionType}}</p>
            <p class="text-xs font-semibold">
                PERIOD: {{ period }} 
                <ng-container *ngIf="dateFromValue">
                  ({{ dateFromValue | date: 'dd MMM yyyy' }} 
                  <span *ngIf="dateToValue">to {{ dateToValue | date: 'dd MMM yyyy' }}</span>)
                </ng-container>
                <ng-container *ngIf="!dateFromValue">
                  <span *ngIf="dateToValue">{{ dateToValue | date: 'dd MMM yyyy' }} </span>
                </ng-container>
              </p>
        </div>
        </form>
      
        <div class="flex items-center space-x-3 text-xs justify-center mt-14">
            <button (click)="reset()" class="uppercase custom-btn btn-dark-orange font-semibold text-xs px-6 py-2 mt-5 mb-5 pr-8 pl-8 " >
                RESET 
             </button>
            <button (click)="submit()" class="uppercase custom-btn btn-dark-orange font-semibold text-xs px-6 py-2 mt-5 mb-5 " >
                SUBMIT
            </button>
        </div>
    </div>
    <div class=" bg-light-orange w-full  maxh-550-px py-4 px-3 overflow-x-auto w-table" *ngIf="submitted">
        <div class="data-table mt-3 barlow text-xs">
            <table class="w-full">
                <thead class="capitalize"> 
                    <tr>
                        <th class="text-left barlow" style=" border-top: none">
                           Start Date
                        </th>
                        <th class="text-left barlow">
                            End Date
                        </th>
                        <th class="text-left barlow">
                            Active Users
                        </th>
                        <th class="text-left barlow">
                            Amount
                        </th>
                        <th class="text-left barlow">
                           New Users
                        </th>
                        <th class="text-left barlow">
                            Number of Transaction
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="!itemLists_filter && !itemLists_filter2">
                        <tr class="focus:outline-none" *ngFor="let item of itemLists" >
                            <td>{{item.startDate | date:' yyyy-dd-MM  hh:mm:ss'| nullWithDefault}}</td>
                            <td>{{item.endDate | date:' yyyy-dd-MM  hh:mm:ss'| nullWithDefault}}</td>
                            <td>{{ item.activeUsers | nullWithDefault }}</td>
                            <td>{{ item.amount  | nullWithDefault}}</td>
                            <td>{{ item.newUsers | nullWithDefault }}</td>
                            <td>{{ item.numberOfTransactions | nullWithDefault }}</td>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="itemLists_filter">
                        <tr class="focus:outline-none" *ngFor="let item of itemLists_filter" >
                            <td>{{item.startDate | date:' yyyy-dd-MM  hh:mm:ss'| nullWithDefault}}</td>
                            <td>{{item.endDate | date:' yyyy-dd-MM  hh:mm:ss'| nullWithDefault}}</td>
                            <td>{{ item.activeUsers | nullWithDefault }}</td>
                            <td>{{ item.amount  | nullWithDefault}}</td>
                            <td>{{ item.newUsers | nullWithDefault }}</td>
                            <td>{{ item.numberOfTransactions | nullWithDefault }}</td>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="itemLists_filter2">
                        <tr class="focus:outline-none" *ngFor="let item of itemLists_filter2" >
                            <td>{{item.startDate | date:' yyyy-dd-MM  hh:mm:ss'| nullWithDefault}}</td>
                            <td>{{item.endDate | date:' yyyy-dd-MM  hh:mm:ss'| nullWithDefault}}</td>
                            <td>{{ item.activeUsers | nullWithDefault }}</td>
                            <td>{{ item.amount  | nullWithDefault}}</td>
                            <td>{{ item.newUsers | nullWithDefault }}</td>
                            <td>{{ item.numberOfTransactions | nullWithDefault }}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>
