<div class="w-full h-full">
    <div class="flex items-center justify-between space-x-3 pt-9 text-sm">
        <div class="flex items-center space-x-3 text-xs"></div>
        <div class="flex items-center space-x-3 text-xs"></div>
        <div class="flex items-center space-x-3 pr-10"  >
            <svg (click)="print()" class="cursor-pointer " xmlns="http://www.w3.org/2000/svg" width="20" height="21.433" viewBox="0 0 25 21.433" >
                <g id="iconfinder_icon-printer_211880" transform="translate(-32 -64)">
                <path id="Path_369" data-name="Path 369" d="M155.961,313.926h.541v-7.245a.7.7,0,0,0-.692-.681H144.692a.7.7,0,0,0-.692.681v7.245h11.961Z" transform="translate(-105.749 -228.493)"/>
                <g id="Group_77" data-name="Group 77" transform="translate(32 64)">
                    <path id="Path_370" data-name="Path 370" d="M53.869,67.74H52.15V64.82a.856.856,0,0,0-.887-.82H37.743a.856.856,0,0,0-.887.82V67.74H35.137A3.34,3.34,0,0,0,32,70.787V77.53c0,1.518,1.58,3.444,3.137,3.444h1.021c.274,0,.307,0,.307-.781v-2.4A1.788,1.788,0,0,1,38.3,76.006H50.8c.988,0,1.736,1.189,1.736,2.233v1.954c0,.781.006.781.279.781h1.044a3.294,3.294,0,0,0,3.137-3V70.787A3.33,3.33,0,0,0,53.869,67.74Zm-1.328,3.4a.893.893,0,1,1,.893-.893A.9.9,0,0,1,52.54,71.144Z" transform="translate(-32 -64)"/>
                </g>
                </g>
            </svg>
        </div>
    </div>
    <div class="mx-auto">
        <form [formGroup]="form" > 
            <div class="grid grid-cols-8 gap-3 p-5"  >
                <div class="col-span-4 mt-36">
                    <div class="grid grid-cols-2 gap-2">
                        <div class="col-span-1">
                            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                                <span class="text-xs w-150-px font-semibold">
                                    Date From:
                                </span>
                                <!-- <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy"> -->
                                <mat-form-field color="accent" appearance="">
                                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker1" readonly (click)="startDate.click()" formControlName="dateFrom">
                                    <mat-datepicker-toggle matSuffix [for]="picker1" class="focus:outline-none" >
                                        <svg #startDate class="focus:outline-none" matDatepickerToggleIcon id="iconfinder_calendar-book-date-monthly_2203551" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                            <path id="Path_371" data-name="Path 371" d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z" fill="#5f4d9c"/>
                                            <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4" transform="translate(13 6.936)" fill="#5f4d9c"/>
                                            <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4" transform="translate(13 11.936)" fill="#5f4d9c"/>
                                            <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4" transform="translate(8 11.936)" fill="#5f4d9c"/>
                                            <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4" transform="translate(3 11.936)" fill="#5f4d9c"/>
                                            <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4" transform="translate(8 6.936)" fill="#5f4d9c"/>
                                            <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4" transform="translate(3 6.936)" fill="#5f4d9c"/>
                                        </svg>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-span-1">
                            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                                <span class="text-xs w-135-px font-semibold">
                                    Date To:
                                </span>
                                <mat-form-field color="accent" appearance="">
                                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker2" readonly (click)="startDate.click()" formControlName="dateTo">
                                    <mat-datepicker-toggle matSuffix [for]="picker2" class="focus:outline-none" >
                                        <svg #startDate class="focus:outline-none" matDatepickerToggleIcon id="iconfinder_calendar-book-date-monthly_2203551" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                            <path id="Path_371" data-name="Path 371" d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z" fill="#5f4d9c"/>
                                            <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4" transform="translate(13 6.936)" fill="#5f4d9c"/>
                                            <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4" transform="translate(13 11.936)" fill="#5f4d9c"/>
                                            <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4" transform="translate(8 11.936)" fill="#5f4d9c"/>
                                            <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4" transform="translate(3 11.936)" fill="#5f4d9c"/>
                                            <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4" transform="translate(8 6.936)" fill="#5f4d9c"/>
                                            <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4" transform="translate(3 6.936)" fill="#5f4d9c"/>
                                        </svg>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                            </div>  
                        </div>
                    </div>      
                    <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                        <span class="text-xs w-130-px font-semibold ">
                            Transaction:
                        </span>
                        <div #t2="matMenuTrigger" #select2 [matMenuTriggerFor]="load_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                            <span *ngIf="!tradeType_text"class="barlow text-black text-xs font-lg p-5 italic">All</span>
                            <span *ngIf="tradeType_text"class="barlow text-black text-xs font-lg p-5 italic">{{tradeType_text}}</span>
                            <span class="material-icons violet-text ml-3">
                                arrow_drop_down
                            </span>
                        </div>
                        <mat-menu class="w-full sports-menu" #load_select="matMenu">
                            <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setTradeType('All','All')">
                                All
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setTradeType('Cash In','Cash-In')">
                                Cash In
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setTradeType('Buy Load','Buy Load')">
                                Buy Load
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setTradeType('Pay Bills','Pay Bills')">
                                Pay Bills
                            </button>
                        </mat-menu>
                    </div>
                    <div class="flex items-center space-x-3 text-xs justify-center">
                        <button (click)="reset()"class="uppercase custom-btn btn-dark-orange font-semibold text-xs px-6 py-2 mt-2 mb-5 pr-8 pl-8 " >
                            RESET
                        </button>
                        <button (click)="submit()" class="uppercase custom-btn btn-dark-orange font-semibold text-xs px-6 py-2 mt-2 mb-5 " >
                            SUBMIT
                        </button>
                    </div>
                </div>
                <div class="col-span-4 pl-2">
                    <div class="data-table mt-3 barlow text-xs  " >
                        <div class="flex items-center justify-center ">
                            <h1 class=" focus:outline-none py-3 px-3 uppercase font-bold btn-light-orange-no " >
                                OVERVIEW (ALL TIME)
                            </h1>
                        </div>
                        <table class="w-full header-row" >
                            <thead class="bg-light-orange ">
                            <tr >
                                <th class="text-center barlow orange-text" >Cash In</th>
                                <th class="text-center barlow orange-text" >Buyload</th>
                                <th class="text-center barlow orange-text" >Paybills</th>
                            </tr>
                            <tr >
                                <th>
                                    <span class="name-span">Name</span>
                                    <span class="amount-span">Amount</span>
                                </th>
                                <th>
                                    <span class="name-span">Name</span>
                                    <span class="amount-span">Amount</span>
                                </th>
                                <th>
                                    <span class="name-span">Name</span>
                                    <span class="amount-span">Amount</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="!cashInOverview_filter && !cashInOverview_filter2 && !buyLoadOverview_filter && !buyLoadOverview_filter2 && !payBillsOverview_filter && !payBillsOverview_filter2">
                                    <tr class="focus:outline-none" *ngFor="let item of cashInOverview let i = index" >
                                        <td>
                                            <ng-container *ngIf="item">
                                              <span style="float: left;">{{ item.name | nullWithDefault }}</span>
                                              <span style="float: right;">{{ item.amount | number:'1.2-2' }}</span>
                                            </ng-container>
                                          </td>
                                        <td>
                                            <span style="float: left;">{{ buyLoadOverview[i]?.name | nullWithDefault }}</span>
                                            <span style="float: right;">{{ buyLoadOverview[i]?.amount | number:'1.2-2' }}</span>
                                        </td>
                                        <td>
                                            <span style="float: left;">{{ payBillsOverview[i]?.name | nullWithDefault }}</span>
                                            <span style="float: right;">{{ payBillsOverview[i]?.amount | number:'1.2-2' }}</span>
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-container *ngIf="cashInOverview_filter || buyLoadOverview_filter || payBillsOverview_filter">
                                    <tr class="focus:outline-none" *ngFor="let item of cashInOverview_filter let i = index" >
                                        <td>
                                            <ng-container *ngIf="item">
                                              <span style="float: left;">{{ item.name | nullWithDefault }}</span>
                                              <span style="float: right;">{{ item.amount | number:'1.2-2' }}</span>
                                            </ng-container>
                                          </td>
                                          <td>
                                            <span style="float: left;">{{ buyLoadOverview_filter[i]?.name | nullWithDefault }}</span>
                                            <span style="float: right;">{{ buyLoadOverview_filter[i]?.amount  | number:'1.2-2' }}</span>
                                          </td>
                                          <td>
                                            <span style="float: left;">{{ payBillsOverview_filter[i]?.name | nullWithDefault }}</span>
                                            <span style="float: right;">{{ payBillsOverview_filter[i]?.amount  | number:'1.2-2' }}</span>
                                          </td>
                                    </tr>
                                </ng-container>
                                <ng-container *ngIf="cashInOverview_filter2 || buyLoadOverview_filter2 || payBillsOverview_filter2">
                                    <tr class="focus:outline-none" *ngFor="let item of cashInOverview_filter2; let i = index">
                                      <td>
                                        <ng-container *ngIf="item">
                                          <span style="float: left;">{{ item.name | nullWithDefault }}</span>
                                          <span style="float: right;">{{ item.amount | number:'1.2-2' }}</span>
                                        </ng-container>
                                      </td>
                                      <td>
                                        <span style="float: left;">{{ buyLoadOverview_filter2[i]?.name | nullWithDefault }}</span>
                                        <span style="float: right;">{{ buyLoadOverview_filter2[i]?.amount  | number:'1.2-2' }}</span>
                                      </td>
                                      <td>
                                        <span style="float: left;">{{ payBillsOverview_filter2[i]?.name | nullWithDefault }}</span>
                                        <span style="float: right;">{{ payBillsOverview_filter2[i]?.amount  | number:'1.2-2' }}</span>
                                      </td>
                                    </tr>
                                  </ng-container>
                                  
                            <!-- <tr *ngFor="let item of cashInOverview; let i = index" >
                                <td>
                                    <span style="float: left;">{{ item.name | nullWithDefault }}</span>
                                    <span style="float: right;">{{item.amount | nullWithDefault |number:'1.2-2'}}</span>
                                </td>
                                <td>
                                    <span style="float: left;">{{ buyLoadOverview[i].name | nullWithDefault }}</span>
                                    <span style="float: right;">{{ buyLoadOverview[i].amount | nullWithDefault |number:'1.2-2'}}</span>
                                </td>
                                <td>
                                    <span style="float: left;">{{ payBillsOverview[i].name | nullWithDefault }}</span>
                                    <span style="float: right;">{{ payBillsOverview[i].amount | nullWithDefault |number:'1.2-2'}}</span>
                                </td>
                            </tr> -->
                            </tbody>
                        </table>
                    </div>
                    <div class="flex items-center space-x-3 text-xs justify-center">
                        <button class="uppercase custom-btn btn-dark-blue font-semibold text-xs px-6 py-2 mt-2 mb-5 pr-8 pl-8 "  (click)="setDays(30)">ALL TIME LAST 30 DAYS</button>
                        <button class="uppercase custom-btn btn-dark-blue font-semibold text-xs px-6 py-2 mt-2 mb-5 "  (click)="setDays(7)">LAST 7 DAYS</button>
                    </div>  
                </div>
            </div> 
        </form>
        <div class="data-table mt-3 barlow text-xs m-5 " >
            <div class="mt-5 mb-2">
                <p class="text-xs  font-semibold">TRANSACTION: {{ tradeType }}</p>
                <p class="text-xs font-semibold">
                    PERIOD: {{daysOverview }}
                    <ng-container *ngIf="dateFromValue">
                      ({{ dateFromValue | date: 'dd MMM yyyy' }} 
                      <span *ngIf="dateToValue">to {{ dateToValue | date: 'dd MMM yyyy' }}</span>)
                    </ng-container>
                    <ng-container *ngIf="!dateFromValue">
                      <span *ngIf="dateToValue">{{ dateToValue | date: 'dd MMM yyyy' }} </span>
                    </ng-container>
                  </p>
            </div>
            <table class="w-full" >
                <thead class="bg-light-orange "> 
                    <tr class="header-row" style="border-left: 1px #CAC2BC solid; border-right: 1px #CAC2BC solid;">
                        <th class="text-left barlow orange-text">
                            USERNAME
                        </th>
                        <th class="text-left barlow orange-text">
                            PHONE NUMBER
                        </th>
                        <th class="text-left barlow orange-text">
                            TOTAL AMOUNT (PHP)
                        </th>
                        <th class="text-left barlow orange-text">
                            NUMBER OF TRANSACTIONS
                        </th>
                        
                    </tr>
                </thead>
                <tbody class="header-row" style="border-left: 1px #CAC2BC solid; border-right: 1px #CAC2BC solid;">
                    <ng-container *ngIf="!itemLists_filter && !itemLists_filter2">
                        <tr class="focus:outline-none" *ngFor="let item of itemLists" >
                            <td>{{ item.userName | nullWithDefault | titlecase }}</td>
                            <td>{{ item.phoneNumber | nullWithDefault }}</td>
                            <td>{{ item.totalAmount | nullWithDefault }}</td>
                            <td>{{ item.numberOfTransactions | nullWithDefault }}</td>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="itemLists_filter">
                        <tr class="focus:outline-none" *ngFor="let item of itemLists_filter" >
                            <td>{{ item.userName | nullWithDefault | titlecase }}</td>
                            <td>{{ item.phoneNumber | nullWithDefault }}</td>
                            <td>{{ item.totalAmount | nullWithDefault }}</td>
                            <td>{{ item.numberOfTransactions | nullWithDefault }}</td>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="itemLists_filter2">
                        <tr class="focus:outline-none" *ngFor="let item of itemLists_filter2" >
                            <td>{{ item.userName | nullWithDefault | titlecase }}</td>
                            <td>{{ item.phoneNumber | nullWithDefault }}</td>
                            <td>{{ item.totalAmount | nullWithDefault }}</td>
                            <td>{{ item.numberOfTransactions | nullWithDefault }}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table> 
        </div>
    </div>
</div>