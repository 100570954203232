<div class="h-70-px shadow-md w-full bg-white">
    <div class="flex items-center justify-between h-full px-8">
        <span class="font-semibold uppercase text-xs" *ngIf="!title_is_array">{{ navigation_title }}</span>
        <div class="grid grid-cols-4 gap-1" *ngIf="title_is_array">
            <div class="col-span-1" *ngFor="let item of navigation_title">
                <span class="title uppercase cursor-pointer  font-bold" [routerLink]="item.route" routerLinkActive="active-route" >
                    {{ item.title }}
                </span>
            </div>
        </div>
        <div class="flex items-center">
            <span class="material-icons mr-3 cursor-pointer" *ngIf="title == 'dashboard'">
                refresh
            </span>
        </div>
    </div>
</div>