import { Component, OnInit } from '@angular/core';
import { RequestsService } from 'src/app/services/http/requests.service';
import { Urls } from 'src/app/lib/urls';
import { DebounceService } from 'src/app/services/debounce.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-portal-accounts',
  templateUrl: './admin-portal-accounts.component.html',
  styleUrls: ['./admin-portal-accounts.component.scss'],
})

export class AdminPortalAccountsComponent implements OnInit {

  status:number;
  users: any[]=[];
  search:any;
  selected_tab: any = 1;
  print:any;
  pageSize = 0;
  page=1;

  constructor(
    public debounce: DebounceService,
    private request: RequestsService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.users.forEach((_users) => {
      _users.isExpanded = false;
    });
  
    this.get_user();
  }

  get_user(){
    this.request.post(Urls.users + '/' ,{}).then(response => {
      console.log(response);
      this.users=response['result'] ;
    }) 
  }

  setheader(index){
    localStorage.setItem('navigate', index);
  } 
  
  openCoverages = false;
  indexSelectedCoverage = 1;

  changeStatus(id,value){
    this.status=value;
    this.request.put(Urls.users + '/' + id,{
      status:this.status
    }).then(response => {
      console.log(response);
      this.get_user();
      if(response['status']==200){
        this.toastr.success(response['message']+'!', 'Success!');
      }else if(response['status']==401){
        this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
      }
      else{
        this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
      }
    })
  }
  
  public get size(): number{
    return this.users.length;
  }
}
