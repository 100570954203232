<form [formGroup]="accountsEditForm" >  
    <div class="grid grid-cols-12 gap-3">
        <div class="col-span-12">
            <div class="text-m text-black font-bold uppercase text-center">
                Change Name
            </div>
        </div>
        <div class="col-span-4">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-110-px font-semibold ">
                    First Name:
                </span>
                <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" style="text-transform: capitalize;" formControlName="firstName"  type="text" placeholder="First Name">
            </div>
        </div>
        <div class="col-span-4">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-150-px font-semibold">
                    Middle Name:
                </span>
                <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" style="text-transform: capitalize;" formControlName="middleName"  type="text" placeholder="Middle Name">
            </div>
        </div>
        <div class="col-span-4">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-120-px font-semibold">
                    Last Name:
                </span>
                <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" style="text-transform: capitalize;" formControlName="lastName" type="text" placeholder="Last Name">
            </div>
        </div>
        <div class="col-span-12">
            <hr class="border border-gray-300 w-full my-1 mb-3">
        </div>
        <div class="col-span-12">
            <div class="text-m text-black font-bold uppercase text-center ">
                Change Number
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-150-px font-semibold">
                    Current Number:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    {{userName | nullWithDefault}}
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-150-px font-semibold">
                    New Number:
                </span>
                <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="userName"  type="text" placeholder="New Number">
            </div>
        </div>
        <div class="col-span-12">
            <hr class="border border-gray-300 w-full my-1 mb-3">
        </div>
        <div class="col-span-12">
            <div class="text-m text-black font-bold uppercase text-center">
                Change Email
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-150-px font-semibold">
                    Current Email:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3  flex items-center italic">
                    {{currentEmail | nullWithDefault}}
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-120-px font-semibold">
                    New Email:
                </span>
                <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="email"  type="email" placeholder="New Email">
            </div>
        </div>
        <div class="col-span-12">
            <hr class="border border-gray-300 w-full my-1 mb-3">
        </div>
        <div class="col-span-12">
            <div class="text-m text-black font-bold uppercase text-center">
                Change Tier
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-150-px font-semibold">
                    Current Tier:
                </span>
                <div class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px italic">
                    {{currentTier_text | nullWithDefault}}
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-xs py-2 w-3/4 mb-3">
                <div class="flex items-center space-x-3">
                <span class="text-xs w-100-px font-semibold font-semibold">
                    Tier Type:
                </span>
                    <div #t1="matMenuTrigger" #select1 [matMenuTriggerFor]="type_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                        <span *ngIf="!tierType_text" class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                        <span *ngIf="tierType_text" class="barlow text-black text-xs font-lg p-5 italic">{{tierType_text}}</span>
                        <span class="material-icons violet-text">
                            arrow_drop_down
                        </span>
                    </div>
                    <mat-menu class="w-full sports-menu" #type_select="matMenu">
                        <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" >
                            == Select an option ==
                        </button>
                        <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setTierType('Default','1')">
                            Default
                        </button>
                        <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setTierType('Full','4')">
                        Full
                        </button>
                        <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setTierType('Internal','5')">
                            Internal
                        </button>
                        <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setTierType('Business','6')">
                            Business
                        </button>
                        <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setTierType('MegaBiz','7')">
                            MegaBiz
                        </button>
                        <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs" (click)="setTierType('MegaOne','8')">
                            MegaOne
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
        <div class="col-span-12">
            <hr class="border border-gray-300 w-full my-1 mb-3">
        </div>
        <div class="col-span-12">
            <div class="text-m text-black font-bold uppercase text-center">
                Change Account Type
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                <span class="text-xs w-150-px font-semibold">
                    Current Account Type:
                </span>
                <div class=" rounded-md py-3 pl-0 minw-200-px flex justify-between items-center h-30-px italic">
                {{currentAccountType_text}}
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-xs py-2 w-3/4 mb-3">
                <div class="flex items-center space-x-3">
                <span class="text-xs w-100-px font-semibold">
                    Account Type:
                </span>
                    <div #t2="matMenuTrigger" #select2 [matMenuTriggerFor]="acc_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                        <span *ngIf="!accountType_text" class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                        <span *ngIf="accountType_text" class="barlow text-black text-xs font-lg p-5 italic">{{accountType_text}}</span>
                        <span class="material-icons violet-text">
                            arrow_drop_down
                        </span>
                    </div>
                    <mat-menu class="w-full sports-menu" #acc_select="matMenu">
                        <button mat-menu-item class="tracking-wide focus:outline-none  italic  text-xs" >
                            == Select an option ==
                        </button>
                        <button mat-menu-item class="tracking-wide focus:outline-none  italic  text-xs" (click)="setAccountType('Not Merchant','1')">
                        Not Merchant
                        </button>
                        <button mat-menu-item class="tracking-wide focus:outline-none  italic  text-xs" (click)="setAccountType('Merchant','2')">
                        Merchant
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
        <div class="col-span-12  mb-10">
            <div class="w-full flex items-center justify-center mt-10 ">
                <button (click)="update()" class="uppercase custom-btn btn-dark-orange font-semibold text-xs px-6 py-2">
                    submit
                </button>
            </div>
        </div>
    </div>
</form>