import { MatConfirmDialogComponent } from '../mat-confirm-dialog/mat-confirm-dialog.component';
import { Injectable } from '@angular/core';
import { MatDialog} from'@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private md:MatDialog
  ) { }

  openConfirmDialog(msg1,msg2,hideButtons:boolean = false){
  return this.md.open(MatConfirmDialogComponent,{
      width:'350px',
      disableClose:true,
      data:{
        message1:msg1,
        message2:msg2,
        hideButtons: hideButtons
      }
    })
  }
}
