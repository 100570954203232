import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { RequestsService } from 'src/app/services/http/requests.service';
import { Urls } from 'src/app/lib/urls';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sku-biller-all-transaction-edit',
  templateUrl: './sku-biller-all-transaction-edit.component.html',
  styleUrls: ['./sku-biller-all-transaction-edit.component.scss']
})
export class SkuBillerAllTransactionEditComponent implements OnInit {
  public updateForm: FormGroup;
  uid:any;
  status:number;

  // load providers
  providerDesc:any;
  providerDesc_text:any;

  // load provider
  provider:any;
  provider_text:any;

  // provider Type
  providerType:any;
  providerType_text:any;

  // item Type
  itemType:any;
  itemType_text:any;
 
  //add SKU
  isDisplay= true;
  toggleDisplay(){
    this.isDisplay= 
    !this.isDisplay;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb : FormBuilder,
    private request: RequestsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    this.initializeForm()
   }

  ngOnInit(): void {
    this.uid = this.route.snapshot.paramMap.get('id');
    this.get_buyload();
  }
  initializeForm(){
    this.updateForm = this.fb.group({
      itemName: [''],
      itemId: [''],
      itemDesc: [''],
      itemPrice: [''],
    });
  }
  
  
  get_buyload(){
    this.request.put(Urls.buyload + '/' + this.uid,{}).then(response => { 
      console.log('ako',response);
      this.updateForm = this.fb.group({
        itemName:(response['result']['itemName']),
        itemId: (response['result']['itemId']),
        itemDesc:(response['result']['itemDesc']),
        itemPrice:(response['result']['itemPrice']),
      });
      this.providerDesc=response['result']['providerDesc'];
        if(response['result']['providerDesc']==0){ 
          this.providerDesc_text='Default ( Resound for Globe, Digilink for Smart/Sun/TNT )';
        }else if(response['result']['providerDesc']==1){ 
          this.providerDesc_text='Digilink ( Digilink For All Network )';
        }else {
          this.providerDesc_text='Resound ( Resound For All Network )';
        }
      this.provider=response['result']['provider'];
        if(response['result']['provider']==0){ 
          this.provider_text='Default ( Resound for Globe, Digilink for Smart/Sun/TNT )';
        }else if(response['result']['provider']==1){ 
          this.provider_text='Digilink ( Digilink For All Network )';
        }else {
          this.provider_text='Resound ( Resound For All Network )';
        }
      this.providerType=response['result']['providerType'];
        if(response['result']['providerType']==1){ 
          this.providerType_text='Smart';
        }else if(response['result']['providerType']==2){ 
          this.providerType_text='Globe';
        }else if(response['result']['providerType']==3){ 
          this.providerType_text='Sun';
        }else if(response['result']['providerType']==4){ 
          this.providerType_text='TNT';
        }else if(response['result']['providerType']==5){ 
          this.providerType_text='Smart Bro';
        }else if(response['result']['providerType']==6){ 
          this.providerType_text='Kuryente Load';
        }else if(response['result']['providerType']==7){ 
          this.providerType_text='Cignal';
        }else if(response['result']['providerType']==8){ 
          this.providerType_text='DITO';
        }
      this.itemType=response['result']['itemType'];
        if(response['result']['itemType']==1){ 
          this.itemType_text='Promo';
        }else if(response['result']['itemType']==2){ 
          this.itemType_text='Load';
        }else if(response['result']['itemType']==3){ 
          this.itemType_text='Text';
        }else if(response['result']['itemType']==4){ 
          this.itemType_text='Combo';
        }else if(response['result']['itemType']==5){ 
          this.itemType_text='Meralco';
        }else if(response['result']['itemType']==6){ 
          this.itemType_text='Cignal';
        }else if(response['result']['itemType']==7){ 
          this.itemType_text='Cignal PPV';
        }
    }) 
  }

  switchLoadProvider(){ 
    this.request.put(Urls.providers + '/' + '?provider=' + this.providerDesc ,{
      status:this.status
      }).then(response => {
      console.log(response); 
      if(response['status']==200){
        this.toastr.success(response['message']+'!', 'Success!');
      }
      else{
        this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
      }
      this.providerDesc_text=null;
    })    
  }

  update(){
    this.spinner.show();
    this.request.put(Urls.buyload + '/' + this.uid, {
      itemName:this.updateForm.controls.itemName.value,
      itemId:this.updateForm.controls.itemId.value,
      itemDesc:this.updateForm.controls.itemDesc.value,
      itemPrice:this.updateForm.controls.itemPrice.value,
      provider:this.provider,
      providerType:this.providerType,
      itemType:this.itemType,
    }).then(response => {
      console.log(response);
      if(response['status']==200){
        this.toastr.success(response['message']+'!', 'Success!');
        this.router.navigate(['/features/sku-biller-all-transaction']);
      }
      else{
        this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
      }
    })
    this.updateForm.reset();
    this.providerType_text=null;
    this.provider_text=null;
    this.itemType_text=null;
  } 

  setLoadProvider(text,value){
    this.providerDesc=value;
    this.providerDesc_text=text;
    
  }

  setProvider(text,value){
    this.provider=value;
    this.provider_text=text;
  }

  setProviderType(text,value){
    this.providerType=value;
    this.providerType_text=text;
  }

  setItemType(text,value){ 
    this.itemType=value;
    this.itemType_text=text; 
  }

}
