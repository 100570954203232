import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { RequestsService } from 'src/app/services/http/requests.service';
import { Urls } from 'src/app/lib/urls';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sku-stores-edit',
  templateUrl: './sku-stores-edit.component.html',
  styleUrls: ['./sku-stores-edit.component.scss']
})
export class SkuStoresEditComponent implements OnInit {
  public updateForm: FormGroup;
  uid:any;
  status:number;

 // category
 category_text:any;
 category:any;

 //fieldName
 fieldName_text:any;
 fieldName:any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb : FormBuilder,
    private request: RequestsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { 
    this.initializeForm()
  }

 ngOnInit(): void {
   this.uid = this.route.snapshot.paramMap.get('id');
   this.get_stores();
 }
 initializeForm(){
   this.updateForm = this.fb.group({
    productDesc: [''],
    productName: [''],
    productCode: [''],
    itemPrice: [''],
    salesStartDate: [''],
    salesEndDate: [''],
    goodsImgUrl:['']

   });
 }
  
 get_stores(){
  this.request.put(Urls.items + '/' + this.uid,{}).then(response => { 
    this.updateForm = this.fb.group({
      productDesc:(response['result']['productDesc']),
      productName: (response['result']['productName']),
      productCode:(response['result']['productCode']),
      itemPrice:(response['result']['itemPrice']),
      salesStartDate:(response['result']['salesStartDate']),
      salesEndDate:(response['result']['salesEndDate']),
      goodsImgUrl:(response['result']['goodsImgUrl']),
    });
    this.category=response['result']['category'];
      if(response['result']['category']==2){ 
        this.category_text='Food and Beverage';
      }else if(response['result']['category']==3){ 
        this.category_text='Prepaid Load';
      }else if(response['result']['category']==4){ 
        this.category_text='Shopping';
      }else if(response['result']['category']==5){ 
        this.category_text='Entertainment';
      }else if(response['result']['category']==6){ 
        this.category_text='Travel';
      }else if(response['result']['category']==7){ 
        this.category_text='Health';
      }else if(response['result']['category']==8){ 
        this.category_text='Gaming Pins';
      }
    this.fieldName=response['result']['fieldName'];
      if(response['result']['fieldName']== 'Phone Number'){ 
        this.fieldName_text='Phone Number';
      }else if(response['result']['fieldName']=='Email Address'){ 
        this.fieldName_text='Email Address';
      }
  }) 
}

update(){
  this.spinner.show();
  this.request.put(Urls.items + '/' + this.uid, {
    productDesc:this.updateForm.controls.productDesc.value,
    productName:this.updateForm.controls.productName.value,
    productCode:this.updateForm.controls.productCode.value,
    itemPrice:this.updateForm.controls.itemPrice.value,
    salesStartDate:this.updateForm.controls.salesStartDate.value,
    salesEndDate:this.updateForm.controls.salesEndDate.value,
    goodsImgUrl:this.updateForm.controls.goodsImgUrl.value,
    category:this.category,
    fieldName:this.fieldName
  }).then(response => {
    console.log(response);
    if(response['status']==200){
      this.toastr.success(response['message']+'!', 'Success!');
      this.router.navigate(['/features/sku-stores']);
    }
    else{
      this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
    }
  })
  this.updateForm.reset();
  this.category_text=null;
  this.fieldName_text=null;
} 

setCategory(text,value){
  this.category=value;
  this.category_text=text;
}

setFieldName(text,value){
  this.fieldName=value;
  this.fieldName_text=text;

}


}
