import { Component, OnInit ,  VERSION} from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { RequestsService } from 'src/app/services/http/requests.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { DebounceService } from 'src/app/services/debounce.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'src/app/services/dialog.service';
import { Urls } from 'src/app/lib/urls';

@Component({
  selector: 'app-features-push-notif',
  templateUrl: './features-push-notif.component.html',
  styleUrls: ['./features-push-notif.component.scss']
})
export class FeaturesPushNotifComponent implements OnInit {
  isLoading=true;
  public pushNotifForm: FormGroup;
  pushNotif_lists:any;
  file:any;

  //image
  imageFile: {link: string, file: any, name: string};
  imageFile2: {link: string, file: any, name: string};
  cover_img:any;
  feature_img:any;

  //search
  searchKey: any=null;
  searchForm : FormGroup;
  search_keyword='';
  
  //limit
  limitKey:any=null;
  limitForm : FormGroup;
  
  //pagination
  page=1;
  size=10;
  total_pages:any;
  current_page = 1;
  pages_array=[
  ];
  total:any;
  
   //null with default
   name = 'Angular ' + VERSION.major;

  //sorting
  sort_column="dateTime";
  sort_type="ASC";

  //print
  search:any;
  tabId:any;
  fileName:any;
  url:any;
  params={ 
    search: '',
  }


  constructor(
    public userService: UserService,
    private toastr: ToastrService,
    public request: RequestsService,
    private fb : FormBuilder,
    public debounce: DebounceService,
    private spinner: NgxSpinnerService,
    private dialogService:DialogService,
  ) { 
    this.initializeForm()
  }

  ngOnInit(): void {
    var tableHeader = document.getElementsByTagName("th");  
    for (var i = 0; i < tableHeader.length; i++) { 
      tableHeader[i].classList.add("headerSortDown");
    }
    this. get_pushNotifList();
    
  }

  initializeForm(){
    this.pushNotifForm = this.fb.group({
      title: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      message: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      coverImage: [
        '',
        Validators.compose([
          Validators.required
        ]) 
      ],
      featureImage: [
        '',
        Validators.compose([
          Validators.required
        ])
      ], 
      dateFrom: [
        '',
        Validators.compose([
          Validators.required
        ])
      ], 
      dateTo: [
        '',
        Validators.compose([
          Validators.required
        ])
      ], 
    });
    this.searchKey = this.debounce.debounce(() => {
      this.current_page=1;
      this.search_keyword=this.searchForm.value.search;
      console.log('searchKeyword', this.search_keyword)
      this.get_pushNotifList();
    }, 500); 

    this.searchForm = this.fb.group({
      search:['']
    });

    this.limitKey = this.debounce.debounce(() => {
      this.current_page=1;
      this.size=this.limitForm.value.limit;
      this.get_pushNotifList();
    }, 500);  

    this.limitForm = this.fb.group({
      limit:['']
    });
  }
  sortList(sort_column, sort_type){
    this.spinner.show();
    if(sort_type=="ASC"&&this.sort_column!=sort_column&&this.sort_type==sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="ASC"&&this.sort_column==sort_column&&this.sort_type!=sort_type){
      this.sort_type="ASC";
      this.sort_column=sort_column;
    }else if(sort_type=="ASC"&&this.sort_column==sort_column&&this.sort_type==sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="DESC"&&this.sort_column!=sort_column&&this.sort_type==sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="DESC"&&this.sort_column==sort_column&&this.sort_type!=sort_type){
      this.sort_type="DESC";
      this.sort_column=sort_column;
    }else if(sort_type=="DESC"&&this.sort_column==sort_column&&this.sort_type==sort_type){
      this.sort_type="ASC";
      this.sort_column=sort_column;
    }
    if(this.pushNotif_lists){
      this.get_pushNotifList();
    }
    console.log('sort_column',this.sort_column);
    console.log('sort_type',this.sort_type);
  }

  onSelectFileCover(event) { 
      if (event.target.files && event.target.files[0]) {
        const reader = new FileReader();
        let link=event.srcElement.files[0];
        let name=event.srcElement.files[0].name;
        reader.onload = (event: any) => {
          this.imageFile = {
              link: event.target.result,
              file: link,
              name: name
          };
          this.cover_img = event.target.result;
        };
        reader.readAsDataURL(event.target.files[0]); 
      }
      console.log(event.srcElement.files[0].name);
  }

  onSelectFileFeatured(event) { 
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
        let link=event.srcElement.files[0];
        let name=event.srcElement.files[0].name;
        reader.onload = (event: any) => {
          this.imageFile2 = {
              link: event.target.result,
              file: link,
              name: name
          };
          this.feature_img = event.target.result;
        };
        reader.readAsDataURL(event.target.files[0]); 
      }
  }
  reset(){
    this.pushNotifForm.reset();
    this.cover_img=null;
    this.feature_img=null;
  }

  store(){
    if(!this.imageFile && !this.imageFile2){
      this.spinner.show();
      let formData = new FormData();
      formData.append('dateFrom', moment.utc(this.pushNotifForm.controls.dateFrom.value).format());
      formData.append('dateTo', moment.utc(this.pushNotifForm.controls.dateTo.value).format());
      formData.append('title', this.pushNotifForm.controls.title.value);
      formData.append('message', this.pushNotifForm.controls.message.value);
      // formData.append('coverImage', this.imageFile.file);
      // formData.append('featureImage', this.imageFile2.file);
      this.request.post(Urls.store_pushNotif, formData).then(response => {
        console.log(response);
        if(response['status']==200){
          this.toastr.success(response['message']+'!', 'Success!');
          this.get_pushNotifList();
        }else{
          console.log(response['error']);
          this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
        }
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      });
    }else if(!this.imageFile){
      this.spinner.show();
      let formData = new FormData();
      formData.append('dateFrom', moment.utc(this.pushNotifForm.controls.dateFrom.value).format());
      formData.append('dateTo', moment.utc(this.pushNotifForm.controls.dateTo.value).format());
      formData.append('title', this.pushNotifForm.controls.title.value);
      formData.append('message', this.pushNotifForm.controls.message.value);
      // formData.append('coverImage', this.imageFile.file);
      formData.append('featureImage', this.imageFile2.file);
      this.request.post(Urls.store_pushNotif, formData).then(response => {
        console.log(response);
        if(response['status']==200){
          this.toastr.success(response['message']+'!', 'Success!');
          this.get_pushNotifList();
        }else{
          console.log(response['error']);
          this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
        }
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      });

    }else if(!this.imageFile2){
      this.spinner.show();
      let formData = new FormData();
      formData.append('dateFrom', moment.utc(this.pushNotifForm.controls.dateFrom.value).format());
      formData.append('dateTo', moment.utc(this.pushNotifForm.controls.dateTo.value).format());
      formData.append('title', this.pushNotifForm.controls.title.value);
      formData.append('message', this.pushNotifForm.controls.message.value);
      formData.append('coverImage', this.imageFile.file);
      // formData.append('featureImage', this.imageFile2.file);
      this.request.post(Urls.store_pushNotif, formData).then(response => {
        console.log(response);
        if(response['status']==200){
          this.toastr.success(response['message']+'!', 'Success!');
          this.get_pushNotifList();
        }else{
          console.log(response['error']);
          this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
        }
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      });

    }
    else if(this.imageFile && this.imageFile2){
      this.spinner.show();
      let formData = new FormData();
      formData.append('dateFrom', moment.utc(this.pushNotifForm.controls.dateFrom.value).format());
      formData.append('dateTo', moment.utc(this.pushNotifForm.controls.dateTo.value).format());
      formData.append('title', this.pushNotifForm.controls.title.value);
      formData.append('message', this.pushNotifForm.controls.message.value);
      formData.append('coverImage', this.imageFile.file);
      formData.append('featureImage', this.imageFile2.file);
      this.request.post(Urls.store_pushNotif, formData).then(response => {
        console.log(response);
        if(response['status']==200){
          this.toastr.success(response['message']+'!', 'Success!');
          this.get_pushNotifList();
        }else{
          console.log(response['error']);
          this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
        }
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      });

    }
     
    this.pushNotifForm.reset();
    this.cover_img=null;
    this.feature_img=null; 
   
  } 


  get_pushNotifList(){
    this.spinner.show();
    let params ={ 
      search : this.search_keyword,
      sortBy: {
        [this.sort_column]: this.sort_type
      }
    }
    this.pages_array=[];
    this.request.post(Urls.pushNotif + '?page=' + this.current_page + '&size=' + this.size, params).then(response => {
      console.log(response)
      if(response['status']==200){
        this.pushNotif_lists=response['result']['data'];
        this.isLoading=false;
        if(this.current_page  === 0  ){
          this.pages_array=[];
          for(let i=this.current_page; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }else if(this.current_page==1){
          for(let i=1; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }else if(this.current_page==response['result']['totalPages']){
          for(let i=this.current_page-2; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }else{
          for(let i=this.current_page-1; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }
        
        this.total_pages=parseInt(response['result']['totalPages']);
        this.current_page=response['result']['currentPage'];
        this.total=parseInt(response['result']['totalItems']);
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      }
    }).finally(()=>{
    });   
  }

  setPage(num){
    this.current_page = num;
    this.get_pushNotifList();
  }
  nextPage(){
    if(this.current_page < Number(this.total_pages)){
         this.current_page++;
         this.get_pushNotifList()
     }
   }
  prevPage(){
    if(this.current_page <= Number(this.total_pages)){
      this.current_page--;
      this.get_pushNotifList();
    }
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.searchList();
    }
  }
  searchList(){
    this.current_page=1;
    this.search_keyword=this.searchForm.value.search;
    console.log('searchKeyword', this.search_keyword)
    this.get_pushNotifList();
  }

  print(){
    this.spinner.show();
    var current = new Date();
    this.params=JSON.parse(localStorage.getItem('pushNotif_lists')); 
    this.fileName=current.toLocaleDateString()+"-push-notification.xlsx";
    console.log('parent params',this.params);
    this.request.postBlob(Urls.exportPushNotif,this.params).then(report => {
      const blob = new Blob([report], { type: 'application/vnd.ms-excel' });
      const data = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = data;
      link.download = this.fileName;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        this.spinner.hide();
      }, 500);
    });
  }


}
