export const Urls = {
    //ads
    ads: '/ads',
    store_ads: '/ads/new',
    exportAds:'/ads/excel',
  
    //announcements
    announcements: '/announcements',
    store_announcement: '/announcements/new',
    exportAnnouncements:'/announcements/excel',
  
    //switchprovider
    providers:'/buyload/providers',
  
    //buyload
    buyload:'/buyload/items',
    store_buyload:'/buyload/items/new',
    exportBuyload:'/buyload/items/excel',
  
    cashIn: '/cardPaymentProviders/all',
    store_provider:'/cardPaymentProviders/new',
    updateCashIn:'/cardPaymentProviders',
    switch: '/cardPaymentProviders/switch',
  
    //paybills
    billers:'/billers',
    store_billers:'/billers/new',
    fields_billers:'/billers/fieldNames',
    exportPaybills:'/billers/excel',
  
    //stores
    items:'/stores/items',
    store_items:'/stores/items/new',
    exportItems:'/stores/item/excel',
  
    //users
    users:'/users',
    store_users:'/users/new',
    login:'/users/login',
    logout:'/users/logout',
  
    //user accounts
    userAccount:'/accounts',
    exportuserAccount:'/accounts/excel',
  
    //kyc registration
    kycRegistrations:'/kyc/registrations',
    exportKycRegistrations:'/kyc/registrations/excel',
  
    //kyc logs
    kycLogs:'/kyc/logs',
    exportKycLogs:'/kyc/logs/excel',
  
    //pushNotif
    pushNotif:'/push_notifications',
    store_pushNotif:'/push_notifications/new',
    exportPushNotif:'/push_notifications/excel',
  
    //all transactions
    transactionsAll:'/transactions/all',
    exportTransactionsAll:'/transactions/all/excel',
  
    //buyload
    transactionsBuyload:'/transactions/buyload',
    exportTransactionsBuyload:'/transactions/buyload/excel',
  
    //cashin
    transactionsCashin:'/transactions/cashin',
    exportTransactionsCashin:'/transactions/cashin/excel',
  
    //paybills
    transactionsPaybills:'/transactions/paybills',
    exportTransactionsPaybills:'/transactions/paybills/excel',
  
    //transfers
    transactionsTransfers:'/transactions/transfer',
    exportTransactionsTransfers:'/transactions/transfer/excel',
  
    //purchases
    transactionsPurchases:'/transactions/purchases',
    exportTransactionsPurchases:'/transactions/purchases/excel',
  
    //rewards
    transactionsRewards:'/transactions/rewards',
    exportTransactionsRewards:'/transactions/rewards/excel',
  
    //overview
    transactionsOverview:'/transactions/overview',
    exportTransactionsOverview:'/transactions/overview/excel',
  
    //cashin
    cashinDeposits:'/cashin/deposits/',
    exportCashinDeposits:'/cashin/deposits/excel',
  
    //cashout
    cashoutDeposits:'/cashout/deposits',
    exportCashoutDeposits:'/cashout/deposits/excel',
  
    //summary
    summary:'/reports/summary',
    exportSummary:'/reports/summary/excel',
  
    //dailySales
    dailySales:'/reports/dailySales',
    exportDailySales:'/reports/dailySales/excel',
  
    //topUsers
    topUsers:'/reports/topUsers',
    exportTopUsers:'/reports/topUsers/excel',
  
    //referralCommissions
    referralCommissions:'/reports/referralCommissions',
    referralCommissionsVerify:'/reports/referralCommissions/verify',
    exportReferralCommission:'/reports/referralCommissions/excel',
  
    //admin portal user logs
    portalUserLogs:'/portal/users/logs',
  
    //changePassword
    changePassword:'/user/accounts/password/update',
  
   //maintenance
    maintenance:'/maintenance',

  //sendForgot
  sendForgotPasswordEmail:'/users/sendForgotPasswordEmail',

  //reset
  reset:'/users/resetPassword'
  }