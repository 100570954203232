<div class="w-full h-full">
    <div class="w-900-px mx-auto">
        <form [formGroup]="form" >
            <div class="grid grid-cols-8 gap-3 mt-5">
                <div class="col-span-4">
                    <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                        <span class="text-xs w-130-px font-semibold ">
                            User Name:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="username" type="text" placeholder="User Name">
                    </div>
                    <div class="card-input flex items-center text-xs py-2 w-3/4 mb-3">
                        <span class="text-xs w-106-px font-semibold ">
                            Date From:
                        </span>
                        <!-- <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy"> -->
                        <mat-form-field color="accent" appearance="">
                            <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker1" readonly (click)="startDate.click()" formControlName="dateFrom">
                            <mat-datepicker-toggle matSuffix [for]="picker1" class="focus:outline-none" >
                                <svg #startDate class="focus:outline-none" matDatepickerToggleIcon id="iconfinder_calendar-book-date-monthly_2203551" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                    <path id="Path_371" data-name="Path 371" d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z" fill="#5f4d9c"/>
                                    <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4" transform="translate(13 6.936)" fill="#5f4d9c"/>
                                    <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4" transform="translate(13 11.936)" fill="#5f4d9c"/>
                                    <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4" transform="translate(8 11.936)" fill="#5f4d9c"/>
                                    <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4" transform="translate(3 11.936)" fill="#5f4d9c"/>
                                    <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4" transform="translate(8 6.936)" fill="#5f4d9c"/>
                                    <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4" transform="translate(3 6.936)" fill="#5f4d9c"/>
                                </svg>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-span-4">
                    <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                        <div class="flex items-center space-x-3">
                            <span class="text-xs w-120-px font-semibold">
                                Portal Page / Tab:
                            </span>
                                <div #t2="matMenuTrigger" #select2 [matMenuTriggerFor]="tab_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                                    <span *ngIf="!portalTab_text "class="barlow text-black text-xs font-lg p-5 italic">Select an Option</span>
                                    <span *ngIf="portalTab_text"class="barlow text-black text-xs font-lg p-5 italic">{{portalTab_text}}</span>
                                    <span class="material-icons violet-text ml-3">
                                        arrow_drop_down
                                    </span>
                                </div>
                                <mat-menu class="w-full sports-menu" #tab_select="matMenu">
                                    <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs " >
                                        Select an Option
                                    </button>
                                    <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setPortalTabType('ADS DASHBOARD','ADS DASHBOARD')">
                                        ADS DASHBOARD
                                    </button>
                                    <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setPortalTabType('Portal User Logs','Portal User Logs')">
                                        Portal User Logs
                                    </button>
                                    <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setPortalTabType('ANNOUNCEMENT','ANNOUNCEMENT')">
                                        ANNOUNCEMENT
                                    </button>
                                </mat-menu>
                            </div>
                    
                    </div>
                    <div class="card-input flex items-center text-xs py-2 w-3/4 mb-3">
                        <span class="text-xs w-106-px font-semibold ">
                            Date To:
                        </span>
                        <mat-form-field color="accent" appearance="">
                            <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker2" readonly (click)="startDate.click()" formControlName="dateTo">
                            <mat-datepicker-toggle matSuffix [for]="picker2" class="focus:outline-none" >
                                <svg #startDate class="focus:outline-none" matDatepickerToggleIcon id="iconfinder_calendar-book-date-monthly_2203551" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                    <path id="Path_371" data-name="Path 371" d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z" fill="#5f4d9c"/>
                                    <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4" transform="translate(13 6.936)" fill="#5f4d9c"/>
                                    <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4" transform="translate(13 11.936)" fill="#5f4d9c"/>
                                    <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4" transform="translate(8 11.936)" fill="#5f4d9c"/>
                                    <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4" transform="translate(3 11.936)" fill="#5f4d9c"/>
                                    <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4" transform="translate(8 6.936)" fill="#5f4d9c"/>
                                    <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4" transform="translate(3 6.936)" fill="#5f4d9c"/>
                                </svg>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
        <div class="w-full flex items-center justify-center mt-10 mb-4">
            <button (click)="submit()" class="uppercase custom-btn btn-dark-orange font-semibold text-xs px-6 py-2">
                submit
            </button>
        </div> 
    </div>
    <div class=" w-full  maxh-550-px py-4 px-3 overflow-x-auto w-table mt-5" *ngIf="submitted">
    <div class="data-table mt-3 barlow m-5 text-xs  " >
        <table class="w-full" >
            <thead class="bg-light-orange  "> 
                <tr class="header-row" style="border-left: 1px #CAC2BC solid; border-right: 1px #CAC2BC solid;">
                    <th class="text-left barlow orange-text">
                        DATE TAKEN
                    </th>
                    <th class="text-left barlow orange-text">
                        PORTAL TAB
                    </th>
                    <th class="text-left barlow orange-text">
                        ACTION MADE
                    </th>
                    <th class="text-left barlow orange-text">
                        ACCOUNT TAKEN BY
                    </th>
                    <th class="text-left barlow orange-text">
                        USERNAME
                    </th>
                </tr>
            </thead>
            <tbody class="header-row" style="border-left: 1px #CAC2BC solid; border-right: 1px #CAC2BC solid;">
                <ng-container *ngIf="!itemLists_filter && !itemLists_filter2">
                    <tr class="focus:outline-none" *ngFor="let item of itemLists" >
                        <td>{{item.dateTaken | date:' yyyy-dd-MM  hh:mm:ss'| nullWithDefault}}</td>
                        <td>{{ item.portalTab | nullWithDefault }}</td>
                        <td>{{ item.actionMade  | nullWithDefault}}</td>
                        <td>{{ item.actionTakenBy | nullWithDefault }}</td>
                        <td>{{ item.username | nullWithDefault }}</td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="itemLists_filter">
                    <tr class="focus:outline-none" *ngFor="let item of itemLists_filter" >
                        <td>{{item.dateTaken | date:' yyyy-dd-MM  hh:mm:ss'| nullWithDefault}}</td>
                        <td>{{ item.portalTab | nullWithDefault }}</td>
                        <td>{{ item.actionMade  | nullWithDefault}}</td>
                        <td>{{ item.actionTakenBy | nullWithDefault }}</td>
                        <td>{{ item.username | nullWithDefault }}</td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="itemLists_filter2">
                    <tr class="focus:outline-none" *ngFor="let item of itemLists_filter2" >
                        <td>{{item.dateTaken | date:' yyyy-dd-MM  hh:mm:ss'| nullWithDefault}}</td>
                        <td>{{ item.portalTab | nullWithDefault }}</td>
                        <td>{{ item.actionMade  | nullWithDefault}}</td>
                        <td>{{ item.actionTakenBy | nullWithDefault }}</td>
                        <td>{{ item.username | nullWithDefault }}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table> 
    </div>
</div>
   