import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { RequestsService } from 'src/app/services/http/requests.service';
import { Urls } from 'src/app/lib/urls';
import { ActivatedRoute, Router} from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { CustomValidationService } from 'src/app/services/custom_validation/custom-validation.service';

@Component({
  selector: 'app-send-pass',
  templateUrl: './send-pass.component.html',
  styleUrls: ['./send-pass.component.scss']
})
export class SendPassComponent implements OnInit {
  public Form: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb : FormBuilder,
    private request: RequestsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private customValidator: CustomValidationService
  ) { 
    this.initializeForm()
  }

  ngOnInit(): void {
  }

  initializeForm(){
    this.Form = this.fb.group({
      username: ['', Validators.required],
      setPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      code: ['', Validators.required],
    },
    {
      validator: this.customValidator.passwordMatchValidator3(
        'setPassword',
        'confirmPassword'
      ),
    });
  }
    
  changePass(){
    this.spinner.show();
    this.request.post2(Urls.reset,{
      username:this.Form.controls.username.value,
      setPassword:this.Form.controls.setPassword.value,
      confirmPassword:this.Form.controls.confirmPassword.value,
      code:this.Form.controls.code.value,
    }).then(response => {
      console.log(response);
      if(response['status'] === 200){
        this.toastr.success(response['message'], 'Success!');
        this.router.navigate(['/login'])
      } else {
        this.toastr.error(response['error']['message'], 'Error');
      }
    }).catch(error => {
      console.error(error);
      this.toastr.error('Something went wrong while changing password!', 'Error');
    }).finally(() => {
      this.spinner.hide();
    });
  } 
}
