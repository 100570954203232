import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgModel, Validators } from '@angular/forms';
import { RequestsService } from 'src/app/services/http/requests.service';
import { Urls } from 'src/app/lib/urls';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sku-cash-in',
  templateUrl: './sku-cash-in.component.html',
  styleUrls: ['./sku-cash-in.component.scss']
})
export class SkuCashInComponent implements OnInit {
  public Form: FormGroup;
  buyLoad_lists: any;
  isLoading: boolean;
  status: number;
  search: any;
  id: any;
  switchProviders: string[] = [];
  switchProviders_text: string;
  selectedProvider: string = 'all'; 

  //provider
  defaultChecked: boolean = false;
  mayaChecked: boolean = false;
  c2c2pChecked: boolean = false;

  //sku
  isDisplay = true;

  // Sorting
  sort_column = "dateTime";
  sort_type = "ASC";

  // Print
  tabId: any;
  fileName: any;
  url: any;
  params = {
    search: '',
  }

  @ViewChild('dropdown') dropdown: ElementRef;
  isDropdownOpen: boolean = false;

  constructor(
    private fb: FormBuilder,
    private request: RequestsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) {
    this.initializeForm()
  }

  ngOnInit(): void {
    const storedProvider = localStorage.getItem('selectedProvider');
    this.selectedProvider = storedProvider ? storedProvider : 'all';
    if (storedProvider === 'maya,2c2p') {
      this.selectedProvider = 'both';
    }
    const storedSwitchProvidersText = localStorage.getItem('switchProviders_text');
    if (storedSwitchProvidersText) {
      this.switchProviders_text = storedSwitchProvidersText;
    }
    this.get_buyLoad_list();
  }

  initializeForm() {
    this.Form = this.fb.group({
      code: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      name: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      description: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
    });
  }

  onDefaultChange() {
    if (this.defaultChecked) {
        // If Default is checked, disable Maya and 2C2P
        this.mayaChecked = false;
        this.c2c2pChecked = false;
    } else {
        // If Default is unchecked, enable Maya and 2C2P
    }
}

onMayaChange() {
    if (this.mayaChecked) {
        // If Maya is checked, disable Default
        this.defaultChecked = false;
    } else {
        // If Maya is unchecked, enable Default if necessary
    }
}

onC2C2PChange() {
    if (this.c2c2pChecked) {
        // If 2C2P is checked, disable Default
        this.defaultChecked = false;
    } else {
        // If 2C2P is unchecked, enable Default if necessary
    }
}

  toggleDefault() {
    if (this.defaultChecked) {
        this.selectedProvider = 'all';
    } else {
        this.selectedProvider = 'all';
    }
  }

  toggleDisplay() {
    this.isDisplay = !this.isDisplay;
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event) {
    if (!this.dropdown.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }

  submitSelections() {
    this.spinner.show();
    const selectedOptions = Array.from(this.dropdown.nativeElement.querySelectorAll('input[type="checkbox"]:checked'))
      .map((checkbox: HTMLInputElement) => checkbox.value);

console.log('Selected Options:', selectedOptions);
// Update selectedProvider based on selected options
if (selectedOptions.includes('maya') && selectedOptions.includes('2c2p')) {
  this.selectedProvider = 'both';
} else if (selectedOptions.includes('maya')) {
  this.selectedProvider = 'maya';
} else if (selectedOptions.includes('2c2p')) {
  this.selectedProvider = '2c2p';
} else if (selectedOptions.includes('all')) {
  this.selectedProvider = 'all';
}
// Store selected provider in local storage
localStorage.setItem('selectedProvider', this.selectedProvider);
console.log('Selected Provider:', this.selectedProvider);

const requestBody = {
  "switchProviders": selectedOptions
};

this.request.put(Urls.switch, requestBody)
  .then((response: any) => {
    console.log(response);
    if (response.status === 200) {
      this.toastr.success(response.message + '!', 'Success!');
      if (selectedOptions.includes('maya') && selectedOptions.includes('2c2p')) {
        this.switchProviders_text = 'Maya and 2C2P';
      } else if (selectedOptions.includes('maya')) {
        this.switchProviders_text = 'Maya';
      } else if (selectedOptions.includes('2c2p')) {
        this.switchProviders_text = '2C2P';
      } else if (selectedOptions.includes('all')) {
        this.switchProviders_text = 'Default - Show All Available Facility';
      }else {
        this.toastr.error(response.error.message + '!', 'Something went wrong!');
      }
      localStorage.setItem('switchProviders_text', this.switchProviders_text);
      localStorage.setItem('selectedProvider', this.selectedProvider);
      this.switchProviders=null;
    } 
    setTimeout(() => {
      this.spinner.hide();
    }, 500);
  })
  .catch((error: any) => {
    console.error('Error occurred:', error);
    this.toastr.error('Failed to submit selections!', 'Error');
    this.spinner.hide();
  });
  this.defaultChecked = false;
 this.mayaChecked = false;
 this.c2c2pChecked = false;

  }

  store() {
    this.spinner.show();
    this.request.post(Urls.store_provider, {
      name: this.Form.controls.name.value,
      code: this.Form.controls.code.value,
      description: this.Form.controls.description.value,
    }).then(response => {
      console.log(response);
      if (response['status'] == 200) {
        this.toastr.success(response['message'] + '!', 'Success!');
      }
      else {
        this.toastr.error(response['error']['message'] + '!', 'Something went wrong!');
      }
      this.get_buyLoad_list();
      setTimeout(() => {
        this.spinner.hide();
      }, 500);
    })
    this.Form.reset();
  }

  get_buyLoad_list() {
    this.request.get(Urls.cashIn).then(response => {
      this.buyLoad_lists = response['result'];
    });
  }
}