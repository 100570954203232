<div class="h-full w-full">
    <div class="flex items-center justify-between space-x-3 pt-7 text-sm">
        <div class="flex items-center" ></div>
        <div class="flex items-center pl-16" >
            <button class="custom-tabbtn text-xs focus:outline-none py-3 px-3 uppercase font-bold  btn-light-orange-no">
                MEGAPAY USER ACCOUNTS
            </button>
        </div>
        <div class="flex items-center space-x-3 pr-10"  >
            <svg (click)="print()" class="cursor-pointer " xmlns="http://www.w3.org/2000/svg" width="20" height="21.433" viewBox="0 0 25 21.433" >
                <g id="iconfinder_icon-printer_211880" transform="translate(-32 -64)">
                <path id="Path_369" data-name="Path 369" d="M155.961,313.926h.541v-7.245a.7.7,0,0,0-.692-.681H144.692a.7.7,0,0,0-.692.681v7.245h11.961Z" transform="translate(-105.749 -228.493)"/>
                <g id="Group_77" data-name="Group 77" transform="translate(32 64)">
                    <path id="Path_370" data-name="Path 370" d="M53.869,67.74H52.15V64.82a.856.856,0,0,0-.887-.82H37.743a.856.856,0,0,0-.887.82V67.74H35.137A3.34,3.34,0,0,0,32,70.787V77.53c0,1.518,1.58,3.444,3.137,3.444h1.021c.274,0,.307,0,.307-.781v-2.4A1.788,1.788,0,0,1,38.3,76.006H50.8c.988,0,1.736,1.189,1.736,2.233v1.954c0,.781.006.781.279.781h1.044a3.294,3.294,0,0,0,3.137-3V70.787A3.33,3.33,0,0,0,53.869,67.74Zm-1.328,3.4a.893.893,0,1,1,.893-.893A.9.9,0,0,1,52.54,71.144Z" transform="translate(-32 -64)"/>
                </g>
                </g>
            </svg>
        </div>
    </div>
    <div class="px-8 py-5">
        <form [formGroup]="form" >  
            <div class="w-900-px mx-auto">
                <div class="grid grid-cols-12 gap-3">
                    <div class="col-span-6">
                        <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                            <span class="text-xs w-100-px font-semibold">
                                First Name:
                            </span>
                            <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" style="text-transform: capitalize;" formControlName="firstName" type="text" placeholder="First Name">
                        </div>

                        <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                            <span class="text-s w-140-px font-semibold">
                                Phone Number:
                            </span>
                            <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="phoneNumber"  type="text" placeholder="Phone Number">
                        </div>
                    </div>
                    <div class="col-span-6">
                        <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                            <span class="text-xs w-100-px font-semibold">
                                Last Name:
                            </span>
                            <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" style="text-transform: capitalize;"  formControlName="lastName"  type="text" placeholder="Last Name">
                        </div>
                        <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                            <span class="text-xs w-160-px font-semibold">
                                Referrer Number:
                            </span>
                            <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="referrerNumber" type="text" placeholder="Referrer Number">
                        </div>
                    </div>
                    <div class="col-span-4">
                        <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                            <span class="text-xs w-106-px font-semibold">
                                Date From:
                            </span>
                            <!-- <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy"> -->
                            <mat-form-field color="accent" appearance="">
                                <input
                                    class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic"
                                    type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker1" readonly
                                    (click)="startDate.click()" formControlName="dateFrom" >
                                <mat-datepicker-toggle matSuffix [for]="picker1" class="focus:outline-none">
                                    <svg #startDate class="focus:outline-none" matDatepickerToggleIcon
                                        id="iconfinder_calendar-book-date-monthly_2203551"
                                        xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                        <path id="Path_371" data-name="Path 371"
                                            d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z"
                                            fill="#5f4d9c" />
                                        <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4"
                                            transform="translate(13 6.936)" fill="#5f4d9c" />
                                        <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4"
                                            transform="translate(13 11.936)" fill="#5f4d9c" />
                                        <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4"
                                            transform="translate(8 11.936)" fill="#5f4d9c" />
                                        <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4"
                                            transform="translate(3 11.936)" fill="#5f4d9c" />
                                        <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4"
                                            transform="translate(8 6.936)" fill="#5f4d9c" />
                                        <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4"
                                            transform="translate(3 6.936)" fill="#5f4d9c" />
                                    </svg>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                            <span class="text-xs w-100-px font-semibold">
                                Email:
                            </span>
                            <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic" formControlName="email"  type="text" placeholder="User Email">
                        </div>
                    </div>
                    <div class="col-span-4">
                        <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                            <span class="text-xs w-106-px font-semibold">
                                Date To:
                            </span>
                            <mat-form-field color="accent" appearance="">
                                <input
                                    class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic"
                                    type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker2" readonly
                                    (click)="startDate.click()" formControlName="dateTo">
                                <mat-datepicker-toggle matSuffix [for]="picker2" class="focus:outline-none">
                                    <svg #startDate class="focus:outline-none" matDatepickerToggleIcon
                                        id="iconfinder_calendar-book-date-monthly_2203551"
                                        xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                        <path id="Path_371" data-name="Path 371"
                                            d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z"
                                            fill="#5f4d9c" />
                                        <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4"
                                            transform="translate(13 6.936)" fill="#5f4d9c" />
                                        <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4"
                                            transform="translate(13 11.936)" fill="#5f4d9c" />
                                        <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4"
                                            transform="translate(8 11.936)" fill="#5f4d9c" />
                                        <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4"
                                            transform="translate(3 11.936)" fill="#5f4d9c" />
                                        <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4"
                                            transform="translate(8 6.936)" fill="#5f4d9c" />
                                        <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4"
                                            transform="translate(3 6.936)" fill="#5f4d9c" />
                                    </svg>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                            <div class="flex items-center">
                                <span class="text-xs w-95-px font-semibold">
                                    Account Type:
                                </span>
                                <div #t2="matMenuTrigger" #select2 [matMenuTriggerFor]="acc_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                                    <span *ngIf="!accountType_text" class="barlow text-black text-xs font-lg p-5 italic">All</span>
                                    <span *ngIf="accountType_text" class="barlow text-black text-xs font-lg p-5 ">{{accountType_text}}</span>
                                    <span class="material-icons violet-text pr-4">
                                        arrow_drop_down
                                    </span>
                                </div>
                                <mat-menu class="w-full sports-menu" #acc_select="matMenu">
                                    <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs " >
                                    All
                                    </button>
                                    <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setAccountType('User','1')">
                                    User
                                    </button>
                                    <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs" (click)="setAccountType('Merchant','2')">
                                    Merchant
                                    </button>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-4">
                        <div class="card-input flex items-center text-xs py-2 w-full mb-3">
                            <div class="flex items-center space-x-3 ">
                                <span class="text-xs w-40-px font-semibold">
                                    Status:
                                </span>
                                <div #t3="matMenuTrigger" #select3 [matMenuTriggerFor]="status_select"  class=" rounded-md py-3 pl-0  minw-220-px flex justify-between items-center h-30-px cursor-pointer">
                                    <span *ngIf="!statusType_text" class="barlow text-black text-xs font-lg p-5 italic">All</span>
                                    <span *ngIf="statusType_text" class="barlow text-black text-xs font-lg p-5 ">{{statusType_text}}</span>
                                    <span class="material-icons violet-text pr-4">
                                        arrow_drop_down
                                    </span>
                                </div>
                                <mat-menu class="w-full sports-menu" #status_select="matMenu">
                                    <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs  " >
                                        All
                                    </button>
                                    <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs " (click)="setStatusType('Active','0')">
                                    Active
                                    </button>
                                    <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs " (click)="setStatusType('Inactive','1')">
                                        Inactive
                                    </button>
                                </mat-menu>
                            </div>
                        </div>
                        <div class="card-input flex items-center text-xs py-2 w-full mb-3"> 
                            <div class="flex items-center">
                                <span class="text-xs w-80-px font-semibold">
                                    Tier Type:
                                </span>
                                <div #t1="matMenuTrigger" #select1 [matMenuTriggerFor]="type_select" id="dateTo" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                                    <span *ngIf="!tierType_text" class="barlow text-black text-xs font-lg p- italic ">All</span>
                                    <span *ngIf="tierType_text" class="barlow text-black text-xs font-lg p-5 ">{{tierType_text}}</span>
                                    <span class="material-icons violet-text  pr-4">
                                        arrow_drop_down
                                    </span>
                                </div>
                                <mat-menu class="w-full sports-menu" #type_select="matMenu">
                                    <button mat-menu-item class="tracking-wide focus:outline-none italic text-xs  " >
                                        All
                                    </button>
                                    <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs " (click)="setTierType('Default','1')">
                                        Default
                                    </button>
                                    <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs " (click)="setTierType('BLA','9')">
                                        BLA
                                    </button>
                                    <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs  " (click)="setTierType('MegaOne','8')">
                                        MegaOne
                                    </button>
                                    <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs " (click)="setTierType('MegaBiz','7')">
                                        MegaBiz
                                    </button>
                                    <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs " (click)="setTierType('Platinum','6')">
                                        Platinum
                                    </button>
                                    <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs " (click)="setTierType('Internal','5')">
                                        Internal
                                    </button>
                                    <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs " (click)="setTierType('Full','4')">
                                        Full
                                    </button>
                                    <button mat-menu-item class="tracking-wide focus:outline-none  italic text-xs " (click)="setTierType('N/A','3')">
                                        N/A
                                    </button>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-full flex items-center justify-center mt-5">
                    <button (click)="submit()" class="uppercase custom-btn btn-dark-orange font-semibold text-xs px-6 py-2">
                        submit
                    </button>
                </div>  
            </div>
        </form>
    </div>
    <div class="bg-light-orange w-full flex items-center justify-between space-x-3 pr-3 pt-4 text-sm mt-10">
        <ng-container *ngIf="!itemLists_filter && !itemLists_filter2" >
            <div class="flex items-center pl-6" [formGroup]="limitForm">
                <div class="orange-text text-xs" >Show</div>
                <input class="orange-input focus:outline-none text-center mx-1 w-25-px  text-xs p-1" type="number" formControlName="limit" (change)="limitKey($event)" value="{{size}}" >
                <div class="orange-text text-xs" >entries</div>
            </div>
        </ng-container>
        <ng-container *ngIf="itemLists_filter && !itemLists_filter2 && total_filter > 9" >
            <div class="flex items-center  pl-6" [formGroup]="limitForm_filter">
                <div class="orange-text text-xs" >Show</div>
                <input class="orange-input focus:outline-none text-center mx-1 w-25-px  text-xs p-1" type="number" formControlName="limit" (change)="limitKey_filter($event)" value="{{size_filter}}" >
                <div class="orange-text text-xs" >entries</div>
            </div>
        </ng-container>
        <ng-container *ngIf="itemLists_filter && !itemLists_filter2 && total_filter < 10 " >
            <div class="flex items-center  pl-6" [formGroup]="limitForm_filter">
                <div class="orange-text text-xs" >Show</div>
                <input class="orange-input focus:outline-none text-center mx-1 w-25-px  text-xs p-1" type="number" formControlName="limit" (change)="limitKey_filter($event)" value="{{total_filter}}" >
                <div class="orange-text text-xs" >entries</div>
            </div>
        </ng-container>
        <ng-container *ngIf="itemLists_filter2 && !itemLists_filter && total_filter2 > 9 ">
            <div class="flex items-center  pl-6" [formGroup]="limitForm_filter2">
                <div class="orange-text text-xs" >Show</div>
                <input class="orange-input focus:outline-none text-center mx-1 w-25-px  text-xs p-1" type="number" formControlName="limit" (change)="limitKey_filter2($event)" value="{{size_filter2}}" >
                <div class="orange-text text-xs" >entries</div>
            </div>
        </ng-container>
        <ng-container *ngIf="itemLists_filter2 && !itemLists_filter && total_filter2 < 10 " >
            <div class="flex items-center  pl-6" [formGroup]="limitForm_filter2">
                <div class="orange-text text-xs" >Show</div>
                <input class="orange-input focus:outline-none text-center mx-1 w-25-px  text-xs p-1" type="number" formControlName="limit" (change)="limitKey_filter2($event)" value="{{total_filter2}}" >
                <div class="orange-text text-xs" >entries</div>
            </div>
        </ng-container>
        <ng-container   *ngIf="itemLists && !itemLists_filter2" >
            <div class="flex items-center space-x-3 pr-3" [formGroup]="searchForm" (keydown)="keyDownFunction($event)">
                <div class="orange-text text-xs" >Search:</div>
                <input class="orange-input focus:outline-none pl-1 text-xs p-1" type="text" placeHolder="Search"  formControlName="search" (keyup)="searchKey($event)">
            </div>
        </ng-container>
        <ng-container  *ngIf="itemLists_filter" >
            <div class="flex items-center space-x-3 pr-3" [formGroup]="searchForm_filter" (keydown)="keyDownFunction($event)">
                <div class="orange-text text-xs" >Search:</div>
                <input class="orange-input focus:outline-none pl-1 text-xs p-1" type="text" placeHolder="Search"  formControlName="search" (keyup)="searchKey_filter($event)">
            </div>
        </ng-container>
        <ng-container  *ngIf="itemLists_filter2" >
            <div class="flex items-center space-x-3 pr-3" [formGroup]="searchForm_filter2" (keydown)="keyDownFunction($event)">
                <div class="orange-text text-xs" >Search:</div>
                <input class="orange-input focus:outline-none pl-1 text-xs p-1" type="text" placeHolder="Search"  formControlName="search" (keyup)="searchKey_filter2($event)">
            </div>
        </ng-container>
    </div>
    <div class=" bg-light-orange w-full  maxh-550-px px-3 overflow-x-auto w-table">
        <div class="data-table4 mt-3 barlow " style="font-size: 10px;">
            <table class="w-full" >
                <thead>
                    <tr>
                        <th class="text-left barlow pl-2 pt-3 pb-3" >
                            FIRST NAME
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('firstName', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            MIDDLE NAME
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('middleName', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            LAST NAME
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('lastName', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            PHONE NUMBER
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('phoneNumber', sort_type)">
                        </th>
                        <th class="text-left  barlow pl-2 pt-3 pb-3">
                            EMAIL
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('email', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            DATE CREATED
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('dateCreated', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            REFERRER NAME
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('referrerName', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            BALANCE
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('balance', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            REWARD
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('reward', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            STATUS
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('status', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            ACCOUNT TYPE
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('account', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            TIER TYPE
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('tierId', sort_type)">
                        </th>
                        <th class="text-left barlow pl-2 pt-3 pb-3">
                            ACTION
                            <img src="assets/images/icon/sort2.webp" style="height:15px;float:right" (click)= "sortList('status', sort_type)">
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="!itemLists_filter && !itemLists_filter2">
                        <tr class="focus:outline-none" *ngFor="let item of itemLists" >
                            <td>{{item.firstName | nullWithDefault | titlecase}}</td>
                            <td>{{ item.middleName | nullWithDefault | titlecase }}</td>
                            <td>{{ item.lastName  | nullWithDefault | titlecase}}</td>
                            <td>{{ item.phoneNumber | nullWithDefault }}</td>
                            <td>{{ item.email | nullWithDefault }}</td>
                            <td>{{ item.dateCreated | date:' yyyy-dd-MM  hh:mm:ss'| nullWithDefault }}</td>
                            <td>{{ item.referrerName | nullWithDefault | titlecase}}</td>
                            <td>
                                <p *ngIf="item.balance !== 0"> {{item.balance |number:'1.2-2'}}</p>
                                <p *ngIf="item.balance == null || item.balance == 0  ">0</p>
                            </td>
                            <td>
                                <p *ngIf="item.rewards !== 0"> {{item.rewards}}</p>
                                <p *ngIf="item.rewards == null">0</p>
                            </td>
                            <td>
                                <p *ngIf="item.status==0">Active</p>
                                <p *ngIf="item.status!==0">Inactive</p>
                            </td>
                            <td>{{ item.accountType | nullWithDefault }}</td>
                            <td>{{ item.tierType | nullWithDefault }}</td>
                            <td>
                                <div class="flex items-center">
                                    <span class="action red cursor-pointer " (click)="disable(item.uid,1)">
                                    </span>
                                    <span class="action green mx-1 cursor-pointer" (click)="enable(item.uid,0)">
                                    </span>
                                    <span class="action black cursor-pointer" [routerLink]="['/accounts/view/'+ item.uid]">
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="itemLists_filter">
                        <tr class="focus:outline-none" *ngFor="let item of itemLists_filter" >
                            <td>{{item.firstName | nullWithDefault | titlecase}}</td>
                            <td>{{ item.middleName | nullWithDefault | titlecase }}</td>
                            <td>{{ item.lastName  | nullWithDefault | titlecase}}</td>
                            <td>{{ item.phoneNumber | nullWithDefault }}</td>
                            <td>{{ item.email | nullWithDefault }}</td>
                            <td>{{ item.dateCreated | date:' yyyy-dd-MM  hh:mm:ss'| nullWithDefault }}</td>
                            <td>{{ item.referrerName | nullWithDefault | titlecase}}</td>
                            <td>
                                <p *ngIf="item.balance !== 0"> {{item.balance |number:'1.2-2'}}</p>
                                <p *ngIf="item.balance == null || item.balance == 0  ">0</p>
                            </td>
                            <td>
                                <p *ngIf="item.rewards !== 0"> {{item.rewards}}</p>
                                <p *ngIf="item.rewards == null">0</p>
                            </td>
                            <td>
                                <p *ngIf="item.status==0">Active</p>
                                <p *ngIf="item.status!==0">Inactive</p>
                            </td>
                            <td>{{ item.accountType | nullWithDefault }}</td>
                            <td>{{ item.tierType | nullWithDefault }}</td>
                            <td>
                                <div class="flex items-center">
                                    <span class="action red cursor-pointer " (click)="disable(item.uid,1)">
                                    </span>
                                    <span class="action green mx-1 cursor-pointer" (click)="enable(item.uid,0)">
                                    </span>
                                    <span class="action black cursor-pointer" [routerLink]="['/accounts/view/'+ item.uid]">
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="itemLists_filter2">
                        <tr class="focus:outline-none" *ngFor="let item of itemLists_filter2" >
                            <td>{{item.firstName | nullWithDefault | titlecase}}</td>
                            <td>{{ item.middleName | nullWithDefault | titlecase }}</td>
                            <td>{{ item.lastName  | nullWithDefault | titlecase}}</td>
                            <td>{{ item.phoneNumber | nullWithDefault }}</td>
                            <td>{{ item.email | nullWithDefault }}</td>
                            <td>{{ item.dateCreated | date:' yyyy-dd-MM  hh:mm:ss'| nullWithDefault }}</td>
                            <td>{{ item.referrerName | nullWithDefault | titlecase}}</td>
                            <td>
                                <p *ngIf="item.balance !== 0"> {{item.balance |number:'1.2-2'}}</p>
                                <p *ngIf="item.balance == null || item.balance == 0  ">0</p>
                            </td>
                            <td>
                                <p *ngIf="item.rewards !== 0"> {{item.rewards}}</p>
                                <p *ngIf="item.rewards == null">0</p>
                            </td>
                            <td>
                                <p *ngIf="item.status==0">Active</p>
                                <p *ngIf="item.status!==0">Inactive</p>
                            </td>
                            <td>{{ item.accountType | nullWithDefault }}</td>
                            <td>{{ item.tierType | nullWithDefault }}</td>
                            <td>
                                <div class="flex items-center">
                                    <span class="action red cursor-pointer " (click)="disable(item.uid,1)">
                                    </span>
                                    <span class="action green mx-1 cursor-pointer" (click)="enable(item.uid,0)">
                                    </span>
                                    <span class="action black cursor-pointer" [routerLink]="['/accounts/view/'+ item.uid]">
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
    <div class="bg-light-orange pb-4 col-span-full flex justify-between items-center  pt-4 ">
        <ng-container *ngIf="!itemLists_filter && !itemLists_filter2" >
            <div class="orange-text text-xs pl-6" >
                Showing {{size}} of {{total}} entree
            </div>
        </ng-container>
        <ng-container *ngIf="itemLists_filter && !itemLists_filter2 && total_filter > 9 " >
            <div class="orange-text text-xs pl-6" >
                Showing {{size_filter}} of {{total_filter}} entree
           </div>
        </ng-container>
        <ng-container *ngIf="itemLists_filter && !itemLists_filter2 && total_filter < 10 " >
            <div class="orange-text text-xs pl-6" >
                Showing {{total_filter}} of {{total_filter}} entree
            </div>
        </ng-container>
        <ng-container *ngIf="itemLists_filter2 && !itemLists_filter && total_filter2 > 9 " >
            <div class="orange-text text-xs pl-6" >
                Showing {{size_filter2}} of {{total_filter2}} entree
            </div>
        </ng-container>
        <ng-container *ngIf="itemLists_filter2 && !itemLists_filter && total_filter2 < 10 " >
            <div class="orange-text text-xs pl-6" >
                Showing {{total_filter2}} of {{total_filter2}} entree
            </div>
        </ng-container>
        <div class="pr-6  flex items-center justify-end table-pagination text-xs" style="text-align: right;  ">
            <ng-container  *ngIf="option==1">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled] ="this.current_page == 1" (click)="prevPage()">
                    Previous
                </button>
            </ng-container>
            <ng-container  *ngIf=" option==2 ">
               <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled] ="this.current_page_filter == 1" (click)="prevPage()">
                    Previous
                </button>
            </ng-container>
            <ng-container  *ngIf=" option==3 ">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled] ="this.current_page_filter2== 1" (click)="prevPage()">
                    Previous
                </button>
            </ng-container>
            <ng-container  *ngIf=" option==1 ">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active':current_page == 1 }"  style="white-space: nowrap;" (click) = "setPage(page)">
                    {{page}}
                </button>  
            </ng-container>
            <ng-container  *ngIf="option==2 && total_filter > 10 ">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active':current_page_filter == 1 }"  style="white-space: nowrap;" (click) = "setPage(page_filter)">
                    {{page_filter}}
                </button> 
            </ng-container>
            <ng-container  *ngIf="option==3 && total_filter2 > 10 ">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active':current_page_filter2 == 1 }"  style="white-space: nowrap;" (click) = "setPage(page_filter2)">
                    {{page_filter2}}
                </button> 
            </ng-container>
            <ng-container *ngIf="!itemLists_filter && !itemLists_filter2 && current_page >  2">
                <button class="page-btn sarabun-bold orange-box px-2 py-1"  style="white-space: nowrap;">
                    ...
               </button> 
            </ng-container>
            <ng-container *ngIf="itemLists_filter && current_page_filter >  2">
                <button class="page-btn sarabun-bold orange-box px-2 py-1"  style="white-space: nowrap;">
                    ...
                </button> 
            </ng-container>
            <ng-container *ngIf="itemLists_filter2 && current_page_filter2 >  2">
                <button class="page-btn sarabun-bold orange-box px-2 py-1"  style="white-space: nowrap;">
                    ...
                </button> 
            </ng-container>
            <ng-container  *ngIf="option==1">
                <button *ngFor="let item of pages_array | slice:1:3;let i=index;" class=" py-1 page-btn sarabun-bold orange-box px-2" [ngClass]="{'active':current_page == item }" style="white-space: nowrap;" (click) = "setPage(item)">
                    {{item}}
                </button>                  
            </ng-container>
            <ng-container  *ngIf="option==2 && total_filter > 30">
                <button *ngFor="let item_filter of pages_array_filter | slice:1:3;let i=index;" class=" py-1 page-btn sarabun-bold orange-box px-2" [ngClass]="{'active':current_page_filter == item_filter}" style="white-space: nowrap;" (click) = "setPage(item_filter)">
                    {{item_filter}}
                </button>  
            </ng-container>
            <ng-container  *ngIf="option==3 && total_filter2 > 30">
                <button *ngFor="let item_filter2 of pages_array_filter2 | slice:1:3;let i=index;" class=" py-1 page-btn sarabun-bold orange-box px-2" [ngClass]="{'active':current_page_filter2 == item_filter2}" style="white-space: nowrap;" (click) = "setPage(item_filter2)">
                    {{item_filter2}}
                </button>  
            </ng-container>
            <ng-container *ngIf="itemLists && !itemLists_filter && !itemLists_filter2 && total_pages > 4 && current_page+1 < total_pages">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;">
                    ...
                </button> 
            </ng-container>
            <ng-container *ngIf="!itemLists && itemLists_filter && !itemLists_filter2 && total_pages_filter > 4 && current_page_filter+1 < total_pages_filter">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;">
                    ...
                </button> 
            </ng-container>
            <ng-container *ngIf="!itemLists && !itemLists_filter && itemLists_filter2 && total_pages_filter2 > 4 && current_page_filter2+1 < total_pages_filter2">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;">
                    ...
                </button> 
            </ng-container>
            <ng-container  *ngIf="option==1 && total_pages > 4 && current_page+1 < total_pages">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active': current_page == item}" style="white-space: nowrap;" (click) = "setPage(total_pages)">
                    {{total_pages}}
                </button> 
            </ng-container>
            <ng-container  *ngIf="option==2  && total_pages_filter > 4 && current_page_filter+1 < total_pages_filter">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active': current_page_filter == item_filter}" style="white-space: nowrap;" (click) = "setPage(total_pages_filter)">
                    {{total_pages_filter}}
                </button> 
            </ng-container>
            <ng-container  *ngIf="option==2 && total_filter < 30">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active': current_page_filter == total_pages_filter}" style="white-space: nowrap;" (click) = "setPage(total_pages_filter)">
                   {{total_pages_filter}}
                </button> 
            </ng-container>
            <ng-container  *ngIf="option==3  && total_pages_filter2 > 4 && current_page_filter2+1 < total_pages_filter2">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active': current_page_filter2 == item_filter2}" style="white-space: nowrap;" (click) = "setPage(total_pages_filter2)">
                    {{total_pages_filter2}}
                </button> 
            </ng-container>
            <ng-container  *ngIf="option==3 && total_filter2 < 30">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" [ngClass]="{'active': current_page_filter2 == total_pages_filter2}" style="white-space: nowrap;" (click) = "setPage(total_pages_filter2)">
                    {{total_pages_filter2}}
                </button> 
            </ng-container>
            <ng-container *ngIf="option==1">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled]="this.current_page == total_pages " (click)= "nextPage()">
                    Next 
                </button> 
            </ng-container>
            <ng-container *ngIf="option==2">
               <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled]="this.current_page_filter == total_pages_filter " (click)= "nextPage()">
                    Next 
                </button> 
            </ng-container>
            <ng-container *ngIf="option==3">
                <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;" [disabled]="this.current_page_filter2 == total_pages_filter2 " (click)= "nextPage()">
                    Next 
                </button> 
            </ng-container>
        </div>
    </div>
</div> 
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "timer" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>