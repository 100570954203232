<div class="w-full h-full">
    <div class="flex items-center justify-between space-x-3 pt-7 text-sm">
        <div class="flex items-center" ></div>
        <div class="flex items-center pl-16" >
            <button class="custom-tabbtn text-xs focus:outline-none py-3 px-3 uppercase font-bold  btn-light-orange-no">
                FRAUD PREVENTION
            </button>
        </div>
        <div class="flex items-center space-x-3 pr-10"  >
            <svg class="cursor-pointer " xmlns="http://www.w3.org/2000/svg" width="20" height="21.433" viewBox="0 0 25 21.433" >
                <g id="iconfinder_icon-printer_211880" transform="translate(-32 -64)">
                <path id="Path_369" data-name="Path 369" d="M155.961,313.926h.541v-7.245a.7.7,0,0,0-.692-.681H144.692a.7.7,0,0,0-.692.681v7.245h11.961Z" transform="translate(-105.749 -228.493)"/>
                <g id="Group_77" data-name="Group 77" transform="translate(32 64)">
                    <path id="Path_370" data-name="Path 370" d="M53.869,67.74H52.15V64.82a.856.856,0,0,0-.887-.82H37.743a.856.856,0,0,0-.887.82V67.74H35.137A3.34,3.34,0,0,0,32,70.787V77.53c0,1.518,1.58,3.444,3.137,3.444h1.021c.274,0,.307,0,.307-.781v-2.4A1.788,1.788,0,0,1,38.3,76.006H50.8c.988,0,1.736,1.189,1.736,2.233v1.954c0,.781.006.781.279.781h1.044a3.294,3.294,0,0,0,3.137-3V70.787A3.33,3.33,0,0,0,53.869,67.74Zm-1.328,3.4a.893.893,0,1,1,.893-.893A.9.9,0,0,1,52.54,71.144Z" transform="translate(-32 -64)"/>
                </g>
                </g>
            </svg>
        </div>
    </div>
    <div class=" w-full maxh-550-px overflow-x-auto w-table pr-20">
        <div class="w-full flex items-center justify-between space-x-3 pr-5 text-sm ">
            <div class="flex items-center pl-6" >
                <div class="orange-text text-xs" >Show</div>
                <input class="orange-input focus:outline-none text-center mx-1 w-25-px  text-xs p-1" type="number" >
                <div class="orange-text text-xs" >entries</div>
            </div>
            <div class="flex items-center space-x-3 "  >
                <div class="orange-text text-xs" >Search:</div>
                <input class="orange-input focus:outline-none pl-1 text-xs p-1" type="text" >
            </div>
        </div>
        <div class="data-table4 mt-3 barlow  m-5  "  style="font-size: 10px;">
            <table class="w-full" >
                <thead class="bg-light-orange "> 
                    <tr class="header-row" style="border-left: 1px #CAC2BC solid; border-right: 1px #CAC2BC solid;">
                        <!-- <th></th> -->
                        <th class="text-left barlow orange-text pl-2 pt-3 pb-3">
                            DATE/TIME
                        </th>
                        <th class="text-left barlow orange-text pl-2 pt-3 pb-3">
                            NAME
                        </th>
                        <th class="text-left barlow orange-text pl-2 pt-3 pb-3">
                            PHONE NUMBER
                        </th>
                        <th class="text-left barlow orange-text pl-2 pt-3 pb-3">
                            ACTION
                        </th>
                        <th class="text-left barlow orange-text pl-2 pt-3 pb-3">
                            REASON
                        </th>
                        <th class="text-left barlow orange-text pl-2 pt-3 pb-3">
                            REVIEW
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-row-not-expanded focus:outline-none" style="border-left: 1px #CAC2BC solid; border-right: 1px #CAC2BC solid; color: red;">
                        <td >
                            <div class="flex items-center ">
                                <button class="material-icons lock-unlock w-30-px  h-full focus:outline-none">
                                    <img src="assets/images/icon/minus icon (red).png" >
                                </button>
                                2021-05-18 13:01:27
                            </div>
                        </td>
                        <td >Lorem Ipsum</td>
                        <td >09876787654</td>
                        <td >Auto-Disable</td>
                        <td >Lorem Ipsum</td>
                        <td >Lorem Ipsum</td>
                    </tr>
                    <tr class="table-row-not-expanded focus:outline-none" style="border-left: 1px #CAC2BC solid; border-right: 1px #CAC2BC solid;">
                        <td >
                            Remarks Used different cards (24 hours)
                        </td>
                    </tr>
                    <tr class="table-row-not-expanded focus:outline-none" style="border-left: 1px #CAC2BC solid; border-right: 1px #CAC2BC solid;  color: red;"
                    *ngFor="let item of [1,2,3,4,5,6]">
                        <td >
                            <div class="flex items-center ">
                                <button class="material-icons lock-unlock w-30-px  h-full focus:outline-none">
                                    <img src="assets/images/icon/add icon (blue).png" >
                                </button>
                                2021-05-18 13:01:27
                            </div>
                        </td>
                        <td >Lorem Ipsum</td>
                        <td >09876787654</td>
                        <td >Auto-Disable</td>
                        <td >Lorem Ipsum</td>
                        <td >Lorem Ipsum</td>
                    </tr>
                </tbody>
            </table>
            <div class="col-span-full flex justify-between items-center mt-2">
                <div class="orange-text text-xs pl-6" >
                    showing 1 to 10 of 400 entree
                </div>
                <div class="flex items-center justify-end table-pagination text-xs" style="text-align: right;  ">
                    <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;">
                        Previous 
                    </button> 
                    <button class="page-btn sarabun-bold orange-box px-2 py-1 active" style="white-space: nowrap;">
                        1 
                    </button> 
                    <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;">
                        2 
                    </button> 
                    <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;">
                        3 
                    </button> 
                    <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;">
                        4 
                    </button> 
                    <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;">
                        5 
                    </button> 
                    <button class="page-btn sarabun-bold orange-box px-2 py-1" style="white-space: nowrap;">
                        Next 
                    </button> 
              </div>
            </div>
        </div>
    </div>
</div>
