import { Component, OnInit ,  VERSION} from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DebounceService } from 'src/app/services/debounce.service';

@Component({
  selector: 'app-sku-redeem',
  templateUrl: './sku-redeem.component.html',
  styleUrls: ['./sku-redeem.component.scss']
})
export class SkuRedeemComponent implements OnInit {
  public redeemForm: FormGroup;

   //add SKU
   isDisplay= true;
   toggleDisplay(){
     this.isDisplay= 
     !this.isDisplay;
   }

    // category
  category:any;
  category_text:any;


  constructor(
    private fb : FormBuilder,
    public debounce: DebounceService
  ) { 
    this.initializeForm();
  }

  ngOnInit(): void {
    var tableHeader = document.getElementsByTagName("th");  
    for (var i = 0; i < tableHeader.length; i++) { 
      tableHeader[i].classList.add("headerSortDown");
    }
  }
  initializeForm(){
    this.redeemForm = this.fb.group({
      description: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
    })
  }

  setheader(index){
    localStorage.setItem('navigate', index);
  } 
  
  setCategoryType(text,value){
    this.category=value;
    this.category_text=text;
  }
}
