import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login/login.component';
import { TemplateComponent } from './layout/template/template.component';
import { ChangePassComponent } from './component/change-pass/change-pass.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { TransactionsComponent } from './component/transactions/transactions.component';
import { AccountsComponent } from './component/accounts/accounts.component';
import { AccountViewComponent } from './component/account-view/account-view.component';
import { KycComponent } from './component/kyc/kyc.component';
import { MegapayFeaturesComponent } from './component/megapay-features/megapay-features.component';
import { FeaturesBillerComponent } from './component/features-biller/features-biller.component';
import { FeaturesPushNotifComponent } from './component/features-push-notif/features-push-notif.component';
import { FeaturesAnnouncementComponent } from './component/features-announcement/features-announcement.component';
import { AdsDashboardComponent } from './component/ads-dashboard/ads-dashboard.component';
import { FeaturesProfanityFilterComponent } from './component/features-profanity-filter/features-profanity-filter.component';
import { FeaturesAutoDisableLogsComponent } from './component/features-auto-disable-logs/features-auto-disable-logs.component';
import { FeaturesRdpdDashboardComponent } from './component/features-rdpd-dashboard/features-rdpd-dashboard.component';
import { FeaturesReferalComponent } from './component/features-referal/features-referal.component';
import { CashinOutComponent } from './component/cashin-out/cashin-out.component';
import { AdminComponent } from './component/admin/admin.component';
import { AdminTpLogsComponent } from './component/admin-tp-logs/admin-tp-logs.component';
import { AdminSystemMaintenanceComponent } from './component/admin-system-maintenance/admin-system-maintenance.component';
import { KycFormComponent } from './component/kyc-form/kyc-form.component';
import { AuthGuard } from './auth.guard';
import { EditAdsScreenComponent } from './component/edit-ads-screen/edit-ads-screen.component';
import { EditAdsLinkComponent } from './component/edit-ads-link/edit-ads-link.component';
import { AdminPortalAccountsComponent } from './component/admin-portal-accounts/admin-portal-accounts.component'
import { AdminPortalCreateAccountComponent } from './component/admin-portal-create-account/admin-portal-create-account.component';
import { AdminPortalModifyAccountComponent } from './component/admin-portal-modify-account/admin-portal-modify-account.component';
import { AccountsEditComponent } from './component/accounts-edit/accounts-edit.component';
import { AdminPortalUserLogsComponent } from './component/admin-portal-user-logs/admin-portal-user-logs.component';
import { ReportsComponent } from './component/reports/reports.component';
import { ReportsSummaryComponent } from './component/reports-summary/reports-summary.component';
import { ReportsDailySalesComponent } from './component/reports-daily-sales/reports-daily-sales.component';
import { ReportsTopUserComponent } from './component/reports-top-user/reports-top-user.component';
import { ReportsReferalComissionComponent } from './component/reports-referal-comission/reports-referal-comission.component';
import { ReportsBranchComissionComponent } from './component/reports-branch-comission/reports-branch-comission.component';
import { FeaturesAnnouncementEditComponent } from './component/features-announcement-edit/features-announcement-edit.component';
import { SkuBillerAllTransactionEditComponent } from './component/sku-biller-all-transaction-edit/sku-biller-all-transaction-edit.component';
import { SkuBillerAllTransactionComponent } from './component/sku-biller-all-transaction/sku-biller-all-transaction.component';
import { SkuStoresComponent } from './component/sku-stores/sku-stores.component';
import { SkuStoresEditComponent } from './component/sku-stores-edit/sku-stores-edit.component';
import { SkuPaybillsEditComponent } from './component/sku-paybills-edit/sku-paybills-edit.component';
import { SkuPayBillsComponent } from './component/sku-pay-bills/sku-pay-bills.component';
import { SkuRedeemComponent } from './component/sku-redeem/sku-redeem.component';
import { AllTransactionComponent } from './component/all-transaction/all-transaction.component';
import { KycRegistrationComponent } from './component/kyc-registration/kyc-registration.component';
import { KycLogsComponent } from './component/kyc-logs/kyc-logs.component';
import { BuyLoadComponent } from './component/buy-load/buy-load.component';
import { AdminBuyloadComponent } from './component/admin-buyload/admin-buyload.component';
import { AdminTpRedeemComponent } from './component/admin-tp-redeem/admin-tp-redeem.component';
import { AdminTpStoresComponent } from './component/admin-tp-stores/admin-tp-stores.component';
import { AdminTpCashInComponent } from './component/admin-tp-cash-in/admin-tp-cash-in.component';
import { AdminTpPayBillsComponent } from './component/admin-tp-pay-bills/admin-tp-pay-bills.component';
import { CashInTransactionLogsComponent } from './component/cash-in-transaction-logs/cash-in-transaction-logs.component';
import { CashInTransactionComponent } from './component/cash-in-transaction/cash-in-transaction.component';
import { CashOutTransactionComponent } from './component/cash-out-transaction/cash-out-transaction.component';
import { CashInComponent } from './component/cash-in/cash-in.component';
import { PayBillsComponent } from './component/pay-bills/pay-bills.component';
import { TransferFundsComponent } from './component/transfer-funds/transfer-funds.component';
import { StorePurchaseComponent } from './component/store-purchase/store-purchase.component';
import { MegaRewardComponent } from './component/mega-reward/mega-reward.component';
import { PromoComponent } from './component/promo/promo.component';
import { OverviewComponent } from './component/overview/overview.component';
import { AdsDashboardScreenComponent } from './component/ads-dashboard-screen/ads-dashboard-screen.component';
import { AdsDashboardLinkComponent } from './component/ads-dashboard-link/ads-dashboard-link.component';
import { SkuCashInEditComponent } from './component/sku-cash-in-edit/sku-cash-in-edit.component';
import { SkuCashInComponent } from './component/sku-cash-in/sku-cash-in.component';
import { SendPassComponent } from './component/send-pass/send-pass.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent},
  { path: '', component: TemplateComponent, canActivate:[AuthGuard],
    children: [
      { path: '',
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'dashboard'},
          { path: 'dashboard', component: DashboardComponent },
          { path: 'transactions', component: TransactionsComponent },
          { path: 'transactions/all-transaction', component: AllTransactionComponent },
          { path: 'transactions/buy-load', component: BuyLoadComponent },
          { path: 'transactions/cash-in', component: CashInComponent },
          { path: 'transactions/pay-bills', component: PayBillsComponent },
          { path: 'transactions/transfer-funds', component: TransferFundsComponent },
          { path: 'transactions/store-purchase', component: StorePurchaseComponent },
          { path: 'transactions/mega-reward', component: MegaRewardComponent },
          { path: 'transactions/promo', component: PromoComponent },
          { path: 'transactions/overview', component: OverviewComponent },
          { path: 'all-transaction', component: AllTransactionComponent },
          { path: 'accounts', component: AccountsComponent },
          // { path: 'search/:searchTerm', component: AccountsComponent },
          { path: 'accounts/view/:id', component: AccountViewComponent },
          { path: 'accounts/accounts-edit/:id', component: AccountsEditComponent},
          { path: 'kyc', component: KycComponent },
          { path: 'kyc/form/:id', component: KycFormComponent },
          { path: 'kyc/kyc-registration', component: KycRegistrationComponent},
          { path: 'kyc/kyc-logs', component: KycLogsComponent},

          {path: 'features/biller-dashboard/sku-cash-in/sku-cash-in-edit/:id', component:SkuCashInEditComponent},
          { path: 'features/sku-cash-in', component:SkuCashInComponent},
          { path: 'features/biller-dashboard/sku-biller-all-transaction/sku-biller-all-transaction-edit/:id', component: SkuBillerAllTransactionEditComponent},
          { path: 'features/biller-dashboard/sku-biller-all-transaction', component: SkuBillerAllTransactionComponent},
          { path: 'features/biller-dashboard/sku-pay-bills/sku-paybills-edit/:id', component: SkuPaybillsEditComponent},
          { path: 'features/biller-dashboard/sku-stores/sku-stores-edit/:id', component: SkuStoresEditComponent},
          { path: 'features/sku-biller-all-transaction', component: SkuBillerAllTransactionComponent},
          { path: 'features/sku-pay-bills', component: SkuPayBillsComponent},
          { path: 'features/sku-stores', component: SkuStoresComponent},
          { path: 'features/sku-redeem', component: SkuRedeemComponent},
          { path: 'admin/admin-buyload', component:AdminBuyloadComponent },
          { path: 'admin/admin-tp-pay-bills', component:AdminTpPayBillsComponent },
          { path: 'admin/admin-tp-cash-in', component:AdminTpCashInComponent },
          { path: 'admin/admin-tp-stores', component:AdminTpStoresComponent },
          { path: 'admin/admin-tp-redeem', component:AdminTpRedeemComponent },
          { path: 'features/ads-dashboard-screen',component:AdsDashboardScreenComponent},
          { path: 'features/ads-dashboard-link',component:AdsDashboardLinkComponent},
          { path: 'features', component: MegapayFeaturesComponent, children:[
          { path: 'features', pathMatch: 'full', redirectTo: 'biller-dashboard'},
          { path: 'biller-dashboard', component: FeaturesBillerComponent },
          { path: 'push-notification', component: FeaturesPushNotifComponent },
          { path: 'announcement', component: FeaturesAnnouncementComponent },
          { path: 'announcement/announcement-edit/:id', component: FeaturesAnnouncementEditComponent },
          { path: 'ads-dashboard', component: AdsDashboardComponent },
          { path: 'edit-ads-screen/:id', component: EditAdsScreenComponent },
          { path: 'edit-ads-link/:id', component: EditAdsLinkComponent },
          { path: 'profanity-filter', component: FeaturesProfanityFilterComponent },
          { path: 'disable-logs', component: FeaturesAutoDisableLogsComponent },
          { path: 'rd-pd-dashboard', component: FeaturesRdpdDashboardComponent },
          { path: 'referrals', component: FeaturesReferalComponent },
        ]},
        { path: 'cash-in-out', component: CashinOutComponent },
        { path: 'cash-in-tranaction-logs', component: CashInTransactionLogsComponent},
        { path: 'cash-in-tranaction',component: CashInTransactionComponent},
        { path: 'cash-out-tranaction', component: CashOutTransactionComponent},

        { path: 'admin', component: AdminComponent, children:[
          { path: 'admin', pathMatch: 'full', redirectTo: 'third-party-log'},
          { path: 'third-party-log', component: AdminTpLogsComponent },
          { path: 'system-maintenance', component: AdminSystemMaintenanceComponent },
          { path: 'portal-account', component: AdminPortalAccountsComponent },
          { path: 'portal-account/portal-create-account', component: AdminPortalCreateAccountComponent },
          { path: 'portal-account/portal-modify-account/:id', component: AdminPortalModifyAccountComponent },
          { path: 'portal-user-logs', component: AdminPortalUserLogsComponent },
        ]},
        { path: 'reports', component:ReportsComponent, children:[
          { path:'reports', pathMatch:'full',redirectTo: 'reports-summary'},
          { path:'reports-summary', component:ReportsSummaryComponent},
          { path: 'reports-daily-sales', component:ReportsDailySalesComponent},
          { path: 'reports-top-user', component:ReportsTopUserComponent},
          { path: 'reports-referal-comission', component:ReportsReferalComissionComponent},
          { path: 'reports-branch-comission', component:ReportsBranchComissionComponent}
        ]}

        ]
      }
    ]
  },
  { path: 'change-pass', component: ChangePassComponent},
  { path: 'send-pass', component: SendPassComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
