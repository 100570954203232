import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { RequestsService } from 'src/app/services/http/requests.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { DebounceService } from 'src/app/services/debounce.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'src/app/services/dialog.service';
import { Urls } from 'src/app/lib/urls';

@Component({
  selector: 'app-sku-paybills-edit',
  templateUrl: './sku-paybills-edit.component.html',
  styleUrls: ['./sku-paybills-edit.component.scss']
})
export class SkuPaybillsEditComponent implements OnInit {
  public updateForm: FormGroup;
  isLoading: boolean;
  selected_redirection:string | Blob;
  uid:any;

 // Biller Category
 billerCategory_text:any;
 billerCategory:any;

 // First Field Format
 firstFieldFormat_text:any;
 firstFieldFormat:any;

 //Second Field Format
 secondFieldFormat_text:any;
 secondFieldFormat:any;

  
  constructor(
    private modal: NgxSmartModalService,
    private route: ActivatedRoute,
    private router: Router,
    private fb : FormBuilder,
    private request: RequestsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private dialogService:DialogService,
    public userService: UserService,
    public debounce: DebounceService,
  ) {
    this.initializeForm()
   }

  ngOnInit(): void {
    this.uid = this.route.snapshot.paramMap.get('id');
    this.get_billers();
  }
  initializeForm(){
    this.updateForm = this.fb.group({
      billerName: [''],
      billerDescription: [''],
      firstFieldName: [''],
      secondFieldName: [''],
      firstFieldWidth: [''],
      secondFieldWidth: [''],
      convenienceFee: [''],
     
    });
  }
  get_billers(){
    let formData: FormData = new FormData(); 
    formData.append('uid', this.uid); 
    this.request.put(Urls.billers + '/' + this.uid,formData).then(response => {
      this.updateForm = this.fb.group({
        billerName:(response['result']['billerName']),
        billerDescription:(response['result']['billerDescription']),
        firstFieldName:(response['result']['firstFieldName']),
        secondFieldName:(response['result']['secondFieldName']),
        firstFieldWidth:(response['result']['firstFieldWidth']),
        secondFieldWidth:(response['result']['secondFieldWidth']),
        convenienceFee:(response['result']['convenienceFee']),
      });
      this.billerCategory=response['result']['billerCategory'];
      if(response['result']['billerCategory']==2){ 
        this.billerCategory_text='Telecom ';
      }else if(response['result']['billerCategory']==3){ 
        this.billerCategory_text='Credit Card';
      }else if(response['result']['billerCategory']==4){ 
        this.billerCategory_text='Cable and Internet';
      }else if(response['result']['billerCategory']==5){ 
        this.billerCategory_text='Government';
      }else if(response['result']['billerCategory']==6){ 
        this.billerCategory_text='Loans';
      }else if(response['result']['billerCategory']==7){ 
        this.billerCategory_text='Other';
      }else if(response['result']['billerCategory']==8){ 
        this.billerCategory_text='Travel';
      }else if(response['result']['billerCategory']==9){ 
        this.billerCategory_text='Transpo';
      }else if(response['result']['billerCategory']==10){ 
        this.billerCategory_text='Shopping';
      }else if(response['result']['billerCategory']==11){ 
        this.billerCategory_text='Power';
      }else if(response['result']['billerCategory']==12){ 
        this.billerCategory_text='Water';
      }
      this.firstFieldFormat=response['result']['firstFieldFormat'];
      if(response['result']['firstFieldFormat']==1){ 
        this.firstFieldFormat_text='Numeric ';
      }else if(response['result']['firstFieldFormat']==2){ 
        this.firstFieldFormat_text='Alphanumeric';
      }
      this.secondFieldFormat=response['result']['secondFieldFormat'];
      if(response['result']['secondFieldFormat']==1){ 
        this.secondFieldFormat_text='Numeric ';
      }else if(response['result']['secondFieldFormat']==2){ 
        this.secondFieldFormat_text='Alphanumeric';
      }
    }) 
  }

  
  update(){
    
      this.spinner.show();
      this.updateForm.markAllAsTouched();
      let formData = new FormData();
      formData.append('billerName', this.updateForm.controls.billerName.value);
      formData.append('billerDescription', this.updateForm.controls.billerDescription.value);
      formData.append('firstFieldName', this.updateForm.controls.firstFieldName.value);
      formData.append('secondFieldName', this.updateForm.controls.secondFieldName.value);
      formData.append('firstFieldWidth', this.updateForm.controls.firstFieldWidth.value);
      formData.append('secondFieldWidth', this.updateForm.controls.secondFieldWidth.value);
      formData.append('convenienceFee', this.updateForm.controls.convenienceFee.value);
      formData.append('billerCategory', this.billerCategory);
      formData.append('firstFieldFormat', this.firstFieldFormat);
      formData.append('secondFieldFormat', this.secondFieldFormat);
      this.request.put(Urls.billers + '/' + this.uid, formData).then(response => {
        console.log(response);
        if(response['status']==200){
          this.toastr.success(response['message']+'!', 'Success!');
          this.router.navigate(['features/sku-pay-bills']);
        }
        else{
          this.toastr.error(response['error']['message']+'!', 'Something went wrong!');
        }
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      });
      this.updateForm.reset();
      
    }

  setBillerCategory(text,value){
      this.billerCategory=value;
      this.billerCategory_text=text;
  }
  setFirstFieldFormat(text,value){
    this.firstFieldFormat=value;
    this.firstFieldFormat_text=text;
  }
  setSecondFieldFormat(text,value){
    this.secondFieldFormat=value;
    this.secondFieldFormat_text=text;
  }
}
