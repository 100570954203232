import { Component, OnInit,VERSION } from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { RequestsService } from 'src/app/services/http/requests.service';
import { Urls } from 'src/app/lib/urls';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-accounts-information',
  templateUrl: './accounts-information.component.html',
  styleUrls: ['./accounts-information.component.scss']
})
export class AccountsInformationComponent implements OnInit {
  uid:any;
  firstName:any;
  lastName:any;
  middleName:any;
  userName:any;
  email:any;
  createDate:any;
  referrerNumber:any;
  cardNo:any;
  cardHolderName:any;
  cardEmail:any;
  time:any;
  abcLists:any;
  referrerData:any;

  //tiertype
  tierType_text:any;
  tierId:any;

  //status
  status_text:any;
  status:any;

  //emailverified
  emailVerified_text:any;
  emailVerified:any;

  //null with default
  name = 'Angular ' + VERSION.major;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb : FormBuilder,
    private request: RequestsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.uid = this.route.snapshot.paramMap.get('id');
    this.get_user(); 
    this.getAttachedBankCards();
  }

  get_user(){
    this.request.get(Urls.userAccount + '/' + this.uid, ).then(response => { 
      console.log(response);
      this.firstName=response['result']['firstName'];
      this.lastName=response['result']['lastName'];
      this.middleName=response['result']['middleName']; 
      this.userName=response['result']['userName']; 
      this.email=response['result']['email']; 
      this.createDate=response['result']['createDate']; 
      this.referrerNumber=response['result']['referrerNumber']; 
      //tier
      this.tierId=response['result']['tierId']; 
      if(response['result']['tierId']==1){ 
        this.tierType_text='Default';
      }else if (response['result']['tierId']==4) {
        this.tierType_text=' Full';
      }else if (response['result']['tierId']==5) {
        this.tierType_text='Internal';
      }else if (response['result']['tierId']==6) {
        this.tierType_text='Business';
      }else if (response['result']['tierId']==7) {
        this.tierType_text='MegaBiz';
      }else {
        this.tierType_text='MegaOne';
      }
      //status
      this.status=response['result']['status']; 
      if(response['result']['status']==0){ 
        this.status_text='Yes';
      }else {
        this.status_text='No';
      }
      //emailVerified
      this.emailVerified=response['result']['emailVerified']; 
      if(response['result']['emailVerified']==0){ 
        this.emailVerified_text='Yes';
      }else {
        this.emailVerified_text='No';
      }
      //referrerNum
      let referrerNumber: string = response.result.referrerNumber;
      if (referrerNumber !== null && referrerNumber.length > 0) {
        console.log(referrerNumber);
        this.request.post(Urls.userAccount + '?page=1&size=1', {
          userName: referrerNumber
        }).then(response => {
          this.referrerData = response['result']['data'];
        })  
      }
    }) 
  }

  getAttachedBankCards(){
    this.request.get(Urls.userAccount + '/' + this.uid, ).then(response => { 
     this.abcLists=response['result']['attachedBankCards'];
   }) 
  }
}
