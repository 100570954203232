<div class="w-full h-full">
    <div class="flex items-center justify-between space-x-3 pt-9 text-sm">
        <div class="flex items-center space-x-3 text-xs"></div>
        <div class="flex items-center space-x-3 text-xs"></div>
        <div class="flex items-center space-x-3 pr-10"  >
            <svg class="cursor-pointer " xmlns="http://www.w3.org/2000/svg" width="20" height="21.433" viewBox="0 0 25 21.433" >
                <g id="iconfinder_icon-printer_211880" transform="translate(-32 -64)">
                <path id="Path_369" data-name="Path 369" d="M155.961,313.926h.541v-7.245a.7.7,0,0,0-.692-.681H144.692a.7.7,0,0,0-.692.681v7.245h11.961Z" transform="translate(-105.749 -228.493)"/>
                <g id="Group_77" data-name="Group 77" transform="translate(32 64)">
                    <path id="Path_370" data-name="Path 370" d="M53.869,67.74H52.15V64.82a.856.856,0,0,0-.887-.82H37.743a.856.856,0,0,0-.887.82V67.74H35.137A3.34,3.34,0,0,0,32,70.787V77.53c0,1.518,1.58,3.444,3.137,3.444h1.021c.274,0,.307,0,.307-.781v-2.4A1.788,1.788,0,0,1,38.3,76.006H50.8c.988,0,1.736,1.189,1.736,2.233v1.954c0,.781.006.781.279.781h1.044a3.294,3.294,0,0,0,3.137-3V70.787A3.33,3.33,0,0,0,53.869,67.74Zm-1.328,3.4a.893.893,0,1,1,.893-.893A.9.9,0,0,1,52.54,71.144Z" transform="translate(-32 -64)"/>
                </g>
                </g>
            </svg>
        </div>
    </div>
    <div class="w-900-px mx-auto">
        <div class="grid grid-cols-12 gap-6">
            <div class="col-span-6 space-y-5">
                <div class="card-input flex items-start text-xs py-2 w-full">
                    <span class="text-xs w-300-px font-semibold">
                        Restrict the following words:
                    </span>
                    <textarea class="placeholder-black focus:outline-none w-full h-90-px bg-gray-100 rounded-md pl-3 resize-none italic" placeholder="Description (optional)" name="" id="" cols="30" rows="10"></textarea>
                </div>
            </div>
            <div class="col-span-6 space-y-5">
                <div class="card-input flex items-start text-xs py-2 w-full">
                    <span class="text-xs w-300-px font-semibold">
                        Allow the following words:
                    </span>
                    <textarea class="placeholder-black focus:outline-none w-full h-90-px bg-gray-100 rounded-md pl-3 resize-none italic" placeholder="Description (optional)" name="" id="" cols="30" rows="10"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="w-full flex items-center justify-center mt-6 mb-4 ">
        <button class="uppercase custom-btn btn-dark-orange font-semibold text-xs px-6 py-2">
            Add
        </button>
    </div>
    <div class="w-700-px mx-auto mt-8">
        <div class="text-m font-semibold">List of prohibited words:</div>
        <ul class="px-7 mt-3">
            <div class="grid grid-cols-3 gap-1">
                <div class="col-span-1" *ngFor="let item of [1,2,3,4,5,6,7,8,9,10]">
                    <li class="text-xs">Words</li>
                </div>
            </div>
        </ul>
    </div>
</div>